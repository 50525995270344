import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";

export const CallTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Username",
    },
    {
      id: "loginTime",
      numeric: false,
      disablePadding: false,
      label: "Login Time",
    },
    {
      id: "activeHoursLogged",
      numeric: false,
      disablePadding: false,
      label: "Active Hours Logged",
    },
    {
      id: "utilizationTime",
      numeric: false,
      disablePadding: false,
      label: "Utilization Time",
    },
    {
      id: "calls",
      numeric: false,
      disablePadding: false,
      label: "Calls",
    },
    {
      id: "idleTime",
      numeric: false,
      disablePadding: false,
      label: "Idle Time",
    },
    {
      id: "workDay",
      numeric: false,
      disablePadding: false,
      label: "Work Day",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "Work Day" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CallTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
