import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import {
  CALL_RESULT,
  CATEGORY_IN_OBJ,
  PROSPECTS__KEYS,
} from "../../../helpers";
import Popper from "@mui/material/Popper";
import { useSelector } from "react-redux";

export const MassUpdate = ({
  open,
  onClose,
  onSubmit,
  options,
  fieldOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [textValue, setTextValue] = useState("");
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [inputType, setInputType] = useState("text");

  const campaignNames = useSelector(
    (state) => state?.campaign?.campaignNamesWithID?.results
  );

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: 3,
    borderColor: "#6DD6C2",
    p: 3,
    borderRadius: "7px",
    "&:focus-visible": {
      outline: "0",
    },
  };

  const optionError = triedSubmit && selectedOption?.trim().length === 0;

  const optionErrorMessage =
    triedSubmit && selectedOption?.trim().length === 0
      ? "Please select field"
      : "";

  const handleFormSubmit = () => {
    setTriedSubmit(true);

    // Validate the text field
    if (textValue?.toString()?.trim().length === 0 || optionError) {
      return;
    }
    onSubmit(selectedOption, textValue);
    setTriedSubmit(false);
  };
  const handleChange = (event, newValue) => {
    let inputType;
    switch (newValue.value) {
      case "renewal_date":
        inputType = "date";
        break;
      case "call_result":
        inputType = "callResult";
        break;
      case "agency_calling_for":
        inputType = "agency_calling_for";
        break;
      case "auto_renewal_date":
        inputType = "auto_renewal_date";
        break;
      case "liability_renewable_date":
        inputType = "liability_renewable_date";
        break;
      case "next_renewable_date":
        inputType = "next_renewable_date";
        break;
      case "workers_comp_renewal_date":
        inputType = "workers_comp_renewal_date";
        break;
      case "category":
        inputType = "category";
        break;
      default:
        inputType = "text";
        break;
    }
    setInputType(inputType);
    setSelectedOption(newValue.value);
  };

  const handleOnChange = (event, newValue) => {
    setTextValue(newValue.id);
  };

  //   const callResultError =
  //     triedSubmit && additionalData?.callResult?.toString().trim().length === 0;

  //   const callResultErrorMessage =
  //     triedSubmit && additionalData?.callResult?.toString().trim().length === 0
  //       ? "Please select call result"
  //       : "";

  const renderInputs = () => {
    let inputField;

    switch (inputType) {
      case "callResult":
        inputField = (
          <Autocomplete
            id="combo-box-demo"
            options={callResult}
            getOptionLabel={(option) => option.description}
            onChange={(event, newValue) => {
              handleOnChange(event, newValue);
            }}
            size="small"
            error={triedSubmit && textValue?.toString()?.trim()?.length === 0}
            disableClearable={true}
            sx={{
              zIndex: 9999,
              width: 500,
              marginBottom: 2,
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#18a689!important",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#18a689",
                },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#18a689",
              },
            }}
            InputLabelProps={{
              sx: {
                "&.Mui-focused": {
                  color: "#6DD6C2",
                },
              },
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Select field value" />
                {triedSubmit && textValue?.toString()?.trim()?.length === 0 && (
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {"Please select any option"}
                  </FormHelperText>
                )}
              </>
            )}
            PopperComponent={({ children, ...other }) => (
              <Popper {...other} style={{ zIndex: 10000, width: 500 }}>
                {children}
              </Popper>
            )}
          />
        );
        break;
      case "agency_calling_for":
        inputField = (
          <Autocomplete
            id="combo-box-demo"
            options={campaignNames}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              handleOnChange(event, newValue);
            }}
            size="small"
            error={triedSubmit && textValue?.toString()?.trim()?.length === 0}
            disableClearable={true}
            sx={{
              zIndex: 9999,
              width: 500,
              marginBottom: 2,
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#18a689!important",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#18a689",
                },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#18a689",
              },
            }}
            InputLabelProps={{
              sx: {
                "&.Mui-focused": {
                  color: "#6DD6C2",
                },
              },
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Select field value" />
                {triedSubmit && textValue?.toString()?.trim()?.length === 0 && (
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {"Please select any option"}
                  </FormHelperText>
                )}
              </>
            )}
            PopperComponent={({ children, ...other }) => (
              <Popper {...other} style={{ zIndex: 10000, width: 500 }}>
                {children}
              </Popper>
            )}
          />
        );
        break;
      case "auto_renewal_date":
      case "liability_renewable_date":
      case "next_renewable_date":
      case "workers_comp_renewal_date":
        inputField = (
          <TextField
            name="update"
            type="date"
            value={textValue}
            onChange={handleTextChange}
            error={triedSubmit && textValue?.trim().length === 0}
            helperText={
              triedSubmit && textValue?.trim().length === 0
                ? "Please enter valid field value"
                : ""
            }
            size="small"
            InputLabelProps={{
              sx: {
                fontSize: "15px",
                "&.Mui-focused": {
                  color: "#5fdbc4",
                  fontWeight: 600,
                },
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2!important",
                },
              width: "100%",
              maxWidth: "100%",
            }}
          />
        );
        break;
      case "category":
        inputField = (
          <Autocomplete
            id="combo-box-demo"
            options={CATEGORY_IN_OBJ}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              handleOnChange(event, newValue);
            }}
            size="small"
            error={triedSubmit && textValue?.toString()?.trim()?.length === 0}
            disableClearable={true}
            sx={{
              zIndex: 9999,
              width: 500,
              marginBottom: 2,
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#18a689!important",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#18a689",
                },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#18a689",
              },
            }}
            InputLabelProps={{
              sx: {
                "&.Mui-focused": {
                  color: "#6DD6C2",
                },
              },
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Select field value" />
                {triedSubmit && textValue?.toString()?.trim()?.length === 0 && (
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {"Please select any option"}
                  </FormHelperText>
                )}
              </>
            )}
            PopperComponent={({ children, ...other }) => (
              <Popper {...other} style={{ zIndex: 10000, width: 500 }}>
                {children}
              </Popper>
            )}
          />
        );
        break;
      default:
        inputField = (
          <TextField
            name="update-value"
            type={inputType}
            value={textValue}
            onChange={handleTextChange}
            label={inputType === "date" ? "" : "New value"}
            error={triedSubmit && textValue?.trim().length === 0}
            helperText={
              triedSubmit && textValue?.trim().length === 0
                ? "Please enter valid field value"
                : ""
            }
            size="small"
            InputLabelProps={{
              sx: {
                fontSize: "15px",
                "&.Mui-focused": {
                  color: "#5fdbc4",
                  fontWeight: 600,
                },
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2!important",
                },
              width: "100%",
              maxWidth: "100%",
            }}
          />
        );
    }

    return inputField;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setTriedSubmit(false);
        setInputType("text");
        setTextValue("");
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 9999,
      }}
    >
      <Box sx={style}>
        <Typography
          component="h2"
          variant="h2"
          sx={{
            fontSize: 18,
            color: "#212223",
            fontWeight: 600,
            backgroundColor: "#90949f21",
            pt: 1.5,
            pl: 1.5,
            pb: 1.5,
            mb: 2.5,
          }}
        >
          Mass Update
        </Typography>
        <Box>
          <Box sx={{ marginTop: "10px", gap: "10px" }}>
            <Box>
              <FormControl
                size="small"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "95%",
                    lg: "95%",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: optionError
                      ? "#d32f2f"
                      : "rgba(0, 0, 0, 0.23)",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#6DD6C2",
                    },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6DD6C2!important",
                  },
                }}
              >
                <Autocomplete
                  id="combo-box-demo"
                  options={fieldOptions}
                  getOptionLabel={(option) => option.key}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  size="small"
                  error={optionError}
                  disableClearable={true}
                  sx={{
                    zIndex: 9999,
                    width: 500,
                    marginBottom: 2,
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#18a689!important",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#18a689",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#6DD6C2",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} label="Select field" />
                      {optionError && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f",
                          }}
                        >
                          {optionErrorMessage}
                        </FormHelperText>
                      )}
                    </>
                  )}
                  PopperComponent={({ children, ...other }) => (
                    <Popper {...other} style={{ zIndex: 10000, width: 500 }}>
                      {children}
                    </Popper>
                  )}
                />
              </FormControl>
              {renderInputs()}
              {/* {inputType === "callResult" ? (
                <Autocomplete
                  id="combo-box-demo"
                  options={CALL_RESULT}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    handleOnChange(event, newValue);
                  }}
                  size="small"
                  error={
                    triedSubmit && textValue?.toString()?.trim()?.length === 0
                  }
                  disableClearable={true}
                  sx={{
                    zIndex: 9999,
                    width: 500,
                    marginBottom: 2,
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#18a689!important",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#18a689",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#6DD6C2",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} label="Select field value" />
                      {triedSubmit &&
                        textValue?.toString()?.trim()?.length === 0 && (
                          <FormHelperText
                            sx={{
                              color: "#d32f2f",
                            }}
                          >
                            {"Please select any option"}
                          </FormHelperText>
                        )}
                    </>
                  )}
                  PopperComponent={({ children, ...other }) => (
                    <Popper {...other} style={{ zIndex: 10000, width: 500 }}>
                      {children}
                    </Popper>
                  )}
                />
              ) : (
                <TextField
                  name="update-value"
                  type={inputType}
                  value={textValue}
                  onChange={handleTextChange}
                  label={inputType === "date" ? "" : "New value"}
                  error={triedSubmit && textValue?.trim().length === 0}
                  helperText={
                    triedSubmit && textValue?.trim().length === 0
                      ? "Please enter valid field value"
                      : ""
                  }
                  size="small"
                  InputLabelProps={{
                    sx: {
                      fontSize: "15px",
                      "&.Mui-focused": {
                        color: "#5fdbc4",
                        fontWeight: 600,
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              )} */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: 1,
            borderColor: "#dcdcdc",
            mt: 3.5,
          }}
        >
          <Button
            variant="contained"
            onClick={handleFormSubmit}
            sx={{
              mt: 2.5,
              mr: 2,
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
          >
            Okay
          </Button>
          <Button
            onClick={() => {
              setTriedSubmit(false);
              setInputType("text");
              onClose();
            }}
            variant="contained"
            sx={{
              mt: 2.5,
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#000",
              borderColor: "#6DD6C2",
              color: "#fff",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#000",
                borderColor: "#6DD6C2",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

MassUpdate.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};
MassUpdate.defaultProps = {
  open: false,
  fieldOptions: [],
  onClose: () => {},
  onSubmit: () => {},
};
