export const CREATE_LEAD_IN_PROGRESS = "CREATE_LEAD_IN_PROGRESS";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_FAILURE = "CREATE_LEAD_FAILURE";

//upload csv
export const UPLOAD_LEAD_CSV_IN_PROGRESS = 'UPLOAD_LEAD_CSV_IN_PROGRESS';
export const UPLOAD_LEAD_CSV_SUCCESS = 'UPLOAD_LEAD_CSV_SUCCESS';
export const UPLOAD_LEAD_CSV_FAILURE = 'UPLOAD_LEAD_CSV_FAILURE';

//lead serach listing
export const GET_LEAD_SEARCH_IN_PROGRESS = "GET_LEAD_SEARCH_IN_PROGRESS";
export const GET_LEAD_SEARCH_SUCCESS = "GET_LEAD_SEARCH_SUCCESS";
export const GET_LEAD_SEARCH_FAILURE = "GET_LEAD_SEARCH_FAILURE";

// Action types for deleting a lead
export const DELETE_LEAD_IN_PROGRESS = 'DELETE_LEAD_IN_PROGRESS';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE';

export const UPDATE_LEAD_KEY_IN_PROGRESS = "UPDATE_LEAD_KEY_IN_PROGRESS";
export const UPDATE_LEAD_KEY_SUCCESS = "UPDATE_LEAD_KEY_SUCCESS";
export const UPDATE_LEAD_KEY_FAILURE = "UPDATE_LEAD_KEY_FAILURE";

export const GET_LEAD_SHOW_IN_PROGRESS = "GET_LEAD_SHOW_IN_PROGRESS";
export const GET_LEAD_SHOW_SUCCESS = "GET_LEAD_SHOW_SUCCESS";
export const GET_LEAD_SHOW_FAILURE = "GET_LEAD_SHOW_FAILURE";

// Action Types for leadMassUpdate
export const LEAD_MASS_UPDATE_IN_PROGRESS = 'LEAD_MASS_UPDATE_IN_PROGRESS';
export const LEAD_MASS_UPDATE_SUCCESS = 'LEAD_MASS_UPDATE_SUCCESS';
export const LEAD_MASS_UPDATE_FAILURE = 'LEAD_MASS_UPDATE_FAILURE';

// ActionTypes
export const CREATE_NEW_NOTE_IN_PROGRESS = "CREATE_NEW_NOTE_IN_PROGRESS";
export const CREATE_NEW_NOTE_SUCCESS = "CREATE_NEW_NOTE_SUCCESS";
export const CREATE_NEW_NOTE_FAILURE = "CREATE_NEW_NOTE_FAILURE";

// ActionTypes
export const GET_ALL_NOTES_IN_PROGRESS = "GET_ALL_NOTES_IN_PROGRESS";
export const GET_ALL_NOTES_SUCCESS = "GET_ALL_NOTES_SUCCESS";
export const GET_ALL_NOTES_FAILURE = "GET_ALL_NOTES_FAILURE";

// ActionTypes
export const DELETE_NOTE_IN_PROGRESS = "DELETE_NOTE_IN_PROGRESS";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";

// ActionTypes
export const RETURN_CREDIT_IN_PROGRESS = "RETURN_CREDIT_IN_PROGRESS";
export const RETURN_CREDIT_SUCCESS = "RETURN_CREDIT_SUCCESS";
export const RETURN_CREDIT_FAILURE = "RETURN_CREDIT_FAILURE";


// ActionTypes for getReturnCredit
export const GET_RETURN_CREDIT_IN_PROGRESS = "GET_RETURN_CREDIT_IN_PROGRESS";
export const GET_RETURN_CREDIT_SUCCESS = "GET_RETURN_CREDIT_SUCCESS";
export const GET_RETURN_CREDIT_FAILURE = "GET_RETURN_CREDIT_FAILURE";


// ActionTypes for updateAppointmentStatus
export const UPDATE_APPOINTMENT_STATUS_IN_PROGRESS = "UPDATE_APPOINTMENT_STATUS_IN_PROGRESS";
export const UPDATE_APPOINTMENT_STATUS_SUCCESS = "UPDATE_APPOINTMENT_STATUS_SUCCESS";
export const UPDATE_APPOINTMENT_STATUS_FAILURE = "UPDATE_APPOINTMENT_STATUS_FAILURE";


// Action types for updating appointment key 
export const UPDATE_APPOINTMENT_KEY_IN_PROGRESS = 'UPDATE_APPOINTMENT_KEY_IN_PROGRESS';
export const UPDATE_APPOINTMENT_KEY_SUCCESS = 'UPDATE_APPOINTMENT_KEY_SUCCESS';
export const UPDATE_APPOINTMENT_KEY_FAILURE = 'UPDATE_APPOINTMENT_KEY_FAILURE';


// Add this to your constants or action types file
export const DELETE_RETURN_CREDIT_IN_PROGRESS = "DELETE_RETURN_CREDIT_IN_PROGRESS";
export const DELETE_RETURN_CREDIT_SUCCESS = "DELETE_RETURN_CREDIT_SUCCESS";
export const DELETE_RETURN_CREDIT_FAILURE = "DELETE_RETURN_CREDIT_FAILURE";


