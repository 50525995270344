import * as React from "react";
import Box from "@mui/material/Box";
import { AppointmentsTable } from "./AppointmentsTable";
import { useSelector } from "react-redux";

export const AppointmentsParentTable = (props) => {
  const {
    clientData,
    onDelete,
    onChangeAppointmentKey,
    onAppointmentStatus,
    onChangeLeadKey,
  } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const createData = (
    operations,
    view,
    status,
    fullName,
    companyName,
    phoneNumber,
    agent,
    agencyName,
    accountName,
    campaign,
    appointmentDate,
    appointmentTime,
    appointmentType,
    createdBy,
    modifiedBy,
    modifiedDateTime,
    alternatePhoneNumber,
    address,
    city,
    state,
    zipCode,
    comment
  ) => {
    return {
      operations,
      view,
      status,
      fullName,
      companyName,
      phoneNumber,
      agent,
      agencyName,
      accountName,
      campaign,
      appointmentDate,
      appointmentTime,
      appointmentType,
      createdBy,
      modifiedBy,
      modifiedDateTime,
      alternatePhoneNumber,
      address,
      city,
      state,
      zipCode,
      comment,
    };
  };

  const rows = [
    createData(
      "Edit",
      "View 1",
      "Active",
      "Marybeth Nausbaum",
      "Nationwide Insurance",
      "610-777-3258",
      "Agent 1",
      "Christine Ramsey Agency",
      "Account 1",
      "Campaign 1",
      "2023-09-01",
      "10:00 AM",
      "Type A",
      "Admin",
      "Admin",
      "2023-08-31 15:30:00",
      "610-555-1234",
      "123 Main St",
      "City 1",
      "State 1",
      "12345",
      "Comment 1"
    ),
    // Add more rows as needed
  ];

  const appointmentList = useSelector(
    (state) => state?.appointment?.appointmentData?.appointmentData
  );

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        operations,
        view,
        status,
        full_name,
        company_name,
        phone_number,
        agent_name,
        agency_name,
        account_name,
        campaign,
        appointment_datetime,
        appointment_type,
        created_by,
        modified_by,
        created_at,
        modified_at,
        alternative_phone_number,
        address,
        city,
        state,
        zip_code,
        comment,
      } = item;

      const user = item;
      return createData(
        user,
        user,
        status,
        full_name,
        company_name,
        phone_number,
        agent_name,
        agency_name,
        account_name,
        campaign,
        appointment_datetime,
        appointment_datetime,
        appointment_type,
        created_by,
        modified_by,
        modified_at,
        alternative_phone_number,
        address,
        city,
        state,
        zip_code,
        comment
      );
    });
  };

  const tableRows = convertApiResponseToRows(appointmentList);

  // Filtered rows based on the filter values
  const filteredRows = rows?.filter((row) => {
    const nameFilter = filterName.trim().toLowerCase();
    const activeFilter =
      filterActive === "all" ||
      (filterActive === "active" && row.isActive) ||
      (filterActive === "inactive" && !row.isActive);
    return row.fullName.toLowerCase().includes(nameFilter) && activeFilter;
  });

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <AppointmentsTable
        rows={tableRows}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        handleActiveFilterChange={handleActiveFilterChange}
        onDelete={onDelete}
        onChangeAppointmentKey={onChangeAppointmentKey}
        onAppointmentStatus={onAppointmentStatus}
        onChangeLeadKey={onChangeLeadKey}
      />
    </Box>
  );
};
