import React, { useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  Slide,
} from "@mui/material";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1ab394", // Green color
    },
    background: {
      default: "#ffffff", // White color
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const QRCodeGenerate = (props) => {
  const { isQrCodeOpen, onCloseQrCode, setIsQrCodeOtpOpen,setIsQrCodeOpen } = props;
  const qrCodeResult = useSelector((state) => state?.loginAuth?.qrCode);
  const handleClose = () => {
    onCloseQrCode();
    setIsQrCodeOpen(false)
  };
  
  const handleQrOtpOpen=()=>{
    setIsQrCodeOpen(false)
    setIsQrCodeOtpOpen(true)
  }
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isQrCodeOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        transitionDuration={{
          enter: 250,
          exit: 250,
        }}
        maxWidth="xs"
        PaperProps={{ style: { overflow: "hidden" } }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          padding={2}
          width={340}
          mx={2}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              color: "#000",
              marginBottom: "5px",
              textAlign: "center",
            }}
          >
            QR Code Scanner
          </Typography>
           {qrCodeResult?.qrUrl && <img loading="lazy" src ={qrCodeResult?.qrUrl} alt ="Qr Code"></img>}

           <Typography variant="subtitle2"> Please scan the QR code to receive the OTP, and then click the 'NEXT' button to proceed.</Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
          
            sx={{
              mt: 1,
              mb: 1,
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              fontWeight: 500,
              padding: "7px",
              borderRadius: "5px",
              textTransform: "uppercase",
              marginBottom: "8px",
              width: "100%",
              display: "block",
              textAlign: "center",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={handleQrOtpOpen}
          >
            Next
          </Button>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
