import * as React from "react";
import Box from "@mui/material/Box";
import { CallTable } from "./CallTable";
import { useSelector } from "react-redux";

export const CallParentTable = React.memo((props) => {
  const { clientData } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let userProduction = useSelector((state) => state?.home?.userProduction);

  const createData = (
    userProduction,
    activeHoursLogged,
    utilizationTime,
    totalHoursLogged,
    calls,
    totalCallsPerHour,
    validStandardLeads,
    validPlusLeads,
    totalPoints,
    validPointsPerHour,
    hourlyEarnings,
    commisionOnly,
    bonus
  ) => {
    return {
      userProduction,
      activeHoursLogged,
      utilizationTime,
      totalHoursLogged,
      calls,
      totalCallsPerHour,
      validStandardLeads,
      validPlusLeads,
      totalPoints,
      validPointsPerHour,
      hourlyEarnings,
      commisionOnly,
      bonus,
    };
  };

  const rows = [
    createData(
      "Andria Smith",
      "6:20:46",
      "10:1:34",
      "7:3:55",
      "207",
      "29.30",
      "0",
      "1",
      "2.1875",
      "0.34",
      "$ 0",
      "$ 115.0",
      "0"
    ),
    createData(
      "Andria Smith",
      "6:20:46",
      "10:1:34",
      "7:3:55",
      "207",
      "29.30",
      "0",
      "1",
      "2.1875",
      "0.34",
      "$ 0",
      "$ 115.0",
      "0"
    ),
    createData(
      "Andria Smith",
      "6:20:46",
      "10:1:34",
      "7:3:55",
      "207",
      "29.30",
      "0",
      "1",
      "2.1875",
      "0.34",
      "$ 0",
      "$ 115.0",
      "0"
    ),
    createData(
      "Andria Smith",
      "6:20:46",
      "10:1:34",
      "7:3:55",
      "207",
      "29.30",
      "0",
      "1",
      "2.1875",
      "0.34",
      "$ 0",
      "$ 115.0",
      "0"
    ),
    createData(
      "Andria Smith",
      "6:20:46",
      "10:1:34",
      "7:3:55",
      "207",
      "29.30",
      "0",
      "1",
      "2.1875",
      "0.34",
      "$ 0",
      "$ 115.0",
      "0"
    ),
  ];

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((item) => {
      const {
        username,
        active_hours_logged,
        total_hours_logged,
        call_counts,
        total_calls_per_hour,
        standard_leads,
        plus_leads,
        total_points,
        valid_point_per_hour,
        hourly_earning,
        commission_only,
        bonus_pay,
      } = item;

      return createData(
        username,
        active_hours_logged,
        "00:00:00",
        total_hours_logged,
        call_counts,
        total_calls_per_hour,
        standard_leads,
        plus_leads,
        total_points,
        valid_point_per_hour,
        hourly_earning,
        commission_only,
        bonus_pay
      );
    });
  };

  const res = userProduction.report ?? [];
  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <CallTable rows={tableRows} filterName={filterName} />
    </Box>
  );
});
