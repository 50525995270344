import {
  LEAD_OWED_REPORT_FAILURE,
  LEAD_OWED_REPORT_IN_PROGRESS,
  LEAD_OWED_REPORT_SUCCESS,
  UPDATE_LEAD_OWN_KEY_FAILURE,
  UPDATE_LEAD_OWN_KEY_IN_PROGRESS,
  UPDATE_LEAD_OWN_KEY_SUCCESS,
  USER_DASHBOARD_PRODUCTION_FAILURE,
  USER_DASHBOARD_PRODUCTION_IN_PROGRESS,
  USER_DASHBOARD_PRODUCTION_SUCCESS,
} from "../actions/home/types";
import initialState from "../initialState";

export const home = (state = initialState.home, action) => {
  switch (action.type) {
    case USER_DASHBOARD_PRODUCTION_IN_PROGRESS:
      return {
        ...state,
        isUserProdLoading: true,
        error: false,
        errorMessage: "",
        userProduction: {},
      };
    case USER_DASHBOARD_PRODUCTION_SUCCESS:
      return {
        ...state,
        isUserProdLoading: false,
        userProduction: action?.data,
        error: false,
        errorMessage: "",
      };
    case USER_DASHBOARD_PRODUCTION_FAILURE:
      return {
        ...state,
        isUserProdLoading: false,
        error: true,
        userProduction: {},
        errorMessage: action?.error?.message,
      };
    case LEAD_OWED_REPORT_IN_PROGRESS:
      return {
        ...state,
        isLeadOwedLoading: true,
        error: false,
        errorMessage: "",
        leadOwed: {},
      };
    case LEAD_OWED_REPORT_SUCCESS:
      return {
        ...state,
        isLeadOwedLoading: false,
        leadOwed: action?.data,
        error: false,
        errorMessage: "",
      };
    case LEAD_OWED_REPORT_FAILURE:
      return {
        ...state,
        isLeadOwedLoading: false,
        error: true,
        leadOwed: {},
        errorMessage: action?.error?.message,
      };
    case UPDATE_LEAD_OWN_KEY_IN_PROGRESS:
      return {
        ...state,
        isleadOwedKeyLoading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_LEAD_OWN_KEY_SUCCESS:
      return {
        ...state,
        isleadOwedKeyLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_LEAD_OWN_KEY_FAILURE:
      return {
        ...state,
        isleadOwedKeyLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
