import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CWTRReport,
  CallHistoryReport,
  CampaignReport,
  OnlineUserReport,
} from "../components/ReportComponent";
import {
  GetCurrentOnlineUsers,
  callHistorySearch,
  campaignReportSearch,
  createCallHistory,
} from "../store/actions/report";
import { GetAllEmployees, getAllCampaigns } from "../store/actions";
import { UserReport } from "../components/ReportComponent/UserReport";
import { userReportSearch } from "../store/actions/report";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateCallHistoty: (payload, cb) =>
      dispatch(createCallHistory(payload, cb)),
    getAllEmployeesList: () => dispatch(GetAllEmployees()),
    getCallHistoryReport: (payload, cb) =>
      dispatch(callHistorySearch(payload, cb)),
    getUserReportSearch: (payload, cb) =>
      dispatch(userReportSearch(payload, cb)),
    getCampaignReportSearch: (payload,isFromCwtr, cb) =>
      dispatch(campaignReportSearch(payload, isFromCwtr,cb)),
    getAllCampaignNames: () => dispatch(getAllCampaigns()),
    getOnlineUsers: () => dispatch(GetCurrentOnlineUsers()),
  };
};

export const ReportContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/CallHistoryReport":
      ComponentToRender = CallHistoryReport;
      break;
    case "/UserReport":
      ComponentToRender = UserReport;
      break;
    case "/CampaignReport":
      ComponentToRender = CampaignReport;
      break;
    case "/OnlineUserReport":
      ComponentToRender = OnlineUserReport;
      break;
    case "/CWTRReport":
      ComponentToRender = CWTRReport;
      break;

    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
