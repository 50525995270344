import * as React from "react";
import Box from "@mui/material/Box";
import { TableComponent } from "./TableComponent";
import { useEffect } from "react";

export const ParentComponent = (props) => {
  const { employeeData, onDeleteEmployee, handleCallStatusSubmit } = props;

  return (
    <Box>
      <TableComponent
        rows={employeeData}
        onDeleteEmployee={onDeleteEmployee}
        handleStatusSubmit={handleCallStatusSubmit}
      />
    </Box>
  );
};
