import React, { useState } from "react";
import { Box, Modal, Button } from "@mui/material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { formatPhoneNumber } from "../../../helpers";
import CloseIcon from "@mui/icons-material/Close";
export const ProfileModal = (props) => {
  const { open, onClose, profileInformation } = props;

  const handleClose = () => {
    onClose(false);
  };
  const ProfileInformation = () => {
    return (
      <Box sx={{ p: 0.3, backgroundColor: "#6DD6C2", borderRadius: "4px" }}>
        <Box sx={{ p: 2, backgroundColor: "#fff", borderRadius: "4px" }}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 18,
              color: "#212223",
              fontWeight: 600,
              backgroundColor: "#90949f21",
              pt: 1.5,
              pl: 1.5,
              pb: 1.5,
            }}
          >
            Basic Profile Information :
          </Typography>
          <Box sx={{ backgroundColor: "#fff", padding: "15px", pr: 0 }}>
            <Grid container spacing={2.4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    User Type :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.user_type}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Account Status :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.account_status
                      ? "True"
                      : "False"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    First Name :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.first_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Last Name :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.last_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    E-mail ID :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Phone Number :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.phone_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Street :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.street}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Address :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,

                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    City :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.city}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    State :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.state}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                    pb: 2,
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Zip Code :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user?.zip_code}
                  </Typography>
                </Box>
              </Grid>
              <>
                {profileInformation?.user?.user_type === "Client" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 20,
                          color: "#212223",
                          fontWeight: 600,
                          marginBottom: "5px",
                          marginTop: "20px",
                          backgroundColor: "#90949f21",
                          pt: 1.5,
                          pl: 1.5,
                          pb: 1.5,
                        }}
                      >
                        Additional Profile Information -
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Additional E-Mail :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.additional_email !== ""
                            ? profileInformation?.additional_email
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Account Name :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.account_name !== ""
                            ? profileInformation?.account_name
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Agency Name :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.agency_name !== ""
                            ? profileInformation?.agency_name
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Plan Purchased :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.plan_purchased !== ""
                            ? profileInformation?.plan_purchased
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Agent ID :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.agent_text_id !== ""
                            ? profileInformation?.agent_text_id
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          SMS Notification:
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.send_introduction_mail
                            ? "True"
                            : "False"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Notes :
                        </Typography>
                        {/* <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.notes !== ""
                            ? profileInformation?.notes
                            : "None"}
                        </Typography> */}
                        <Box
                          sx={{
                            height: "50px",
                            maxWidth: "320px",
                            width: "100%",
                            overflowY: "scroll",
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            sx={{
                              fontSize: 15,
                              color: "#000",
                              fontWeight: 500,
                              marginLeft: "20px",
                            }}
                          >
                            {profileInformation?.notes !== ""
                              ? profileInformation?.notes
                              : "None"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: {
                        xs: "grid",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                      },
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                    >
                      Invoka ID :
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 15,
                        color: "#000",
                        fontWeight: 500,
                        marginLeft: "20px",
                      }}
                    >
                      {profileInformation?.invoka_id !== ""
                        ? profileInformation?.invoka_id
                        : "None"}
                    </Typography>
                  </Box>
                </Grid> */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Additional E-Mail For Invoices :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.additional_email_for_invoices !==
                          ""
                            ? profileInformation?.additional_email_for_invoices
                            : "None"}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: "0px!important",
                        pl: "3px!important",
                        pr: "0px!important",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 20,
                          color: "#212223",
                          fontWeight: 600,

                          backgroundColor: "#90949f21",
                          pt: 1.5,
                          pl: 1.5,
                          pb: 1.5,
                        }}
                      >
                        Additional Profile Information :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Rate Per Hour :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          ${" "}
                          {profileInformation?.rate_per_hour !== ""
                            ? profileInformation?.rate_per_hour
                            : "0.0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Commission Standard Pay :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          ${" "}
                          {profileInformation?.commission_standard_pay !== ""
                            ? profileInformation?.commission_standard_pay
                            : "0.0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Commission Plus Pay :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          ${" "}
                          {profileInformation?.commission_plus_pay !== ""
                            ? profileInformation?.commission_plus_pay
                            : "0.0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Bonus Pay :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          ${" "}
                          {profileInformation?.bonus_pay !== ""
                            ? profileInformation?.bonus_pay
                            : "0.0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "grid",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#6B7489",
                            fontWeight: 600,
                          }}
                        >
                          Team :
                        </Typography>
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 15,
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: "20px",
                          }}
                        >
                          {profileInformation?.team?.charAt(0).toUpperCase() +
                            profileInformation?.team?.slice(1)}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "800px",
          width: "100%",
          maxHeight: { xs: "500px", sm: "500px", md: "100%" },
          overflowY: { xs: "scroll", sm: "scroll", md: "inherit" },
          boxShadow: 0,
          borderRadius: "7px",
          p: 0,
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#000",
            backgroundColor: "#6DD6C2",
            minWidth: "20px",
            "&:hover": {
              backgroundColor: "#6DD6C2",
            },
          }}
        >
          <CloseIcon />
        </Button>
        <ProfileInformation />
      </Box>
    </Modal>
  );
};
