import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { Loader } from "../Common";

export const ChangePassword = (props) => {
  const { onChangePassword } = props;

  const isLoading = useSelector((state) => state.profile.isLoading);

  // State for form inputs
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // State for validation errors
  const [validationError, setValidationError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset validation errors on input change
    setValidationError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = () => {
    // Reset validation errors
    setValidationError({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    // Validation
    let isValid = true;

    if (!formData.oldPassword) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        oldPassword: "Old Password is required",
      }));
      isValid = false;
    }

    if (!formData.newPassword) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        newPassword: "New Password is required",
      }));
      isValid = false;
    }

    if (!formData.confirmPassword) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Confirm Password is required",
      }));
      isValid = false;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
      isValid = false;
    }

    // If all validations pass, invoke the onChangePassword callback
    if (isValid) {
      onChangePassword(formData.oldPassword, formData.newPassword, () => {
        setFormData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      });
      // console.log("data===>", formData);
      // Optionally, you can reset the input fields here
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Change / Update Password Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        margin="0"
                        fullWidth
                        size="small"
                        name="oldPassword"
                        label="Old Password"                       
                        type="password"
                        id="oldpassword"
                        value={formData.oldPassword}
                        onChange={handleChange}
                        error={!!validationError.oldPassword}
                        helperText={validationError.oldPassword}
                        fontSize="15"
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "95%",
                            lg: "95%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        margin="0"
                        fullWidth
                        size="small"
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newpassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                        error={!!validationError.newPassword}
                        helperText={validationError.newPassword}
                        fontSize="15"
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "95%",
                            lg: "95%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        margin="0"
                        fullWidth
                        size="small"
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="conpassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={!!validationError.confirmPassword}
                        helperText={validationError.confirmPassword}
                        fontSize="15"
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "95%",
                            lg: "95%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                          textTransform: "capitalize",
                          width: "100%",
                          maxWidth: "max-content",
                          backgroundColor: "#6DD6C2",
                          borderColor: "#6DD6C2",
                          color: "#212223",
                          fontWeight: 600,
                          "&:hover": {
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isLoading} />
      </Box>
    </>
  );
};
