import { connect } from "react-redux";
import {
  AddClient,
  ClientList,
  CommercialInvoices,
  InvoiceCreate,
  InvoiceList,
  PaymentDetail,
  UploadClient,
} from "../components/ClientComponent";
import { useLocation } from "react-router-dom";
import {
  CreateClient,
  GetClients,
  deleteClient,
  updateAccountStatus,
  updateClient,
  uploadClientCsv,
} from "../store/actions";
import {
  commercialInvoiceSearch,
  createCommercialInvoice,
  generateAndDownloadInvoicePDF,
  sendEmailCommercialInvoices,
  updateCommercialInvoice,
} from "../store/actions/invoices";

const mapStateToProps = (state) => ({
  clientsData: state?.client?.client_listing?.results,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllClients: (payload,cb) => dispatch(GetClients(payload,cb)),
    onCreateClient: (payload, cb) => dispatch(CreateClient(payload, cb)),
    onUpdateClient: (clientId, payload, cb) =>
      dispatch(updateClient(clientId, payload, cb)),
    onDeleteClient: (payload, cb) => dispatch(deleteClient(payload, cb)),
    onUpdateUserAccountStatus: (payload, cb) =>
      dispatch(updateAccountStatus(payload, cb)),
    onUploadClientCSV: (file, cb) => dispatch(uploadClientCsv(file, cb)),
    getAllCommercialInvoices: (payload, cb) =>
      dispatch(commercialInvoiceSearch(payload, cb)),
    generateAndDownloadInvoicePDF: (id, url, cb) =>
      dispatch(generateAndDownloadInvoicePDF(id, url, cb)),
    sendEmailForCommercialInvoices: (id, cb) =>
      dispatch(sendEmailCommercialInvoices(id, cb)),
    updateCommercialInvoice: (payload, cb) =>
      dispatch(updateCommercialInvoice(payload, cb)),
  };
};

export const ClientContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/AddClient":
      ComponentToRender = AddClient;
      break;
    case "/ClientList":
      ComponentToRender = ClientList;
      break;
    case "/InvoiceList":
      ComponentToRender = InvoiceList;
      break;
    case "/InvoiceCreate":
      ComponentToRender = InvoiceCreate;
      break;
    case "/CommercialInvoices":
      ComponentToRender = CommercialInvoices;
      break;
    case "/UploadClient":
      ComponentToRender = UploadClient;
      break;
    case "/PaymentDetail":
      ComponentToRender = PaymentDetail;
      break;

    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
