import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Papa from "papaparse";
import { toast } from "react-hot-toast";
import { de } from "date-fns/locale";

export const UploadLead = (props) => {
  const { onUploadLeadCSV } = props;
  const [csvFile, setCsvFile] = useState(null);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [csvColumns, setCsvColumns] = useState({});
  const [triedSubmit, setTriedSubmit] = useState(false);
  const fileInputRef = useRef(null);
  const fileError = !csvFile && triedSubmit;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const columns = results.meta.fields.map((column) =>
            column.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
          );
          const initialOptions = columns.reduce((options, column) => {
            options[column] = null;
            return options;
          }, {});
          setCsvColumns(initialOptions);
          setCsvFile(file);
        },
      });
    }
  };

  const handleUpload = () => {
    setTriedSubmit(true);
    if (csvFile) {
      const uploadPromise = onUploadLeadCSV(csvFile);
      // Show loading toast using toast.promise
      toast.promise(
        uploadPromise,
        {
          loading: "Uploading...",
          success: "CSV file uploaded successfully",
          error: (error) => {
            if (error?.message && Array.isArray(error?.message)) {
              return error.message.map((errorMessage) => errorMessage.trim());
            } else if (error?.message) {
              return [error.message.trim()];
            } else {
              return ["Failed to upload CSV file"];
            }
          },
        },
        {
          position: "bottom-center",
        }
      );
    }
  };

  const handleCancel = () => {
    setTriedSubmit(false);
    setCsvFile(null);
    setCsvColumns({});
    if (fileInputRef.current) {
      const fileInput = document.getElementById("upload-file");
      if (fileInput) {
        fileInput.value = ""; // Clear the selected file
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", boxShadow: 3 }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderRadius: "10px 10x 0 0!Important",              
              
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Upload Leads CSV
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <Box
                sx={{
                  display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  id="upload-file"
                  name="upload-file"
                  type="file"
                  size="small"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  error={fileError}
                  helperText={fileError && "Please select any CSV file"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                  }}
                />
                {csvFile && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                      textTransform: "capitalize",
                      width: "100%",
                      maxWidth: "max-content",
                      backgroundColor: "#212223",
                      borderColor: "#212223",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#212223",
                        borderColor: "#212223",
                      },
                    }}
                  >
                    Clear
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleUpload}
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{
            borderRadius: "10px!Important",
            boxShadow: 3,
            marginTop: "25px!important",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",              
                  
                  backgroundColor: " #d2f5ec",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Listing Leads CSV Fields
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 18,
                    color: "#454647",
                    fontWeight: 600,
                  }}
                >
                  Column Names
                </Typography>
                <Grid container spacing={1}>
                  {Object.keys(csvColumns).map((option, index) => (
                    <Grid
                      item
                      key={option}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {option}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
