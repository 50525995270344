import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest, putRequest } from "../../../services";
import { logout } from "../authentication";
import toast from "react-hot-toast";
import {
  CLIENT_INVOICE_SEARCH_SUCCESS,
  COMMERCIAL_INVOICE_SEARCH_FAILURE,
  COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS,
  COMMERCIAL_INVOICE_SEARCH_SUCCESS,
  CREATE_COMMERCIAL_INVOICE_FAILURE,
  CREATE_COMMERCIAL_INVOICE_IN_PROGRESS,
  CREATE_COMMERCIAL_INVOICE_SUCCESS,
  GENERATE_INVOICE_PDF_FAILURE,
  GENERATE_INVOICE_PDF_REQUEST,
  GENERATE_INVOICE_PDF_SUCCESS,
  SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE,
  SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS,
  SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS,
  UPDATE_COMMERCIAL_INVOICE_FAILURE,
  UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS,
  UPDATE_COMMERCIAL_INVOICE_SUCCESS,
} from "./types";
import {
  BASE_URL,
  COMMERCIAL_INVOICE_SEARCH_URL,
  CREATE_COMMERCIAL_INVOICE_URL,
  GENERATE_INVOICE_PDF_URL,
  SEND_EMAIL_COMMERCIAL_INVOICES_URL,
  UPDATE_COMMERCIAL_INVOICE_URL,
} from "../../../config/api_urls";

// Action Creators for creating commercial invoice
export const createCommercialInvoiceInProgress = () => ({
  type: CREATE_COMMERCIAL_INVOICE_IN_PROGRESS,
});

export const createCommercialInvoiceSuccess = (data) => ({
  type: CREATE_COMMERCIAL_INVOICE_SUCCESS,
  data,
});

export const createCommercialInvoiceFailure = (error) => ({
  type: CREATE_COMMERCIAL_INVOICE_FAILURE,
  error,
});

export const createCommercialInvoice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(createCommercialInvoiceInProgress());

      // Make API request to create commercial invoice
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${CREATE_COMMERCIAL_INVOICE_URL}`; // Update with the correct URL

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(createCommercialInvoiceSuccess(response?.data));
        cb();
        toast.success("Invoice Created", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createCommercialInvoiceFailure({
            type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createCommercialInvoiceFailure({
          type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for updating commercial invoice
export const updateCommercialInvoiceInProgress = () => ({
  type: UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS,
});

export const updateCommercialInvoiceSuccess = (data) => ({
  type: UPDATE_COMMERCIAL_INVOICE_SUCCESS,
  data,
});

export const updateCommercialInvoiceFailure = (error) => ({
  type: UPDATE_COMMERCIAL_INVOICE_FAILURE,
  error,
});

export const updateCommercialInvoice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(updateCommercialInvoiceInProgress());

      // Make API request to update commercial invoice
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${UPDATE_COMMERCIAL_INVOICE_URL}${payload.id}/`; // Update with the correct URL

      const response = await putRequest(URL, payload, token); // Assuming you have a putRequest function for updating

      // Check response status
      if (response?.status === 200) {
        dispatch(updateCommercialInvoiceSuccess(response?.data));
        cb();
        toast.success("Invoice Updated", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateCommercialInvoiceFailure({
            type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateCommercialInvoiceFailure({
          type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const commercialInvoiceSearchInProgress = () => ({
  type: COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS,
});

export const commercialInvoiceSearchSuccess = (data) => ({
  type: COMMERCIAL_INVOICE_SEARCH_SUCCESS,
  data,
});

export const clientInvoiceSearchSuccess = (data) => ({
  type: CLIENT_INVOICE_SEARCH_SUCCESS,
  data,
});

export const commercialInvoiceSearchFailure = (error) => ({
  type: COMMERCIAL_INVOICE_SEARCH_FAILURE,
  error,
});

export const commercialInvoiceSearch = (payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const state = getState();

      const userType = state?.loginAuth?.user_details?.user_type;
      dispatch(commercialInvoiceSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for commercial invoices

      const URL = `${BASE_URL}${COMMERCIAL_INVOICE_SEARCH_URL}`; // Update with the correct URL

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        if (userType !== "Client") {
          dispatch(commercialInvoiceSearchSuccess(response?.data));
        } else {
          dispatch(clientInvoiceSearchSuccess(response?.data));
        }
        cb();
      } else {
        dispatch(
          commercialInvoiceSearchFailure({
            type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        commercialInvoiceSearchFailure({
          type: ERROR_TYPE.API.INVOICES.UNKNOWN, // Update with the correct error type
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const generateInvoicePDFRequest = () => ({
  type: GENERATE_INVOICE_PDF_REQUEST,
});

export const generateInvoicePDFSuccess = () => ({
  type: GENERATE_INVOICE_PDF_SUCCESS,
});

export const generateInvoicePDFFailure = (error) => ({
  type: GENERATE_INVOICE_PDF_FAILURE,
  error,
});

// Async Action Creator
export const generateAndDownloadInvoicePDF = (
  id,
  url = "generate",
  cb = () => {}
) => {
  return async (dispatch) => {
    dispatch(generateInvoicePDFRequest());

    try {
      //   const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${GENERATE_INVOICE_PDF_URL}${url}/${id}/`;

      //   const response = await getRequest(URL, token);

      if (url !== "generate") {
        fetch(URL)
          .then((response) => response.blob())
          .then((blob) => {
            // const url = window.URL.createObjectURL(new Blob([blob]));
            // const a = document.createElement("a");
            // a.href = url;
            // a.download = "Invoice.pdf";
            // document.body.appendChild(a);
            // a.click();
            // window.URL.revokeObjectURL(url);
            dispatch(generateInvoicePDFSuccess());
            toast.success("Invoice Downloaded", {
              position: "bottom-center",
            });
          })
          .catch((error) => {
            dispatch(
              generateInvoicePDFFailure({
                type: ERROR_TYPE.API.INVOICES.UNKNOWN,
                message: error?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
              })
            );
            toast.error(error?.statusText ?? ERROR_MESSAGE.API.UNKNOWN, {
              position: "bottom-center",
            });
          });
      } else {
        fetch(URL)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement("a");
            a.href = url;
            a.download = "Invoice.pdf";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(generateInvoicePDFSuccess());
            toast.success("Invoice Downloaded", {
              position: "bottom-center",
            });
          })
          .catch((error) => {
            dispatch(
              generateInvoicePDFFailure({
                type: ERROR_TYPE.API.INVOICES.UNKNOWN,
                message: error?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
              })
            );
            toast.error(error?.statusText ?? ERROR_MESSAGE.API.UNKNOWN, {
              position: "bottom-center",
            });
          });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        generateInvoicePDFFailure({
          type: ERROR_TYPE.API.INVOICES.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for sending email commercial invoices
export const sendEmailCommercialInvoicesInProgress = () => ({
  type: SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS,
});

export const sendEmailCommercialInvoicesSuccess = (data) => ({
  type: SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS,
  data,
});

export const sendEmailCommercialInvoicesFailure = (error) => ({
  type: SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE,
  error,
});

export const sendEmailCommercialInvoices = (id, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(sendEmailCommercialInvoicesInProgress());

      // Make API request to send email commercial invoices
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${SEND_EMAIL_COMMERCIAL_INVOICES_URL}/${id}/`; // Update with the correct URL

      const response = await postRequest(URL, {}, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(sendEmailCommercialInvoicesSuccess(response?.data));
        cb();
        toast.success("Invoice Sent", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          sendEmailCommercialInvoicesFailure({
            type: ERROR_TYPE.API.INVOICES.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        sendEmailCommercialInvoicesFailure({
          type: ERROR_TYPE.API.INVOICES.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
