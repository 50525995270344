import { CALL_RESULT } from "./constants";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import toast from "react-hot-toast";

// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const setTokenInLocalStorage = (token) => {
  localStorage.setItem("token", token);
};

// Function to get the token from local storage
export const getItemFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setItemInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

//clear token
export const clearTokenFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

//formate phone numbers
export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber?.replace(/\D/g, "");

  // Check if the cleaned phone number is valid
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format the phone number in US format: (XXX) XXX-XXXX
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return the original phone number if it is not valid
  return phoneNumber;
};

export const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

export const convertOptToPayload = (criteriaArray) => {
  const operatorArray = criteriaArray.map((criteria) => criteria.operator);
  const fieldsArray = criteriaArray.map((criteria) => criteria.field);
  const typesArray = criteriaArray.map((criteria) => criteria.type);
  const valuesArray = criteriaArray.map((criteria) => criteria.value);

  const result = {
    operator_array: operatorArray,
    fields_array: fieldsArray,
    types_array: typesArray,
    values_array: valuesArray,
  };

  return result;
};

export const convertOptToPayloadForCampaignSearch = (criteriaArray) => {
  const fieldsArray = criteriaArray.map((criteria) => criteria.field);
  const typesArray = criteriaArray.map((criteria) => criteria.type);
  const valuesArray = criteriaArray.map((criteria) => criteria.value);

  const result = {
    fields_array: fieldsArray,
    types_array: typesArray,
    values_array: valuesArray,
  };

  return result;
};

export const convertPayloadToOpt = (payload) => {
  const operatorArray = payload.operator_array;
  const fieldsArray = payload.fields_array;
  const typesArray = payload.types_array;
  const valuesArray = payload.values_array;

  const criteriaArray = operatorArray.map((operator, index) => ({
    operator: operatorArray[index],
    field: fieldsArray[index],
    type: typesArray[index],
    value: valuesArray[index],
  }));

  return criteriaArray;
};

export const checkAndReturnNone = (value) => {
  if (value === undefined || value === null || value === "") {
    return "None";
  }
  return value;
};

export const getCallResultLabel = (id) => {
  const result = CALL_RESULT?.find((item) => item.id === id);
  return result ? result.label : "Unknown"; // Return "Unknown" if id not found in CALL_RESULT
};

export const getLastAppWithName = (id, data) => {
  const result = data.find((item) => item.id === id);
  return result ? result.full_name : "None"; // Return "Unknown" if id not found in CALL_RESULT
};

export const openNewWindow = (url = "", width = "680", height = "510") => {
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;

  var signinWin;
  // Use the childURL as needed
  signinWin = window.open(
    "url",
    "SignIn",
    `width=${width},height=${height},toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=${left},top=${top}`
  );

  setTimeout(CheckLoginStatus, 2000);
  signinWin.focus();

  function CheckLoginStatus() {
    //console.log(signinWin);
    if (signinWin.closed) {
      if (signinWin.status) {
        localStorage.setItem("Status", signinWin.status);
        //console.log(signinWin.status);
        window.location.reload();
      }
    } else setTimeout(CheckLoginStatus, 1000);
  }

  return false;
};

export const downloadXLSXFile = (data, filename) => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, filename);
};

export const tableToXLSX = (tableId) => {
  // Variable to store the final xlsx data
  var xlsx_data = [];

  // Get table headers
  var headers = [];
  var headerCols = document
    .getElementById(tableId)
    .children[0].children[0].querySelectorAll("th");
  for (var k = 0; k < headerCols.length; k++) {
    headers.push(headerCols[k].innerText);
  }
  xlsx_data.push(headers);

  // Get each row data
  var rows = document.getElementById(tableId).children[1].children;
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll("td,th");

    // Stores each xlsx row data
    var xlsxrow = [];
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell of
      // a row and push it to xlsxrow
      xlsxrow.push(cols[j].innerText);
    }

    // Combine each column value
    xlsx_data.push(xlsxrow);
  }

  // download the xlsx file
  downloadXLSXFile(xlsx_data, `${tableId}_data.xlsx`);
};

export const downloadCSVFile = (csv_data, filename) => {
  // Create CSV file object and feed our
  // csv_data into it
  let CSVFile = new Blob([csv_data], { type: "text/csv" });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement("a");

  // Download csv file
  temp_link.download = filename;
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
};

export const tableToCSV = (tableId) => {
  // Variable to store the final csv data
  var csv_data = [];

  // Get table headers
  var headers = [];
  var headerCols = document
    .getElementById(tableId)
    .children[0].children[0].querySelectorAll("th");
  for (var k = 0; k < headerCols.length; k++) {
    headers.push(headerCols[k].innerText);
  }
  csv_data.push(headers.join(","));

  // Get each row data
  var rows = document.getElementById(tableId).children[1].children;
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll("td,th");

    // Stores each csv row data
    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell of
      // a row and push it to csvrow
      csvrow.push(cols[j].innerText);
    }

    // Combine each column value with comma
    csv_data.push(csvrow.join(","));
  }

  // combine each row data with a new line character
  csv_data = csv_data.join("\n");
  downloadCSVFile(csv_data, `${tableId}_data.csv`);
  /* We will use this function later to download
  the data in a csv file 
  */
};

// const styles = {
//   header: {
//     fontSize: 22,
//     bold: true,
//     alignment: "center",
//     color: "#2d4154",
//     margin: [0, 0, 0, 10], // top, right, bottom, left
//   },
//   tableHeader: {
//     bold: true,
//     color: "white",
//     fillColor: "#2d4154",
//   },
//   tableBodyEven: {
//     fillColor: "#f3f3f3",
//   },
//   tableBodyOdd: {},
// };

// // Download PDF function
// export const downloadPDFFile = (data, filename) => {
//   const documentDefinition = {
//     pageSize: "LEGAL",
//     pageOrientation: "landscape",
//     content: [
//       {
//         table: {
//           headerRows: 1,
//           body: data,
//         },
//       },
//     ],
//     styles,
//   };

//   pdfMake.createPdf(documentDefinition).download(filename);
// };

// // Convert table to PDF function
// export const tableToPDF = (tableId) => {
//   const pdfData = [];

//   const headers = [];
//   const headerCols = document
//     .getElementById(tableId)
//     .children[0].children[0].querySelectorAll("th");
//   for (let k = 0; k < headerCols.length; k++) {
//     headers.push(headerCols[k].innerText);
//   }
//   pdfData.push(headers);

//   const rows = document.getElementById(tableId).children[1].children;
//   for (let i = 0; i < rows.length; i++) {
//     const cols = rows[i].querySelectorAll("td,th");
//     const pdfRow = [];
//     for (let j = 0; j < cols.length; j++) {
//       pdfRow.push(cols[j].innerText);
//     }
//     pdfData.push(pdfRow);
//   }

//   downloadPDFFile(pdfData, `${tableId}_data.pdf`);
// };

export const downloadPDFFile = (data, filename) => {
  const pdf = new jsPDF({
    orientation: "landscape",
  });

  pdf.autoTable({
    head: [data[0]],
    body: data.slice(1),
    theme: "grid",
    startY: 20, // Adjust the starting Y position
    margin: { top: 20 }, // Add margin at the top
    styles: { overflow: "linebreak" }, // Allow line breaks within cells
    columnStyles: { "*": { cellWidth: "wrap" } }, // Set all columns to maximum content width
    headStyles: {
      fillColor: [238, 238, 238],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      cellWidth: 21,
    }, // Set header style
    tableWidth: "auto", // Set table width to auto
  });
  pdf.save(filename);
};

export const tableToPDF = (tableId) => {
  // Variable to store the final pdf data
  var pdf_data = [];

  // Get table headers
  var headers = [];
  var headerCols = document
    .getElementById(tableId)
    .children[0].children[0].querySelectorAll("th");
  for (var k = 0; k < headerCols.length; k++) {
    headers.push(headerCols[k].innerText);
  }
  pdf_data.push(headers);

  // Get each row data
  var rows = document.getElementById(tableId).children[1].children;
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll("td,th");

    // Stores each pdf row data
    var pdfrow = [];
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell of
      // a row and push it to pdfrow
      pdfrow.push(cols[j].innerText);
    }

    // Combine each column value
    pdf_data.push(pdfrow);
  }

  // download the pdf file
  downloadPDFFile(pdf_data, `${tableId}_data.pdf`);
};

export const printPDFTable = (data, filename) => {
  const pdf = new jsPDF({
    orientation: "landscape",
  });

  pdf.autoTable({
    head: [data[0]],
    body: data.slice(1),
    theme: "grid",
    startY: 20, // Adjust the starting Y position
    margin: { top: 20 }, // Add margin at the top
    styles: { overflow: "linebreak" }, // Allow line breaks within cells
    columnStyles: { "*": { cellWidth: "wrap" } }, // Set all columns to maximum content width
    headStyles: {
      fillColor: [238, 238, 238],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      cellWidth: 21,
    }, // Set header style
    tableWidth: "auto", // Set table width to auto
  });

  // Print the PDF
  pdf.autoPrint();
  window.open(pdf.output("bloburl"), "_blank");
};

export const tableToPrint = (tableId) => {
  // Variable to store the final pdf data
  var pdf_data = [];

  // Get table headers
  var headers = [];
  var headerCols = document
    .getElementById(tableId)
    .children[0].children[0].querySelectorAll("th");
  for (var k = 0; k < headerCols.length; k++) {
    headers.push(headerCols[k].innerText);
  }
  pdf_data.push(headers);

  // Get each row data
  var rows = document.getElementById(tableId).children[1].children;
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll("td,th");

    // Stores each pdf row data
    var pdfrow = [];
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell of
      // a row and push it to pdfrow
      pdfrow.push(cols[j].innerText);
    }

    // Combine each column value
    pdf_data.push(pdfrow);
  }

  // download the pdf file
  printPDFTable(pdf_data, `${tableId}_data.pdf`);
};

export const copyTableToClipboard = (tableId) => {
  try {
    // Get table headers
    const headers = [];
    const headerCols = document
      .getElementById(tableId)
      .querySelectorAll("th:not(:first-child)");
    for (let k = 0; k < headerCols.length; k++) {
      headers.push(headerCols[k].innerText);
    }

    // Get each row data
    const rows = document.getElementById(tableId).querySelectorAll("tr");
    const rowData = Array.from(rows).map((row) => {
      const cols = row.querySelectorAll("td, th");
      return Array.from(cols).map((col) => col.innerText);
    });

    // Separate header row from body rows
    const [headerRow, ...bodyRows] = rowData;

    // Combine headers and body rows separately
    const headerString = headerRow.join("\t");
    const bodyString = bodyRows.map((row) => row.join("\t")).join("\n");

    // Combine header and body strings
    const tableString = `${headerString}\n${bodyString}`;

    // Copy the string to the clipboard
    navigator.clipboard.writeText(tableString);

    // Notify the user or handle success
    toast.success("Table data Copied", {
      position: "bottom-center",
    });
  } catch (error) {
    // Handle any errors that may occur during the copy operation
    console.error("Error copying table data:", error);
    toast.error("Error copying table data. Please try again", {
      position: "bottom-center",
    });
  }
};

export const printPDFElement = (elementId, filename) => {
  const pdf = new jsPDF({
    orientation: "portrait",
  });

  const element = document.getElementById(elementId);
  if (!element) {
    console.error(`Element with id ${elementId} not found.`);
    return;
  }

  // Add the HTML content of the element to the PDF
  pdf.html(element, {});
  // Print the PDF
  pdf.autoPrint();
  window.open(pdf.output("bloburl"), "_blank");
};

export const elementToPrint = (elementId) => {
  // const element = document.getElementById(elementId);
  // if (!element) {
  //   console.error(`Element with id ${elementId} not found.`);
  //   return;
  // }
  // let printContents = document.getElementById(elementId).innerHTML;
  // let originalContents = document.body.innerHTML;
  // document.body.innerHTML = printContents;
  window.print();
  // document.body.innerHTML = originalContents;

  // //  var w = window.open();
  //  let printContents = document.getElementById(elementId).innerHTML;
  //  document.body.innerHTML = printContents;
  // w.print();

  // Print the element to a PDF
  // printPDFElement(elementId, `${elementId}_data.pdf`);
};


export const convertApiResponseToRows = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((item) => {
    const {
      id,
      name,
      is_active,
      owed_value,
      account_name,
      agent_id,
    } = item;

    return {
      campaignName: name,
      status: is_active ? "Active" : "Inactive",
      amountOwed: owed_value,
      accountName: account_name,
      showCalendar: agent_id,
      viewMap: item,
      id: id, 
    };
  });
};


export const getMinToDate = (fromDate) => {
  if (!fromDate) return null;
  const nextDay = new Date(fromDate);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay.toISOString().split('T')[0];
};