// LoginScreen.js
import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  CALL_RESULT,
  ERROR_MESSAGE,
  TIME_ZONES,
  formatPhoneNumber,
  isValidDateFormat,
  validateEmail,
  validateMobileNumber,
  validateName,
  validatePincode,
} from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "../Common";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  GoogleMap,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import moment from "moment/moment";
import { forwardRef } from "react";

export const CreateLead = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { onCreateLead } = props;

  const [triedSubmit, setTriedSubmit] = useState(false);

  const isLoading = useSelector((state) => state?.lead?.isLoading);

  const infoInsuranceInfo = {
    totalEmployees: "",
    liabilityRenewalDate: "",
    liabilityCarrier: "",
    workersCompRenewalDate: "",
    autoRenewalDate: "",
    totalLocations: "",
    workersCompCarrier: "",
    autoCarrier: "",
    totalVehicles: "",
  };

  const [insuranceInfo, setInsuranceInfo] = useState(infoInsuranceInfo);

  const initialAppointmentData = {
    appointmentDate: "",
    appointmentTime: dayjs("2022-04-17T15:30"),
    confirmAppointmentDate: "",
    confirmAppointmentTime: dayjs("2022-04-17T15:30"),
    appointmentType: "",
    status: "1",
    comment: "",
  };

  const [appointmentData, setAppointmentData] = useState(
    initialAppointmentData
  );

  const [isChecked, setIsChecked] = useState(false);

  const appointmentDateError =
    triedSubmit &&
    appointmentData?.appointmentDate?.toString()?.trim().length === 0;

  const appointmentDateErrorMessage =
    triedSubmit && appointmentData?.appointmentDate?.trim().length === 0
      ? "Please enter a valid date"
      : "";

  const appointmentTimeError =
    triedSubmit &&
    appointmentData?.appointmentTime?.toString()?.trim().length === 0;

  const appointmentTimeErrorMessage =
    triedSubmit &&
    appointmentData?.appointmentTime?.toString()?.trim().length === 0
      ? "Please enter a valid appointment time"
      : "";

  const confirmAppointmentDateError =
    triedSubmit &&
    (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentDate !==
        appointmentData?.confirmAppointmentDate);

  const confirmAppointmentDateErrorMessage =
    triedSubmit &&
    (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentDate !==
        appointmentData?.confirmAppointmentDate)
      ? "Please enter a valid confirm appointment date"
      : "";

  const confirmAppointmentTimeError =
    triedSubmit &&
    (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentTime?.toString() !==
        appointmentData?.confirmAppointmentTime?.toString());

  const confirmAppointmentTimeErrorMessage =
    triedSubmit &&
    (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentTime?.toString() !==
        appointmentData?.confirmAppointmentTime?.toString())
      ? "Please enter a valid confirm appointment time"
      : "";

  const appointmentTypeError =
    triedSubmit &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0;

  const appointmentTypeErrorMessage =
    triedSubmit &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0
      ? "Please enter a valid appointment type"
      : "";

  const totalLocationError =
    triedSubmit && insuranceInfo?.totalLocations?.trim().length === 0;

  const totalLocationErrorMessage =
    triedSubmit && insuranceInfo?.totalLocations?.trim().length === 0
      ? "Please enter a valid total location"
      : "";

  const totalEmployeesError =
    triedSubmit && insuranceInfo?.totalEmployees?.trim().length === 0;

  const totalEmployeesErrorMessage =
    triedSubmit && insuranceInfo?.totalEmployees?.trim().length === 0
      ? "Please enter a valid total employees"
      : "";

  const handleChangeOptions = (event, type) => {
    switch (type) {
      case "appointmentType":
        setAppointmentData({
          ...appointmentData,
          appointmentType: event.target.value,
        });
        break;
      case "status":
        setAppointmentData({
          ...appointmentData,
          status: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const isCheckFormValid = (e) => {
    const isApppointmentDateValid =
      appointmentData?.appointmentDate?.toString()?.trim().length > 0;

    const isAppointmentTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim().length > 0;

    const isCNFApppointmentDateValid =
      appointmentData?.confirmAppointmentDate?.toString()?.trim().length > 0;

    const isCNFAppointmentTimeValid =
      appointmentData?.confirmAppointmentTime?.toString()?.trim().length > 0;

    const isAppointmentTypeValid =
      appointmentData?.appointmentType?.toString()?.trim().length > 0;

    const isconfirmTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim() ===
      appointmentData?.confirmAppointmentTime?.toString()?.trim();

    const isconfirmDateValid =
      appointmentData?.appointmentDate?.toString()?.trim() ===
      appointmentData?.confirmAppointmentDate?.toString()?.trim();

    const isTotalLocationValid =
      insuranceInfo?.totalLocations?.toString()?.trim().length > 0;

    const iSTotalEmployeesValid =
      insuranceInfo?.totalEmployees?.toString()?.trim().length > 0;

    return (
      isApppointmentDateValid &&
      isAppointmentTimeValid &&
      isCNFApppointmentDateValid &&
      isCNFAppointmentTimeValid &&
      isAppointmentTypeValid &&
      isconfirmTimeValid &&
      isconfirmDateValid &&
      iSTotalEmployeesValid &&
      isTotalLocationValid
    );
  };

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(isCheckFormValid());
    // 
  }, [appointmentData, insuranceInfo, triedSubmit ,isFormValid]);

  const createLeadCb = () => {
    setTriedSubmit(false);
    setInsuranceInfo(infoInsuranceInfo);
    setAppointmentData(initialAppointmentData);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const isCheckFormValidhere = isCheckFormValid();
    console.log(isCheckFormValidhere)
    if (!isFormValid) return;

    
    const payload = {
      ...insuranceInfo,
      ...appointmentData,
    };
    onCreateLead(payload, createLeadCb);
  };

  // const onReset = () => {
  //   setTriedSubmit(false);
  //   setAppointmentData(initialAppointmentData);
  //   setInsuranceInfo(infoInsuranceInfo);
  // };

  return (
    <div ref={ref}>
      <Box sx={{ marginTop: "25px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Insurance Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Liability Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            value={insuranceInfo.liabilityRenewalDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                liabilityRenewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="liabilitycarrier"
                          label="Liability Carrier"
                          type="text"
                          id="liabilitycarrier"
                          autoComplete="current-password"
                          fontSize="15"
                          value={insuranceInfo.liabilityCarrier}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              liabilityCarrier: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Workers Comp Renewal Date :
                          </Typography>
                          <Box>
                            <Checkbox
                              checked={
                                insuranceInfo?.liabilityRenewalDate ===
                                  insuranceInfo.workersCompRenewalDate &&
                                insuranceInfo?.liabilityRenewalDate !== ""
                              }
                              onChange={() => {
                                if (
                                  insuranceInfo?.liabilityRenewalDate !==
                                  insuranceInfo.workersCompRenewalDate
                                ) {
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    workersCompRenewalDate:
                                      insuranceInfo?.liabilityRenewalDate,
                                  });
                                } else {
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    workersCompRenewalDate: "",
                                  });
                                }
                              }}
                              color="primary"
                              size="small"
                            />
                            <TextField
                              margin="0"
                              size="small"
                              type="date"
                              fontSize="15"
                              value={insuranceInfo.workersCompRenewalDate}
                              onChange={(e) =>
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  workersCompRenewalDate: e.target.value,
                                })
                              }
                              InputLabelProps={{
                                sx: {
                                  fontSize: "15px",
                                  "&.Mui-focused": {
                                    color: "#6DD6C2",
                                  },
                                },
                              }}
                              sx={{
                                marginBottom: "10px",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "200px",
                                  lg: "200px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: "#fff",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Checkbox
                            checked={
                              insuranceInfo?.liabilityCarrier ===
                                insuranceInfo.workersCompCarrier &&
                              insuranceInfo?.liabilityCarrier !== ""
                            }
                            onChange={() => {
                              if (
                                insuranceInfo?.liabilityCarrier !==
                                insuranceInfo.workersCompCarrier
                              ) {
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  workersCompCarrier:
                                    insuranceInfo?.liabilityCarrier,
                                });
                              } else {
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  workersCompCarrier: "",
                                });
                              }
                            }}
                            color="primary"
                            size="small"
                          />
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="carrier"
                            label="Workers Comp Carrier"
                            type="text"
                            id="carrier"
                            fontSize="15"
                            value={insuranceInfo.workersCompCarrier}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                workersCompCarrier: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "90%",
                                lg: "90%",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Auto Renewal Date :
                          </Typography>
                          <Box>
                            <Checkbox
                              checked={
                                insuranceInfo?.liabilityRenewalDate ===
                                  insuranceInfo.autoRenewalDate &&
                                insuranceInfo?.liabilityRenewalDate !== ""
                              }
                              onChange={() => {
                                if (
                                  insuranceInfo?.liabilityRenewalDate !==
                                  insuranceInfo.autoRenewalDate
                                ) {
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    autoRenewalDate:
                                      insuranceInfo?.liabilityRenewalDate,
                                  });
                                } else {
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    autoRenewalDate: "",
                                  });
                                }
                              }}
                              color="primary"
                              size="small"
                            />
                            <TextField
                              margin="0"
                              size="small"
                              type="date"
                              fontSize="15"
                              value={insuranceInfo.autoRenewalDate}
                              onChange={(e) =>
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  autoRenewalDate: e.target.value,
                                })
                              }
                              InputLabelProps={{
                                sx: {
                                  fontSize: "15px",
                                  "&.Mui-focused": {
                                    color: "#6DD6C2",
                                  },
                                },
                              }}
                              sx={{
                                marginBottom: "10px",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "200px",
                                  lg: "200px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: "#fff",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Checkbox
                            checked={
                              insuranceInfo?.liabilityCarrier ===
                                insuranceInfo.autoCarrier &&
                              insuranceInfo?.liabilityCarrier !== ""
                            }
                            onChange={() => {
                              if (
                                insuranceInfo?.liabilityCarrier !==
                                insuranceInfo.autoCarrier
                              ) {
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  autoCarrier: insuranceInfo?.liabilityCarrier,
                                });
                              } else {
                                setInsuranceInfo({
                                  ...insuranceInfo,
                                  autoCarrier: "",
                                });
                              }
                            }}
                            color="primary"
                            size="small"
                          />
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="autocarrier"
                            label="Auto Carrier"
                            type="text"
                            id="autocarrier"
                            fontSize="15"
                            value={insuranceInfo.autoCarrier}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                autoCarrier: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "90%",
                                lg: "90%",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="totallocations"
                          label="Total Locations"
                          type="text"
                          id="totallocations"
                          value={insuranceInfo.totalLocations}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              totalLocations: e.target.value,
                            })
                          }
                          error={totalLocationError}
                          helperText={
                            totalLocationError && totalLocationErrorMessage
                          }
                          autoComplete="current-password"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          value={insuranceInfo.totalEmployees}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              totalEmployees: e.target.value,
                            })
                          }
                          error={totalEmployeesError}
                          helperText={
                            totalEmployeesError && totalEmployeesErrorMessage
                          }
                          label="Total Employees"
                          type="text"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="totalvehicles"
                          label="Total Vehicles"
                          type="number"
                          id="totalvehicles"
                          value={insuranceInfo.totalVehicles}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              totalVehicles: e.target.value,
                            })
                          }
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginTop: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={appointmentDateError}
                            helperText={
                              appointmentDateError &&
                              appointmentDateErrorMessage
                            }
                            value={appointmentData.appointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                appointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Time :
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "TimePicker",
                                "MobileTimePicker",
                                "DesktopTimePicker",
                                "StaticTimePicker",
                              ]}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <MobileTimePicker
                                value={appointmentData?.appointmentTime}
                                onChange={(value) => {
                                  setAppointmentData({
                                    ...appointmentData,
                                    appointmentTime: value,
                                  });
                                }}
                                error={appointmentTimeError}
                                helperText={
                                  appointmentTimeError &&
                                  appointmentTimeErrorMessage
                                }
                                slotProps={{
                                  textField: {
                                    helperText: appointmentTimeErrorMessage,
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={confirmAppointmentDateError}
                            helperText={
                              confirmAppointmentDateError &&
                              confirmAppointmentDateErrorMessage
                            }
                            value={appointmentData.confirmAppointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                confirmAppointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Time :
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "TimePicker",
                                "MobileTimePicker",
                                "DesktopTimePicker",
                                "StaticTimePicker",
                              ]}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <MobileTimePicker
                                value={appointmentData.confirmAppointmentTime}
                                onChange={(value) => {
                                  setAppointmentData({
                                    ...appointmentData,
                                    confirmAppointmentTime: value,
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <>
                            {confirmAppointmentTimeError && (
                              <Typography
                                sx={{
                                  color: "#d32f2f",
                                  fontSize: 12,
                                }}
                              >
                                {confirmAppointmentTimeErrorMessage}
                              </Typography>
                            )}
                          </>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Appointment Type
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Appointment Type"
                            error={appointmentTypeError}
                            value={appointmentData?.appointmentType}
                            onChange={(event) => {
                              handleChangeOptions(event, "appointmentType");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem value={1} sx={{ fontSize: 15 }}>
                              In Person
                            </MenuItem>
                            <MenuItem value={2} sx={{ fontSize: 15 }}>
                              Phone Call
                            </MenuItem>
                          </Select>
                          <>
                            {appointmentTypeError && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f",
                                }}
                              >
                                {appointmentTypeErrorMessage}
                              </FormHelperText>
                            )}
                          </>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Status"
                            value={appointmentData?.status}
                            onChange={(event) => {
                              handleChangeOptions(event, "status");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem value={"1"} sx={{ fontSize: 15 }}>
                              Awaiting Confirmation
                            </MenuItem>
                            <MenuItem value={"2"} sx={{ fontSize: 15 }}>
                              Confirmed
                            </MenuItem>
                            <MenuItem value={"3"} sx={{ fontSize: 15 }}>
                              Left Message
                            </MenuItem>
                            <MenuItem value={"4"} sx={{ fontSize: 15 }}>
                              Rescheduled
                            </MenuItem>
                            <MenuItem value={"5"} sx={{ fontSize: 15 }}>
                              Cancelled
                            </MenuItem>
                            <MenuItem value={"6"} sx={{ fontSize: 15 }}>
                              Cancelled - Waiting to Rescheduled
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="comment"
                          label="Comment"
                          type="text"
                          id="comment"
                          fontSize="15"
                          value={appointmentData.comment}
                          onChange={(event) => {
                            setAppointmentData({
                              ...appointmentData,
                              comment: event.target.value,
                            });
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <FormControlLabel
                        sx={{
                          paddingLeft: 2,
                          fontSize: "5!important",
                          color: "#7b7b7b",
                          fontWeight: 600,
                        }}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                            color="primary"
                          />
                        }
                        label="I have reviewed everything and I verify all information including but not limited to the Address, Date/Time of the Appointment and the Decision Makers Name are correct."
                      />
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onSubmitHandler}
                          disabled={!isChecked}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isLoading} />
      </Box>
    </div>
  );
});
