import { connect } from "react-redux";
import { HomeComponent } from "../components";
import {
  LoginAuthErrorReset,
  leadOwedReport,
  updateLeadOwnKey,
  userDashboardProduction,
} from "../store/actions";
import { createCommercialInvoice } from "../store/actions/invoices";
import { useLocation } from "react-router-dom";
// Actions

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onResetError: () => dispatch(LoginAuthErrorReset()),
    getUserDashboardProduction: (payload, cb) =>
      dispatch(userDashboardProduction(payload, cb)),
    getLeadOwedReport: (payload, cb) => dispatch(leadOwedReport(payload, cb)),
    createCommercialInvoice: (payload, cb) =>
      dispatch(createCommercialInvoice(payload, cb)),
    onLeadOwedKeyUpdate: (id, key, value, cb) =>
      dispatch(updateLeadOwnKey(id, key, value, cb)),
  };
};

// export const HomeContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(HomeComponent);

export const HomeContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/HomeComponent":
      ComponentToRender = HomeComponent;
      break;
    default:
      ComponentToRender = HomeComponent;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
