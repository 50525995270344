import * as React from "react";
import Box from "@mui/material/Box";
import { ClientInvoicesTable } from "./ClientInvoicesTable";
import { useDispatch, useSelector } from "react-redux";
import { generateAndDownloadInvoicePDF } from "../../store/actions/invoices";

export const ClientInvoicesParentTable = (props) => {

  const [filterName, setFilterName] = React.useState("");

  const dispatch = useDispatch();

  const clientInvoices = useSelector(
    (state) => state?.invoices?.clientInvoices
  );

  const createData = (
    agent,
    amount,
    month,
    createdDate,
    modifiedDate,
    operations
  ) => {
    return {
      agent,
      amount,
      month,
      createdDate,
      modifiedDate,
      operations,
    };
  };

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const { agent, total_amount, billing_period, created_at, modified_at } =
        item;
      return createData(
        agent,
        total_amount,
        billing_period,
        created_at,
        modified_at,
        item
      );
    });
  };

  const generatePdf = (id, url) => {
    dispatch(generateAndDownloadInvoicePDF(id, url))
  };

  const res = clientInvoices?.all_invoices ?? [];

  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <ClientInvoicesTable
        rows={tableRows}
        filterName={filterName}
        generatePdf={generatePdf}
      />
    </Box>
  );
};
