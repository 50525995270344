import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { GetUsers } from "../store/actions/Prospects";
import { LeadList, NewLead, ShowLead, UploadLead } from "../components/LeadComponent";
import {
  createLead,
  createNewNote,
  deleteLead,
  deleteNote,
  deleteReturnCredit,
  getAllNotes,
  getLeadSearch,
  getLeadShow,
  getReturnCredit,
  leadMassUpdate,
  returnCredit,
  updateAppointmentKey,
  updateAppointmentStatus,
  updateLeadKey,
  uploadLeadCsv,
} from "../store/actions/lead";
import { createAppointment, deleteAppointment, getAllCampaigns, updateLeadOwnKey } from "../store/actions";

const mapStateToProps = (state) => ({
  userDetails: state?.prospect?.user_details?.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUser: () => dispatch(GetUsers()),
    onCreateLead: (payload, cb) => dispatch(createLead(payload, cb)),
    onUploadLeadCSV: (file, cb) => dispatch(uploadLeadCsv(file, cb)),
    onSearchLeads: (payload, cb) => dispatch(getLeadSearch(payload, cb)),
    getAllCampaignNames: () => dispatch(getAllCampaigns()),
    onDeleteLead: (leadId, cb) =>
    dispatch(deleteLead(leadId, cb)),
    onLeadUpdateKey:(leadId, key, value, cb)=>dispatch(updateLeadKey(leadId, key, value, cb)),
    getLeadDetails :(leadId,cb)=>dispatch(getLeadShow(leadId,cb)),
    onleadMassUpdate:(payload,cb)=>dispatch(leadMassUpdate(payload,cb)),
    createNewNote:(payload,cb,isEdit)=>dispatch(createNewNote(payload,cb,isEdit)),
    getAllNotes:(payload)=>dispatch(getAllNotes(payload)),
    onDeleteNote:(id,cb)=>dispatch(deleteNote(id,cb)),
    returnCredit:(payload,cb)=>dispatch(returnCredit(payload,cb)),
    deleteReturnCredit:(id,cb)=>dispatch(deleteReturnCredit(id,cb)),
    getReturnCredit:(payload)=>dispatch(getReturnCredit(payload)),
    updateAppointmentStatus:(payload,cb)=>dispatch(updateAppointmentStatus(payload,cb)),
    updateAppointmentKey:(appId, key, value, cb)=>dispatch(updateAppointmentKey(appId, key, value, cb)),
    onDeleteAppointment: (payload, cb) =>
    dispatch(deleteAppointment(payload, cb)),
    onCreateAppointment: (payload, cb) =>
      dispatch(createAppointment(payload, cb)),
  };
};

export const LeadContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/LeadList":
      ComponentToRender = LeadList;
      break;
    case "/NewLead":
      ComponentToRender = NewLead;
      break;
    case "/UploadLead":
      ComponentToRender = UploadLead;
      break;
      case "/ShowLead":
        ComponentToRender = ShowLead;
        break;

    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
