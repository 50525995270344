import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ChangePassword,
  ProfileComponent,
} from "../components/ProfileComponent";
import {
  authCallback,
  authOutlookCallback,
  changePassword,
  getGoogleAuthenticationUrl,
  googleCalendarLoginStatus,
  syncGoogleCalendar,
  updateProfileKey,
} from "../store/actions/profile";
import { GetUsers, VerifyOTP, qrCodeGenerate, userAuthenticationType, verifyQrOTPProfile } from "../store/actions";

const mapStateToProps = (state) => ({
  isQrCodeGenerating :state?.loginAuth?.qrCodeGenerate?.isLoading,
  isOtpError: state?.loginAuth?.verifyOTP?.error,
  otpErrorMessage: state?.loginAuth?.verifyOTP?.errorMessage,
  isQrCodeGenerating :state?.loginAuth?.qrCodeGenerate?.isLoading,
   isverifyQrOTPProfileloading: state?.loginAuth?.verifyQrOTPProfile?.isLoading,
   isVerifyOYPloading: state?.loginAuth?.verifyOTP?.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getGoogleAuthUrl: (cb) => {
      dispatch(getGoogleAuthenticationUrl(cb));
    },
    syncGoogleCalendar: () => {
      dispatch(syncGoogleCalendar());
    },
    authCallBack: (payload, cb) => {
      dispatch(authCallback(payload, cb));
    },
    authOutlookCallBack: (payload, cb) => {
      dispatch(authOutlookCallback(payload, cb));
    },
    getGoogleLoginStatus: () => {
      dispatch(googleCalendarLoginStatus());
    },
    onChangePassword: (currentPassword, newPassword, cb) => {
      dispatch(changePassword(currentPassword, newPassword, cb));
    },
    updateProfileKey: (id, key, value, isAdditional, cb) => {
      dispatch(updateProfileKey(id, key, value, isAdditional, cb));
    },
    qrCodeGenerate:(payload,cb)=>dispatch(qrCodeGenerate(payload,cb)),
    userAuthenticationType:(payload,cb)=>dispatch(userAuthenticationType(payload,cb)),
    verifyQrOTPProfile :(payload,cb)=>dispatch(verifyQrOTPProfile(payload,cb)),
    verifyOTP: (payload, cb) => dispatch(VerifyOTP(payload, cb)),
  };
};

export const ProfileContainer = () => {
  let location = useLocation();
  let ComponentToRender; 

  switch (location.pathname) {
    case "/Profile":
      ComponentToRender = ProfileComponent;
      break;
    case "/ChangePassword":
      ComponentToRender = ChangePassword;
      break;
    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
