import { connect } from "react-redux";
import { LoginComponent } from "../components";
// Actions
import {
  LoginAuth,
  PageLoader,
  LoginAuthErrorReset,
  VerifyOTP,
  forgotPassword,
  qrCodeGenerate,
  verifyQrOTP
} from "../store/actions";

const mapStateToProps = (state) => ({
  isLoggedIn: state?.loginAuth?.isLoggedIn,
  isloading: state?.loginAuth?.loading,
  isVerifyOYPloading: state?.loginAuth?.verifyOTP?.isLoading,
  isforgetloading: state?.loginAuth?.forgetPassord?.isLoading,
  errorMessage: state?.loginAuth?.errorMessage,
  error: state?.loginAuth?.error,
  isOtpError: state?.loginAuth?.verifyOTP?.error,
  otpErrorMessage: state?.loginAuth?.verifyOTP?.errorMessage,
  isQrCodeGenerating :state?.loginAuth?.qrCodeGenerate?.isLoading,
   isVerifyQrOtploading: state?.loginAuth?.verifyQrOTP?.isLoading
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoginAuth: (payload, cb) => dispatch(LoginAuth(payload, cb)),
    onResetError: () => dispatch(LoginAuthErrorReset()),
    verifyOTP: (payload, cb) => dispatch(VerifyOTP(payload, cb)),
    onForgetPassword: (payload, cb) => dispatch(forgotPassword(payload, cb)),
    qrCodeGenerate:(payload,cb)=>dispatch(qrCodeGenerate(payload,cb)),
    verifyQrOTP :(payload,cb)=>dispatch(verifyQrOTP(payload,cb))
  };
};

export const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
