// LoginScreen.js
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  APPTYPE,
  APPTYPE_OBJ,
  CATEGORY,
  CATEGORY_IN_OBJ,
  STATUS,
  STATUS_OBJ,
  checkAndReturnNone,
  elementToPrint,
  formatDate,
  validateName,
} from "../../helpers";
import {
  Loader,
  ProfileModal,
  TwilioVoice,
  UpdateTooltip,
} from "../Common";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/actions/profile";
import { calendarSearch, clearEvents } from "../../store/actions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F6FA",
    color: "#212223",
    fontWeight: "600",
    border: 0,
    padding: "15px 15px",
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
 
  "td, th": {
    border: 0,
    padding: "12px 15px",
    color: "#6B7489!important",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    padding: "12px 15px",
  },
}));

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: 3,
  borderColor: "#6DD6C2",
  p: 3,
  borderRadius: "7px",
  "&:focus-visible": {
    outline: "0",
  },
};

export const ShowLead = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { format, isValid } = require("date-fns");

  const {
    onLeadUpdateKey,
    getLeadDetails,
    getAllCampaignNames,
    createNewNote,
    onDeleteNote,
    getAllNotes,
    returnCredit,
    getReturnCredit,
    updateAppointmentStatus,
    updateAppointmentKey,
    onDeleteAppointment,
    onCreateAppointment,
    deleteReturnCredit,
  } = props;

  const { leadData } = location.state || {};

  const [newNote, setNewNote] = useState("");

  const [credit, setCredit] = useState("");

  const [triedSubmit, setTriedSubmit] = useState(false);
  const [triedSubmitForApp, setTriedSubmitForApp] = useState(false);

  const [triedSubmitCredit, setTriedSubmitCredit] = useState(false);

  const [open, setOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //handle errors
  const newNoteNameError =
    triedSubmit && (newNote?.trim().length === 0 || !validateName(newNote));
  const newNoteNameErrorMessage =
    triedSubmit && (newNote?.trim().length === 0 || !validateName(newNote))
      ? "Please enter valid note details"
      : "";

  const creditError = triedSubmitCredit && credit?.trim().length === 0;
  const creditErrorMessage =
    triedSubmitCredit && credit?.trim().length === 0
      ? "Please enter valid credit details"
      : "";

  const isFromValid = newNote?.trim().length > 0 && validateName(newNote);

  const isFromValidForCredit = credit?.trim().length > 0;

  useEffect(() => {
    leadData && getLeadDetails(leadData?.id);
    getNotes();
    getReturnCredit({ lead: leadData?.id });
  }, [leadData]);

  useEffect(() => {
    // Retrieve the data from localStorage

    userInfo?.user_type !== "Client" && getAllCampaignNames();

    const jsonData = localStorage.getItem("newTabState");

    if (jsonData) {
      localStorage.removeItem("newTabState");
    }

    if (navigator.geolocation) {
      // Get the current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation(`${latitude},${longitude}`);
        },
        (error) => {
          // console.error("Error getting current location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);

  const leadDetails = useSelector((state) => state?.lead?.leadShow);
  const leadInfo = true ? leadDetails : leadData;
  
  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const [isShow, setIsShow] = useState();
  const [currentLocation, setCurrentLocation] = useState(null);

  const [open1, set1Open] = React.useState({ value: null, isOpen: false });

  const isUpdateKeyLoading = useSelector((state) => state?.lead?.isLoading);
  const isAppointmentStatusUpdate = useSelector(
    (state) => state?.lead?.isAppointmentStatusUpdate
  );

  const allCampaigns = useSelector(
    (state) => state?.campaign?.campaignNamesWithID?.results
  );
  // debugger
  const isCalendarLoading = useSelector(
    (state) => state?.appointment?.isCalendarLoading
  );

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);
  const createNoteLoading = useSelector((state) => state?.lead?.notes?.loading);
  const notes = useSelector((state) => state?.lead?.notes?.data);

  const returnCreditLoading = useSelector(
    (state) => state?.lead?.credit?.loading
  );

  const isdeleteLoading = useSelector((state) => state?.appointment?.isLoading);
  const creditInfo = useSelector((state) => state?.lead?.credit?.data?.data);

  const profileData = useSelector((state) => state?.profile);

  // create appointment
  const initialAppointmentData = {
    appointmentDate: "",
    appointmentTime: dayjs(),
    confirmAppointmentDate: "",
    confirmAppointmentTime: dayjs(),
    appointmentType: "",
    status: "",
    comment: "",
  };

  const [appointmentData, setAppointmentData] = useState(
    initialAppointmentData
  );

  const appointmentDateError =
    triedSubmitForApp &&
    appointmentData?.appointmentDate?.toString()?.trim().length === 0;

  const appointmentDateErrorMessage =
    triedSubmitForApp && appointmentData?.appointmentDate?.trim().length === 0
      ? "Please enter valid date"
      : "";

  const appointmentTimeError =
    triedSubmitForApp &&
    appointmentData?.appointmentTime?.toString()?.trim().length === 0;

  const appointmentTimeErrorMessage =
    triedSubmitForApp &&
    appointmentData?.appointmentTime?.toString()?.trim().length === 0
      ? "Please enter valid appointment time "
      : "";
  const confirmAppointmentDateError =
    triedSubmitForApp &&
    (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentDate !==
        appointmentData?.confirmAppointmentDate);

  const confirmAppointmentDateErrorMessage =
    triedSubmitForApp &&
    (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentDate !==
        appointmentData?.confirmAppointmentDate)
      ? "Please enter valid confirm appointment date "
      : "";

  const confirmAppointmentTimeError =
    triedSubmitForApp &&
    (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentTime?.toString() !==
        appointmentData?.confirmAppointmentTime?.toString());

  const confirmAppointmentTimeErrorMessage =
    triedSubmitForApp &&
    (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentTime?.toString() !==
        appointmentData?.confirmAppointmentTime?.toString())
      ? "Please enter valid confirm appointment time "
      : "";

  const appointmentTypeError =
    triedSubmitForApp &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0;

  const appointmentTypeErrorMessage =
    triedSubmitForApp &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0
      ? "Please enter valid appointment type "
      : "";

  const handleChangeOptions = (event, type) => {
    switch (type) {
      case "appointmentType":
        setAppointmentData({
          ...appointmentData,
          appointmentType: event.target.value,
        });
        break;
      case "status":
        setAppointmentData({
          ...appointmentData,
          status: event.target.value,
        });
        break;

      default:
        break;
    }
  };

  const isCheckFormValid = () => {
    const isApppointmentDateValid =
      appointmentData?.appointmentDate?.toString()?.trim().length > 0;

    const isAppointmentTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim().length > 0;

    const isCNFApppointmentDateValid =
      appointmentData?.confirmAppointmentDate?.toString()?.trim().length > 0;

    const isCNFAppointmentTimeValid =
      appointmentData?.confirmAppointmentTime?.toString()?.trim().length > 0;

    const isAppointmentTypeValid =
      appointmentData?.appointmentType?.toString()?.trim().length > 0;

    const isconfirmTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim() ===
      appointmentData?.confirmAppointmentTime?.toString()?.trim();

    const isconfirmDateValid =
      appointmentData?.appointmentDate?.toString()?.trim() ===
      appointmentData?.confirmAppointmentDate?.toString()?.trim();
    return (
      isApppointmentDateValid &&
      isAppointmentTimeValid &&
      isCNFApppointmentDateValid &&
      isCNFAppointmentTimeValid &&
      isAppointmentTypeValid &&
      isconfirmTimeValid &&
      isconfirmDateValid
    );
  };

  const isFormValid = isCheckFormValid();

  const onSubmitHandler = () => {
    setTriedSubmitForApp(true);
    if (!isFormValid) return;

    const otherPayload = {
      agentId: leadInfo?.agent?.id,
      agentCallingFor: leadInfo?.agency_calling_for,
      lead: leadInfo?.id,
    };

    let payload = {
      ...otherPayload,
      ...appointmentData,
    };

    onCreateAppointment(payload, () => {
      onChangeLeadKey("category", 6);
      onCloseAppModal();
    });
  };

  const handle1ClickOpen = (value) => {
    set1Open({ value: value, isOpen: true });
  };

  const openNewLeadTab = (id) => {
    const dataToSend = {
      isNewTab: true,
      agent_id: id,
    };

    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabStateForCalendar", jsonData);

    // Open a new tab
    const newTab = window.open("/Calendar", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getNotes = () => {
    const payload = {
      lead_id: leadData?.id ?? null,
    };
    getAllNotes(payload);
  };

  const onCreateNewNote = () => {
    setTriedSubmit(true);
    if (!isFromValid) {
      return;
    }
    const payload = {
      body: newNote,
      lead: leadInfo?.id ?? null,
    };
    //  console.log("payload--->",payload);
    createNewNote(payload, () => {
      getNotes();
      setTriedSubmit(false);
      setNewNote("");
    });
  };

  const onReturnCredit = () => {
    setTriedSubmitCredit(true);
    if (!isFromValidForCredit) {
      return;
    }
    const payload = {
      lead: leadInfo?.id ?? null,
      reason: credit,
    };
    returnCredit(payload, () => {
      getReturnCredit({ lead: leadInfo?.id });
      setTriedSubmitCredit(false);
      setCredit("");
    });
  };

  const findVerifiedByid = (status) => {
    return (
      [
        {
          id: true,
          label: "True",
        },
        {
          id: false,
          label: "False",
        },
      ].find((st) => st.id == status) || null
    );
  };

  const getCalenderData = (leadData) => {
    dispatch(clearEvents());
    dispatch(
      calendarSearch(leadData.agent.id, () => {
        openNewLeadTab(leadData.agent.id);
      })
    );
  };

  const handle1Close = () => {
    setErrors({});
    set1Open({ value: null, isOpen: false });
  };

  const handleOpenGoogleMapsForCurrentLocation = () => {
    if (currentLocation) {
      // Construct the Google Maps URL for directions
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation}&destination=${leadInfo?.address},${leadInfo?.city},${leadInfo?.state}&travelmode=driving`;

      // Open a new tab with the Google Maps URL
      const newTab = window.open(googleMapsUrl, "_blank");

      if (newTab) {
        newTab.focus();
      }
    }
  };

  const handleOpenGoogleMapsForAgentLocation = () => {
    if (leadInfo?.agent) {
      // Construct the Google Maps URL for directions
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${leadInfo?.agent?.street},${leadInfo?.agent?.city},${leadInfo?.agent?.state}&destination=${leadInfo?.address},${leadInfo?.city},${leadInfo?.state}&travelmode=driving`;
      // Open a new tab with the Google Maps URL
      const newTab = window.open(googleMapsUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    }
  };

  const viewUserProfile = (agent) => {
    const payload = {
      agent_id: agent.id,
    };
    dispatch(
      getUserProfile(payload, () => {
        setIsShow(true);
      })
    );
  };

  const [open2, set2Open] = React.useState(false);

  const handle2ClickOpen = () => {
    set2Open(true);
  };

  const handle2Close = () => {
    set2Open(false);
  };

  function getOptionName(input, key) {
    if (key === "status") {
      const optionName = STATUS[input];
      return optionName || "none";
    } else {
      const optionName = APPTYPE[input];
      return optionName || "None";
    }
  }

  const findLeadCategoryByid = (catId) => {
    return CATEGORY_IN_OBJ.find((cat) => cat.id == catId) || null;
  };

  const findLeadAppTypeByid = (appId) => {
    return APPTYPE_OBJ.find((app) => app.id == appId) || null;
  };

  const findLeadAppStatusByid = (statusId) => {
    return STATUS_OBJ.find((status) => status.id == statusId) || null;
  };

  const findCampaignAppByid = (statusId) => {
    return allCampaigns?.find((status) => status.id == statusId) || null;
  };

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const keyCss = {
    fontSize: 13,
    color: "#0088cc",
    fontWeight: 500,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#0088cc",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    maxWidth: "fit-content",
    position: "relative",
    cursor: "pointer",
  };

  const onChangeLeadKey = (key, val) => {
    onLeadUpdateKey(leadInfo?.id, key, val, () => {
      getLeadDetails(leadInfo?.id);
      handleTooltipClose();
    });
  };

  const onChangeAppointmentKey = (key, val) => {
    updateAppointmentKey(leadInfo?.appointment_data?.id, key, val, () => {
      getLeadDetails(leadInfo?.id);
      handleTooltipClose();
      if (open1.value === "app_rescheduled") {
        setErrors({});
        handle1Close();
      }
    });
  };

  const [formData, setFormData] = useState({
    appointmentDate: "",
    confirmAppointmentDate: "",
    appointmentTime: "",
    confirmAppointmentTime: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFormSubmit = () => {
    // Validation logic
    const newErrors = {};

    if (!formData.appointmentDate) {
      newErrors.appointmentDate = "Appointment Date is required";
    }

    if (!formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate = "Confirm Appointment Date is required";
    }

    if (!formData.appointmentTime) {
      newErrors.appointmentTime = "Appointment Time is required";
    }

    if (!formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime = "Confirm Appointment Time is required";
    }

    if (formData.appointmentDate !== formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate =
        "Confirm Appointment date and Appointment date is not same";
    }

    if (formData.appointmentTime !== formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime =
        "Confirm Appointment time and Appointment time is not same";
    }

    // Add additional validation logic, e.g., date/time matching

    if (Object.keys(newErrors).length > 0) {
      // There are errors, don't submit the form
      setErrors(newErrors);
    } else {
      const formattedDate = moment(
        formData?.confirmAppointmentDate,
        "YYYY-MM-DD"
      ).format("MM-DD-YYYY");

      const payload = {
        appointment_id: leadInfo?.appointment_data?.id,
        appointment_date: formattedDate,
        appointment_time: formData.confirmAppointmentTime,
        lead_status:
          open1.value !== null
            ? open1?.value === "app_rescheduled"
              ? 6
              : parseInt(open1?.value, 10)
            : 6,
      };

      updateAppointmentStatus(payload, () => {
        if (open1?.value === "app_rescheduled") {
          onChangeAppointmentKey("status", 4);
        } else {
          getLeadDetails(leadData?.id);
          setErrors({});
          handle1Close();
        }
      });
    }
  };
  let appoinment_date = moment(
    leadInfo?.appointment_data?.appointment_date
  ).format("MMM D YYYY");

  useEffect(() => {
    appoinment_date = moment(
      leadInfo?.appointment_data?.appointment_date
    ).format("MMM D YYYY");
  }, [leadInfo]);

  function getOptionNameForCat(input) {
    const optionName = CATEGORY[input];
    return optionName || "none";
  }

  const deleteNote = (id) => {
    onDeleteNote(id, getNotes);
  };

  const onChangeNotes = (id, val) => {
    const payload = {
      note_id: id,
      value: val,
    };
    createNewNote(
      payload,
      () => {
        handleTooltipClose();
        getNotes();
      },
      true
    );
  };

  const onDeleteLeadAppoinment = () => {
    handle2Close();
    onDeleteAppointment(leadInfo?.appointment_data?.id, () => {
      onChangeLeadKey("category", 4);
    });
  };

  const openAppModal = () => {
    setAppModalOpen(true);
  };

  const onCloseAppModal = () => {
    setAppModalOpen(false);
  };

  const onDeleteCredit = (id) => {
    deleteReturnCredit(id, () => {
      getReturnCredit({ lead: leadData?.id });
    });
  };

  return (
    <>
      <Box
        id="show-lead-page"
        sx={{
          margin: "0 20px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Lead Contact Information
                  </Typography>
                </AccordionSummary>
                <Button
                  variant="contained"
                  onClick={() => {
                    elementToPrint("ShowLeadPage");
                  }}
                  sx={{
                    mr: 2,
                    textTransform: "capitalize",
                    width: "100%",
                    maxWidth: "max-content",
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Print
                </Button>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            First Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 1 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"First Name"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("first_name", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.first_name
                                );
                                setData(val);
                                handleTooltipOpen(1);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.first_name)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Full Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {`${checkAndReturnNone(
                              leadInfo?.first_name
                            )}, ${checkAndReturnNone(leadInfo?.last_name)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Title
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 2 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Title"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("title", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(leadInfo?.title);
                                setData(val);
                                handleTooltipOpen(2);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.title)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Phone Number
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 3 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Phone Number"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("phone_number", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    leadInfo?.phone_number
                                  );
                                  setData(val);
                                  handleTooltipOpen(3);
                                }}
                                mr={2}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(leadInfo?.phone_number)}
                              </Typography>
                              {leadInfo?.phone_number &&
                                userInfo?.user_type !== "Client" && (
                                  <TwilioVoice
                                    phoneNumber={leadInfo?.phoneNumber}
                                    fontColor={"#6B7489"}
                                    fontSize={13}
                                  />
                                )}
                            </Box>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Website
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 4 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Website"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("website", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.website
                                );
                                setData(val);
                                handleTooltipOpen(4);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.website)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Last Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 5 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Last Name"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("last_name", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.last_name
                                );
                                setData(val);
                                handleTooltipOpen(5);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.last_name)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Company Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 6 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Company Name"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("company_name", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.company_name
                                );
                                setData(val);
                                handleTooltipOpen(6);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.company_name)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Email Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 7 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Email"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("email", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(leadInfo?.email);
                                setData(val);
                                handleTooltipOpen(7);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.email)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Alternate Phone Number
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 8 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Alternate Phone Number"}
                                onSubmit={(value) => {
                                  onChangeLeadKey(
                                    "alternative_phone_number",
                                    value
                                  );
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    leadInfo?.alternative_phone_number
                                  );
                                  setData(val);
                                  handleTooltipOpen(8);
                                }}
                                mr={2}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(
                                  leadInfo?.alternative_phone_number
                                )}
                              </Typography>
                              {checkAndReturnNone(
                                leadInfo?.alternative_phone_number
                              ) !== "None" &&
                                userInfo?.user_type !== "Client" && (
                                  <TwilioVoice
                                    phoneNumber={
                                      leadInfo?.alternative_phone_number
                                    }
                                    fontColor={"#6B7489"}
                                    fontSize={13}
                                  />
                                )}
                            </Box>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Appointments Information
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Agent
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            onClick={() => {
                              viewUserProfile(leadInfo?.agent);
                            }}
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {checkAndReturnNone(leadInfo?.agent_name)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Campaign
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type === "Client" ? (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                                textTransform: "capitalize",
                              }}
                            >
                              {checkAndReturnNone(leadInfo?.campaign_name)}
                            </Typography>
                          ) : (
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === 30 ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Campaign"}
                                  onSubmit={(value) => {
                                    onChangeLeadKey(
                                      "agency_calling_for",
                                      value
                                    );
                                  }}
                                  selectedKey={
                                    findCampaignAppByid(
                                      leadInfo?.agency_calling_for
                                    )?.name
                                  }
                                  inputType={"SelectOptions"}
                                  labelKey={"name"}
                                  optionsData={allCampaigns}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    findCampaignAppByid(
                                      leadInfo?.agency_calling_for
                                    )?.name
                                  );
                                  setData(val);
                                  handleTooltipOpen(30);
                                }}
                                sx={{
                                  fontSize: 15,
                                  color: "#0088cc",
                                  fontWeight: 500,
                                  borderStyle: "dashed",
                                  borderWidth: 1,
                                  borderColor: "#0088cc",
                                  borderTop: 0,
                                  borderRight: 0,
                                  textTransform: "capitalize",
                                  borderLeft: 0,
                                  maxWidth: "fit-content",
                                }}
                              >
                                {checkAndReturnNone(
                                  findCampaignAppByid(
                                    leadInfo?.agency_calling_for
                                  )?.name
                                )}
                              </Typography>
                            </Tooltip>
                          )}
                          {/* <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                              borderStyle: "dashed",
                              borderWidth: 1,
                              borderColor: "#0088cc",
                              borderTop: 0,
                              borderRight: 0,
                              borderLeft: 0,
                              maxWidth: "fit-content",
                            }}
                          >
                            {checkAndReturnNone(leadInfo?.campaign_name)}
                          </Typography> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type !== "Client" && (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Calendar
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type !== "Client" && (
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={() => {
                                // navigate("/Calendar", {
                                //   state: {
                                //     isViewCalendar: true,
                                //     data: leadInfo,
                                //   },
                                // });
                                getCalenderData(leadInfo);
                              }}
                              sx={{
                                fontSize: 13,
                                color: "#0088cc",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}
                            >
                              Show Calendar
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {leadInfo?.appointment_data !== null ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                Appointment Date
                              </StyledTableCell>
                              <StyledTableCell>
                                Appointment Time
                              </StyledTableCell>
                              <StyledTableCell>
                                Appointment Type
                              </StyledTableCell>
                              <StyledTableCell>
                                Appointment Status
                              </StyledTableCell>
                              {userInfo?.user_type === "Administration" && (
                                <StyledTableCell>Operations</StyledTableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell component="th">
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  onClick={() => {
                                    handle1ClickOpen(null);
                                  }}
                                  sx={{
                                    fontSize: 15,
                                    color: "#0088cc",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                >
                                  {checkAndReturnNone(appoinment_date)}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  onClick={() => {
                                    handle1ClickOpen(null);
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: 15,
                                    color: "#0088cc",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                >
                                  {checkAndReturnNone(
                                    moment(
                                      leadInfo?.appointment_data
                                        ?.appointment_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")
                                  )}
                                </Typography>
                                <Modal
                                  open={open1.isOpen}
                                  onClose={handle1Close}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box sx={style}>
                                    <Typography
                                      component="h2"
                                      variant="h2"
                                      sx={{
                                        fontSize: 18,
                                        color: "#212223",
                                        fontWeight: 600,
                                        backgroundColor: "#90949f21",
                                        pt: 1.5,
                                        pl: 1.5,
                                        pb: 1.5,
                                        mb: 2.5,
                                      }}
                                    >
                                      Rescheduled Date Time
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h2"
                                        variant="h2"
                                        sx={{
                                          fontSize: 15,
                                          color:
                                            "#6B7489                        ",
                                          fontWeight: 600,
                                          marginBottom: "0px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        Appointment Date
                                      </Typography>
                                      <Box
                                        sx={{ marginTop: "10px", gap: "10px" }}
                                      >
                                        <Box>
                                          <TextField
                                            name="appointmentDate"
                                            type="date"
                                            size="small"
                                            value={formData.appointmentDate}
                                            onChange={(e) =>
                                              handleInputChange(
                                                "appointmentDate",
                                                e.target.value
                                              )
                                            }
                                            error={!!errors.appointmentDate}
                                            helperText={errors.appointmentDate}
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                backgroundColor: "#fff",
                                              },
                                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#6DD6C2",
                                                },
                                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor:
                                                    "#6DD6C2!important",
                                                },
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Typography
                                        component="h2"
                                        variant="h2"
                                        sx={{
                                          fontSize: 15,
                                          color:
                                            "#6B7489                        ",
                                          fontWeight: 600,
                                          marginBottom: "0px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        Confirm Appointment Date
                                      </Typography>
                                      <Box
                                        sx={{ marginTop: "10px", gap: "10px" }}
                                      >
                                        <Box>
                                          <TextField
                                            name="confirmAppointmentDate"
                                            type="date"
                                            size="small"
                                            value={
                                              formData.confirmAppointmentDate
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                "confirmAppointmentDate",
                                                e.target.value
                                              )
                                            }
                                            error={
                                              !!errors.confirmAppointmentDate
                                            }
                                            helperText={
                                              errors.confirmAppointmentDate
                                            }
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                backgroundColor: "#fff",
                                              },
                                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#6DD6C2",
                                                },
                                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor:
                                                    "#6DD6C2!important",
                                                },
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Typography
                                        component="h2"
                                        variant="h2"
                                        sx={{
                                          fontSize: 15,
                                          color:
                                            "#6B7489                        ",
                                          fontWeight: 600,
                                          marginBottom: "0px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        Appointment Time :
                                      </Typography>
                                      <Box
                                        sx={{
                                          marginTop: "10px",
                                          display: "flex",
                                          gap: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            ampm={true}
                                            focused
                                            type="time"
                                            label="Time"
                                            name="appointmentTime"
                                            size="small"
                                            value={formData.appointmentTime}
                                            onChange={(e) =>
                                              handleInputChange(
                                                "appointmentTime",
                                                e.target.value
                                              )
                                            }
                                            error={!!errors.appointmentTime}
                                            helperText={errors.appointmentTime}
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                backgroundColor: "#fff",
                                              },
                                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#6DD6C2",
                                                },
                                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor:
                                                    "#6DD6C2!important",
                                                },
                                              width: "100%",
                                            }}
                                          />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            name="confirmAppointmentTime"
                                            type="time"
                                            size="small"
                                            value={
                                              formData.confirmAppointmentTime
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                "confirmAppointmentTime",
                                                e.target.value
                                              )
                                            }
                                            error={
                                              !!errors.confirmAppointmentTime
                                            }
                                            helperText={
                                              errors.confirmAppointmentTime
                                            }
                                            label="Confirm Time"
                                            focused
                                            ampm={true}
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                backgroundColor: "#fff",
                                              },
                                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#6DD6C2",
                                                },
                                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor:
                                                    "#6DD6C2!important",
                                                },

                                              width: "100%",
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderTop: 1,
                                        borderColor: "#dcdcdc",
                                        mt: 3.5,
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={handleFormSubmit}
                                        sx={{
                                          mt: 2.5,
                                          mr: 2,
                                          textTransform: "capitalize",
                                          width: "100%",
                                          maxWidth: "max-content",
                                          backgroundColor: "#6DD6C2",
                                          borderColor: "#6DD6C2",
                                          color: "#212223",
                                          fontWeight: 600,
                                          "&:hover": {
                                            backgroundColor: "#6DD6C2",
                                            borderColor: "#6DD6C2",
                                          },
                                        }}
                                      >
                                        Submit
                                      </Button>
                                      <Button
                                        onClick={handle1Close}
                                        variant="contained"
                                        sx={{
                                          mt: 2.5,
                                          textTransform: "capitalize",
                                          width: "100%",
                                          maxWidth: "max-content",
                                          backgroundColor: "#000",
                                          borderColor: "#6DD6C2",
                                          color: "#fff",
                                          fontWeight: 600,
                                          "&:hover": {
                                            backgroundColor: "#000",
                                            borderColor: "#6DD6C2",
                                          },
                                        }}
                                      >
                                        Close
                                      </Button>
                                    </Box>
                                  </Box>
                                </Modal>
                              </StyledTableCell>
                              <StyledTableCell>
                                {userInfo?.user_type === "Client" ? (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(
                                      findLeadAppTypeByid(
                                        leadInfo?.appointment_data
                                          ?.appointment_type
                                      )?.label
                                    )}
                                  </Typography>
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 27 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Appointment Type"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey(
                                          //   "appointment_type",
                                          //   value
                                          // );
                                          onChangeAppointmentKey(
                                            "appointment_type",
                                            value.toString()
                                          );
                                        }}
                                        selectedKey={
                                          findLeadAppTypeByid(
                                            leadInfo?.appointment_data
                                              ?.appointment_type
                                          )?.label
                                        }
                                        inputType={"SelectOptions"}
                                        labelKey={"label"}
                                        optionsData={APPTYPE_OBJ}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          leadInfo?.appointment_data
                                            ?.appointment_type
                                        );
                                        setData(val);
                                        handleTooltipOpen(27);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color: "#0088cc",
                                        fontWeight: 600,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        cursor: "pointer",
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        findLeadAppTypeByid(
                                          leadInfo?.appointment_data
                                            ?.appointment_type
                                        )?.label
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {userInfo?.user_type === "Client" ? (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(
                                      getOptionName(
                                        leadInfo?.appointment_data?.status,
                                        "type"
                                      )
                                    )}
                                  </Typography>
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 28 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Appointment Status"}
                                        onSubmit={(value) => {
                                          if (value == 4) {
                                            handleTooltipClose();
                                            handle1ClickOpen("app_rescheduled");
                                          } else {
                                            onChangeAppointmentKey(
                                              "status",
                                              value
                                            );
                                          }
                                        }}
                                        selectedKey={
                                          findLeadAppStatusByid(
                                            leadInfo?.appointment_data?.status
                                          )?.label
                                        }
                                        inputType={"SelectOptions"}
                                        labelKey={"label"}
                                        optionsData={STATUS_OBJ}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          findLeadAppStatusByid(
                                            leadInfo?.appointment_data?.status
                                          )?.label
                                        );
                                        setData(val);
                                        handleTooltipOpen(28);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color: "#0088cc",
                                        fontWeight: 600,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        getOptionName(
                                          leadInfo?.appointment_data?.status,
                                          "status"
                                        )
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </StyledTableCell>

                              {userInfo?.user_type === "Administration" && (
                                <StyledTableCell>
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={handle2ClickOpen}
                                    sx={{
                                      cursor: "pointer",
                                      fontSize: 15,
                                      color: "#0088cc",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Delete
                                  </Typography>
                                  <Modal
                                    open={open2}
                                    onClose={handle2Close}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                  >
                                    <Box sx={style}>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          cursor: "pointer",
                                          fontSize: 16,
                                          color: "#000",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Are You Sure, You really want to delete
                                        appointment ?
                                      </Typography>

                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "#dcdcdc",
                                          mt: 3,
                                          textAlign: "end",
                                        }}
                                      >
                                        <Button
                                          onClick={handle2Close}
                                          variant="contained"
                                          sx={{
                                            mt: 2.5,
                                            textTransform: "capitalize",
                                            width: "100%",
                                            maxWidth: "max-content",
                                            backgroundColor: "#000",
                                            borderColor: "#6DD6C2",
                                            color: "#fff",
                                            fontWeight: 600,
                                            "&:hover": {
                                              backgroundColor: "#000",
                                              borderColor: "#6DD6C2",
                                            },
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="contained"
                                          onClick={onDeleteLeadAppoinment}
                                          sx={{
                                            mt: 2.5,
                                            ml: 2,
                                            textTransform: "capitalize",
                                            width: "100%",
                                            maxWidth: "max-content",
                                            backgroundColor: "#6DD6C2",
                                            borderColor: "#6DD6C2",
                                            color: "#212223",
                                            fontWeight: 600,
                                            "&:hover": {
                                              backgroundColor: "#6DD6C2",
                                              borderColor: "#6DD6C2",
                                            },
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Modal>
                                </StyledTableCell>
                              )}
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => {
                          openAppModal();
                        }}
                        sx={{
                          textTransform: "capitalize",
                          width: "100%",
                          maxWidth: "max-content",
                          backgroundColor: "#6DD6C2",
                          borderColor: "#6DD6C2",
                          color: "#212223",
                          marginTop: -3,
                          fontWeight: 600,
                          "&:hover": {
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                          },
                        }}
                      >
                        Create Appointment
                      </Button>
                    </>
                  )}
                  {leadInfo?.appointment_data !== null && (
                    <Box sx={{ mt: 3, display: "flex", flexDirection: "row" }}>
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 16,
                          color: "#212223",
                          fontWeight: 600,
                          marginRight: 2,
                        }}
                      >
                        Appointment Comment :
                      </Typography>
                      <Tooltip
                        sx={{
                          marginTop: "0!important",
                          " & .MuiTooltip-tooltip": {
                            marginTop: "0!important",
                          },
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          borderColor: "black",
                        }}
                        arrow
                        placement="bottom-start"
                        PopperProps={{
                          disablePortal: false,
                        }}
                        onClose={handleTooltipClose}
                        open={tooltipID === 29 ? true : false}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <UpdateTooltip
                            onClose={handleTooltipClose}
                            label={"Comments"}
                            onSubmit={(value) => {
                              onChangeAppointmentKey("comment", value);
                            }}
                            inputType={"Text"}
                            value={data}
                            setValue={setData}
                          />
                        }
                      >
                        <Typography
                          component="h4"
                          variant="h4"
                          onClick={(event) => {
                            event.stopPropagation();
                            const val = checkAndReturnNone(
                              leadInfo?.appointment_data?.comment
                            );
                            setData(val);
                            handleTooltipOpen(29);
                          }}
                          sx={{
                            fontSize: 15,
                            color: "#0088cc",
                            fontWeight: 600,
                            borderStyle: "dashed",
                            borderWidth: 1,
                            borderColor: "#0088cc",
                            borderTop: 0,
                            borderRight: 0,
                            borderLeft: 0,
                            cursor: "pointer",
                            maxWidth: "fit-content",
                          }}
                        >
                          {checkAndReturnNone(
                            leadInfo?.appointment_data?.comment
                          )}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Lead Information
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Lead Category
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type === "Administration" ? (
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === 9 ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Lead Category"}
                                  onSubmit={(value) => {
                                    if (value == 6 || value == 7) {
                                      handleTooltipClose();
                                      handle1ClickOpen(value);
                                    } else if (value == 4) {
                                      if (
                                        userInfo?.user_type === "Administration"
                                      ) {
                                        handleTooltipClose();
                                        handle2ClickOpen();
                                      } else {
                                        handleTooltipClose();
                                      }
                                    } else {
                                      onChangeLeadKey("category", value);
                                    }
                                  }}
                                  selectedKey={
                                    findLeadCategoryByid(leadInfo?.category)
                                      ?.label
                                  }
                                  inputType={"SelectOptions"}
                                  labelKey={"label"}
                                  optionsData={CATEGORY_IN_OBJ}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    leadInfo?.category
                                  );
                                  setData(val);
                                  handleTooltipOpen(9);
                                }}
                                sx={keyCss}
                              >
                                {getOptionNameForCat(leadInfo?.category)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {getOptionNameForCat(leadInfo?.category)}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Primary SIC Code
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 10 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"SIC Code"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("sic_code", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.sic_code
                                );
                                setData(val);
                                handleTooltipOpen(10);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.sic_code)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Business Class
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={
                              tooltipID === "sic_code_description"
                                ? true
                                : false
                            }
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Business Class"}
                                onSubmit={(value) => {
                                  onChangeLeadKey(
                                    "sic_code_description",
                                    value
                                  );
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.sic_code_description
                                );
                                setData(val);
                                handleTooltipOpen("sic_code_description");
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(
                                leadInfo?.sic_code_description
                              )}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Years In Business
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 11 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Years In Business"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("year_in_business", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.year_in_business
                                );
                                setData(val);
                                handleTooltipOpen(11);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.year_in_business)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Created
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {isValid(new Date(leadInfo?.created_at))
                              ? format(
                                  new Date(leadInfo?.created_at),
                                  "MMMM d, yyyy, h:mm a"
                                )
                              : leadInfo?.created_at}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type !== "Client" && (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Verified
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type === "Administration" ? (
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === 12 ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Verified"}
                                  onSubmit={(value) => {
                                    const val =
                                      value === true ? "True" : "False";
                                    onChangeLeadKey("is_verified", val);
                                  }}
                                  selectedKey={
                                    findVerifiedByid(leadInfo?.is_verified)
                                      ?.label
                                  }
                                  inputType={"SelectOptions"}
                                  labelKey={"label"}
                                  optionsData={[
                                    {
                                      id: true,
                                      label: "True",
                                    },
                                    {
                                      id: false,
                                      label: "False",
                                    },
                                  ]}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = leadInfo?.is_verified
                                    ? "True"
                                    : "False";
                                  setData(val);
                                  handleTooltipOpen(12);
                                }}
                                sx={keyCss}
                              >
                                {leadInfo?.is_verified ? "True" : "False"}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <>
                              {userInfo?.user_type !== "Client" && (
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 13,
                                    color: "#6B7489",
                                    fontWeight: 500,
                                  }}
                                >
                                  {leadInfo?.is_verified ? "True" : "False"}
                                </Typography>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Appointment Type
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {checkAndReturnNone(
                              findLeadAppTypeByid(
                                leadInfo?.appointment_data?.appointment_type
                              )?.label
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Appointment Set By
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {appoinment_date
                              ? checkAndReturnNone(leadInfo?.created_by_name)
                              : "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Modified
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {isValid(new Date(leadInfo?.modified_at))
                              ? format(
                                  new Date(leadInfo?.modified_at),
                                  "MMMM d, yyyy, h:mm a"
                                )
                              : leadInfo?.created_at}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Modified by
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#6B7489",
                              fontWeight: 500,
                            }}
                          >
                            {checkAndReturnNone(leadInfo?.modified_by_name)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Lead Address Information
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 13 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Address"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("address", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.address
                                );
                                setData(val);
                                handleTooltipOpen(13);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.address)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            State
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 14 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"State"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("state", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(leadInfo?.state);
                                setData(val);
                                handleTooltipOpen(14);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.state)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            County
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 15 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"County"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("county", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.county
                                );
                                setData(val);
                                handleTooltipOpen(15);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.county)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            City
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 16 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"City"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("city", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(leadInfo?.city);
                                setData(val);
                                handleTooltipOpen(16);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.city)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Zip Code
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 17 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Zip Code"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("zip_code", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.zip_code
                                );
                                setData(val);
                                handleTooltipOpen(17);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.zip_code)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Google Directions From
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleOpenGoogleMapsForCurrentLocation();
                            }}
                            sx={{
                              fontSize: "13px",
                              mr: 2,
                              textTransform: "capitalize",
                              width: "100%",
                              maxWidth: "max-content",
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                              color: "#212223",
                              fontWeight: 600,
                              "&:hover": {
                                backgroundColor: "#6DD6C2",
                                borderColor: "#6DD6C2",
                              },
                            }}
                          >
                            Current Location
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleOpenGoogleMapsForAgentLocation();
                            }}
                            sx={{
                              fontSize: "13px",
                              textTransform: "capitalize",
                              width: "100%",
                              maxWidth: "max-content",
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                              color: "#212223",
                              fontWeight: 600,
                              "&:hover": {
                                backgroundColor: "#6DD6C2",
                                borderColor: "#6DD6C2",
                              },
                            }}
                          >
                            Agent Location
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Insurance Information
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Liability Renewal Date
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 18 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={" Liability Renewal Date"}
                                onSubmit={(value) => {
                                  onChangeLeadKey(
                                    "liability_renewable_date",
                                    value
                                  );
                                }}
                                inputType={"Date"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.liability_renewable_date
                                );
                                setData(val);
                                handleTooltipOpen(18);
                              }}
                              sx={keyCss}
                            >
                              {leadInfo?.liability_renewable_date !== null
                                ? checkAndReturnNone(
                                    moment(
                                      leadInfo?.liability_renewable_date
                                    )?.format("MMM D YYYY")
                                  )
                                : "None"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Liability Carrier
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 19 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Liability Carrier"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("liability_carrier", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.liability_carrier
                                );
                                setData(val);
                                handleTooltipOpen(19);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.liability_carrier)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Total Locations
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 20 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Total Locations"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("total_locations", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.total_locations
                                );
                                setData(val);
                                handleTooltipOpen(20);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.total_locations)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Workers Comp Renewal Date
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 21 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Workers Comp Renewal Date"}
                                onSubmit={(value) => {
                                  onChangeLeadKey(
                                    "workers_comp_renewal_date",
                                    value
                                  );
                                }}
                                inputType={"Date"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.workers_comp_renewal_date
                                );
                                setData(val);
                                handleTooltipOpen(21);
                              }}
                              sx={keyCss}
                            >
                              {leadInfo?.workers_comp_renewal_date !== null
                                ? checkAndReturnNone(
                                    moment(
                                      leadInfo?.workers_comp_renewal_date
                                    )?.format("MMM D YYYY")
                                  )
                                : "None"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Workers Comp Carrier
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 22 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={" Workers Comp Carrier"}
                                onSubmit={(value) => {
                                  onChangeLeadKey(
                                    "workers_comp_carrier",
                                    value
                                  );
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.workers_comp_carrier
                                );
                                setData(val);
                                handleTooltipOpen(22);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(
                                leadInfo?.workers_comp_carrier
                              )}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Total Employees
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 23 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Total Employees"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("employee_size", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.employee_size
                                );
                                setData(val);
                                handleTooltipOpen(23);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.employee_size)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Auto Renewal Date
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 24 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Auto Renewal Date"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("auto_renewal_date", value);
                                }}
                                inputType={"Date"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.auto_renewal_date
                                );
                                setData(val);
                                handleTooltipOpen(24);
                              }}
                              sx={keyCss}
                            >
                              {leadInfo?.auto_renewal_date !== null
                                ? checkAndReturnNone(
                                    moment(leadInfo?.auto_renewal_date)?.format(
                                      "MMM D YYYY"
                                    )
                                  )
                                : "None"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Auto Carrier
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 26 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Auto Carrier"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("auto_carrier", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.auto_carrier
                                );
                                setData(val);
                                handleTooltipOpen(26);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.auto_carrier)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Total Vehicles
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 25 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Total Vehicles"}
                                onSubmit={(value) => {
                                  onChangeLeadKey("total_vehicles", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  leadInfo?.total_vehicles
                                );
                                setData(val);
                                handleTooltipOpen(25);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(leadInfo?.total_vehicles)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {userInfo?.user_type !== "Client" && (
          <Grid container spacing={3} sx={{ marginTop: "5px" }}>
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "15px",
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#90949f21",
                    borderTopLeftRadius: "10px!Important",
                    borderTopRightRadius: "10px!Important",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "#212223", pointerEvents: "auto" }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 18,
                        color: "#212223",
                        fontWeight: 600,
                      }}
                    >
                      Lead Notes Information | Total Count :
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 18,
                          color: "#6DD6C2",
                          fontWeight: 600,
                          display: "initial",
                          ml: 1,
                        }}
                      >
                        ( {notes?.data?.length ?? 0} )
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                </Box>
                <AccordionDetails>
                  <Box sx={{ pt: 2, pb: 1 }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Note Description</StyledTableCell>
                            <StyledTableCell>Created At</StyledTableCell>
                            <StyledTableCell>Created By</StyledTableCell>
                            {userInfo?.user_type === "Administration" && (
                              <StyledTableCell>Operations</StyledTableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notes?.data?.map((item, index) => {
                            return (
                              <>
                                <StyledTableRow>
                                  <StyledTableCell component="th">
                                    <Tooltip
                                      sx={{
                                        marginTop: "0!important",
                                        " & .MuiTooltip-tooltip": {
                                          marginTop: "0!important",
                                        },
                                        backgroundColor: "#ffffff",
                                        borderRadius: "5px",
                                        borderColor: "black",
                                      }}
                                      arrow
                                      placement="bottom-start"
                                      PopperProps={{
                                        disablePortal: false,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={
                                        tooltipID === `notes${index}`
                                          ? true
                                          : false
                                      }
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title={
                                        <UpdateTooltip
                                          onClose={handleTooltipClose}
                                          label={"Notes"}
                                          onSubmit={(value) => {
                                            onChangeNotes(item?.id, value);
                                          }}
                                          inputType={"Text"}
                                          value={data}
                                          setValue={setData}
                                        />
                                      }
                                    >
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        onClick={(event) => {
                                          event.stopPropagation();

                                          setData(item?.body);
                                          handleTooltipOpen(`notes${index}`);
                                        }}
                                        sx={keyCss}
                                      >
                                        {item?.body}
                                      </Typography>
                                    </Tooltip>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {formatDate(item?.created_at) ?? "None"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Typography
                                      sx={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        item?.created_by_name
                                      )}
                                    </Typography>
                                  </StyledTableCell>
                                  {userInfo?.user_type === "Administration" && (
                                    <StyledTableCell>
                                      <Typography
                                        onClick={() => {
                                          deleteNote(item?.id);
                                        }}
                                        sx={{
                                          fontSize: 13,
                                          color: "#0088cc",
                                          fontWeight: 500,
                                          borderTop: 0,
                                          borderRight: 0,
                                          borderLeft: 0,
                                          maxWidth: "fit-content",
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                </StyledTableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{ mt: 3 }}>
                      <TextField
                        margin="0"
                        fullWidth
                        size="small"
                        name="Newnote"
                        label="Create New Note"
                        type="text"
                        id="newnote"
                        autoComplete="current-password"
                        value={newNote}
                        onChange={(event) => {
                          setNewNote(event.target.value);
                        }}
                        error={newNoteNameError}
                        helperText={newNoteNameError && newNoteNameErrorMessage}
                        fontSize="15"
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#4fe1c6",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "55%",
                            lg: "55%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={onCreateNewNote}
                        sx={{
                          ml: 2,
                          textTransform: "capitalize",
                          width: "100%",
                          maxWidth: "max-content",
                          backgroundColor: "#6DD6C2",
                          borderColor: "#6DD6C2",
                          color: "#212223",
                          fontWeight: 600,
                          "&:hover": {
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                          },
                        }}
                      >
                        Save Note
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "5px", marginBottom: "50px" }}
        >
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    // pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 18,
                      color: "#212223",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    Return For Credit
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 18,
                        color: "#3663c9",
                        fontWeight: 600,
                        display: "initial",
                        ml: 1,
                      }}
                    >
                      <InfoIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpen();
                        }}
                      />
                    </Typography>
                  </Typography>
                </AccordionSummary>
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  {creditInfo?.length > 0 ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Reason
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(creditInfo[0]?.reason)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Requested at
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 13,
                                  color: "#6B7489",
                                  fontWeight: 500,
                                }}
                              >
                                {formatDate(creditInfo[0]?.created_at) ??
                                  "None"}
                              </Typography>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  onDeleteCredit(creditInfo[0]?.id);
                                }}
                                sx={{
                                  ml: 4,
                                  mr: 4,
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <TextField
                        label="Notes"
                        multiline
                        rows={3}
                        variant="outlined"
                        margin="0"
                        fullWidth
                        size="small"
                        name="notes"
                        type="text"
                        id="notes"
                        fontSize="15"
                        value={credit}
                        onChange={(event) => {
                          setCredit(event?.target?.value);
                        }}
                        error={creditError}
                        helperText={creditError && creditErrorMessage}
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#4fe1c6",
                              fontWeight: 600,
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "50%",
                            lg: "50%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={onReturnCredit}
                        sx={{
                          ml: 2,
                          textTransform: "capitalize",
                          width: "100%",
                          maxWidth: "max-content",
                          backgroundColor: "#6DD6C2",
                          borderColor: "#6DD6C2",
                          color: "#212223",
                          fontWeight: 600,
                          "&:hover": {
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                          },
                        }}
                      >
                        Return
                      </Button>
                    </>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Loader
          loading={
            isUpdateKeyLoading ||
            profileData?.isLoading ||
            isCalendarLoading ||
            isAppointmentStatusUpdate ||
            createNoteLoading ||
            returnCreditLoading ||
            isdeleteLoading
          }
        />
        <ProfileModal
          open={isShow}
          profileInformation={profileData?.userDetails?.User_data}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            color: "#676a6c",
            fontSize: 13,
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          <Typography variant="h5">Conditional Guarantee</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8, color: "#676a6c" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            color: "#676a6c",
            fontSize: 13,
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          <Typography variant="body1">
            CONDITIONAL GUARANTEE: MARKETING COMPANY offers a conditional
            guarantee on appointments set for CLIENT. Conditions are set below.
          </Typography>
          <ol>
            <li>
              If MARKETING COMPANY confirms that:
              <ol>
                <li>
                  1. Prospect is/was not available at the address listed on the
                  scheduled appointment date/time.
                </li>
                <li>
                  2. Prospect notifies CLIENT or MARKETING COMPANY they are no
                  longer interested in meeting with CLIENT on or before the
                  scheduled appointment date.
                </li>
                <li>
                  3. The prospect is not in a business class selected by CLIENT.
                </li>
                <li>
                  4. The location of the business is outside of the territory
                  selected by CLIENT.
                </li>
              </ol>
              <p>
                The MARKETING COMPANY will accept a return and replace the
                appointment at no additional charge.
              </p>
              <p>
                Note: MARKETING COMPANY will not accept returns based on carrier
                underwriting guidelines. CLIENT must either subscribe to
                accepting a business class in general from the separately
                provided business class selection list - or not at all.
              </p>
            </li>
            <li>
              2. MARKETING COMPANY will not accept a return after 10 days of the
              scheduled appointment.
            </li>
            <li>
              3. MARKETING COMPANY will attempt to confirm all of CLIENTS
              scheduled appointments one business day prior to the scheduled
              date of the appointment. MARKETING COMPANY makes no guarantee that
              appointments will be verbally confirmed. In some cases, if we
              cannot get the prospect on the phone during the confirmation call,
              we will leave a message telling them to expect the CLIENTS
              arrival. An appointment cannot be returned because it was not
              confirmed or because we left a message as a result of the prospect
              not being available for the confirmation call.
            </li>
          </ol>
        </DialogContent>
      </Dialog>
      <Dialog open={appModalOpen} onClose={onCloseAppModal} maxWidth="sm">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Create Appointment
            </Typography>
            <IconButton onClick={onCloseAppModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Box
              sx={{
                padding: "20px",
              }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        fullWidth
                        sx={{
                          fontSize: 15,
                          color: "#7b7b7b",
                          fontWeight: 600,
                          mb: 1.5,
                        }}
                      >
                        Appointment Date :
                      </Typography>
                      <TextField
                        margin="0"
                        size="small"
                        type="date"
                        fontSize="15"
                        error={appointmentDateError}
                        helperText={
                          appointmentDateError && appointmentDateErrorMessage
                        }
                        value={appointmentData.appointmentDate}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            appointmentDate: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: "200px!important",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        fullWidth
                        sx={{
                          fontSize: 15,
                          color: "#7b7b7b",
                          fontWeight: 600,
                          mb: 1.5,
                        }}
                      >
                        Appointment Time :
                      </Typography>
                      <TextField
                        ampm={true}
                        type="time"
                        name="appointmentTime"
                        size="small"
                        value={appointmentData?.appointmentTime}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            appointmentTime: e.target.value,
                          });
                        }}
                        error={appointmentTimeError}
                        helperText={
                          appointmentTimeError && appointmentTimeErrorMessage
                        }
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: "200px!important",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        fullWidth
                        sx={{
                          fontSize: 15,
                          color: "#7b7b7b",
                          fontWeight: 600,
                          mb: 1.5,
                        }}
                      >
                        Confirm Appointment Date :
                      </Typography>
                      <TextField
                        margin="0"
                        size="small"
                        type="date"
                        fontSize="15"
                        error={confirmAppointmentDateError}
                        helperText={
                          confirmAppointmentDateError &&
                          confirmAppointmentDateErrorMessage
                        }
                        value={appointmentData.confirmAppointmentDate}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            confirmAppointmentDate: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: "200px!important",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        fullWidth
                        sx={{
                          fontSize: 15,
                          color: "#7b7b7b",
                          fontWeight: 600,
                          mb: 1.5,
                        }}
                      >
                        Confirm Appointment Time :
                      </Typography>
                      <TextField
                        ampm={true}
                        type="time"
                        name="confirmappointmentTime"
                        size="small"
                        value={appointmentData.confirmAppointmentTime}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            confirmAppointmentTime: e.target.value,
                          });
                        }}
                        error={confirmAppointmentTimeError}
                        helperText={
                          confirmAppointmentTimeError &&
                          confirmAppointmentTimeErrorMessage
                        }
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          marginBottom: "10px",
                          width: "200px!important",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      size="small"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },

                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },

                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          fontSize: 15,
                          "&.Mui-focused": {
                            color: "#6DD6C2!important",
                            fontWeight: "600",
                          },
                        }}
                      >
                        Appointment Type
                      </InputLabel>
                      <Select
                        labelId="sms-label"
                        id="demo-simple-select"
                        label="Appointment Type"
                        error={appointmentTypeError}
                        onChange={(event) => {
                          handleChangeOptions(event, "appointmentType");
                        }}
                        size="small"
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                      >
                        <MenuItem value={1} sx={{ fontSize: 15 }}>
                          In Person
                        </MenuItem>
                        <MenuItem value={2} sx={{ fontSize: 15 }}>
                          Phone Call
                        </MenuItem>
                      </Select>
                      <>
                        {appointmentTypeError && (
                          <FormHelperText
                            sx={{
                              color: "#d32f2f",
                            }}
                          >
                            {appointmentTypeErrorMessage}
                          </FormHelperText>
                        )}
                      </>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      size="small"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },

                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },

                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          fontSize: 15,
                          "&.Mui-focused": {
                            color: "#6DD6C2!important",
                            fontWeight: "600",
                          },
                        }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="sms-label"
                        id="demo-simple-select"
                        label="Status"
                        onChange={(event) => {
                          handleChangeOptions(event, "status");
                        }}
                        size="small"
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                      >
                        <MenuItem value={"1"} sx={{ fontSize: 15 }}>
                          Awaiting Confirmation
                        </MenuItem>
                        <MenuItem value={"2"} sx={{ fontSize: 15 }}>
                          Confirmed
                        </MenuItem>
                        <MenuItem value={"3"} sx={{ fontSize: 15 }}>
                          Left Message
                        </MenuItem>
                        <MenuItem value={"4"} sx={{ fontSize: 15 }}>
                          Rescheduled
                        </MenuItem>
                        <MenuItem value={"5"} sx={{ fontSize: 15 }}>
                          Cancelled
                        </MenuItem>
                        <MenuItem value={"6"} sx={{ fontSize: 15 }}>
                          Cancelled - Waiting to Rescheduled
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      margin="0"
                      fullWidth
                      size="small"
                      name="comment"
                      label="Comment"
                      type="text"
                      id="comment"
                      fontSize="15"
                      value={appointmentData.comment}
                      onChange={(event) => {
                        setAppointmentData({
                          ...appointmentData,
                          comment: event.target.value,
                        });
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "10px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onSubmitHandler}
            sx={{
              mr: 1,
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
          >
            Create
          </Button>
          <Button
            onClick={onCloseAppModal}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#000",
              borderColor: "#6DD6C2",
              color: "#fff",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#000",
                borderColor: "#6DD6C2",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
