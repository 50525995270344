import {
  AUTH_CALLBACK_URL,
  BASE_URL,
  CHANGE_PASSWORD_URL,
  CHECK_GOOGLE_CALENDAR_LOGIN_STATUS_URL,
  GET_ALL_CLIENTS_URL,
  GET_GOOGLE_AUTH_URL,
  GET_USER_PROFILE_URL,
  SYNC_GOOGLE_CALENDER_URL,
  UPDATE_PROFILE_KEY_URL,
  OUTLOOK_CALLBACK_URL
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest, putRequest } from "../../../services";
import {
  AUTH_CALLBACK_FAILURE,
  AUTH_CALLBACK_IN_PROGRESS,
  AUTH_CALLBACK_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_IN_PROGRESS,
  CHANGE_PASSWORD_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_CLIENTS_IN_PROGRESS,
  GET_ALL_CLIENTS_SUCCESS,
  GET_GOOGLE_AUTH_URL_FAILURE,
  GET_GOOGLE_AUTH_URL_IN_PROGRESS,
  GET_GOOGLE_AUTH_URL_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_IN_PROGRESS,
  GET_USER_PROFILE_SUCCESS,
  GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE,
  GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS,
  GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS,
  SYNC_GOOGLE_CALENDAR_FAILURE,
  SYNC_GOOGLE_CALENDAR_IN_PROGRESS,
  SYNC_GOOGLE_CALENDAR_SUCCESS,
  UPDATE_LOGIN_STATUS,
  UPDATE_PROFILE_KEY_FAILURE,
  UPDATE_PROFILE_KEY_IN_PROGRESS,
  UPDATE_PROFILE_KEY_SUCCESS,
} from "./types";
import toast from "react-hot-toast";
import { logout } from "../authentication";

export const getGoogleAuthenticationUrlInProgress = () => ({
  type: GET_GOOGLE_AUTH_URL_IN_PROGRESS,
});

export const getGoogleAuthenticationUrlSuccess = (url) => ({
  type: GET_GOOGLE_AUTH_URL_SUCCESS,
  url,
});

export const getGoogleAuthenticationUrlFailure = (error) => ({
  type: GET_GOOGLE_AUTH_URL_FAILURE,
  error,
});

export const updateLoginStatus = (data) => ({
  type: UPDATE_LOGIN_STATUS,
  data,
});

// const openNewWindow = (
//   url = "",
//   type,
//   width = "680",
//   height = "510",
//   dispatch,
//   login
// ) => {
//   if (login) {
//     dispatch(updateLoginStatus({ islogin: false, loginType: "" }));
//   }
//   const left = window.innerWidth / 2 - width / 2;
//   const top = window.innerHeight / 2 - height / 2;

//   var signinWin;
//   // Use the childURL as needed
//   signinWin = window.open(
//     url,
//     "SignIn",
//     `width=${width},height=${height},toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=${left},top=${top}`
//   );

//   setTimeout(CheckLoginStatus, 2000);
//   signinWin.focus();

//   function CheckLoginStatus() {
//     //console.log("window===>",signinWin.location.href);
//     // if (signinWin?.closed) {
//     //     //console.log("window===>",signinWin.location.href);
//     // //   //console.log("window===>", signinWin?.closed);
//     // //   //console.log("new window===,", window);
//     // //   //console.log("window===>",signinWin?.status);

//     // //   if (signinWin?.status) {
//     // //     dispatch(updateLoginStatus({ islogin: true, loginType: type }));
//     // //     //console.log(signinWin.status);
//     // //     window.location.reload();
//     // //   }
//     // } else
//     setTimeout(CheckLoginStatus, 1000);
//   }
// };

// Method for getting the Google Authentication URL
export const getGoogleAuthenticationUrl = (cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const { state } = getState();

      const isCalendarLogin = state?.profile?.isCalendarLogin;

      dispatch(getGoogleAuthenticationUrlInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get appointment search data
      const URL = `${BASE_URL}${GET_GOOGLE_AUTH_URL}`;

      const response = await getRequest(URL, token);

      if (response?.status === 200) {
        dispatch(getGoogleAuthenticationUrlSuccess(response.data.auth_url));
        cb(response.data.auth_url, "google", "680", "580");
      } else {
        dispatch(
          getGoogleAuthenticationUrlFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
            message: ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getGoogleAuthenticationUrlFailure({
          type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const syncGoogleCalendarInProgress = () => ({
  type: SYNC_GOOGLE_CALENDAR_IN_PROGRESS,
});

export const syncGoogleCalendarSuccess = (data) => ({
  type: SYNC_GOOGLE_CALENDAR_SUCCESS,
  data,
});

export const syncGoogleCalendarFailure = (error) => ({
  type: SYNC_GOOGLE_CALENDAR_FAILURE,
  error,
});

// Method for syncing Google Calendar
export const syncGoogleCalendar = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(syncGoogleCalendarInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get Google authentication URL
      const URL = `${BASE_URL}${SYNC_GOOGLE_CALENDER_URL}`;

      const response = await postRequest(URL, {}, "", token);

      if (response?.status === 200) {
        dispatch(syncGoogleCalendarSuccess(response.data));
        toast.success("Sync successfully", {
          position: "bottom-center",
        }); // Resolve the promise with the response data
      } else {
        dispatch(
          syncGoogleCalendarFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
            message: ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        toast.error(ERROR_MESSAGE.API.UNKNOWN, {
          position: "bottom-center",
        });
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response.data?.error ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        syncGoogleCalendarFailure({
          type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
          message: errorMessage,
        })
      );
      if (error?.response.status === 404) {
        dispatch(updateLoginStatus({ islogin: false, loginType: "" }));
      }
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// New Action Creators
export const authCallbackInProgress = () => ({
  type: AUTH_CALLBACK_IN_PROGRESS,
});

export const authCallbackSuccess = (data) => ({
  type: AUTH_CALLBACK_SUCCESS,
  data,
});

export const authCallbackFailure = (error) => ({
  type: AUTH_CALLBACK_FAILURE,
  error,
});

// Updated Method
export const authCallback = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(authCallbackInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request for authentication callback

      const URL = `${BASE_URL}${AUTH_CALLBACK_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(authCallbackSuccess(response?.data));
        cb();
        toast.success("You are successfully logged in", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          authCallbackFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        authCallbackFailure({
          type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const GoogleCalendarLoginStatusInProgress = () => ({
  type: GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS,
});

export const GoogleCalendarLoginStatusSuccess = (data) => ({
  type: GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS,
  data,
});

export const GoogleCalendarLoginStatusFailure = (error) => ({
  type: GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE,
  error,
});

// Method for checking Google Calendar login status
export const googleCalendarLoginStatus = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GoogleCalendarLoginStatusInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to check Google Calendar login status
      const response = await getRequest(
        `${BASE_URL}${CHECK_GOOGLE_CALENDAR_LOGIN_STATUS_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GoogleCalendarLoginStatusSuccess(response?.data));
      } else {
        dispatch(
          GoogleCalendarLoginStatusFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      if (error?.response?.status === 400) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(
          GoogleCalendarLoginStatusFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
            message: errorMessage,
          })
        );
        return;
      }
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
    
      dispatch(
        GoogleCalendarLoginStatusFailure({
          type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for changing password
export const changePasswordInProgress = () => ({
  type: CHANGE_PASSWORD_IN_PROGRESS,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data,
});

export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
});

// Method for changing password
export const changePassword = (currentPassword, newPassword, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(changePasswordInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to change password
      const URL = `${BASE_URL}${CHANGE_PASSWORD_URL}`;

      const payload = {
        old_password: currentPassword,
        new_password: newPassword,
      };

      const response = await postRequest(URL, payload, "", token);

      if (response?.status === 200) {
        dispatch(changePasswordSuccess(response.data));
        toast.success(response?.data?.message, {
          position: "bottom-center",
        });
        cb();
      } else {
        dispatch(
          changePasswordFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: response?.data?.message || ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        toast.error(response?.data?.message || ERROR_MESSAGE.API.UNKNOWN, {
          position: "bottom-center",
        });
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage = error?.response?.data?.message || ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error?.response?.data) {
        let value = error?.response?.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        changePasswordFailure({
          type: ERROR_TYPE.API.LOGIN.UNKNOWN,
          message: errArr.join(", "),
        })
      );
      if (error?.response.status === 404) {
        dispatch(updateLoginStatus({ islogin: false, loginType: "" }));
      }

    }
  };
};

// Action Creators for getUserProfile
export const getUserProfileInProgress = () => ({
  type: GET_USER_PROFILE_IN_PROGRESS,
});

export const getUserProfileSuccess = (data) => ({
  type: GET_USER_PROFILE_SUCCESS,
  data,
});

export const getUserProfileFailure = (error) => ({
  type: GET_USER_PROFILE_FAILURE,
  error,
});

// Method for getUserProfile
export const getUserProfile = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getUserProfileInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get user profile

      const URL = `${BASE_URL}${GET_USER_PROFILE_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(getUserProfileSuccess(response?.data));
        cb();
      } else {
        dispatch(
          getUserProfileFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        getUserProfileFailure({
          type: ERROR_TYPE.API.LOGIN.UNKNOWN,
          message: errArr.join(", "),
        })
      );
      // toast.error(errorMessage, {
      //   position: "bottom-center",
      // });
    }
  };
};

// Action Creators
export const getAllClientsInProgress = () => ({
  type: GET_ALL_CLIENTS_IN_PROGRESS,
});

export const getAllClientsSuccess = (data) => ({
  type: GET_ALL_CLIENTS_SUCCESS,
  data,
});

export const getAllClientsFailure = (error) => ({
  type: GET_ALL_CLIENTS_FAILURE,
  error,
});

// Method for getting all clients
export const getAllClients = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getAllClientsInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get all clients
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_CLIENTS_URL}`, // Replace with your actual endpoint for getting clients
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(getAllClientsSuccess(response?.data));
      } else {
        dispatch(
          getAllClientsFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getAllClientsFailure({
          type: ERROR_TYPE.API.LOGIN.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for updating profile keys
export const updateProfileKeyInProgress = () => ({
  type: UPDATE_PROFILE_KEY_IN_PROGRESS,
});

export const updateProfileKeySuccess = (data) => ({
  type: UPDATE_PROFILE_KEY_SUCCESS,
  data,
});

export const updateProfileKeyFailure = (error) => ({
  type: UPDATE_PROFILE_KEY_FAILURE,
  error,
});

export const updateProfileKey = (
  id,
  key,
  value,
  isAdditional = false,
  cb = () => {}
) => {
  return async (dispatch) => {
    try {
      // Create params object with the key and value to update
      const params = {
        [key]: value,
        additional_detail: isAdditional,
      };

      // Dispatch in progress action
      dispatch(updateProfileKeyInProgress());

      // Make API request to update the specific key in the profile
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await putRequest(
        `${BASE_URL}${UPDATE_PROFILE_KEY_URL}`,
        params,
        token
      );

      // Check response status
      if (response?.status === 200 || response?.status === 201) {
        dispatch(updateProfileKeySuccess(response?.data));
        cb();
        toast.success(`Updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateProfileKeyFailure({
            type: ERROR_TYPE.API.HOME.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateProfileKeyFailure({
          type: ERROR_TYPE.API.HOME.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};



// New Action Creators
export const authOutlookCallbackInProgress = () => ({
  type: AUTH_CALLBACK_IN_PROGRESS,
});

export const authOutlookCallbackSuccess = (data) => ({
  type: AUTH_CALLBACK_SUCCESS,
  data,
});

export const authOutlookCallbackFailure = (error) => ({
  type: AUTH_CALLBACK_FAILURE,
  error,
});

// Updated Method
export const authOutlookCallback = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(authCallbackInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request for authentication callback

      const URL = `${BASE_URL}${OUTLOOK_CALLBACK_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check resp onse status
      if (response?.status === 200) {
        dispatch(authCallbackSuccess(response?.data));
        cb();
        toast.success("You are successfully logged in", {
          position: "bottom-center",
        })
      } else {
        dispatch(
          authCallbackFailure({
            type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,// using the same error type for outlook
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        authCallbackFailure({
          type: ERROR_TYPE.API.GOOGLE_AUTH.UNKNOWN,// using the same error type for outlook
          message: errorMessage,
        })

      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};