// // LoginScreen.js
import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { KEYS, checkAndReturnNone, getItemFromLocalStorage } from "../../helpers";
import { useEffect } from "react";
import { Loader, OtpInput, UpdateTooltip } from "../Common";
import { useDispatch, useSelector } from "react-redux";
import "aos/dist/aos.css";
import toast from "react-hot-toast";
import { getUserProfile } from "../../store/actions/profile";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { QRCodeGenerate } from "../Common/QRCode";
import { OtpInputQrCode } from "../Common/OtpInputQrCode";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

} from "@mui/material"

export const ProfileComponent = (props) => {
  const {
    getGoogleAuthUrl,
    syncGoogleCalendar,
    authCallBack,
    authOutlookCallBack,
    getGoogleLoginStatus,
    updateProfileKey,
  } = props;

  const calback = () => {
    getGoogleLoginStatus();
  };

  const { instance, accounts } = useMsal();
  let activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (window.location.href.includes("code") && accounts.length < 0) {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");
      const state = query.get("state");

      // Extract user_id from the state parameter
      const userId = state ? state.split("=")[1] : null;

      const payload = {
        auth_code: code ?? "",
        user_id: userId ?? null,
      };

      authCallBack(payload, calback);
    }
    calback();

  }, []);

  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const lastSyncTime = useSelector((state) => state?.profile?.lastSyncTime);
  const user_info = useSelector((state) => state?.loginAuth?.user_details);
  const userInfo = useSelector(
    (state) => state?.profile?.userDetails?.User_data
  );
  const personalData =  userInfo?.user_type === "Administration" ? userInfo : userInfo?.user;
  const isloading = useSelector((state) => state.profile.isLoading);
  const isLogin = useSelector((state) => state.profile.isCalendarLogin);
  const loginType = useSelector((state) => state.profile.calendarType);
  let initialSelectedAuth = (user_info.two_fa_authentication_enable==true)?"2factor":(user_info.two_way_authentication==true)?"2way":"reset"
  const [selectedValue, setSelectedValue] = React.useState(initialSelectedAuth);
  const [authType,setAuthType] =useState("")
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
  const [isQrCodeOtpOpen, setIsQrCodeOtpOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const authenticationCallback =(payload)=>{
    props?.qrCodeGenerate(payload, () => {
      setIsQrCodeOpen(true)
     });
  }


  const handleRedirect = async () => {
    try {

       const response = await instance.handleRedirectPromise();
       const clientId ="64b2d921-a4db-4f99-b43e-b21a79b785e1";
       const {environment,homeAccountId } = response.account;
       const refreshTokenKey = homeAccountId+"-"+environment+"-"+"refreshtoken-"+clientId+"----"
  
       const refreshToken = sessionStorage.getItem(refreshTokenKey);
       const refreshTokenObject = JSON.parse(refreshToken);
   
  
      const payload = {
              user_id: user_info?.id,
              auth_data: {
                access_token: response.accessToken,
                token_expiry: response.expiresOn,
                username: response.account.username,
                tenantId:response.tenantId,
                refresh_token:refreshTokenObject.secret
              },
            }; 
            authOutlookCallBack(payload, calback )
      // Handle the authentication response, e.g., store tokens in state or local storage
       // Redirect to the home page after handling the response
    } catch (error) {
      console.error("Error handling redirect:", error);
    }
  };

  useEffect(() => {
      handleRedirect(); 
  }, []);

  const handleCloseQrCode = () => {
    setIsQrCodeOpen(false);
  };
  const handleCloseQrCodeOtp = () => {
    setIsQrCodeOtpOpen(false);
  };
  
  const handleSubmitQrOtp = (otp) => {
    const userEmail = user_info.email
    if (otp?.trim()?.length == 6){
      if (!(!userEmail || !otp)) {
        console.log("payload",selectedValue)
        const payload = {
          email: userEmail,
          otp: otp,
          two_way_authentication:( authType =="2way"?true:false),
          two_fa_authentication_enable:( authType =="2factor"?true:false),
        };
        props?.verifyQrOTPProfile(payload, () => {
          setSelectedValue(authType)
          setIsQrCodeOtpOpen(false);
        });
      }
    }
  };

  const handleChange =(event)=>{
    let payload = event.target.value;
    setAuthType(payload)
      setOpenDeleteDialog(true);
  }

  const handleConfirmAuthChange = () => {
      if(authType=="2way"){
      props.userAuthenticationType(authType);
      setSelectedValue(authType)

    }else if(authType=="2factor") {
      authenticationCallback(authType)
     } else if(authType=="reset"){
      props.userAuthenticationType(authType);
      setSelectedValue(authType)
    }

    setOpenDeleteDialog(false);
  };

  const handleCloseAuthDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleGoogleSync = () => {
    toast.loading("Synching...", {
      duration: 600,
      position: "bottom-center",
    });
    syncGoogleCalendar();
  };

  const openAuthPage = (url = "") => {
    window.location.href = url;
  };

  const callBack = (url) => {
    openAuthPage(url);
  };

  // const handleOutlookLogin = async () => {
  //   try {
  //  const responseLogin =    await instance.loginPopup({
  //       scopes: ["Calendars.ReadWrite"],
  //       prompt: "select_account", // Optional. Forces the user to choose an account during login.
  //     });
  //       const payload = {
  //       user_id: user_info?.id,
  //       auth_data: {
  //         access_token: responseLogin.accessToken,
  //         token_expiry: responseLogin.expiresOn,
  //         username: responseLogin.account.username,
  //       },
  //     }; 
  //     authOutlookCallBack(payload, calback )
  //   } catch (error) {
  //     console.error("Failed to login:", error);
  //   }
  // };

  const handleOutlookLogin = async () => {
    try {
      await instance.loginRedirect({
        scopes: ["Calendars.ReadWrite"],
        prompt: "select_account",
      });
       // Here, you can access the access token and other user information
    } catch (error) {
      console.log(error);
    }
  };
  
  const formatDateTime = () => {
    if (
      lastSyncTime === "" ||
      lastSyncTime == undefined ||
      lastSyncTime == null
    ) {
      return "";
    }
    const date = new Date(lastSyncTime);

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })?.format(date);
    return formattedDate ?? "";
    //console.log(formattedDate);
  };

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const keyCss = {
    fontSize: 13,
    color: "#0088cc",
    fontWeight: 500,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#0088cc",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    maxWidth: "fit-content",
    position: "relative",
    cursor: "pointer",
  };

  const findStatusByid = (status) => {
    return (
      [
        {
          id: true,
          label: "Active",
        },
        {
          id: false,
          label: "Inactive",
        },
      ].find((st) => st.id == status) || null
    );
  };

  const findVerifiedByid = (status) => {
    return (
      [
        {
          id: true,
          label: "True",
        },
        {
          id: false,
          label: "False",
        },
      ].find((st) => st.id == status) || null
    );
  };

  const onChangeProfileKey = (key, value, isAdditional = false) => {
    updateProfileKey(user_info?.id, key, value, isAdditional, () => {
      const payload = {
        agent_id: user_info?.id,
      };
      dispatch(
        getUserProfile(payload, () => {
          setEdit(true);
          handleTooltipClose();
        })
      );
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Profile Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            User Type -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#454647",
                              fontWeight: 500,
                            }}
                          >
                            {checkAndReturnNone(personalData?.user_type)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            First Name -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 1 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"First Name"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("first_name", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.first_name
                                );
                                setData(val);
                                handleTooltipOpen(1);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.first_name)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Email ID
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {user_info?.user_type === "Employee" ? (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(personalData?.email)}
                            </Typography>
                          ) : (
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === 7 ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Email"}
                                  onSubmit={(value) => {
                                    onChangeProfileKey("email", value);
                                  }}
                                  inputType={"Text"}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    personalData?.email
                                  );
                                  setData(val);
                                  handleTooltipOpen(7);
                                }}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(personalData?.email)}
                              </Typography>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Street
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 18 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Street"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("street", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.street
                                );
                                setData(val);
                                handleTooltipOpen(18);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.street)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            City
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 16 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"City"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("city", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.city
                                );
                                setData(val);
                                handleTooltipOpen(16);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.city)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Zip Code
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 17 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Zip Code"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("zip_code", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.zip_code
                                );
                                setData(val);
                                handleTooltipOpen(17);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.zip_code)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Account Status
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {userInfo?.user_type !== "Administration" ? (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {personalData?.account_status ? "True" : "False"}
                            </Typography>
                          ) : (
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === 12 ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Status"}
                                  onSubmit={(value) => {
                                    const val =
                                      value === true ? "True" : "False";

                                    onChangeProfileKey("is_verified", val);
                                  }}
                                  selectedKey={
                                    findVerifiedByid(
                                      personalData?.account_status
                                    )?.label
                                  }
                                  inputType={"SelectOptions"}
                                  labelKey={"label"}
                                  optionsData={[
                                    {
                                      id: true,
                                      label: "True",
                                    },
                                    {
                                      id: false,
                                      label: "False",
                                    },
                                  ]}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = personalData?.account_status
                                    ? "True"
                                    : "False";
                                  setData(val);
                                  handleTooltipOpen(12);
                                }}
                                sx={keyCss}
                              >
                                {personalData?.account_status
                                  ? "True"
                                  : "False"}
                              </Typography>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Last Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 5 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Last Name"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("last_name", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.last_name
                                );
                                setData(val);
                                handleTooltipOpen(5);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.last_name)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Phone Number
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 3 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Phone Number"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("phone_number", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.phone_number
                                );
                                setData(val);
                                handleTooltipOpen(3);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.phone_number)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 13 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Address"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("address", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.address
                                );
                                setData(val);
                                handleTooltipOpen(13);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.address)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            State
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === 14 ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"State"}
                                onSubmit={(value) => {
                                  onChangeProfileKey("state", value);
                                }}
                                inputType={"Text"}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(
                                  personalData?.state
                                );
                                setData(val);
                                handleTooltipOpen(14);
                              }}
                              sx={keyCss}
                            >
                              {checkAndReturnNone(personalData?.state)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Send Introduction Mail
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Tooltip
                            sx={{
                              marginTop: "0!important",
                              " & .MuiTooltip-tooltip": {
                                marginTop: "0!important",
                              },
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              borderColor: "black",
                            }}
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipID === "sendEmail" ? true : false}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <UpdateTooltip
                                onClose={handleTooltipClose}
                                label={"Send Introduction Mail"}
                                onSubmit={(value) => {
                                  // onChangeCampiagnKey(
                                  //   row?.action?.id,
                                  //   value,
                                  //   () => {
                                  //     handleTooltipClose();
                                  //   }
                                  // );
                                }}
                                selectedKey={findStatusByid(false)?.label}
                                inputType={"SelectOptions"}
                                labelKey={"label"}
                                optionsData={[
                                  {
                                    id: true,
                                    label: "Active",
                                  },
                                  {
                                    id: false,
                                    label: "Inactive",
                                  },
                                ]}
                                value={data}
                                setValue={setData}
                              />
                            }
                          >
                            <Typography
                              component="h4"
                              variant="h4"
                              onClick={(event) => {
                                event.stopPropagation();
                                const val = checkAndReturnNone(false);
                                setData(val);
                                handleTooltipOpen("sendEmail");
                              }}
                              sx={{
                                fontSize: 15,
                                color: "#0088cc",
                                fontWeight: 500,
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: "#0088cc",
                                borderTop: 0,
                                borderRight: 0,
                                borderLeft: 0,
                                maxWidth: "fit-content",
                              }}
                            >
                              {false ? "Active" : "Inactive"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {user_info?.user_type === "Employee" && (
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#d2f5ec",
                    borderRadius: "10px 10px 0 0!Important",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Additional Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ pt: 2, pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Rate Per Hour
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(userInfo?.rate_per_hour)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Commission Plus Pay
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(
                                userInfo?.commission_plus_pay
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Team
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {userInfo?.team?.charAt(0).toUpperCase() +
                                userInfo?.team?.slice(1)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Commission Standard Pay
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(
                                userInfo?.commission_standard_pay
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Bonus Pay
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(userInfo?.bonus_pay)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {user_info?.user_type === "Client" && (
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#d2f5ec",
                    borderRadius: "10px 10px 0 0!Important",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    Additional Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ pt: 2, pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Additional E-Mail
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={
                                tooltipID === "additional_email" ? true : false
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Additional E-Mail"}
                                  onSubmit={(value) => {
                                    onChangeProfileKey(
                                      "additional_email",
                                      value,
                                      true
                                    );
                                  }}
                                  inputType={"Text"}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    userInfo?.additional_email
                                  );
                                  setData(val);
                                  handleTooltipOpen("additional_email");
                                }}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(userInfo?.additional_email)}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Agency Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === "agency_name" ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Agency Name"}
                                  onSubmit={(value) => {
                                    onChangeProfileKey(
                                      "agency_name",
                                      value,
                                      true
                                    );
                                  }}
                                  inputType={"Text"}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    userInfo?.agency_name
                                  );
                                  setData(val);
                                  handleTooltipOpen("agency_name");
                                }}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(userInfo?.agency_name)}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Account Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Tooltip
                              sx={{
                                marginTop: "0!important",
                                " & .MuiTooltip-tooltip": {
                                  marginTop: "0!important",
                                },
                                backgroundColor: "#ffffff",
                                borderRadius: "5px",
                                borderColor: "black",
                              }}
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                disablePortal: false,
                              }}
                              onClose={handleTooltipClose}
                              open={tooltipID === "account_name" ? true : false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <UpdateTooltip
                                  onClose={handleTooltipClose}
                                  label={"Account Name"}
                                  onSubmit={(value) => {
                                    onChangeProfileKey(
                                      "account_name",
                                      value,
                                      true
                                    );
                                  }}
                                  inputType={"Text"}
                                  value={data}
                                  setValue={setData}
                                />
                              }
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  const val = checkAndReturnNone(
                                    userInfo?.account_name
                                  );
                                  setData(val);
                                  handleTooltipOpen("account_name");
                                }}
                                sx={keyCss}
                              >
                                {checkAndReturnNone(userInfo?.account_name)}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Plan Purchased
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#454647",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(userInfo?.plan_purchased)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {userInfo?.user_type !== "Employee" && (
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#d2f5ec",
                    borderRadius: "10px 10px 0 0!Important",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                    {isLogin
                      ? "Sync Calender Information"
                      : "Add Google or Outlook Calendar Information"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ pt: 2, pb: 1 }}>
                    {isLogin ? (
                      <Grid item xs={12} md={5}>
                        <Grid
                          container
                          spacing={0}
                          sx={{ alignItems: "center" }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Sync{" "}
                              {loginType
                                ? loginType === "2"
                                  ? "Outlook "
                                  : "Google "
                                : ""}{" "}
                              Calendar
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "max-content",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleGoogleSync();
                                }}
                                sx={{
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  marginRight: 5,
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Sync now
                              </Button>

                              {formatDateTime() != "" && (
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    width: "100%",

                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  last sync at {formatDateTime() ?? "None"}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ alignItems: "center" }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Sync Outlook Calendar
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Button
                                variant="contained"
                                onClick={handleOutlookLogin}
                                sx={{
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Outlook Login
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ alignItems: "center" }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Sync Google Calendar
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  getGoogleAuthUrl(callBack);
                                }}
                                sx={{
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Google Login
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Box><Dialog open={openDeleteDialog} onClose={handleCloseAuthDialog}>
                      <DialogTitle>Security</DialogTitle>
                      <DialogContent>
                        <Typography>
                          Are you sure you want to change the authentication type?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                          onClick={handleCloseAuthDialog}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            width: "100%",
                            maxWidth: "max-content",
                            backgroundColor: "#212223",
                            borderColor: "#212223",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#212223",
                              borderColor: "#212223",
                            },
                          }}
                          onClick={handleConfirmAuthChange}
                          color="error"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>

                </AccordionDetails>
              </Accordion>
            </Grid>
          )}



          {userInfo?.user_type !== "Employee" && (
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#d2f5ec",
                    borderRadius: "10px 10px 0 0!Important",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                      color: "#212223",
                      fontWeight: 600,
                    }}
                  >
                   
                  Security 
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ pt: 2, pb: 1 }}>
                  <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="2way" control={  <Radio
                              checked={selectedValue === '2way'}
                              onChange={handleChange}
                              value="2way"
                              name="2way"
                            />} label="2 Way Authentication" />
                          <FormControlLabel value="2factor" control={ <Radio
                              checked={selectedValue === '2factor'}
                              onChange={handleChange}
                              value="2factor"
                              name="2factor"
                            />} label="2 Factor Authentication" />
                        
                        </RadioGroup>
                      </FormControl>
                      <Button
                          variant="contained"
                          onClick={handleChange}
                          disabled={selectedValue =='reset'}
                          value="reset"
                          sx={{
                            textTransform: "capitalize",
                            float:"inline-end",
                            width: "100%",
                            maxWidth: "max-content",
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            marginRight: 5,
                            fontWeight: 600,
                            "&:hover": {
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                                Reset
                              </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
    
         <OtpInputQrCode 
         isQrCodeOtpOpen ={isQrCodeOtpOpen}
         onCloseCodeOtp={handleCloseQrCodeOtp}
         onSubmit={handleSubmitQrOtp}
         userEmail={user_info.email}
        />
        <QRCodeGenerate 
         isQrCodeOpen ={isQrCodeOpen}
         onCloseQrCode={handleCloseQrCode}
         setIsQrCodeOtpOpen={setIsQrCodeOtpOpen}
         setIsQrCodeOpen={setIsQrCodeOpen}
        />
        <Loader loading={isloading} />
      </Box>
    </>
  );
};
