// Types
import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_IN_PROGRESS,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_IN_PROGRESS,
  DELETE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_IN_PROGRESS,
  GET_EMPLOYEES_SUCCESS,
  UPDATE_CALL_RECORDING_STATUS_FAILURE,
  UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS,
  UPDATE_CALL_RECORDING_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_IN_PROGRESS,
  UPDATE_EMPLOYEE_SUCCESS,
  UPLOAD_EMPLOYEE_CSV_FAILURE,
  UPLOAD_EMPLOYEE_CSV_IN_PROGRESS,
  UPLOAD_EMPLOYEE_CSV_SUCCESS,
} from "../actions/Employee/types";
import initialState from "../initialState";

export const employee = (state = initialState.employee, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        empoyee_listing: {},
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        empoyee_listing: action?.data,
      };
    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        empoyee_listing: {},
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CALL_RECORDING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CALL_RECORDING_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPLOAD_EMPLOYEE_CSV_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_EMPLOYEE_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_EMPLOYEE_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
