// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  colors,
  Skeleton,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useDispatch, useSelector } from "react-redux";
import { TwilioVoice, getTwilioDevice } from "../Common";
import { DEVICE_STATE } from "../../helpers";
import CallIcon from "@mui/icons-material/Call";
import { updateCampaignKey } from "../../store/actions";
import { getClientDashboard } from "../../store/actions/clientView";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const ClientViewComponent = (props) => {
  const [selectedFilter, setSelectedFilter] = useState();
  const [filterCampaignName, setFilterCampaignName] = useState("");

  const dispatch = useDispatch();

  const isloading = useSelector((state) => state.clientView.loading);

  const clientDashboardInfo = useSelector((state) => state.clientView?.data);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    dispatch(getClientDashboard());
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNameFilterChange = (event) => {
    setFilterCampaignName(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
          borderRadius: "10px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "10px!Important",
              borderTopRightRadius: "10px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Commercial Appointments
              </Typography>
            </AccordionSummary>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: "none" }),
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#6DD6C2",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                },
                borderRadius: "50%",
                borderColor: "#6DD6C2",
                color: "#212223",
              }}
            >
              <SearchIcon />
            </IconButton> */}
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              {isloading ? (
                <>
                  <Grid container spacing={4}>
                    {[1, 2, 3, 4].map((item) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            sx={{
                              background: "#fff",
                              boxShadow: 1,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              sx={{
                                marginBottom: "10px",
                                borderBottom: 1,
                                height: "100px",
                                borderColor: "#676a6c4a",
                              }}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container spacing={4}>
                    {[1, 2, 3, 4].map((item) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            sx={{
                              background: "#fff",
                              boxShadow: 1,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              sx={{
                                marginBottom: "10px",
                                borderBottom: 1,
                                height: "100px",
                                borderColor: "#676a6c4a",
                              }}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <>
                  {clientDashboardInfo?.lead_owed_report?.length <= 0 ? (
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        fontSize: 15,
                        color: "#6B7489",
                        fontWeight: 600,
                      }}
                    >
                      No record Found
                    </Typography>
                  ) : (
                    <>
                      {clientDashboardInfo?.lead_owed_report?.map(
                        (item, index) => {
                          return (
                            <Box>
                              <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#e7f7ff",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",

                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Agency Calling For
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {`${item?.last_name},${item?.first_name}`}{" "}
                                        {item.is_plus && "+"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#e7d5c880",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Amount Purchased Month
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.amount_purchased_month ?? ""}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#fde4eca8",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Paid Through
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.paid_through !== ""
                                          ? item?.paid_through
                                          : "None"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#EEF3F9",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Total Purchased(Paid For)
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.paid_for ?? "0"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#e7f7ff",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Lead Volume
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.total_lead ?? "0"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#e7d5c880",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Conversion Rate
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.conversion_rate ?? "0"}%
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#fde4eca8",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Valid Leads
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.valid_lead_total ?? "0"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        background: "#EEF3F9",
                                        fontSize: 18,
                                        color: "#6B7489",
                                        fontWeight: 600,
                                        marginBottom: "10px",
                                        borderBottom: 1,
                                        borderColor: "#676a6c4a",
                                        padding: "10px",
                                      }}
                                    >
                                      Owed
                                    </Typography>
                                    <Box>
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        sx={{
                                          fontSize: 22,
                                          color: "#676a6c",
                                          fontWeight: 600,
                                          marginBottom: "20px",
                                          padding: {
                                            xs: "10px 5px",
                                            sm: "15px 20px 20px 20px",
                                            md: "15px 20px 20px 20px",
                                            lg: "15px 20px 20px 20px",
                                          },
                                        }}
                                      >
                                        {item?.owed_value ?? "0"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        }
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
