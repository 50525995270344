import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const IntercomChat = () => {
  const isLoggedIn = useSelector((state) => state?.loginAuth?.isLoggedIn);

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  useEffect(() => {
    if (isLoggedIn && userInfo?.user_type !== "Employee") {
      // Initialize Intercom
      window.intercomSettings = {
        app_id: "xabu4m9t",
        name: `${userInfo?.first_name} ${userInfo?.last_name}`,
      };

      if (window.Intercom) {
        window.Intercom("reattach_activator");
        window.Intercom("update", window.intercomSettings);
      } else {
        // Load Intercom script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://widget.intercom.io/widget/xabu4m9t";
        const x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(script, x);

        // Attach Intercom to window
        window.Intercom = function () {
          window.Intercom.q.push(arguments);
        };
        window.Intercom.q = [];
      }

      // Clean up Intercom on component unmount
      return () => {
        if (window.Intercom) {
          window.Intercom("shutdown");
        }
      };
    }
  }, [isLoggedIn]); // The effect now depends on the isLoggedIn state

  return null; // This component doesn't render anything in the DOM
};

export default IntercomChat;
