// Create Client
export const CREATE_CLIENT_IN_PROGRESS = "CREATE_CLIENT_IN_PROGRESS";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

// Get Clients
export const GET_CLIENTS_IN_PROGRESS = "GET_CLIENTS_IN_PROGRESS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

// Update Client
export const UPDATE_CLIENT_IN_PROGRESS = "UPDATE_CLIENT_IN_PROGRESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

// Delete Client
export const DELETE_CLIENT_IN_PROGRESS = "DELETE_CLIENT_IN_PROGRESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const UPDATE_ACCOUNT_STATUS_IN_PROGRESS =
  "UPDATE_USER_STATUS_IN_PROGRESS";
export const UPDATE_ACCOUNT_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_ACCOUNT_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

//upload csv file actions
export const UPLOAD_CLIENT_CSV_IN_PROGRESS = "UPLOAD_CLIENT_CSV_IN_PROGRESS";
export const UPLOAD_CLIENT_CSV_SUCCESS = "UPLOAD_CLIENT_CSV_SUCCESS";
export const UPLOAD_CLIENT_CSV_FAILURE = "UPLOAD_CLIENT_CSV_FAILURE";


// Action Types
export const CLIENT_RESET_PASSWORD_REQUEST = 'CLIENT_RESET_PASSWORD_REQUEST';
export const CLIENT_RESET_PASSWORD_SUCCESS = 'CLIENT_RESET_PASSWORD_SUCCESS';
export const CLIENT_RESET_PASSWORD_FAILURE = 'CLIENT_RESET_PASSWORD_FAILURE';
