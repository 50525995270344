import { connect } from "react-redux";
import {
  CreateProspects,
  UploadProspects,
  X_UploadProspects,
  CallResult,
  ProspectsList,
  ShowProspect,
} from "../components/ProspectsComponent";
import { useLocation } from "react-router-dom";
import {
  GetProspects,
  GetUsers,
  createProspect,
  uploadProspectCsv,
  updateProspect,
  getProspectsSearch,
  prospectsMassUpdate,
  exportCsv,
  getAllCallResultOptions,
  createCallResult,
  deleteCallResult,
} from "../store/actions/Prospects";

const mapStateToProps = (state) => ({
  prospectInfoData: state?.prospect?.prospectInfo,
  userDetails: state?.prospect?.user_details?.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateProspects: (payload, cb) => dispatch(createProspect(payload, cb)),
    getAllProspects: () => dispatch(GetProspects()),
    getAllUser: () => dispatch(GetUsers()),
    onUploadProspectsCSV: (file, cb) => dispatch(uploadProspectCsv(file, cb)),
    onUpdateProspects: (prospectId, payload, cb) =>
      dispatch(updateProspect(prospectId, payload, cb)),
    onSearchProspects: (payload, offset, cb) =>
      dispatch(getProspectsSearch(payload, offset, cb)),
    onMassUpdate: (payload, cb) => dispatch(prospectsMassUpdate(payload, cb)),
    onExport: (payload, cb) => dispatch(exportCsv(payload, cb)),
    getAllCallResultOptions:()=>dispatch(getAllCallResultOptions()),
    createCallResult:(payload,cb)=>dispatch(createCallResult(payload,cb)),
    deleteCallResult:(id,cb)=>dispatch(deleteCallResult(id,cb)),
  };
};

export const ProspectsContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/CreateProspects":
      ComponentToRender = CreateProspects;
      break;
    case "/UploadProspects":
      ComponentToRender = UploadProspects;
      break;
    case "/X_UploadProspects":
      ComponentToRender = X_UploadProspects;
      break;
    case "/CallResult":
      ComponentToRender = CallResult;
      break;
    case "/ProspectsList":
      ComponentToRender = ProspectsList;
      break;
    case "/ShowProspect":
      ComponentToRender = ShowProspect;
      break;

    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
