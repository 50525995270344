import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";

export const CallbackTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "view",
      numeric: false,
      disablePadding: false,
      label: "View",
    },
    {
      id: "amountOwed",
      numeric: false,
      disablePadding: false,
      label: "Amount Owed",
    },
    {
      id: "agent",
      numeric: false,
      disablePadding: false,
      label: "Agent",
    },
    {
      id: "scheduleTime",
      numeric: false,
      disablePadding: false,
      label: "Schedule time",
    },
    {
      id: "campaign",
      numeric: false,
      disablePadding: false,
      label: "Campaign",
    },
    {
      id: "accountName",
      numeric: false,
      disablePadding: false,
      label: "Account Name",
    },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "prospects",
      numeric: false,
      disablePadding: false,
      label: "Prospects",
    },
    {
      id: "alternateCampaign",
      numeric: false,
      disablePadding: false,
      label: "Alternate Campaign",
    },
    {
      id: "assignedTo",
      numeric: false,
      disablePadding: false,
      label: "Assigned To",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created by",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            paddingX: 0,
            whiteSpace: "nowrap",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
        ></TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              paddingX: index == 0 ? 2 : 0,
              whiteSpace: "nowrap",
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "view" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CallbackTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
