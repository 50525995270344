import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import { UPLOAD_EMPLOYES_OPTIONS } from "../../helpers";
import Papa from "papaparse";
import { toast } from "react-hot-toast";
import { PageTitle } from "../Common";

export const UploadEmployee = (props) => {
  const { onUploadEmployeeCSV } = props;
  const [csvFile, setCsvFile] = useState(null);
  PageTitle("Upload Employees");

  const initialOptions = {
    options1: "",
    options2: "",
    options3: "",
    options4: "",
    options5: null,
    options6: null,
    options7: null,
    options8: null,
    options9: null,
    options10: null,
    options11: null,
    options12: null,
    options13: null,
    options14: null,
    options15: null,
    options16: null,
    options17: null,
    options18: null,
  };
  const [selectOptions, setSelectOptions] = useState(initialOptions);
  const [csvCoulums, setCsvCoulums] = useState({});
  const [triedSubmit, setTriedSubmit] = useState(false);
  const fileInputRef = useRef(null);
  const fileError = !csvFile && triedSubmit;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const columns = results.meta.fields.map((column) =>
            column.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
          );
          const initialOptions = columns.reduce((options, column) => {
            options[column] = null;
            return options;
          }, {});
          setCsvCoulums(initialOptions);
          setCsvFile(file);
        },
      });
    }
  };

  const handleChange = (value, option) => {
    setSelectOptions((prevOptions) => ({
      ...prevOptions,
      [option]: value,
    }));
  };

  const handleUpload = () => {
    setTriedSubmit(true);
    if (csvFile) {
      const uploadPromise = onUploadEmployeeCSV(csvFile);
      // Show loading toast using toast.promise
      toast.promise(
        uploadPromise,
        {
          loading: "Uploading...",
          success: "CSV file uploaded successfully",
          error: (error) => {
            // Custom error message based on the error object
            return `${error.message}`;
          },
        },
        {
          position: "bottom-center",
        }
      );
    }
  };

  const handleCancel = () => {
    setTriedSubmit(false);
    setCsvFile(null);
    setCsvCoulums({});
    if (fileInputRef.current) {
      const fileInput = document.getElementById("upload-file");
      if (fileInput) {
        fileInput.value = ""; // Clear the selected file
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Upload Employee CSV
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <Box
                sx={{ display: "flex", alignItems: "flex-start", gap: "20px" }}
              >
                <TextField
                  id="upload-file"
                  name="upload-file"
                  type="file"
                  size="small"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  error={fileError}
                  helperText={fileError && "Please select any CSV file"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                  }}
                />
                {csvFile && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                      textTransform: "capitalize",
                      width: "100%",
                      maxWidth: "max-content",
                      backgroundColor: "#212223",
                      borderColor: "#212223",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#212223",
                        borderColor: "#212223",
                      },
                    }}
                  >
                    Clear
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleUpload}
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{ boxShadow: 3, borderRadius: "10px!Important", mt: 10 }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                color: "#454647",
                fontWeight: 600,
              }}
            >
              Listing Employee CSV Fields
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "5px 0" }}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Column Names
                </Typography>
                <Grid container spacing={1}>
                  {Object.keys(csvCoulums).map((option, index) => (
                    <Grid
                      item
                      key={option}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {option}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
