import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  SvgIcon,
  Box,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceState, updateCallingInfo } from "../../../store/actions";
import { getTwilioDevice } from "../TwillioDevice.js";
import { DEVICE_STATE } from "../../../helpers";

export const CallingCard = ({ imageUrl, title, description }) => {
  const cardStyles = {
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    top: "70px",
    right: "20px",
    zIndex: 999,
    width: "200px",
    borderColor: "#6DD6C2",
  };

  const mediaStyles = {
    height: "200px",
    width: "100%",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    position: "relative",
  };

  const titleDescriptionStyles = {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  };

  const titleStyles = {
    alignSelf: "stretch",
    color: "#fff",
    fontFamily: "Mulish-ExtraBold, Helvetica",
    fontSize: "18px",
    fontWeight: 700,
    letterSpacing: 1,
    lineHeight: "23.4px",
    marginTop: "-1px",
    textAlign: "center",
  };

  const descriptionStyles = {
    alignSelf: "stretch",
    color: "#9194A3",
    fontFamily: "Mulish-Regular, Helvetica",
    fontSize: "15px",
    fontWeight: 700,
    letterSpacing: 0,
    marginBottom: 4,
    lineHeight: "19.2px",
    textAlign: "center",
  };

  const primaryButtonStyles = {
    alignSelf: "stretch",
    display: "flex",
    width: "100%",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: "20px",
    color: "white",
    height: "48px",
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  };

  const callingInfo = useSelector((state) => state?.twillio?.callingCardInfo);

  const dispatch = useDispatch();
  // // Handle hang-up logic when the "Hang Up" button is clicked
  const handleHangup = () => {
    const device = getTwilioDevice();
    if (device) {
      device.disconnectAll();
      dispatch(setDeviceState(DEVICE_STATE.READY));
      dispatch(
        updateCallingInfo({
          isVisible: false,
          contact_no: "",
          isOutgoingOrIncoming: "",
        })
      );
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const OutGoingCard = () => {
    return (
      <Card style={cardStyles} data-aos="zoom-in-down">
        <CardContent
          sx={{
            backgroundColor: "black",
            width: "100%",
          }}
        >
          <div style={titleDescriptionStyles}>
            <Typography variant="h6" style={titleStyles}>
              {callingInfo?.contact_no ?? "None"}
            </Typography>
            <Typography variant="body2" style={descriptionStyles}>
              {/* {description} */}
              On Call
            </Typography>
          </div>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={handleHangup}
              sx={{
                fontWeight: 600,
                textTransform: "capitalize",
                pl: 4,
                pr: 4,
                backgroundColor: "#f22f46",
                borderRadius: "20px",
                color: "#ffffff",
                "&:hover": {
                  // Customize the hover color
                  backgroundColor: "#f22f46",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Hangup
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return callingInfo?.isVisible ? (
    <>
      {callingInfo?.isOutgoingOrIncoming === "out" ? (
        <OutGoingCard />
      ) : (
        <OutGoingCard />
      )}
    </>
  ) : (
    <></>
  );
};
