import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Skeleton, Typography } from "@mui/material";
import { ProfileModal, TwilioVoice } from "../Common";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ProspectsTableHead } from "./ProspectsTableHead";
import { ProspectsTableToolbar } from "./ProspectsTableToolbar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  CALL_RESULT,
  checkAndReturnNone,
  formatPhoneNumber,
} from "../../helpers";
import { useState } from "react";

export const ProspectsTable = (props) => {
  const { rows, filterName, fetchMoreRecord, page, setPage } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [offset, setOffset] = useState(1);

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const getCallResultLabel = (id) => {
    const result = callResult?.find((item) => item.id === id);
    return result ? result.description : "Unknown"; // Return "Unknown" if id not found in CALL_RESULT
  };

  const handleClick = (event, row) => {
    if (!event.target.closest(".show-button")) {
      // Exclude clicks on the "Show" button
      const selectedIndex = selected.indexOf(row?.operations?.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row?.operations?.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const recordsFinished = (newPage + 1) * rowsPerPage >= rows.length;

    if (recordsFinished) {
      //console.log("Records have finished.");
      const nextOffset = offset + 1;
      setOffset((prevState) => prevState + 1);
      fetchMoreRecord(nextOffset);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  const isloading = useSelector((state) => state?.prospect?.loading);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <ProspectsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {isloading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                sx={{
                                  backgroundColor:
                                    index % 2 == 1 ? "#fff" : "#fff",
                                  borderBottom: "0!important",
                                  "&:hover": {
                                    backgroundColor: "#F4F6FA!important",
                                  },
                                }}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row?.firstName}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom: 0,
                                    pl: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      width: "17px",
                                      height: "17px",
                                      minWidth: "27px",
                                      pl: 1.3,
                                      pr: 1.3,
                                      pt: 1.5,
                                      pb: 1.5,
                                      ml: 1,
                                      backgroundColor: "#6B7489",
                                      "&:hover": {
                                        backgroundColor: "#6B7489",
                                      },
                                      borderRadius: "3px",
                                      borderColor: "#6B7489",
                                      color: "#fff",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                      navigate("/ShowProspect", {
                                        state: {
                                          prospectInfoData: row?.view,
                                        },
                                      });
                                    }}
                                    className="show-button" // Add a class to identify the "Show" button
                                  >
                                    <RemoveRedEyeIcon
                                      sx={{ fontSize: "19px" }}
                                    />
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    pl: 2,
                                  }}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <Button
                                    sx={{
                                      "&:hover": {
                                        color: "#0088cc",
                                      },
                                      width: 100,
                                      height: "20px",
                                      minWidth: "50px",
                                      padding: "0 0 0 5px",
                                      color: "#0088cc",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                      navigate("/NewLead", {
                                        state: {
                                          isEdit: true,
                                          prospectInfoData: row?.view,
                                        },
                                      });
                                    }}
                                  >
                                    Create Lead
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {formatPhoneNumber(row?.phoneNumber) ??
                                    row?.phoneNumber}

                                  {row?.phoneNumber && (
                                    <TwilioVoice
                                      phoneNumber={row?.phoneNumber}
                                      fontColor={"#6B7489"}
                                      fontSize={13}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(
                                    formatPhoneNumber(row?.altPhoneNumber)
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {getCallResultLabel(row?.callResult)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.callResultDesc)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.execTitle)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.execFirstName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.execLastName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.companyName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.companyWebsite)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.countyDesc)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.locationState)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.locationCity)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.locationZipCode)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.locationAddress)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.comments)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !isloading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Paper>
    </Box>
  );
};
