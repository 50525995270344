import {
  BASE_URL,
  CALENDAR_SEARCH_URL,
  CREATE_APPOINTMENTS_URL,
  CREATE_EVENTS_URL,
  DELETE_APPOINTMENT_URL,
  GET_ALL_EMPLOYEES_URL,
  GET_APPOINTMENT_SEARCH_URL,
  GET_CALENDAR_EVENTS_URL,
  DELETE_CALENDAR_EVENT_URL
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { deleteRequest, getRequest, postRequest } from "../../../services";
import { LOGOUT } from "../authentication/types";
import {
  CALENDAR_SEARCH_FAILURE,
  CALENDAR_SEARCH_IN_PROGRESS,
  CALENDAR_SEARCH_SUCCESS,
  CLEAR_EVENT,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_IN_PROGRESS,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_IN_PROGRESS,
  CREATE_EVENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_IN_PROGRESS,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_SUCCESS,
  GET_APPOINTMENT_SEARCH_FAILURE,
  GET_APPOINTMENT_SEARCH_IN_PROGRESS,
  GET_APPOINTMENT_SEARCH_SUCCESS,
  GET_All_EMPLOYEES_FAILURE,
  GET_All_EMPLOYEES_IN_PROGRESS,
  GET_All_EMPLOYEES_SUCCESS,
} from "./types";
import toast from "react-hot-toast";
import moment from "moment";
import { logout } from "../authentication";

// Action Creators for creating appointments
export const createAppointmentInProgress = () => ({
  type: CREATE_APPOINTMENT_IN_PROGRESS,
});

export const createAppointmentSuccess = (data) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  data,
});

export const createAppointmentFailure = (error) => ({
  type: CREATE_APPOINTMENT_FAILURE,
  error,
});

export const createAppointment = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data specific to appointments
      const {
        status,
        agentId,
        comment,
        lead,
        agentCallingFor,
        appointmentType,
        confirmAppointmentDate,
        confirmAppointmentTime,
      } = payload;

      const combinedDateTimeString = `${confirmAppointmentDate}T${confirmAppointmentTime}:00Z`;

      // Create a Date object from the combined string
      const combinedDateTime = new Date(combinedDateTimeString);
  
      // Convert to ISO string
      const formattedDateTime = combinedDateTime.toISOString();
      //   Create params object for appointment creation
      const params = {
        agent: agentId ?? null,
        agency_calling_for: agentCallingFor ?? null,
        appointment_datetime: formattedDateTime ?? null,
        appointment_type: appointmentType ?? null,
        comment: comment ?? null,
        status: status ?? null,
        lead: lead ?? null,
      };

      // Dispatch in progress action
      dispatch(createAppointmentInProgress());

      // Make API request to create the appointment
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_APPOINTMENTS_URL}`, // Update to the correct URL for creating appointments
        params,
        "",
        token
      );

      // Check response status and dispatch appropriate actions
      if (response?.status === 201) {
        dispatch(createAppointmentSuccess(response?.data));
        cb();
        toast.success("Appointment has been created successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createAppointmentFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // if(error.code =="ERR_NETWORK"){
      //   toast.error(ERROR_MESSAGE.NETWORK, {
      //     position: "bottom-center",
      //   });
      //   return;
      // }
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let errToast = `${error?.response?.data[key]}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        createAppointmentFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

// Action Creators for appointment search
export const getAppointmentSearchInProgress = () => ({
  type: GET_APPOINTMENT_SEARCH_IN_PROGRESS,
});

export const getAppointmentSearchSuccess = (data) => ({
  type: GET_APPOINTMENT_SEARCH_SUCCESS,
  data,
});

export const getAppointmentSearchFailure = (error) => ({
  type: GET_APPOINTMENT_SEARCH_FAILURE,
  error,
});

const getUpdatedData = (existingData, newData, offset) => {
  return offset !== 1 ? existingData.concat(newData) : newData;
};

export const getAppointmentSearch = (payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const { appointment } = getState();
      dispatch(getAppointmentSearchInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get appointment search data
      const URL = `${BASE_URL}${GET_APPOINTMENT_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        const newData = getUpdatedData(
          appointment?.appointmentData?.appointmentData,
          response?.data?.results ?? [],
          1
        );
        const data = {appointmentData:newData, totalCount:response?.data?.count }

        dispatch(getAppointmentSearchSuccess(data));
        cb();
      } else {
        dispatch(
          getAppointmentSearchFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // if(error.code =="ERR_NETWORK"){
      //   toast.error(ERROR_MESSAGE.NETWORK, {
      //     position: "bottom-center",
      //   });
      //   return;
      // }
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getAppointmentSearchFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for appointment delete in progress
export const deleteAppointmentInProgress = () => ({
  type: DELETE_APPOINTMENT_IN_PROGRESS,
});

// Action creators for appointment delete success
export const deleteAppointmentSuccess = (data) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  data,
});

// Action creators for appointment delete failure
export const deleteAppointmentFailure = (error) => ({
  type: DELETE_APPOINTMENT_FAILURE,
  error,
});

// Action method for appointment delete
export const deleteAppointment = (appointmentId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(deleteAppointmentInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete appointment

      let URL = `${BASE_URL}${DELETE_APPOINTMENT_URL}${appointmentId ?? 0}`;

      const response = await deleteRequest(URL, {}, token);

      // Check response status
      if (response?.status === 204) {
        dispatch(deleteAppointmentSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteAppointmentFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteAppointmentFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Method for getting all employees

export const GetAllEmployeesInProgress = () => ({
  type: GET_All_EMPLOYEES_IN_PROGRESS,
});

export const GetAllEmployeesSuccess = (data) => ({
  type: GET_All_EMPLOYEES_SUCCESS,
  data,
});

export const GetAllEmployeesFailure = (error) => ({
  type: GET_All_EMPLOYEES_FAILURE,
  error,
});

// Method for getting all employees
export const GetAllEmployees = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetAllEmployeesInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_EMPLOYEES_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetAllEmployeesSuccess(response?.data));
      } else {
        dispatch(
          GetAllEmployeesFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetAllEmployeesFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const calendarSearchInProgress = () => ({
  type: CALENDAR_SEARCH_IN_PROGRESS,
});

export const calendarSearchSuccess = (data) => ({
  type: CALENDAR_SEARCH_SUCCESS,
  data,
});

export const calendarSearchFailure = (error) => ({
  type: CALENDAR_SEARCH_FAILURE,
  error,
});

// Thunk Method for Calendar Search
export const calendarSearch = (id, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      const payload = {
        agent_id: id,
      };
      dispatch(calendarSearchInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${CALENDAR_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(calendarSearchSuccess(response?.data ?? []));
        cb();
        toast.success("Calendar data retrieved successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          calendarSearchFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        calendarSearchFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// // Action Creators
// export const getCalendarEventsInProgress = () => ({
//   type: GET_CALENDAR_EVENTS_IN_PROGRESS,
// });

// export const getCalendarEventsSuccess = (calendarEvents) => ({
//   type: GET_CALENDAR_EVENTS_SUCCESS,
//   calendarEvents,
// });

// export const getCalendarEventsFailure = (error) => ({
//   type: GET_CALENDAR_EVENTS_FAILURE,
//   error,
// });

// // Action Method for Getting Calendar Events
// export const getCalendarEvents = () => {
//   return async (dispatch) => {
//     try {
//       // Dispatch in progress action
//       dispatch(getCalendarEventsInProgress());

//       const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace with your token key
//       const response = await getRequest(`${BASE_URL}${GET_CALENDAR_EVENTS_URL}`, token);

//       // Check response status
//       if (response?.status === 200) {
//         dispatch(getCalendarEventsSuccess(response?.data));
//       } else {
//         dispatch(
//           getCalendarEventsFailure({
//             type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
//             message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
//           })
//         );
//       }
//     } catch (error) {
//       // Handle error scenarios
//       if (error?.response?.status === 401) {
//         // Unauthorized response received (status code 401)
//         dispatch({
//           type: LOGOUT,
//         });
//       }
//       const errorMessage =
//         error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
//       dispatch(
//         getCalendarEventsFailure({
//           type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
//           message: errorMessage,
//         })
//       );
//       toast.error(errorMessage, {
//         position: "bottom-center",
//       });
//     }
//   };
// };

export const createEventInProgress = () => ({
  type: CREATE_EVENT_IN_PROGRESS,
});

export const createEventSuccess = (data) => ({
  type: CREATE_EVENT_SUCCESS,
  data,
});

export const createEventFailure = (error) => ({
  type: CREATE_EVENT_FAILURE,
  error,
});

export const clearEvents = (error) => ({
  type: CLEAR_EVENT,
  error,
});

export const createEvent = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(createEventInProgress());

      // Make API request to create the event
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_EVENTS_URL}`, // Update to the correct URL for creating events
        payload,
        "",
        token
      );
      
      // Check response status and dispatch appropriate actions
      if (response?.status === 201) {
        dispatch(createEventSuccess(response?.data));
        cb();
        toast.success("Event has been created successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createEventFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createEventFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};


// Action Creators
export const deleteCalendarEventRequest = () => ({
  type: DELETE_CALENDAR_EVENT_REQUEST,
});

export const deleteCalendarEventSuccess = (data) => ({
  type: DELETE_CALENDAR_EVENT_SUCCESS,
  data,
});

export const deleteCalendarEventFailure = (error) => ({
  type: DELETE_CALENDAR_EVENT_FAILURE,
  error,
});

// Async Action Creator
export const deleteCalendarEvent = (eventId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCalendarEventRequest());

      const payload = {
        event_id :eventId
      }
      const token = getItemFromLocalStorage(KEYS.TOKEN);


      const URL = `${BASE_URL}${DELETE_CALENDAR_EVENT_URL}`; 

      const response = await postRequest(URL, payload,"",token);

      if (response?.status === 200) {
        dispatch(deleteCalendarEventSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteCalendarEventFailure({
            type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteCalendarEventFailure({
          type: ERROR_TYPE.API.APPOINTMENTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
