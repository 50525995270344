import * as React from "react";
import Box from "@mui/material/Box";
import { CallbackTable } from "./CallbackTable";

export const CallbackParentTable = (props) => {
  const { callbackData ,toggleAllComments,allCommentsExpanded,
    page,
    setPage,
    offset,
    setOffset ,
    fetchMoreRecord,
    onUpdateCampaignWithAltCampaign,
    updateEmployeeToCallBack,
  } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const createData = (
    show,
    amountOwed,
    agent,
    scheduleTime,
    campaign,
    accountName,
    phoneNumber,
    prospects,
    alternateCampaign,
    assignedTo,
    createdAt,
    createdBy
  ) => {
    return {
      show,
      amountOwed,
      agent,
      scheduleTime,
      campaign,
      accountName,
      phoneNumber,
      prospects,
      alternateCampaign,
      assignedTo,
      createdAt,
      createdBy,
    };
  };
  
  const rows = [
    createData(
      "Show",
      "$500",
      "Agent 1",
      "09-13-2023 10:00 AM",
      "Campaign 1",
      "Account Name 1",
      "123-456-7890",
      "Prospect 1",
      "Alt Campaign 1",
      "Assigned To 1",
      "09-13-2023 09:00 AM",
      "User 1"
    ),
    createData(
      "Show",
      "$200",
      "Agent 2",
      "09-14-2023 02:00 PM",
      "Campaign 2",
      "Account Name 2",
      "987-654-3210",
      "Prospect 2",
      "Alt Campaign 2",
      "Assigned To 2",
      "09-14-2023 10:30 AM",
      "User 2"
    ),
    // Add more rows as needed
  ];

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

  //   {
  //     "id": 1,
  //     "date_time": "2023-09-13T10:00:00Z",
  //     "comment": "Callback comment",
  //     "prospect_id": 1,
  //     "agent_id": 1,
  //     "agency_calling_for_id": 1,
  //     "is_processed": false,
  //     "assigned_to": null
  // },

        


    return data.map((item) => {
      const {
        amount_owned,
        agent_name,
        date_time,
        campaign_name,
        account_name,
        phone_number,
        prospect_name,
        assigned_to_name,
        created_at,
        created_by_name
      } = item;

      const user = item;

      return createData(
        user,
        amount_owned,
        agent_name,
        date_time,
        campaign_name,
        account_name,
        phone_number,
        prospect_name,
        user,
        assigned_to_name,
        created_at,
        created_by_name,
      );
    });
  };

  const tableRows = convertApiResponseToRows(callbackData);

  // Filtered rows based on the filter values
  // const filteredRows = rows?.filter((row) => {
  //   const nameFilter = filterName.trim().toLowerCase();
  //   const activeFilter =
  //     filterActive === "all" ||
  //     (filterActive === "active" && row.isActive) ||
  //     (filterActive === "inactive" && !row.isActive);
  //   return row.firstName.toLowerCase().includes(nameFilter) && activeFilter;
  // });

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <CallbackTable
        rows={tableRows}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        handleActiveFilterChange={handleActiveFilterChange}
        toggleAllComments={toggleAllComments}
        allCommentsExpanded={allCommentsExpanded}
        page={page}
        setPage={setPage}
        offset={offset}
        setOffset={setOffset}
        fetchMoreRecord={fetchMoreRecord}
        onUpdateCampaignWithAltCampaign={onUpdateCampaignWithAltCampaign}
        updateEmployeeToCallBack={updateEmployeeToCallBack}
      />
    </Box>
  );
};
