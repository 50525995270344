import * as React from "react";
import Box from "@mui/material/Box";
import { ClientTable } from "./ClientTable";

export const ClientParentTable = (props) => {
  const { clientData, onDeleteClient, handleStatusSubmit } = props;

  return (
    <Box>
      <ClientTable
        rows={clientData}
        onDeleteClient={onDeleteClient}
        handleStatusSubmit={handleStatusSubmit}
      />
    </Box>
  );
};
