import * as React from "react";
import Box from "@mui/material/Box";
import { CallTable } from "./CallTable";
import { useSelector } from "react-redux";

export const CallParentTable = (props) => {
  const { clientData } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let campaignReport = useSelector((state) => state?.reports?.campaignReport);

  const createData = (
    campaign,
    username,
    loginTime,
    activeHoursLogged,
    utilizationTime,
    calls,
    idleTime,
    workDay
  ) => {
    return {
      campaign,
      username,
      loginTime,
      activeHoursLogged,
      utilizationTime,
      calls,
      idleTime,
      workDay,
    };
  };

  // Create dummy rows for the specified columns
  const rows = [
    createData(
      "Show",
      "Marybeth",
      "2023-10-11 09:00 AM",
      "5 hours",
      "4 hours",
      10,
      "1 hour",
      "Monday"
    ),
    createData(
      "Show",
      "John",
      "2023-10-11 08:30 AM",
      "7 hours",
      "6 hours",
      15,
      "2 hours",
      "Tuesday"
    ),
    createData(
      "Show",
      "Jane",
      "2023-10-11 10:15 AM",
      "6 hours",
      "5 hours",
      12,
      "1.5 hours",
      "Wednesday"
    ),
    createData(
      "Show",
      "Michael",
      "2023-10-11 09:45 AM",
      "8 hours",
      "7 hours",
      20,
      "2.5 hours",
      "Thursday"
    ),
    createData(
      "Show",
      "Emily",
      "2023-10-11 08:00 AM",
      "4 hours",
      "3.5 hours",
      8,
      "1 hour",
      "Friday"
    ),
  ];

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((item) => {
      const {
        campaign_name,
        username,
        login_time,
        active_hours,
        utilization_time,
        call_count,
        idle_time,
        work_date,
      } = item;

      return createData(
        campaign_name,
        username,
        login_time,
        active_hours,
        utilization_time,
        call_count,
        idle_time,
        work_date
      );
    });
  };

  const res = campaignReport?.campaign_user ?? [];
  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <CallTable rows={tableRows} filterName={filterName} />
    </Box>
  );
};
