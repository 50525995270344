export const validateEmail = (email) => {
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

export const validateMobileNumber = (mobileNumber) => {
  const regExp = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
  const number =
    typeof mobileNumber === "string" ? mobileNumber : mobileNumber.toString();
  return regExp.test(number);
};

export const validateName = (name) => {
  const regExp = /^[a-zA-Z ]{2,30}$/;
  return regExp.test(name);
};
export const validatePincode = (pincode) => {
  const regExp = /^\d{5}$/;
  return regExp.test(pincode.toString());
};

export const ValidatePassport = (value) => {
  const regex = /^[a-zA-Z0-9]{8,10}$/;
  return value && regex.test(value);
};

export const validatePanNumber = (value) => {
  let regex = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

  return value && regex.test(value);
};

export const validatePin = (value) => {
  const regex = new RegExp(/^[0-9]{6}$/gi);
  return value && regex.test(value);
};

export const isValidDateFormat = (dateString) => {
  // Regular expression to match "dd-mm-yyyy" format
  const dateRegex = /^\d{2}-\d{2}-\d{4}$/;

  // Check if the input matches the required format
  if (!dateRegex.test(dateString)) {
    return false;
  }

  // Extract day, month, and year parts from the input string
  const [day, month, year] = dateString.split("-");

  // Convert day, month, and year parts to numbers
  const dayNum = parseInt(day, 10);
  const monthNum = parseInt(month, 10);
  const yearNum = parseInt(year, 10);

  // Check if the day, month, and year are valid numbers
  if (isNaN(dayNum) || isNaN(monthNum) || isNaN(yearNum)) {
    return false;
  }

  // Check if the month is within the valid range (1 to 12)
  if (monthNum < 1 || monthNum > 12) {
    return false;
  }

  // Check if the day is within the valid range based on the month
  const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
  if (dayNum < 1 || dayNum > daysInMonth) {
    return false;
  }

  // Optionally, you can add additional checks for the year range (e.g., yearNum > 1900 and yearNum < 2100)

  // If all checks pass, the date is valid
  return true;
};
