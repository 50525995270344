import {
  LOGIN_IN_PROGRESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_ERROR_RESET,
  VERIFYOTP_IN_PROGRESS,
  VERIFYOTP_SUCCESS,
  VERIFYOTP_FAILURE,
  VERIFYOTP_ERROR_RESET,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT,
  LOGOUT_IN_PROGRESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  QRCODE_IN_PROGRESS,
  QRCODE_SUCCESS,
  QRCODE_FAILURE,
  VERIFY_QR_OTP_IN_PROGRESS,
  VERIFY_QR_OTP_SUCCESS,
  VERIFY_QR_OTP_FAILURE,
  VERIFY_QR_OTP_ERROR_RESET,
  VERIFY_QR_OTP_PROFILE_IN_PROGRESS,
  VERIFY_QR_OTP_PROFILE_SUCCESS,
  VERIFY_QR_OTP_PROFILE_FAILURE,
  VERIFY_QR_OTP_PROFILE_ERROR_RESET,
  USER_AUTHENTICATION_IN_PROGRESS,
  USER_AUTHENTICATION_SUCCESS,
  USER_AUTHENTICATION_FAILURE,
  LOGOUT_ONINE_USER_IN_PROGRESS,
  LOGOUT_ONINE_USER_SUCCESS,
  LOGOUT_ONINE_USER_FAILURE

} from "./types";
// Actions
// API Urls
import { loginUrl } from "../../urlSchema";
// Utils
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  EVENTS,
  KEYS,
  SUCCESS_MESSAGE,
  clearTokenFromLocalStorage,
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest } from "../../../services";
import {
  BASE_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  VERIFY_OTP_URL,
  QR_CODE_GENERATE_URL,
  USER_AUTHENTICATION
} from "../../../config/api_urls";
import toast from "react-hot-toast";
import { persistor } from "../../index";

export const LoginInProgress = () => ({
  type: LOGIN_IN_PROGRESS,
});

export const LoginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

export const LoginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error,
});

//login
export const LoginAuth = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const { userEmail, password, slug } = payload;

      let user_Email = String(userEmail).toLowerCase();

      const params = {
        email: user_Email,
        password: password,
        slug: slug,
      };
      dispatch(LoginInProgress());

      let URL = `${BASE_URL}${LOGIN_URL}`;
      const response = await postRequest(URL, params);
      if (response?.status === 200) {
        dispatch(LoginSuccess(response?.data));
        if (response?.data?.is_otp_required === false && response?.data?.is_2FA_enable === false) {
          dispatch(VerifyOTPSuccess(response?.data));
          const token_value = response?.data?.access;
          const refresh_token = response?.data?.refresh;
          setItemInLocalStorage(KEYS.TOKEN, token_value);
          setItemInLocalStorage(KEYS.REFRESH, refresh_token);
        } 
        else {
          const token_value = response?.data?.access;
          const refresh_token = response?.data?.refresh;
          setItemInLocalStorage(KEYS.TOKEN, token_value);
          setItemInLocalStorage(KEYS.REFRESH, refresh_token)
          cb(response?.data);
        }
      } else {
        dispatch(
          LoginFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422 || error?.response?.status === 401) {
        dispatch({
          type: LOGOUT,
        });
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.status?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(LoginFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =error?.response?.data?.error?.message ??
          ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          LoginFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: errorMessage,
          })
        );
      }
    }
  };
};

//resetErrors
export const LoginAuthErrorReset = () => ({
  type: LOGIN_ERROR_RESET,
});
export const VerifyOtpErrorReset = () => ({
  type: VERIFYOTP_ERROR_RESET,
});

//verifyOtp

export const VerifyOTPInProgress = () => ({
  type: VERIFYOTP_IN_PROGRESS,
});

export const VerifyOTPSuccess = (data) => ({
  type: VERIFYOTP_SUCCESS,
  data,
});

export const verifyOTPFailure = (error) => ({
  type: VERIFYOTP_FAILURE,
  error,
});

//verify Qr Code Otp 
export const VerifyQrOTPInProgress = () => ({
  type: VERIFY_QR_OTP_IN_PROGRESS,
});

export const VerifyQrOTPSuccess = (data) => ({
  type: VERIFY_QR_OTP_SUCCESS,
  data,
});

export const verifyQrOTPFailure = (error) => ({
  type: VERIFY_QR_OTP_FAILURE,
  error,
});
export const VerifyQrOtpErrorReset = () => ({
  type: VERIFY_QR_OTP_ERROR_RESET,
});

//VERIFY OTP FROM PROFILE
export const VerifyQrOTPProfileInProgress = () => ({
  type: VERIFY_QR_OTP_PROFILE_IN_PROGRESS,
});

export const VerifyQrOTPProfileSuccess = (data) => ({
  type: VERIFY_QR_OTP_PROFILE_SUCCESS,
  data,
});

export const verifyQrOTPProfileFailure = (error) => ({
  type: VERIFY_QR_OTP_PROFILE_FAILURE,
  error,
});
export const VerifyQrOtpProfileErrorReset = () => ({
  type: VERIFY_QR_OTP_PROFILE_ERROR_RESET,
});





//enable user authentication type
export const userAuthenticationTypeInProgress = () => ({
  type: USER_AUTHENTICATION_IN_PROGRESS,
});

export const userAuthenticationTypeSuccess = (data) => ({
  type: USER_AUTHENTICATION_SUCCESS,
  data,
});

export const userAuthenticationTypeFailure = (error) => ({
  type: USER_AUTHENTICATION_FAILURE,
  error,
});
export const VerifyOTP = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const { otp, email ,two_way_authentication ,two_fa_authentication_enable} = payload;
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const params = {
        email: email,
        otp: otp,
        two_way_authentication:two_way_authentication,
        two_fa_authentication_enable:two_fa_authentication_enable,
        
      };
      dispatch(VerifyOTPInProgress());
      let URL = `${BASE_URL}${VERIFY_OTP_URL}`;
      const response = await postRequest(URL, params ,"",token);
      if (response?.status === 200) {
        dispatch(VerifyOTPSuccess(response?.data));
        cb();
        const token_value = response?.data?.access;
        const refresh_token = response?.data?.refresh;
        setItemInLocalStorage(KEYS.TOKEN, token_value);
        setItemInLocalStorage(KEYS.REFRESH, refresh_token);
      } else {
        dispatch(
          verifyOTPFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(verifyOTPFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          verifyOTPFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: errorMessage,
          })
        );
      }
    }
  };
};

export const verifyQrOTP = (payload ,cb=()=>{},callBackMessage=()=>{})=>{
  return async (dispatch) => {
    try {
      const { token_otp, email ,two_way_authentication,
      two_fa_authentication_enable} = payload;
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const params = {
        email: email,
        token_otp: token_otp,
        two_way_authentication:two_way_authentication,
        two_fa_authentication_enable:two_fa_authentication_enable

      };
      dispatch(VerifyQrOTPInProgress());
      let URL = `${BASE_URL}${VERIFY_OTP_URL}`;
      const response = await postRequest(URL, params ,"",token);
      if (response?.status === 200) {
        dispatch(VerifyQrOTPSuccess(response?.data));
        cb();
        callBackMessage();
        const token_value = response?.data?.access;
        const refresh_token = response?.data?.refresh;
        setItemInLocalStorage(KEYS.TOKEN, token_value);
        setItemInLocalStorage(KEYS.REFRESH, refresh_token);
      } else {
        dispatch(
          verifyQrOTPFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(verifyQrOTPFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          verifyQrOTPFailure({
            type: VERIFY_QR_OTP_FAILURE,
            message: errorMessage,
          })
        );
      }
    }
  };
}

export const verifyQrOTPProfile = (payload ,cb=()=>{})=>{
  return async (dispatch) => {
    try {
      const { otp, email ,two_way_authentication,
      two_fa_authentication_enable} = payload;
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const params = {
        email: email,
        token_otp: otp,
        two_way_authentication:two_way_authentication,
        two_fa_authentication_enable:two_fa_authentication_enable

      };
      dispatch(VerifyQrOTPProfileInProgress());
      let URL = `${BASE_URL}${VERIFY_OTP_URL}`;
      const response = await postRequest(URL, params ,"",token);
      if (response?.status === 200) {
        dispatch(VerifyQrOTPProfileSuccess(response?.data));
        cb();
        toast.success("2Factor Authentication enabled", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          verifyQrOTPProfileFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(verifyQrOTPProfileFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          verifyQrOTPProfileFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: errorMessage,
          })
        );
      }
    }
  };
}

export const userAuthenticationType = (payload ,cb=()=>{})=>{
  return async (dispatch) => {
    try {
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const params = {
        two_way_authentication:( payload =="2way"?true:false),
        two_fa_authentication_enable:( payload =="2factor"?true:false),
        reset_auth:true
      };
      dispatch(userAuthenticationTypeInProgress());
      let URL = `${BASE_URL}${USER_AUTHENTICATION}`;
      const response = await postRequest(URL, params,
        "",
        token);
      if (response?.status === 200) {
        dispatch(userAuthenticationTypeSuccess(response?.data));
        cb();
        toast.success(response?.data?.message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          userAuthenticationTypeFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(userAuthenticationTypeFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          userAuthenticationTypeFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: errorMessage,
          })
        );
      }
    }
  };
}

export const QrCodeInProgress = () => ({
  type: QRCODE_IN_PROGRESS,
});

export const QrCodeSuccess = (data) => ({
  type: QRCODE_SUCCESS,
  data,
});

export const QrCodeFailure = (error) => ({
  type: QRCODE_FAILURE,
  error,
});

export const qrCodeGenerate = (payload,cb=()=>{})=>{
  return async (dispatch) => {
    try {
      dispatch(QrCodeInProgress());
      let URL = `${BASE_URL}${QR_CODE_GENERATE_URL}`;
      const token = getItemFromLocalStorage(KEYS.TOKEN); 
      const response = await getRequest(URL,token);
      if (response?.status === 200) {
        dispatch(QrCodeSuccess(response?.data));
        cb();
      } else {
        dispatch(
          QrCodeFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message:
              response?.data?.error ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        const errorType = error?.response?.data?.status?.error_code ?? "";
        const errorMessage =
          error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(QrCodeFailure({ type: errorType, message: errorMessage }));
      } else {
        const errorMessage =
          error?.response?.data?.error ?? ERROR_MESSAGE.API.UNKNOWN;
        dispatch(
          QrCodeFailure({
            type: ERROR_TYPE.API.LOGIN.UNKNOWN,
            message: errorMessage,
          })
        );
      }
    }
  };
}

export const forgotPasswordInProgress = () => ({
  type: FORGOT_PASSWORD_IN_PROGRESS,
});

export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  data,
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
});

export const forgotPassword = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const { userEmail } = payload;

      dispatch(forgotPasswordInProgress());

      const params = {
        email: userEmail,
      };

      const URL = `${BASE_URL}${FORGOT_PASSWORD_URL}`;
      const response = await postRequest(URL, params);

      if (response?.status === 200) {
        dispatch(forgotPasswordSuccess(response?.data));
        cb();
        const message =
          response?.data.message ??
          "New Password is sent to registered email id";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          forgotPasswordFailure({
            type: ERROR_TYPE.API.FORGOT_PASSWORD.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.email.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        forgotPasswordFailure({
          type: ERROR_TYPE.API.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const logoutInProgress = () => ({
  type: LOGOUT_IN_PROGRESS,
});

export const logoutSuccess = (data) => ({
  type: LOGOUT_SUCCESS,
  data,
});

export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  error,
});

export const logout = (payload = {}, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(logoutInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${LOGOUT_URL}`;

      const params = payload?.user_id ? payload : {};
      const response = await postRequest(URL, params, "", token);
      if (response?.status === 200) {
        dispatch(logoutSuccess(response?.data));
        persistor.purge();
        cb();
        const message = "Logout successful";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          logoutFailure({
            type: ERROR_TYPE.API.LOGOUT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: LOGOUT,
      });
      const errorMessage =
        error?.response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        logoutFailure({
          type: ERROR_TYPE.API.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};


export const logoutOnlineUsersInProgress = () => ({
  type: LOGOUT_ONINE_USER_IN_PROGRESS,
});

export const logoutOnlineUsersSuccess = (data) => ({
  type: LOGOUT_ONINE_USER_SUCCESS,
  data,
});

export const logoutOnlineUsersFailure = (error) => ({
  type: LOGOUT_ONINE_USER_FAILURE,
  error,
});

export const logoutOnlineUsers = (payload = {}, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(logoutOnlineUsersInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${LOGOUT_URL}`;

      const params = payload?.user_id ? payload : {};
      const response = await postRequest(URL, params, "", token);
      if (response?.status === 200) {
        dispatch(logoutOnlineUsersSuccess(response?.data));
        cb();
        const message = "Logout successful";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          logoutFailure({
            type: ERROR_TYPE.API.LOGOUT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: LOGOUT,
      });
      const errorMessage =
        error?.response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        logoutOnlineUsersFailure({
          type: ERROR_TYPE.API.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
