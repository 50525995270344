import React, { useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_STATE } from "../../helpers";
import { setDeviceState, updateCallingInfo } from "../../store/actions";
import moment from "moment";
import { getTwilioDevice } from "../Common";
import { createCallHistory } from "../../store/actions/report";
import Draggable from "react-draggable";

export const Dialer = ({ pathname, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const deviceStatus = useSelector((state) => state?.twillio?.deviceState);

  const isRecord = useSelector((state) => state?.loginAuth?.isRecord);

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const dispatch = useDispatch();

  const handleButtonClick = (digit) => {
    // Concatenate the pressed digit to the current phone number
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + digit);
  };

  // const handleCall = () => {
  //   // Implement the call functionality using the phone number
  //   alert(`Calling ${phoneNumber}`);
  //   // You can replace the alert with the actual call functionality using APIs or other methods.
  //   // For demonstration purposes, this example uses the alert() function to show the phone number.
  // };

  const handleClear = () => {
    // Clear the phone number when the "Clear" button is pressed
    setPhoneNumber("");
  };

  const handleClose = () => {
    onClose();
    handleClear();
  };
  var startDateTime;
  var endDateTime;

  const onCallHistory = (payload) => {
    dispatch(createCallHistory(payload));
  };

  const handleCall = () => {
    const device = getTwilioDevice();
    if (device && deviceStatus === DEVICE_STATE.READY) {
      // You can implement your call logic here
      const params = {
        To: phoneNumber?.toString() ?? "", // Replace with the recipient's phone number
        record: isRecord ?? false,
      }; // Specify call parameters

      device
        .connect({
          params: params,
          rtcConstraints: {
            audio: true,
          },
        })
        .then((call) => {
          dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          dispatch(
            updateCallingInfo({
              isVisible: true,
              contact_no: phoneNumber ?? "",
              isOutgoingOrIncoming: "out",
            })
          );
          startDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");
          call.on("accept", () => {
            dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          });
          call.on("cancel", () => {
            //console.log("The call has been canceled.");
          });
          call.on("ringing", (hasEarlyMedia) => {
            //console.log("ringing......");
          });

          call.on("connect", (twilioConnection) => {
            dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          });

          call.on("disconnect", (connection) => {
            //console.log("The call has been disconnected.");
            const myMap = new Map(connection.customParameters);
            const recordValue = myMap.get("record");
            endDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");

            const payload = {
              agent: userInfo?.id ?? null,
              agency_calling_for: null,
              prospect: null,
              phone_number: phoneNumber,
              start_datetime: startDateTime,
              end_datetime: endDateTime,
              call_sid: connection.parameters.CallSid,
              is_recorded: recordValue === "true" ? true : false,
            };

            onCallHistory(payload);
            dispatch(setDeviceState(DEVICE_STATE.READY));
            dispatch(
              updateCallingInfo({
                isVisible: false,
                contact_no: "",
                isOutgoingOrIncoming: "",
              })
            );
          });
          call.on("reject", () => {
            //console.log("The call was rejected.");
            dispatch(setDeviceState(DEVICE_STATE.READY));
            dispatch(
              updateCallingInfo({
                isVisible: false,
                contact_no: "",
                isOutgoingOrIncoming: "",
              })
            );
          });
        })
        .catch((error) => {
          console.error("Error initiating the call:", error);
          // Handle errors, e.g., display an error message to the user
        });
    }
  };

  const defaultX = window.innerWidth - 320; // Adjust as needed
  const defaultY = window.innerHeight - 400;

  return (
    <Draggable defaultPosition={{ x: defaultX, y: defaultY }} cancel=".no-drag">
      <div className="react-dialer">
        <div className="display">{phoneNumber}</div>
        <Box>
          <div className="buttons .no-drag">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((digit) => (
              <button
                key={digit}
                onClick={() => {
                  if (phoneNumber.toString().trim().length <= 11) {
                    handleButtonClick(digit);
                  }
                }}
                className="dial_button"
              >
                {digit}
              </button>
            ))}
            <button onClick={handleClose} className="dial_clear">
              <ClearIcon />
            </button>
            <button
              onClick={handleCall}
              className="dial_call"
              disabled={
                phoneNumber?.toString()?.trim()?.length >= 10 ? false : true
              }
            >
              <CallIcon />
            </button>
            <button onClick={handleClear} className="dial_clear">
              <DeleteForeverIcon />
            </button>
          </div>
        </Box>
      </div>
    </Draggable>
  );
};
