// New action types for creating commercial invoice
export const CREATE_COMMERCIAL_INVOICE_IN_PROGRESS =
  "CREATE_COMMERCIAL_INVOICE_IN_PROGRESS";
export const CREATE_COMMERCIAL_INVOICE_SUCCESS =
  "CREATE_COMMERCIAL_INVOICE_SUCCESS";
export const CREATE_COMMERCIAL_INVOICE_FAILURE =
  "CREATE_COMMERCIAL_INVOICE_FAILURE";



export const COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS =
  "COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS";
export const COMMERCIAL_INVOICE_SEARCH_SUCCESS =
  "COMMERCIAL_INVOICE_SEARCH_SUCCESS";
export const COMMERCIAL_INVOICE_SEARCH_FAILURE =
  "COMMERCIAL_INVOICE_SEARCH_FAILURE";

// Action Types
export const GENERATE_INVOICE_PDF_REQUEST = "GENERATE_INVOICE_PDF_REQUEST";
export const GENERATE_INVOICE_PDF_SUCCESS = "GENERATE_INVOICE_PDF_SUCCESS";
export const GENERATE_INVOICE_PDF_FAILURE = "GENERATE_INVOICE_PDF_FAILURE";

// New action types for sending email commercial invoices
export const SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS =
  "SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS";
export const SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS =
  "SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS";
export const SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE =
  "SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE";

  export const UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS = 'UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS';
  export const UPDATE_COMMERCIAL_INVOICE_SUCCESS = 'UPDATE_COMMERCIAL_INVOICE_SUCCESS';
  export const UPDATE_COMMERCIAL_INVOICE_FAILURE = 'UPDATE_COMMERCIAL_INVOICE_FAILURE';
  
export const CLIENT_INVOICE_SEARCH_SUCCESS = "CLIENT_INVOICE_SEARCH_SUCCESS";