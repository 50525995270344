// LoginScreen.js
import React, { useMemo } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { PageTitle, ParentComponent } from "../Common";
import { useEffect } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";


export const EmployeesList = (props) => {
  const {
    GetAllEmployees,
    employeesData,
    onDeleteEmployee,
    onUpdateCallRecStatus,
  } = props;

  const [filterData, setFilterData] = React.useState({
    name: "",
    stateName: "",
    isActive: "active",
  });
  PageTitle("Employee List");


  useEffect(() => {
    fetchAllEmployees();
  }, []);

  const fetchAllEmployees = () => {
    GetAllEmployees(filterData);
  };

  const callBackFunc = () => {
    fetchAllEmployees();
  };

  const handleNameFilterChange = (event) => {
    setFilterData({
      ...filterData,
      name: event.target.value,
    });
  };
  const handleStateFilterChange = (event) => {
    setFilterData({
      ...filterData,
      stateName: event.target.value,
    });
  };

  const handleActiveFilterChange = (event) => {
    setFilterData({
      ...filterData,
      isActive: event.target.value,
    });
  };

  const handleCallStatusSubmit = (payload) => {
    onUpdateCallRecStatus(payload, callBackFunc);
  };

  const onSearch = () => {
    GetAllEmployees(filterData);
  };
  const createData = (
    firstName,
    lastName,
    phoneNumber,
    isActive,
    operations,
    callRecording
  ) => {
    return {
      firstName,
      lastName,
      phoneNumber,
      isActive,
      operations,
      callRecording,
    };
  };
  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const { user } = item;
      const isActive = user?.account_status;
      const call_recordText = item?.call_record;
      const userData = item;
      return createData(
        user?.first_name,
        user?.last_name,
        user?.phone_number,
        isActive,
        userData,
        call_recordText
      );
    });
  };

  const tableRows = useMemo(() => {
    return convertApiResponseToRows(employeesData);
  }, [employeesData]);


  const deleteEmployees = (payload) => {
    onDeleteEmployee(payload, callBackFunc);
  };
  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                borderRadius: "10px 10px 0 0!Important",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Employees Listing
              </Typography>
            </AccordionSummary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                marginRight: { xs: 0, sm: 2, md: 2, lg: 2 },
                marginLeft: { xs: 2, sm: 0, md: 0, lg: 0 },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: 130,
                  textTransform: "capitalize",
                  marginRight: 1,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2!important",
                  border: 2,
                  size: "small",
                  fontWeight: 600,
                  color: "#212223",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Mass Update
              </Button>
              <Button
                variant="contained"
                sx={{
                  mr: { xs: 1, sm: 1, md: 2, lg: 2 },
                  width: 100,
                  backgroundColor: "transparent",
                  borderColor: "#6DD6C2",
                  textTransform: "capitalize",
                  border: 2,
                  fontWeight: 600,
                  color: "#1ab394",
                  size: "small",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2!important",
                    color: "#212223",
                  },
                }}
              >
                Export
                <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
              </Button>
            </Box>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#6DD6C2",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                },
                borderRadius: "50%",
                borderColor: "#6DD6C2",
                color: "#212223",
              }}
            >
              <SearchIcon />
            </IconButton> */}
          </Box>
          {/* <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#6DD6C2",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#6DD6C2",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>

           
            
          </Drawer> */}
          <AccordionDetails>
            <Box sx={{}}>
              <Box sx={{
                display: { xs: "grid", sm: "flex", md: 'flex' }, gap: { xs: "15px", sm: "20px", md: '20px' }, alignItems: 'center',
                border: '1px solid #ddd',
                padding: { xs: "10px", sm: "18px", md: '20px' },
                marginTop: { xs: "15px", sm: "20px", md: '20px' },
                marginBottom: { xs: "15px", sm: "20px", md: '20px' },
                borderRadius: "7px"
              }}>
                {/* <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#212223", fontWeight: 600, mt: 2 }}
              >
                Search Employee Listing
              </Typography> */}
                <Box sx={{}}>
                  {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Filter By Name
                </Typography> */}
                  <TextField
                    margin="0"
                    fullWidth
                    size="small"
                    name="state"
                    label="Filter By Name"
                    type="text"
                    id="state"
                    fontSize="15"
                    value={filterData?.name}
                    onChange={handleNameFilterChange}
                    InputLabelProps={{
                      sx: {
                        fontSize: "15px",
                        "&.Mui-focused": {
                          color: "#6DD6C2",
                        },
                      },
                    }}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    }}
                  />
                </Box>
                <Box sx={{}}>
                  {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Filter By State
                </Typography> */}
                  <TextField
                    margin="0"
                    fullWidth
                    size="small"
                    name="state"
                    label="Filter By State"
                    type="text"
                    id="state"
                    fontSize="15"
                    value={filterData?.stateName}
                    onChange={handleStateFilterChange}
                    InputLabelProps={{
                      sx: {
                        fontSize: "15px",
                        "&.Mui-focused": {
                          color: "#6DD6C2",
                        },
                      },
                    }}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: '100%', maxWidth: { xs: "100%", sm: "230px", md: '230px' }, }}>
                  {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Select
                </Typography> */}
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                          margin: "0",
                        },
                      }}
                    >
                      Active / Inactive
                    </InputLabel>
                    <Select
                      sx={{ backgroundColor: "#fff!important" }}
                      id="selectinfo"
                      label="Active / Inactive"
                      value={filterData?.isActive}
                      onChange={handleActiveFilterChange}
                      size="small"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="active" sx={{ fontSize: 15 }}>
                        Active
                      </MenuItem>
                      <MenuItem value="inactive" sx={{ fontSize: 15 }}>
                        Inactive
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  onClick={onSearch}
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
              <ParentComponent
                employeeData={tableRows}
                onDeleteEmployee={deleteEmployees}
                handleCallStatusSubmit={handleCallStatusSubmit}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box></Box>
      </Box>
    </>
  );
};
