import {
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_IN_PROGRESS,
  CREATE_LEAD_SUCCESS,
  CREATE_NEW_NOTE_FAILURE,
  CREATE_NEW_NOTE_IN_PROGRESS,
  CREATE_NEW_NOTE_SUCCESS,
  DELETE_LEAD_FAILURE,
  DELETE_LEAD_IN_PROGRESS,
  DELETE_LEAD_SUCCESS,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_IN_PROGRESS,
  DELETE_NOTE_SUCCESS,
  DELETE_RETURN_CREDIT_FAILURE,
  DELETE_RETURN_CREDIT_IN_PROGRESS,
  DELETE_RETURN_CREDIT_SUCCESS,
  GET_ALL_NOTES_FAILURE,
  GET_ALL_NOTES_IN_PROGRESS,
  GET_ALL_NOTES_SUCCESS,
  GET_LEAD_SEARCH_FAILURE,
  GET_LEAD_SEARCH_IN_PROGRESS,
  GET_LEAD_SEARCH_SUCCESS,
  GET_LEAD_SHOW_FAILURE,
  GET_LEAD_SHOW_IN_PROGRESS,
  GET_LEAD_SHOW_SUCCESS,
  GET_RETURN_CREDIT_FAILURE,
  GET_RETURN_CREDIT_IN_PROGRESS,
  GET_RETURN_CREDIT_SUCCESS,
  LEAD_MASS_UPDATE_FAILURE,
  LEAD_MASS_UPDATE_IN_PROGRESS,
  LEAD_MASS_UPDATE_SUCCESS,
  RETURN_CREDIT_FAILURE,
  RETURN_CREDIT_IN_PROGRESS,
  RETURN_CREDIT_SUCCESS,
  UPDATE_APPOINTMENT_KEY_FAILURE,
  UPDATE_APPOINTMENT_KEY_IN_PROGRESS,
  UPDATE_APPOINTMENT_KEY_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_FAILURE,
  UPDATE_APPOINTMENT_STATUS_IN_PROGRESS,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_LEAD_KEY_FAILURE,
  UPDATE_LEAD_KEY_IN_PROGRESS,
  UPDATE_LEAD_KEY_SUCCESS,
  UPLOAD_LEAD_CSV_FAILURE,
  UPLOAD_LEAD_CSV_IN_PROGRESS,
  UPLOAD_LEAD_CSV_SUCCESS,
} from "../actions/lead/types";
import initialState from "../initialState";

export const lead = (state = initialState.lead, action) => {
  switch (action.type) {
    case CREATE_LEAD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPLOAD_LEAD_CSV_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_LEAD_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_LEAD_CSV_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_LEAD_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        leadData: {},
      };
    case GET_LEAD_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        leadData: action?.data,
      };
    case GET_LEAD_SEARCH_FAILURE:
      return {
        ...state,
        leadData: {},
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_LEAD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_LEAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_LEAD_KEY_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_LEAD_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_LEAD_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_LEAD_SHOW_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case GET_LEAD_SHOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leadShow: action?.data,
        error: false,
        errorMessage: "",
      };
    case GET_LEAD_SHOW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case LEAD_MASS_UPDATE_IN_PROGRESS:
      return {
        ...state,
        isMassUpdateLoading: true,
        error: false,
        errorMessage: "",
      };
    case LEAD_MASS_UPDATE_SUCCESS:
      return {
        ...state,
        isMassUpdateLoading: false,
        error: false,
        errorMessage: "",
      };
    case LEAD_MASS_UPDATE_FAILURE:
      return {
        ...state,
        isMassUpdateLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_NEW_NOTE_IN_PROGRESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };

    case CREATE_NEW_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };

    case CREATE_NEW_NOTE_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };

    case GET_ALL_NOTES_IN_PROGRESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };

    case GET_ALL_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: action?.data,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };

    case GET_ALL_NOTES_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
    case DELETE_NOTE_IN_PROGRESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };

    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };

    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case RETURN_CREDIT_IN_PROGRESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: true,
        },
      };

    case RETURN_CREDIT_SUCCESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
        },
      };

    case RETURN_CREDIT_FAILURE:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
        },
      };
    case GET_RETURN_CREDIT_IN_PROGRESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };

    case GET_RETURN_CREDIT_SUCCESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          data: action?.data,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };

    case GET_RETURN_CREDIT_FAILURE:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
    case UPDATE_APPOINTMENT_STATUS_IN_PROGRESS:
      return {
        ...state,
        isAppointmentStatusUpdate: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        isAppointmentStatusUpdate: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_APPOINTMENT_STATUS_FAILURE:
      return {
        ...state,
        isAppointmentStatusUpdate: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_APPOINTMENT_KEY_IN_PROGRESS:
      return {
        ...state,
        isAppointmentStatusUpdate: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_APPOINTMENT_KEY_SUCCESS:
      return {
        ...state,
        isAppointmentStatusUpdate: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_APPOINTMENT_KEY_FAILURE:
      return {
        ...state,
        isAppointmentStatusUpdate: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_RETURN_CREDIT_IN_PROGRESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: true,
        },
      };

    case DELETE_RETURN_CREDIT_SUCCESS:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
        },
      };

    case DELETE_RETURN_CREDIT_FAILURE:
      return {
        ...state,
        credit: {
          ...state.credit,
          loading: false,
        },
      };
    default:
      return state;
  }
};
