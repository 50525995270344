import {
  GET_CALLBACK_SEARCH_IN_PROGRESS,
  GET_CALLBACK_SEARCH_SUCCESS,
  GET_CALLBACK_SEARCH_FAILURE,
  CREATE_CALLBACK_IN_PROGRESS,
  CREATE_CALLBACK_SUCCESS,
  CREATE_CALLBACK_FAILURE,
  GET_CALLBACK_SHOW_IN_PROGRESS,
  GET_CALLBACK_SHOW_SUCCESS,
  GET_CALLBACK_SHOW_FAILURE,
  UPDATE_ASSIGNED_LEAD_IN_PROGRESS,
  UPDATE_ASSIGNED_LEAD_SUCCESS,
  UPDATE_ASSIGNED_LEAD_FAILURE,
} from "../actions/callBack/types";
import initialState from "../initialState";

export const callback = (state = initialState.callback, action) => {
  switch (action.type) {
    case CREATE_CALLBACK_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_CALLBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_CALLBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CALLBACK_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        callBackData: [],
      };
    case GET_CALLBACK_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        callBackData: action.data,
      };
    case GET_CALLBACK_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        callBackData: [],
        errorMessage: action?.error?.message,
      };
    case GET_CALLBACK_SHOW_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        callbackDetails: {},
      };
    case GET_CALLBACK_SHOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        callbackDetails: action?.data,
      };
    case GET_CALLBACK_SHOW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        callbackDetails: {},
        errorMessage: action?.error?.message,
      };
    case UPDATE_ASSIGNED_LEAD_IN_PROGRESS:
      return {
        ...state,
        isAssignedLoading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ASSIGNED_LEAD_SUCCESS:
      return {
        ...state,
        isAssignedLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ASSIGNED_LEAD_FAILURE:
      return {
        ...state,
        isAssignedLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
