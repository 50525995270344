import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";

export const LeadOwedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //   User Production	Active Hours Logged	Total Utilization TIme	Total Hours Logged	Total Calls	Total Calls Per Hour	Valid Standard Leads	Valid Plus Leads	Total Points	Valid Points Per Hour	Hourly Earnings	Commision Only	Bonus
  // Andria Smith	6:20:46	10:1:34	7:3:55	207	29.30	0	1	2.1875	0.34	$ 0	$ 115.0	$ 0

  const headCells = [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "state",
      numeric: false,
      disablePadding: false,
      label: "State",
    },
    {
      id: "amountPurchasedMonth",
      numeric: false,
      disablePadding: false,
      label: "Amount Purchased Month",
    },
    {
      id: "agencyCallingFor",
      numeric: false,
      disablePadding: false,
      label: "Agency Calling For",
    },
    {
      id: "owed",
      numeric: false,
      disablePadding: false,
      label: "Owed",
    },
    {
      id: "paidFor",
      numeric: false,
      disablePadding: false,
      label: "Paid For",
    },
    {
      id: "paidThrough",
      numeric: false,
      disablePadding: false,
      label: "Paid Through",
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: "Total",
    },
    {
      id: "monthlyCallTime",
      numeric: false,
      disablePadding: false,
      label: "Monthly Call Time",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
    {
      id: "lastCreated",
      numeric: false,
      disablePadding: false,
      label: "Last Created",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell ,index) => (
          <TableCell
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingX: "0px!important",
              paddingLeft:index === 0 ? "18px" :"0px",
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "Work Day" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

LeadOwedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
