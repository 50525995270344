// LoginScreen.js
import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";

export const CustumTooltip = (props) => {
  const { onClose, user, onSubmit } = props;
  const [activeStatus, setActiveStatus] = useState(
    user?.operations?.call_record
  );

  const onSubmitHandler = () => {
    onClose();
    if (activeStatus !== user?.operations?.call_record) {
      const payload = {
        profile_id: user?.operations?.id,
        call_record: activeStatus,
      };
      onSubmit(payload);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 0.5,
          marginTop: "0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 14,
                color: "#454647",
                fontWeight: 600,
                borderBottom: 1,
                borderColor: "#ddd",
                pb: 1,
              }}
            >
              Update Call Recording
            </Typography>
            <Box sx={{ marginTop: "10px", display: "flex", gap: "7px" }}>
              <FormControl
                size="small"
                sx={{
                  width: {
                    md: "100px",
                    borderColor: "#1ab394",
                  },

                  "& .MuiOutlinedInput-root": {
                    position: "relative",
                    "& .MuiInputBase-input": {
                      paddingTop: "8px",
                    },
                    "&:before, &:after": {
                      display: "none",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderColor: "#1ab394",
                    },
                    "&.Mui-focused:before": {
                      borderColor: "#1ab394",
                    },
                  },
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Select
                  labelId="sms-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={(e) => setActiveStatus(e.target.value)}
                  sx={{
                    height: 32,
                    borderColor: "#1ab394",
                  }}
                  inputProps={{
                    sx: {
                      paddingTop: 0,
                      borderColor: "#1ab394",
                    },
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={true} sx={{ fontSize: 14 }}>
                    Active
                  </MenuItem>
                  <MenuItem value={false} sx={{ fontSize: 14 }}>
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                sx={{
                  width: "25px",
                  height: "30px",
                  minWidth: "30px",
                  pl: 1.5,
                  pr: 1.5,
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "#1ab394",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                  borderRadius: "3px",
                  borderColor: "#1ab394",
                  color: "#fff",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onSubmitHandler();
                }}
              >
                <FileDownloadDoneIcon
                  sx={{ fontSize: "20px", backgroundColor: "#1ab394" }}
                />
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "25px",
                  height: "30px",
                  minWidth: "30px",
                  pl: 1.5,
                  pr: 1.5,
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },

                  color: "#000",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
