import { BASE_URL, GET_CLIENT_DASHBOARD_URL } from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest } from "../../../services";
import { logout } from "../authentication";
import {
  GET_CLIENT_DASHBOARD_FAILURE,
  GET_CLIENT_DASHBOARD_IN_PROGRESS,
  GET_CLIENT_DASHBOARD_SUCCESS,
} from "./types";
import toast from "react-hot-toast";


// Action Creators
export const GetClientDashboardInProgress = () => ({
  type: GET_CLIENT_DASHBOARD_IN_PROGRESS,
});

export const GetClientDashboardSuccess = (data) => ({
  type: GET_CLIENT_DASHBOARD_SUCCESS,
  data,
});

export const GetClientDashboardFailure = (error) => ({
  type: GET_CLIENT_DASHBOARD_FAILURE,
  error,
});

// Method for getting client dashboard
export const getClientDashboard = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetClientDashboardInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get client dashboard
      const response = await getRequest(
        `${BASE_URL}${GET_CLIENT_DASHBOARD_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetClientDashboardSuccess(response?.data));
      } else {
        dispatch(
          GetClientDashboardFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetClientDashboardFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
