let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

// export const INITIAL_EVENTS = [
//   {
//     id: createEventId(),
//     title: 'All-day event',
//     start: todayStr
//   },
//   {
//     id: createEventId(),
//     title: 'Timed event',
//     start: todayStr + 'T12:00:00'
//   }
// ]

// 'Awaiting Confirmation', 'Confirmed' ,'Left Message' ,'Rescheduled' ,'Cancelled', 'Cancelled - Waiting to Rescheduled'
export const colorCode = [
  "#c1c1c1",
  "#1ab394",
  "#f8ac59",
  "#f8ac59",
  "#1ab394",
  "#000000",
  "#000000",
];

export const INITIAL_EVENTS =  [
  {
    "can_delete": 1,
    "created_by": "John Doe",
    "event_id": 1,
    "title": "Meeting with Client",
    "company_name": "ABC Inc.",
    "lead": 456,
    "lead_url": "https://example.com/leads/456",
    "start": "2023-10-26T09:00:00Z",
    "end": "2023-10-26T10:00:00Z",
    "status_id": 2
  },
  {
    "can_delete": 1,
    "created_by": "Alice Smith",
    "event_id": 2,
    "title": "Follow-up Call",
    "company_name": "XYZ Corp.",
    "lead": 789,
    "lead_url": "https://example.com/leads/789",
    "start": "2023-10-27T14:30:00Z",
    "end": "2023-10-27T15:30:00Z",
    "status_id": 1
  }
]

export function createEventId() {
  return String(eventGuid++);
}
