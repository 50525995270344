// LoginScreen.js
import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LeadParentTable } from "./LeadParentTable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { Loader, MassUpdate } from "../Common";
import { getAllClients } from "../../store/actions/profile";
import toast from "react-hot-toast";
import { LEADS__KEYS, tableToCSV } from "../../helpers";
import { updateAppointmentStatus } from "../../store/actions/lead";
import { updateCampaignWithAlternateCamapaign } from "../../store/actions";





export const LeadList = (props) => {
  const {
    onSearchLeads,
    onDeleteLead,
    onLeadUpdateKey,
    onleadMassUpdate,
    getAllCampaignNames,
  } = props;
  const [page, setPage] = useState(0);
  const [selected, setSelected] = React.useState([]);

  useEffect(()=>{
    document.title = 'Lead list | Lead Orchard'
  },[]);

  const [isMassUpdateOpen, setIsMassUpdateOpen] = useState(false);

  const {leadData, totalCount} = useSelector((state) => state?.lead?.leadData);

  const dispatch = useDispatch();

  const isMassUpdateLoading = useSelector(
    (state) => state?.lead?.isMassUpdateLoading
  );

  const userDetails = useSelector((state) => state?.profile?.allClients);

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const initialLeadSearchInfo = {
    agentId: 0,
    days: userInfo?.user_type === "Client" ? 1 : 0,
    leadStatus: userInfo?.user_type === "Client" ? 1 : 2,
    campanyName: "",
    fromDateTime: dayjs(),
    toDateTime: dayjs(),
  };

  //lead info
  const [leadSearchInfo, setLeadSearchInfo] = useState(initialLeadSearchInfo);
  const [triedSubmit, setTriedSubmit] = useState(false);

  const agentNameError =
    triedSubmit && leadSearchInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && leadSearchInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setLeadSearchInfo({
          ...leadSearchInfo,
          agentId: newValue.id,
        });
        break;
      case "days":
        setLeadSearchInfo({
          ...leadSearchInfo,
          days: event.target.value,
        });
        break;
      case "status":
        setLeadSearchInfo({
          ...leadSearchInfo,
          leadStatus: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const fetchLeads = (payload) => {
    onSearchLeads(payload);
  };

  useEffect(() => {
    const payload = {
      agent: userInfo?.id ?? 0,
      lead_status: 1,
      days: 1,
      company_name: null,
      from_datetime: null,
      to_datetime: null,
      page: 1 ?? null,
    };
    //console.log("payload=====>", payload);
    userInfo?.user_type === "Client" && fetchLeads(payload);

    const initialPayload = {
      agent: null,
      lead_status: 2,
      company_name: null,
      from_datetime: null,
      to_datetime: null,
      days: 0,
      page: 1,
    };
    userInfo?.user_type !== "Client" && fetchLeads(initialPayload);

    dispatch(getAllClients());
    getAllCampaignNames();
  }, []);

  const fetchLeadsForClient = () => {
    const formatedFromDate = moment(
      leadSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      leadSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    const payload = {
      agent: userInfo?.id ?? 0,
      lead_status: leadSearchInfo.leadStatus ?? 2,
      days: leadSearchInfo.days ?? 0,
      company_name: null,
      from_datetime:
        leadSearchInfo.days === 2 ? formatedFromDate ?? null : null,
      to_datetime: leadSearchInfo.days === 2 ? formatedToDate ?? null : null,
      page: 1 ?? null,
    };
    //console.log("payload=====>", payload);
    fetchLeads(payload);
  };

  const onSearch = () => {
    const formatedFromDate = moment(
      leadSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      leadSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    const payload = {
      agent: leadSearchInfo.agentId ?? 0,
      lead_status: leadSearchInfo.leadStatus ?? 2,
      days: leadSearchInfo.days ?? 0,
      company_name:
        leadSearchInfo.campanyName !== "" ? leadSearchInfo.campanyName : null,
      from_datetime:
        leadSearchInfo.days === 2 ? formatedFromDate ?? null : null,
      to_datetime: leadSearchInfo.days === 2 ? formatedToDate ?? null : null,
      page: 1 ?? null,
    };
    //console.log("payload=====>", payload);
    userInfo?.user_type === "Client"
      ? fetchLeadsForClient()
      : fetchLeads(payload);
  };

  const fetchMoreRecord = async (offset = 1) => {
    const formatedFromDate = moment(
      leadSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      leadSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    let payload;

    if (userInfo?.user_type !== "Client") {
      payload = {
        agent: leadSearchInfo.agentId ?? 0,
        lead_status: leadSearchInfo.leadStatus ?? 2,
        days: leadSearchInfo.days ?? 0,
        company_name:
          leadSearchInfo.campanyName !== "" ? leadSearchInfo.campanyName : null,
        from_datetime:
          leadSearchInfo.days === 2 ? formatedFromDate ?? null : null,
        to_datetime: leadSearchInfo.days === 2 ? formatedToDate ?? null : null,
        page: offset ?? null,
      };
    } else {
      payload = {
        agent: userInfo?.id ?? 0,
        lead_status: leadSearchInfo.leadStatus ?? 2,
        days: leadSearchInfo.days ?? 0,
        company_name: null,
        from_datetime: null,
        to_datetime: null,
        page: 1 ?? null,
      };
    }
    fetchLeads(payload);
  };

  const deleteLead = (leadId) => {
    onDeleteLead(leadId, onSearch);
  };

  const onChangeLeadKey = (leadId, key, val, cb = () => { }) => {
    onLeadUpdateKey(leadId, key, val, () => {
      onSearch();
      cb();
    });
  };

  const onChangeAppointmentKey = (payload, cb = () => { }) => {
    // console.log("payload===>",payload);
    dispatch(
      updateAppointmentStatus(payload, () => {
        onSearch();
        cb();
      })
    );
  };

  const onUpdateCampaignWithAltCampaign = (payload, cb = () => { }) => {
    dispatch(
      updateCampaignWithAlternateCamapaign(
        payload,
        () => {
          onSearch();
          cb();
        },
        true
      )
    );
  };

  const massUpdate = () => {
    if (selected?.length <= 0) {
      toast.error("Please select any row", {
        position: "bottom-center",
      });
      return;
    } else {
      setIsMassUpdateOpen(true);
    }
  };

  const onSubmitMassUpdate = async (fieldName, fieldValue) => {
    // const optionsArray = await convertOptToPayload(optionValues);
    const payload = {
      leads: selected ?? [],
      field: fieldName,
      value: fieldValue,
    };
    onleadMassUpdate(payload, () => {
      setIsMassUpdateOpen(false);
      onSearch();
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          boxShadow: 1,
          borderRadius: "15px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Listing Leads | Total Count : 
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#6DD6C2",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( {totalCount ?? "0"} )
                </Typography>{" "}
              </Typography>
            </AccordionSummary>


            {userInfo?.user_type !== "Employee" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent:
                    userInfo?.user_type === "Administration"
                      ? "center"
                      : "flex-start",
                  alignItems: "center",
                }}
              >
                {userInfo?.user_type === "Administration" && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      massUpdate();
                    }}
                    sx={{
                      width: 130,
                      textTransform: "capitalize",
                      marginRight: 1,
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                      fontWeight: 600,
                      color: "#212223",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                        borderColor: "#6DD6C2",
                      },
                    }}
                  >
                    Mass Update
                  </Button>
                )}

                <Button
                  variant="contained"
                  // onClick={onClickExport}
                  onClick={() => {
                    tableToCSV("leadlistTable");
                  }}
                  sx={{
                    mr: { xs: 1, sm: 1, md: 2, lg: 2 },
                    width: 100,
                    backgroundColor: "transparent",
                    borderColor: "#6DD6C2",
                    textTransform: "capitalize",
                    border: 2,
                    fontWeight: 600,
                    color: "#1ab394",
                    size: "small",
                    boxShadow: "0",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2!important",
                      color: "#212223",
                    },
                  }}
                >
                  Export
                  <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{

              border: { xs: "0", sm: "0", md: '1px solid #ddd' },
              marginX: { xs: "15px", sm: "27px", md: '18px' },
              marginTop: { xs: "15px", sm: "20px", md: '20px' },
              marginBottom: { xs: "15px", sm: "20px", md: '20px' },
              borderRadius: "7px"
              // border:"2px solid red" 
            }}
          >
            <Box
              sx={{
                display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },

                alignItems: 'center',
                gap: 2,
                // width: "100%",
                flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens
                width: "100%",
                margin: { xs: "0px", md: '20px' },
              }}
            >
              <Box
                sx={{
                  width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "200px", lg: "280px" },
                }}
              >
                {userInfo?.user_type === "Administration" && (
                  <Box sx={{}}>
                    {userInfo?.user_type !== "Client" && (
                      <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "210px", lg: "280px" }, }}>

                        <FormControl
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: agentNameError ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "agentName");
                            }}
                            error={agentNameError}
                            helperText={agentNameError && agentNameErrorMessage}
                            size="small"
                            disableClearable={true}
                            sx={{
                              width: "100%",
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Select Agent" />
                                {agentNameError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentNameErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={`${option.id}-${option.full_name}`}>
                                  {option?.full_name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>

                      </Box>
                    )}
                  </Box>
                )}
              </Box>


              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "210px", lg: "280px" }, }}>
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#6DD6C2",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    Select Days
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Select Days"
                    value={leadSearchInfo.days}
                    onChange={(event) => {
                      handleChange(event, "", "days");
                    }}
                    size="small"
                  >
                    <MenuItem value={0} sx={{ fontSize: 15 }}>
                      Today
                    </MenuItem>
                    <MenuItem value={-1} sx={{ fontSize: 15 }}>
                      Yesterday
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      All w/0 Date
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      All
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "210px", lg: "280px" }, }}>
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#6DD6C2",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    Select status
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Select Status"
                    value={leadSearchInfo.leadStatus}
                    onChange={(event) => {
                      handleChange(event, "", "status");
                    }}
                    size="small"
                  >
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      All
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Valid
                    </MenuItem>
                    <MenuItem value={0} sx={{ fontSize: 15 }}>
                      Invalid
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>


              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "210px", lg: "280px" }, }}>
                {userInfo?.user_type !== "Client" && (
                  <Box sx={{}}>
                    {/* <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 14,
                        color: "#6B7489",
                        fontWeight: 600,
                        marginBottom: "10px",
                      }}
                    >
                      Company Name
                    </Typography> */}
                    <TextField
                      margin="0"
                      fullWidth
                      size="small"
                      name="Address"
                      label="Company Name"
                      type="text"
                      id="Address"
                      fontSize="15"
                      value={leadSearchInfo.campanyName}
                      onChange={(e) =>
                        setLeadSearchInfo({
                          ...leadSearchInfo,
                          campanyName: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>


            </Box>
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                alignItems: "end",
                // gap: { xs: "10px", md: "15px" },
                marginX: { xs: "0px", sm: "0px", md: '18px' },
                marginBottom: { xs: "15px", sm: "20px", md: '20px' },
                marginTop: { xs: "20px", sm: "20px", md: '0px' },
                gap: 2,
                width: "100%",
                gap: { xs: 1, md: 2 },
                // borderBottom: 1,
                // borderColor: "#676a6c4a",
                // backgroundColor: "#90949f21",

              }}
            >

              <Box>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#6B7489",
                    fontWeight: 600,
                    marginBottom: "0px",                    
                  }}
                >
                  From Date Time :
                </Typography> */}
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} sx>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker label="From Date Time"
                        disabled={leadSearchInfo.days == 2 ? false : true}
                        value={leadSearchInfo.fromDateTime}
                        onChange={(value) =>
                          setLeadSearchInfo({
                            ...leadSearchInfo,
                            fromDateTime: value,
                          })
                        }
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px 13px",
                          },
                          width: "100%",

                          "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                            top: '-7px',
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#6B7489",
                    fontWeight: 600,
                    marginBottom: "0px",                   
                  }}
                >
                  To Date Time :
                </Typography> */}
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker label="To Date Time"
                        disabled={leadSearchInfo.days === 2 ? false : true}
                        value={leadSearchInfo.toDateTime}
                        onChange={(value) =>
                          setLeadSearchInfo({
                            ...leadSearchInfo,
                            toDateTime: value,
                          })
                        }
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px 13px",
                          },
                          width: "100%",

                          "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                            top: '-7px',
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>


              <Button
                type="submit"
                variant="contained"
                onClick={onSearch}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  py: 1,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>

            </Box>
          </Box>

          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <LeadParentTable
                fetchMoreRecord={fetchMoreRecord}
                page={page}
                setPage={setPage}
                onDelete={deleteLead}
                onChangeLeadKey={onChangeLeadKey}
                onChangeAppointmentKey={onChangeAppointmentKey}
                selected={selected}
                setSelected={setSelected}
                onUpdateCampaignWithAltCampaign={
                  onUpdateCampaignWithAltCampaign
                }
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <MassUpdate
          open={isMassUpdateOpen}
          onSubmit={onSubmitMassUpdate}
          fieldOptions={LEADS__KEYS}
          onClose={() => {
            setIsMassUpdateOpen(false);
          }}
        />
        <Loader loading={isMassUpdateLoading} />
      </Box>
    </>
  );
};
