import { connect } from "react-redux";
import {
  CreateEmployee,
  DeleteEmployee,
  GetEmployees,
  UpdateEmployee,
  updateCallRecordingStatus,
  uploadEmployeeCsv,
} from "../store/actions";
import {
  Create,
  EmployeesList,
  UploadEmployee,
} from "../components/EmployeeComponent";
import { useLocation } from "react-router-dom";

const mapStateToProps = (state) => ({
  employeesData: state?.employee?.empoyee_listing?.results,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllEmployees: (payload,cb) => dispatch(GetEmployees(payload,cb)),
    onCreateEmployee: (payload, cb) => dispatch(CreateEmployee(payload, cb)),
    onUpdateEmployee: (employeeId, payload, cb) =>
      dispatch(UpdateEmployee(employeeId, payload, cb)),
    onDeleteEmployee: (payload, cb) => dispatch(DeleteEmployee(payload, cb)),
    onUpdateCallRecStatus: (payload, cb) =>
      dispatch(updateCallRecordingStatus(payload, cb)),
    onUploadEmployeeCSV: (file, cb) => dispatch(uploadEmployeeCsv(file, cb)),
  };
};

export const EmployeeContainer = () => {
  let location = useLocation();
  let ComponentToRender;
  switch (location.pathname) {
    case "/Create":
      ComponentToRender = Create;
      break;
    case "/EmployeesList":
      ComponentToRender = EmployeesList;
      break;
    case "/UploadEmployee":
      ComponentToRender = UploadEmployee;
      break;
    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
