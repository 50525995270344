import React, { useEffect, useState, useRef, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { CircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import * as turf from "@turf/turf";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { MouseTooltip } from "./MouseTooltip";
import { useLocation } from "react-router";
import DragCircleMode from "./DragCircleMode";

mapboxgl.accessToken =
  "pk.eyJ1IjoibGVhZG9yY2hhcmQiLCJhIjoiY2pqb2F0dGk2MnAxYzNwdDdmbWxvczNvaCJ9.9dg8cKZTvB2QXL9bqyiV9Q";

export const ViewMap = (props) => {
  const { setCounties, counties, circles, setCircles, campaignInfo } = props;

  const viewMap = true;

  //   const campaignInfo = {
  //     types_array: ["startswith", "not_in", "gte", "not_exact"],
  //     values_array: [
  //       "2082\r\n2085\r\n2511\r\n2512\r\n2514\r\n2515\r\n2519\r\n2521\r\n2522\r\n2599\r\n2752\r\n2754\r\n2759\r\n5182\r\n5331\r\n5399\r\n5411\r\n5412\r\n5421\r\n5431\r\n5441\r\n5451\r\n5461\r\n5499\r\n5521\r\n5531\r\n5541\r\n5551\r\n5611\r\n5621\r\n5632\r\n5641\r\n5651\r\n5661\r\n5699\r\n5712\r\n5713\r\n5714\r\n5719\r\n5722\r\n5731\r\n5734\r\n5735\r\n5736\r\n5812\r\n5912\r\n5921\r\n5932\r\n5941\r\n5942\r\n5943\r\n5945\r\n5946\r\n5947\r\n5948\r\n5992\r\n5993\r\n5995\r\n5999\r\n7211\r\n7212\r\n7213\r\n7215\r\n7216\r\n7217\r\n7218\r\n7219\r\n7221\r\n7231\r\n7241\r\n7251\r\n7261\r\n7291\r\n7299\r\n7532\r\n7533\r\n7536\r\n7537\r\n7538\r\n7539\r\n7542\r\n7623\r\n7933\r\n7992\r\n8351",
  //       "7,3,2,21,5,14,18,17,13,20,9,10",
  //       "15",
  //       "Chris Spicer",
  //     ],
  //     operator_array: ["or", "and", "and", "and"],
  //     county_array: [
  //       {
  //         fips: "51107",
  //         county: "Loudoun County",
  //       },
  //       {
  //         fips: "51013",
  //         county: "Arlington County",
  //       },
  //       {
  //         fips: "51059",
  //         county: "Fairfax County",
  //       },
  //       {
  //         fips: "51600",
  //         county: "Fairfax city",
  //       },
  //       {
  //         fips: "51061",
  //         county: "Fauquier County",
  //       },
  //       {
  //         fips: "51153",
  //         county: "Prince William County",
  //       },
  //     ],
  //     state_array: ["VA", "VA", "VA", "VA", "VA", "VA"],
  //     radius_array: {
  //       $or: [],
  //     },
  //     lead_lat: 38.915795,
  //     lead_long: -77.21411,
  //   };

  const mapContainerRef = useRef(null);
  const iconContainerRef = useRef(null); // Ref for the icon container
  const [map, setMap] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [circleModeActive, setCircleModeActive] = useState(false);
  const [circleDragging, setCircleDragging] = useState(false);

  // const jsonString = '[{"fips":20089,"county":"Jewell County"}, {"fips":20183, "county":"Smith County"},{"fips":31061,"county":"Franklin County"}, {"fips": 31083, "county": "Harlan County"}, {"fips": 20137, "county": "Norton County"}]';
  // const jsonArray = JSON.parse(jsonString);

  // Optimize memoized values
  const initialCounties = useMemo(
    () =>
      viewMap
        ? campaignInfo?.county_array?.map((entry) => entry.county) ?? []
        : [],
    [viewMap, campaignInfo]
  );
  const initialFIPS = useMemo(
    () =>
      viewMap
        ? campaignInfo?.county_array?.map((entry) => {
            const fips = entry.fips;
            const convertedFips = fips.startsWith("0")
              ? `0${parseInt(fips, 10)}`
              : parseInt(fips, 10);
            return convertedFips;
          }) ?? []
        : [],
    [viewMap, campaignInfo]
  );

  const selectedCountiesRef = useRef(initialCounties);
  const selectedFIPSRef = useRef(initialFIPS);
  const drawRef = useRef(null);
  const selectedRadiusDataRef = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ offset: [0, -15] }));

  useEffect(() => {
    const initialMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [campaignInfo?.lead_long, campaignInfo?.lead_lat] ?? [-98, 38.88],
      minZoom: 2,
      zoom: 5,
    });

    initialMap.on("load", () => {
      initialMap.addSource("counties", {
        type: "vector",
        url: "mapbox://mapbox.82pkq93d",
      });

      initialMap.addLayer(
        {
          id: "counties",
          type: "fill",
          source: "counties",
          "source-layer": "original",
          paint: {
            "fill-outline-color": "rgba(0,0,0,0.4)",
            "fill-color": "rgba(0,0,0,0.1)",
          },
        },
        "building"
      );

      initialMap.addLayer(
        {
          id: "counties-highlighted",
          type: "fill",
          source: "counties",
          "source-layer": "original",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#6e599f",
            "fill-opacity": 0.65,
          },
          filter: ["in", "FIPS", ...selectedFIPSRef.current],
        },
        "building"
      );

      let marker1 =
        campaignInfo &&
        new mapboxgl.Marker()
          .setLngLat([campaignInfo?.lead_long, campaignInfo?.lead_lat])
          .addTo(initialMap);

      initialMap.on("click", (e) => {
        if (
          !circleModeActive &&
          drawRef.current.getMode() === "simple_select"
        ) {
          const bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
          ];
          const clickedFeatures = initialMap.queryRenderedFeatures(bbox, {
            layers: ["counties"],
          });

          const circleCenter = selectedRadiusDataRef?.current
            ? turf.point([
                selectedRadiusDataRef.current.long,
                selectedRadiusDataRef.current.lat,
              ])
            : null;

          if (circleCenter) {
            const clickedPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
            const radius = selectedRadiusDataRef.current
              ? selectedRadiusDataRef.current.radiInKm
              : null;

            const distanceToCircle = turf.distance(clickedPoint, circleCenter, {
              units: "kilometers",
            });

            if (radius && distanceToCircle <= radius) {
              // Clicked inside the circle, do not select FIPS
              return;
            }
          }

          const clickedFips = clickedFeatures.map(
            (feature) => feature.properties.FIPS
          );
          console.log("clickedFips===>", clickedFeatures);
          // Combine previously selected features with the newly clicked features.
          // Create a new array to store the selected FIPS values.
          const allSelectedFips = selectedFIPSRef.current.filter(
            (fips) => fips !== clickedFips[0]
          );

          // Add the clicked FIPS value if it's not already present in the selection.
          if (!selectedFIPSRef.current.includes(clickedFips[0])) {
            allSelectedFips.push(clickedFips[0]);
          }
          // Update the selected FIPS values with all selected FIPS values.
          selectedFIPSRef.current = allSelectedFips;

          // Set the filter using all selected FIPS values.
          initialMap.setFilter("counties-highlighted", [
            "in",
            "FIPS",
            ...allSelectedFips,
          ]);

          // initialMap.setFilter("counties-highlighted", [
          //   "in",
          //   "COUNTY",
          //   ...["Alfalfa County", "Barber County"],
          // ]);

          setSelectedFeatures(allSelectedFips);

          const clickedCounties = clickedFeatures.map(
            (feature) => feature.properties.COUNTY
          );
          //console.log("clickedCounties=========>", clickedFeatures);
          const allSelectedCounties = selectedCountiesRef.current.filter(
            (county) => county !== clickedCounties[0]
          );

          if (!selectedCountiesRef.current.includes(clickedCounties[0])) {
            allSelectedCounties.push(clickedCounties[0]);
          }
          //console.log("allSelectedCounties=========>", allSelectedCounties);
          selectedCountiesRef.current = allSelectedCounties;
        }
      });

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        countries: "us", // Restrict geocoding to United States
        placeholder: "Search county or place",
      });

      initialMap.addControl(geocoder, "top-right");

      const nav = new mapboxgl.NavigationControl();
      initialMap.addControl(nav, "top-right");

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        controls: {
          polygon: true,
          trash: true,
        },
        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          drag_circle: DragCircleMode,
          direct_select: SimpleSelectMode,
          simple_select: SimpleSelectMode,
        },
      });

      initialMap.addControl(draw);

      initialMap.on("draw.create", (e) => {
        const mode = drawRef.current.getMode();

        if (mode == "draw_polygon") {
          updateArea();
        } else {
          setCircleDragging(true);

          const { features } = e;
          if (features.length > 0) {
            const circleFeature = features[0];
            const center = circleFeature.properties.center;
            const radiusInKm = circleFeature.properties.radiusInKm;

            const obj = {
              lat: center[1] ?? "",
              long: center[0] ?? "",
              radius: radiusInKm ?? "",
            };
            setCircles([...circles, obj]);
            selectedRadiusDataRef.current = obj;
          }
        }
      });

      initialMap.on("draw.update", (e) => {
        const mode = drawRef.current.getMode();

        if (mode == "draw_polygon") {
          updateArea();
        } else {
          setCircleDragging(true);
          const { features } = e;
          if (features.length > 0) {
            const circleFeature = features[0];
            const center = circleFeature.properties.center;
            const radiusInKm = circleFeature.properties.radius;
            const obj = {
              lat: center[1] ?? "",
              long: center[0] ?? "",
              radius: radiusInKm ?? "",
            };
            selectedRadiusDataRef.current = obj;
            setCircles([...circles, obj]);
            const radiusInMiles = turf.convertLength(
              radiusInKm,
              "kilometers",
              "miles"
            );
          }
          drawRef.current.changeMode("simple_select");
          setCircleModeActive(false);
        }
      });
      initialMap.on("draw.delete", (e) => {
        const mode = drawRef.current.getMode();
        // if (mode == "draw_polygon") {
        // } else {
        //   const { features } = e;
        //   if (features.length > 0) {
        //     const circleFeature = features[0];
        //     const center = circleFeature.properties.center;
        //     const radiusInKm = circleFeature.properties.radiusInKm;

        //     const obj = {
        //       lat: center[1] ?? "",
        //       long: center[0] ?? "",
        //       radiInKm: radiusInKm ?? "",
        //     };

        //     const filteredCircles = circles?.filter(item => (
        //       !(item.lat === obj.lat && item.long === obj.long && Object.keys(item).length > 0)
        //     ));

        //     setCircles(filteredCircles);
        //     //console.log("newDataArray===>", filteredCircles);

        //   }

        // }
        drawRef.current.changeMode("simple_select");
        setCircleModeActive(false);
      });

      initialMap.on("draw.modechange", (e) => {
        if (e.mode === "simple_select") {
          setCircleDragging(false);
          setCircleModeActive(false);
        }
      });

      setMap(initialMap);
      drawRef.current = draw;
    });
  }, []);

  async function geocodeAndZoomToState(stateName) {
    if (campaignInfo?.state_array && campaignInfo?.state_array?.length > 0) {
      const stateJson = await campaignInfo?.state_array;
      // Use Mapbox geocoding API to get the coordinates of the state
      const geocodingEndpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${stateJson[0]}.json?access_token=${mapboxgl.accessToken}&country=US&types=region`;

      fetch(geocodingEndpoint)
        .then((response) => response.json())
        .then((data) => {
          if (data?.features?.length > 0) {
            const coordinates = data.features[0].center;
            // Zoom to the coordinates of the state
            map?.flyTo({
              center: coordinates,
              zoom: 6, // Adjust the zoom level as needed
            });
          }
        })
        .catch((error) => () => {});
    }
  }

  useEffect(() => {
    if (initialFIPS.length > 0) {
      setTimeout(() => {
        geocodeAndZoomToState();
      }, 2000);
    }
  }, [map]);

  useEffect(() => {
    if (selectedCountiesRef && selectedFIPSRef) {
      const county_array = selectedCountiesRef?.current?.map(
        (county, index) => ({
          fips: selectedFIPSRef.current[index].toString(),
          county: county,
        })
      );

      setCounties(county_array);
    }
  }, [selectedCountiesRef.current, selectedRadiusDataRef.current]);

  // useEffect(() => {
  //   if (selectedRadiusDataRef && circles) {
  //     //console.log("circles=====>", circles);

  //     const source = map && map.getSource(circleFeature.id); // Replace with your source ID

  //     //console.log("source====>", source);
  //   }
  // }, [selectedRadiusDataRef.current]);

  useEffect(() => {
    //console.log("campaignInfo?.radius_array=====>", campaignInfo?.radius_array.$or);
    if (viewMap && campaignInfo?.radius_array?.$or?.length > 0) {
      const mode = "draw_circle"; // You can change the mode to "draw_circle" here
      drawRef.current?.changeMode(mode, { initialRadiusInKm: 20 });
      // const circles_data = [
      //   {
      //     lat: 40.095686436257516,
      //     long: -75.31677246093749,
      //     radiInKm: 96.4136682691269, // Radius in meters
      //   },
      //   {
      //     lat: 38.80976647881053,
      //     long: -86.1864320264022,
      //     radiInKm: 96.4136682691269,
      //   },
      //   // Add more circles if needed
      // ];
      const circles_data = campaignInfo?.radius_array["$or"];
      for (let i = 0; i < circles_data.length; i++) {
        const item = circles_data[i];
        const center = turf.point([item?.long, item?.lat]);

        // Create a circle polygon with only one point for the center
        const circlePolygon = turf.circle(center, item?.radius, {
          steps: 64,
          units: "kilometers",
        });

        // Extract the coordinates from the circle polygon
        const coordinates = circlePolygon?.geometry?.coordinates[0];
        // Create a circle feature with properties for latitude, longitude, and radius
        const circleFeature = {
          type: "Feature",
          properties: {
            center: [item.lat, item.long],
            radiusInKm: item.radius,
          },
          geometry: {
            type: "Polygon",
            coordinates: [coordinates],
          },
        };
        // Add the circle feature to the map
        drawRef?.current?.add(circleFeature);
      }
      map &&
        setTimeout(() => {
          map?.flyTo({
            center: [circles_data[0]?.long, circles_data[0]?.lat],
            zoom: 5, // Adjust the zoom level as needed
          });
        }, 2000);

      drawRef?.current?.changeMode("simple_select");
    }
  }, [map, campaignInfo?.radius_array]);

  function updateArea(e) {
    const data = drawRef.current.getAll();
    if (data.features.length > 0) {
      const area = turf.area(data);
      const rounded_area = Math.round(area * 100) / 100;
      //console.log("area=======>", area);
      //console.log("area=======>", rounded_area);
    }
  }

  const handleCircleButtonClick = () => {
    setCircleModeActive(!circleModeActive);
  };

  useEffect(() => {
    if (!circleModeActive) {
      drawRef?.current?.changeMode("simple_select");
    } else {
      drawRef?.current?.changeMode("drag_circle", { initialRadiusInKm: 20 });
    }

    //console.log("circleModeActive========>", circleModeActive);
  }, [circleModeActive]);

  const iconContainerStyle = {
    position: "absolute",
    top: "78%",
    right: "25px",
    transform: "translate(50%, -50%)",
    cursor: "pointer",
    zIndex: 1,
    borderColor: "rgba(0,0,0,1)",
    backgroundColor: "white",
    padding: "4px",
    borderWidth: "17px",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "21px",
    width: "23px",
    justifyContent: "center",
    borderRadius: "6px",
    display: "flex",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
  };

  const selectedCountiesStyle = {
    position: "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
    height: "75px",
    overflowY: "Scroll",
    zIndex: 1,
  };

  return (
    <div>
      <MouseTooltip />
      <div ref={mapContainerRef} style={{ width: "100%", height: "500px" }}>
        <div ref={iconContainerRef} style={iconContainerStyle}>
          <RadioButtonUncheckedIcon
            onClick={handleCircleButtonClick}
            style={{
              fontSize: 24,
              color: circleModeActive ? "#1976d2" : "black",
            }}
          />
        </div>
        <div style={selectedCountiesStyle}>
          <h4 style={{ margin: "0px" }}>Selected Counties</h4>
          {selectedCountiesRef.current.length > 0 ? (
            <>
              {selectedCountiesRef.current.map((county, index) => (
                <li key={index}>{county}</li>
              ))}
            </>
          ) : (
            <>
              <li>0 County Selected</li>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
ViewMap.defaultProps = {
  counties: [],
  setCounties: () => {},
  circles: [],
  setCircles: () => {},
  viewMap: false,
  campaignInfo: {},
};
