import {
  CLIENT_INVOICE_SEARCH_SUCCESS,
  COMMERCIAL_INVOICE_SEARCH_FAILURE,
  COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS,
  COMMERCIAL_INVOICE_SEARCH_SUCCESS,
  CREATE_COMMERCIAL_INVOICE_FAILURE,
  CREATE_COMMERCIAL_INVOICE_IN_PROGRESS,
  CREATE_COMMERCIAL_INVOICE_SUCCESS,
  GENERATE_INVOICE_PDF_FAILURE,
  GENERATE_INVOICE_PDF_REQUEST,
  GENERATE_INVOICE_PDF_SUCCESS,
  SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE,
  SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS,
  SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS,
  UPDATE_COMMERCIAL_INVOICE_FAILURE,
  UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS,
  UPDATE_COMMERCIAL_INVOICE_SUCCESS,
} from "../actions/invoices/types";
import initialState from "../initialState";

export const invoices = (state = initialState.invoices, action) => {
  switch (action.type) {
    case COMMERCIAL_INVOICE_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        commercialInvoices: {},
      };
    case COMMERCIAL_INVOICE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        commercialInvoices: action?.data,
        error: false,
        errorMessage: "",
      };
    case CLIENT_INVOICE_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientInvoices: action?.data,
        error: false,
        errorMessage: "",
      };
    case COMMERCIAL_INVOICE_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        commercialInvoices: {},
        errorMessage: action?.error?.message,
      };
    case GENERATE_INVOICE_PDF_REQUEST:
      return {
        ...state,
        isGenerateLoading: true,
        error: false,
        errorMessage: "",
      };
    case GENERATE_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        isGenerateLoading: false,
        error: false,
        errorMessage: "",
      };
    case GENERATE_INVOICE_PDF_FAILURE:
      return {
        ...state,
        isGenerateLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case SEND_EMAIL_COMMERCIAL_INVOICES_IN_PROGRESS:
      return {
        ...state,
        isSendEmailLoading: true,
        error: true,
        errorMessage: "",
      };
    case SEND_EMAIL_COMMERCIAL_INVOICES_SUCCESS:
      return {
        ...state,
        isSendEmailLoading: false,
        error: false,
        errorMessage: "",
      };
    case SEND_EMAIL_COMMERCIAL_INVOICES_FAILURE:
      return {
        ...state,
        isSendEmailLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_COMMERCIAL_INVOICE_IN_PROGRESS:
      return {
        ...state,
        isCreateInvoiceInProgress: true,
        error: true,
        errorMessage: "",
      };
    case CREATE_COMMERCIAL_INVOICE_SUCCESS:
      return {
        ...state,
        isCreateInvoiceInProgress: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_COMMERCIAL_INVOICE_FAILURE:
      return {
        ...state,
        isCreateInvoiceInProgress: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_COMMERCIAL_INVOICE_IN_PROGRESS:
      return {
        ...state,
        isCreateInvoiceInProgress: true,
        error: true,
        errorMessage: "",
      };
    case UPDATE_COMMERCIAL_INVOICE_SUCCESS:
      return {
        ...state,
        isCreateInvoiceInProgress: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_COMMERCIAL_INVOICE_FAILURE:
      return {
        ...state,
        isCreateInvoiceInProgress: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
