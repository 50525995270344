import * as React from "react";
import Box from "@mui/material/Box";
import { LeadOwedTable } from "./LeadOwedTable";
import { useSelector } from "react-redux";

export const LeadOwedParentTable = React.memo(props => {
  const {
    onCreateCommercialInvoice,
    onChangeCampiagnKey,
    onLeadOwedKeyUpdate,
  } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let leadReports = useSelector((state) => state?.home?.leadOwed);

  const createData = (
    status,
    state,
    amountPurchasedMonth,
    agencyCallingFor,
    owed,
    paidFor,
    paidThrough,
    total,
    monthlyCallTime,
    action,
    lastCreated
  ) => {
    return {
      status,
      state,
      amountPurchasedMonth,
      agencyCallingFor,
      owed,
      paidFor,
      paidThrough,
      total,
      monthlyCallTime,
      action,
      lastCreated,
    };
  };

  const rows = [
    createData(
      "Active",
      "NY",
      0,
      "Abbondandolo, Eric",
      0,
      40,
      "Empty",
      40,
      "0:0:28",
      "",
      "N/A"
    ),
    createData(
      "Active",
      "CA",
      0,
      "Smith, John",
      0,
      30,
      "In Progress",
      30,
      "0:0:20",
      "",
      "N/A"
    ),
    createData(
      "Inactive",
      "TX",
      0,
      "Johnson, Sarah",
      10,
      20,
      "Paid",
      30,
      "0:0:15",
      "",
      "N/A"
    ),
    createData(
      "Active",
      "FL",
      0,
      "Brown, Michael",
      0,
      25,
      "In Progress",
      25,
      "0:0:18",
      "",
      "N/A"
    ),
    createData(
      "Inactive",
      "CA",
      0,
      "Davis, Emily",
      15,
      15,
      "Paid",
      30,
      "0:0:25",
      "",
      "N/A"
    ),
  ];

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((item) => {
      const {
        amount_purchased_month,
        paid_for,
        paid_through,
        status,
        owed,
        state,
        total,
        utilization_time,
        last_created,
        agency_calling_for_name,
      } = item;

      return createData(
        status,
        state,
        amount_purchased_month,
        agency_calling_for_name,
        owed,
        paid_for,
        paid_through,
        total,
        utilization_time,
        item,
        last_created
      );
    });
  };

  const res = leadReports.results ?? [];
  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <LeadOwedTable
        rows={tableRows}
        filterName={filterName}
        onChangeCampiagnKey={onChangeCampiagnKey}
        onCreateCommercialInvoice={onCreateCommercialInvoice}
        onLeadOwedKeyUpdate={onLeadOwedKeyUpdate}
      />
    </Box>
  );
});
