import * as React from "react";
import Box from "@mui/material/Box";
import { CommercialTable } from "./CommercialTable";
import { useSelector } from "react-redux";

export const CommercialParentTable = (props) => {
  const { generatePdf ,sendEmail } = props;
  const [filterName, setFilterName] = React.useState("");

  const commercialInvoices = useSelector(
    (state) => state?.invoices?.commercialInvoices
  );

  const createData = (
    agent,
    amount,
    month,
    createdDate,
    modifiedDate,
    operations
  ) => {
    return {
      agent,
      amount,
      month,
      createdDate,
      modifiedDate,
      operations,
    };
  };

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const { agent, total_amount, billing_period, created_at, modified_at } =
        item;
      return createData(
        agent,
        total_amount,
        billing_period,
        created_at,
        modified_at,
        item
      );
    });
  };

  const res = commercialInvoices?.all_invoices ?? [];

  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <CommercialTable
        rows={tableRows}
        filterName={filterName}
        generatePdf={generatePdf}
        sendEmail={sendEmail}
      />
    </Box>
  );
};
