// twilioService.js

import { Device } from "@twilio/voice-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  GetTwilioAccessToken,
  setDeviceState,
} from "../../../store/actions/twillio";
import { useEffect } from "react";
import { DEVICE_STATE } from "../../../helpers";

let twilioDevice = null;

// const DEVICE_STATE = {
//   CONNECTING: "Connecting",
//   READY: "Ready",
//   ON_CALL: "On call",
//   OFFLINE: "Offline",
// };

export const TwilioDevice = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state?.loginAuth?.isLoggedIn);
  const token = useSelector((state) => state?.twillio?.access_token);
  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const getToken = () => {
    dispatch(GetTwilioAccessToken());
  };
  useEffect(() => {
    userInfo?.user_type !== "Client" && isLogin && getToken();
  }, [isLogin]);

  if (!twilioDevice && token) {
    try {
      twilioDevice = new Device(token, {
        logLevel: "ERROR",
        edge: "ashburn",
        enableRingingState: true,
      });
      // Register the Twilio Device
      twilioDevice.register();

      // Add event listeners for Twilio events
      twilioDevice.on("registered", () => {
        //console.log("Agent registered");
        dispatch(setDeviceState(DEVICE_STATE.READY));
      });

      twilioDevice.on("connect", (twilioConnection) => {
        //console.log("Call connect");
        dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
      });

      twilioDevice.on("disconnectAll", () => {
        //console.log("Disconnect event");
        dispatch(setDeviceState(DEVICE_STATE.READY));
      });

      twilioDevice.on("error", (twilioError, call) => {
        // Access token expired
        if (twilioError.code === 20104) {
          getToken();
          // You can handle token expiration here, e.g., prompt the user to re-authenticate
        } else {
        }
      });

      twilioDevice.on("ringing", () => {
        //console.log("ringing......");
      });
      twilioDevice.on("incoming", (call) => {
        // Handle incoming call here
        //console.log("Incoming call received");
        // You can choose to display UI to accept or reject the incoming call here
      });
    } catch (error) {
      console.error("Error initializing Twilio Device", error);
    }
  }
  return;
};

export const getTwilioDevice = () => {
  return twilioDevice;
};
