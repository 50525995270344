import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { ProfileModal } from "../../../Common";
import { LeadOwedTableHead } from "./LeadOwedTableHead";
import { checkAndReturnNone, formatDate } from "../../../helpers";
import { useState } from "react";
import { UpdateTooltip } from "../../Common";

export const LeadOwedTable = (props) => {
  const {
    rows,
    onCreateCommercialInvoice,
    onChangeCampiagnKey,
    onLeadOwedKeyUpdate,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Username");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const handleUpdateKey = (id, key, value) => {
    onLeadOwedKeyUpdate(id, key, value, () => {
      handleTooltipClose();
    });
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedDate ?? null;
    //console.log(formattedDate);
  };

  // const handleClick = (event, row) => {
  //   event.stopPropagation();
  //   if (!event.target.closest(".show-button")) {
  //     // Exclude clicks on the "Show" button
  //     const selectedIndex = selected.indexOf(row?.operations?.id);
  //     let newSelected = [];

  //     if (selectedIndex === -1) {
  //       newSelected = newSelected.concat(selected, row?.operations?.id);
  //     } else if (selectedIndex === 0) {
  //       newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //       newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //       newSelected = newSelected.concat(
  //         selected.slice(0, selectedIndex),
  //         selected.slice(selectedIndex + 1)
  //       );
  //     }

  //     setSelected(newSelected);
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  let loading = useSelector((state) => state?.home?.isLeadOwedLoading);

  let total_dict = useSelector(
    (state) => state?.home?.leadOwed?.lead_owed_report_total
  );

  const EmptyRow = () => {
    return (
      <TableCell
        sx={{
          color: "#212223",
          fontWeight: 600,
          fontSize: 15,
          backgroundColor: "#F4F6FA",
          whiteSpace: "nowrap",
          paddingX: "0!important",
          "& .Mui-active": {
            color: "#45c9b1!important",
            fontWeight: "600!important",
          },
          "& .Mui-active .MuiTableSortLabel-icon": {
            color: "#45c9b1!important",
            fontWeight: "600!important",
          },
        }}
        align="left"
      >
        {" "}
      </TableCell>
    );
  };

  const formatTupleString = (tupleString) => {
    if (tupleString === undefined || tupleString === null) {
      return "None";
    }

    const tupleArray = tupleString
      .slice(1, -1)
      .split(",")
      .map((value) => value.trim());

    return tupleArray.join(":");
  };

  const TotalTableRow = ({ totalDict }) => {
    return (
      <TableRow
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          borderBottom: "0!important",
          "&:hover": {
            backgroundColor: "#F4F6FA!important",
          },
        }}
      >
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          Total
        </TableCell>

        {/* {
       "total_active_hours": "0:0:0",
       "total_total_login_time": "0:0:0",
       "total_total_utilization_time": "0:0:0",
       "total_total_points": 0.0,
       "total_valid_point_per_hour": 0.0,
       "total_hourly_earning": 0.0,
       "total_commission_only": 0,
       "total_bonus_pay": 0.0,
       "total_standard_leads": 0,
       "total_plus_leads": 0,
       "total_call_counts": 0,
       "total_total_calls_per_hour": 0
   } */}

        <EmptyRow />
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.amount_purchased_total}
        </TableCell>
        <EmptyRow />

        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.owed_total}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.paid_for_total}
        </TableCell>
        <EmptyRow />

        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_total}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.utilization_time_total}
        </TableCell>
        <EmptyRow />
        <EmptyRow />
      </TableRow>
    );
  };

  const createInvoice = (row) => {
    const payload = {
      is_plus: row.action?.is_plus ?? null,
      billing_period: row.action?.paid_through ?? null,
      owed_lead: row.action?.amount_purchased_month ?? null,
      agent: row?.action?.agent ?? null,
    };
    onCreateCommercialInvoice(payload);
  };

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const findCampaignStatusByid = (status) => {
    return (
      [
        {
          id: true,
          label: "Active",
        },
        {
          id: false,
          label: "Inactive",
        },
      ].find((st) => st.id == status) || null
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ height: "65vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxHeight: 500,
              border: 1,
              borderColor: "#ddd",
            }}
            id="leadOwedReport"
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <LeadOwedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    {[1, 2, 3, 4, 5].map((skeletonRow) => (
                      <TableRow key={skeletonRow}>
                        <TableCell colSpan={18}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={38}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "70%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))?.map(
                          (row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                sx={{
                                  backgroundColor:
                                    index % 2 === 1 ? "#f4f6fa" : "#fff",
                                  borderBottom: "0!important",
                                  "&:hover": {
                                    backgroundColor: "#F4F6FA!important",
                                  },
                                }}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.username}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingLeft: "20px",
                                  }}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === index ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Status"}
                                        onSubmit={(value) => {
                                          handleUpdateKey(
                                            row?.action?.agency_calling_for,
                                            "is_active",
                                            value
                                          );
                                        }}
                                        selectedKey={
                                          findCampaignStatusByid(row.status)
                                            ?.label
                                        }
                                        inputType={"SelectOptions"}
                                        labelKey={"label"}
                                        optionsData={[
                                          {
                                            id: true,
                                            label: "Active",
                                          },
                                          {
                                            id: false,
                                            label: "Inactive",
                                          },
                                        ]}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          row.status
                                        );
                                        setData(val);
                                        handleTooltipOpen(index);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        cursor: "pointer",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      {row.status ? "Active" : "Inactive"}
                                    </Typography>
                                  </Tooltip>
                                  {/* {row.status ? "Active" : "Inactive"} */}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {row.state}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID === `${index}Amt` ? true : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Amount Purchased Month"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey("amount", value);
                                          handleUpdateKey(
                                            row?.action?.agency_calling_for,
                                            "amount_purchased_month",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = row.amountPurchasedMonth;
                                        setData(val);
                                        const rv = `${index}Amt`;
                                        handleTooltipOpen(rv);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        cursor: "pointer",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      {row.amountPurchasedMonth}
                                    </Typography>
                                  </Tooltip>
                                  {/* {row.amountPurchasedMonth} */}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {row.agencyCallingFor}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {row.owed}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {/* {row.paidFor} */}
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID === `${index}paidFor`
                                        ? true
                                        : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Paid For"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey("amount", value);
                                          handleUpdateKey(
                                            row?.action?.agency_calling_for,
                                            "paid_for",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = row.paidFor;
                                        setData(val);
                                        const rv = `${index}paidFor`;
                                        handleTooltipOpen(rv);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        cursor: "pointer",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      {row.paidFor}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID === `${index}paidThrough`
                                        ? true
                                        : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Paid Through"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey("amount", value);
                                          handleUpdateKey(
                                            row?.action?.agency_calling_for,
                                            "paid_through",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          row.paidThrough
                                        );
                                        setData(val);
                                        const rv = `${index}paidThrough`;
                                        handleTooltipOpen(rv);
                                      }}
                                      sx={{
                                        fontSize: 15,
                                        color:
                                          checkAndReturnNone(
                                            row.paidThrough
                                          ) === "None"
                                            ? "#DD1144"
                                            : "#0088cc",
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        cursor: "pointer",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      {checkAndReturnNone(row.paidThrough)}
                                    </Typography>
                                  </Tooltip>
                                  {/* {checkAndReturnNone(row.paidThrough)} */}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {row.total}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {/* {formatTupleString(row.monthlyCallTime)} */}
                                  {row.monthlyCallTime}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom: "0",
                                    paddingX: "0!important",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      height: "20px",
                                      textTransform: "capitalize",
                                      pl: 1.5,
                                      pr: 1.5,
                                      mr: 2,
                                      pt: 1.5,
                                      pb: 1.5,
                                      fontWeight: 600,
                                      wordBreak: "unset",
                                      width: "max-content",
                                      backgroundColor: "#6DD6C2",
                                      "&:hover": {
                                        backgroundColor: "#6DD6C2",
                                      },
                                      borderColor: "#ed5565",
                                      color: "#212223",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                      createInvoice(row);
                                    }}
                                  >
                                    Create Invoice
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: "0!important",
                                  }}
                                  align="left"
                                >
                                  {row?.lastCreated != "N/A" &&
                                  row?.lastCreated != null &&
                                  row?.lastCreated != undefined
                                    ? formatDateTime(row?.lastCreated) ?? "N/A"
                                    : "N/A"}
                                  {}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </>
                    )}
                  </>
                )}
                {loading ? (
                  <>
                    {[1].map((skeletonRow) => (
                      <TableRow key={skeletonRow}>
                        <TableCell colSpan={18}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={38}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <></>
                    ) : (
                      <TotalTableRow totalDict={total_dict ?? {}} />
                    )}
                  </>
                )}
                {/* {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )} */}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        {/* <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        /> */}
      </Paper>
    </Box>
  );
};
