// LoginScreen.js
import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../Common";

export const InvoiceCreate = (props) => {
  const { updateCommercialInvoice } = props;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const location = useLocation();
  const navigate = useNavigate();

  const { isEdit, invoiceData } = location.state || {};

  const initialInvoiceData = {
    agent: isEdit ? invoiceData?.agent : "",
    totalAmount: isEdit ? invoiceData?.total_amount : "",
    billingPeriod: isEdit ? invoiceData?.billing_period : "",
    owedLead: isEdit ? invoiceData?.owed_lead : "",
    pricePerLead: isEdit ? invoiceData?.price_per_lead : "",
    fromDate: isEdit ? invoiceData?.from_date : "",
    isPaid: isEdit ? invoiceData?.is_paid : false,
    isPlus: isEdit ? invoiceData?.is_plus : false,
  };

  const [formData, setFormData] = useState(initialInvoiceData);

  //validation
  const [triedSubmit, setTriedSubmit] = useState(false);

  const agentNameError =
    triedSubmit && formData?.agent?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && formData?.agent?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const totalAmountError =
    triedSubmit && formData?.totalAmount?.toString().trim().length === 0;

  const totalAmountErrorMessage =
    triedSubmit && formData?.totalAmount?.toString().trim().length === 0
      ? "Please enter valid total amount"
      : "";

  const billingPeriodError =
    triedSubmit && formData?.billingPeriod?.toString().trim().length === 0;

  const billingPeriodErrorMessage =
    triedSubmit && formData?.billingPeriod?.toString().trim().length === 0
      ? "Please enter valid billing period"
      : "";

  const owedLeadError =
    triedSubmit && formData?.owedLead?.toString().trim().length === 0;

  const owedLeadErrorMessage =
    triedSubmit && formData?.owedLead?.toString().trim().length === 0
      ? "Please enter valid owed lead"
      : "";

  const pricePerLeadError =
    triedSubmit && formData?.pricePerLead?.toString().trim().length === 0;

  const pricePerLeadErrorMessage =
    triedSubmit && formData?.pricePerLead?.toString().trim().length === 0
      ? "Please enter valid price per lead"
      : "";

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid =
    formData?.agent.toString().trim().length > 0 &&
    formData?.totalAmount.toString().trim().length > 0 &&
    formData?.billingPeriod.toString().trim().length > 0 &&
    formData?.owedLead.toString().trim().length > 0 &&
    formData?.pricePerLead.toString().trim().length > 0;

  const handleSubmit = (event) => {
    event.preventDefault();
    setTriedSubmit(true);
    if (!isFormValid) return;

    const payload = {
      id: isEdit ? invoiceData?.id : null,
      agent: formData.agent,
      total_amount: formData.totalAmount,
      billing_period: formData.billingPeriod,
      owed_lead: formData.owedLead,
      price_per_lead: formData.pricePerLead,
      from_date: formData.fromDate,
      is_paid: formData.isPaid,
      is_plus: formData.isPlus,
      // created_by: invoiceData.created_by,
      // modified_by: invoiceData.modified_by,
    };
    updateCommercialInvoice(payload, () => {
      navigate(-1);
      setTriedSubmit(false);
    });
  };

  const [age, setAge] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const reset = () => {
    // setFormData({
    //   agent: "",
    //   totalAmount: "",
    //   billingPeriod: "",
    //   owedLead: "",
    //   pricePerLead: "",
    //   fromDate: "",
    //   isPaid: false,
    //   isPlus: false,
    // });
    setTriedSubmit(false);
    setFormData(initialInvoiceData);
  };

  const userDetails = useSelector(
    (state) => state?.prospect?.user_details?.data
  );

  const isLoading = useSelector(
    (state) => state?.invoices?.isCreateInvoiceInProgress
  );

  const findUserById = (userId) => {
    return userDetails.find((user) => user.id === userId) || null;
  };

  const handleChangeForAgent = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setFormData({
          ...formData,
          agent: newValue.id,
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "40px",
          paddingX: "20px",
          borderRadius: "10px!Important",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ paddingTop: "0!important" }}>
            <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  paddingTop: "0!important",
                  borderRadius: "10px 10px 0 0!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Update Commercial Invoice
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: agentNameError
                                ? "#d32f2f"
                                : "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            value={findUserById(formData.agent)}
                            autoHighlight
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChangeForAgent(
                                event,
                                newValue,
                                "agentName"
                              );
                            }}
                            error={agentNameError}
                            helperText={agentNameError && agentNameErrorMessage}
                            size="small"
                            disableClearable={true}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Agent" />
                                {agentNameError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentNameErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.full_name}`}
                                >
                                  {option?.full_name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="totalAmount"
                          label="Total Amount"
                          type="number"
                          value={formData.totalAmount}
                          error={totalAmountError}
                          helperText={
                            totalAmountError && totalAmountErrorMessage
                          }
                          onChange={handleChange}
                          id="amount"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4cdcc2",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="billingPeriod"
                          label="Billing Period"
                          type="text"
                          id="billingPeriod"
                          value={formData.billingPeriod}
                          onChange={handleChange}
                          error={billingPeriodError}
                          helperText={
                            billingPeriodError && billingPeriodErrorMessage
                          }
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4cdcc2",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="owedLead"
                          label="Owed Lead"
                          type="number"
                          value={formData.owedLead}
                          onChange={handleChange}
                          error={owedLeadError}
                          helperText={owedLeadError && owedLeadErrorMessage}
                          id="owedLead"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4cdcc2",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="pricePerLead"
                          label="Price per Lead"
                          type="number"
                          value={formData.pricePerLead}
                          onChange={handleChange}
                          error={pricePerLeadError}
                          helperText={
                            pricePerLeadError && pricePerLeadErrorMessage
                          }
                          id="pricePerLead"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4cdcc2",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="fromDate"
                          label="From Date"
                          focused
                          type="date"
                          value={formData.fromDate}
                          onChange={handleChange}
                          id="fromDate"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4cdcc2",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: {
                              xs: "grid",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                            },
                            width: "20%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "0px",
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            sx={{
                              fontSize: 18,
                              color: "#454647",
                              fontWeight: 500,
                            }}
                          >
                            Is Paid -
                          </Typography>

                          <Checkbox
                            {...label}
                            name="isPaid"
                            checked={formData.isPaid}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: {
                              xs: "grid",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                            },
                            width: "20%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "0px",
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            sx={{
                              fontSize: 18,
                              color: "#454647",
                              fontWeight: 500,
                            }}
                          >
                            Is Plus -
                          </Typography>
                          <Checkbox
                            {...label}
                            name="isPlus"
                            checked={formData.isPlus}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={(event) => {
                            handleSubmit(event);
                          }}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#32c3a3",
                              borderColor: "#32c3a3",
                            },
                          }}
                        >
                          Submit
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            reset();
                          }}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#212223",
                            borderColor: "#212223",
                            color: "#FFFFFF",
                            marginLeft: "10px",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#212223",
                              borderColor: "#212223",
                            },
                          }}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isLoading} />
      </Box>
    </>
  );
};
