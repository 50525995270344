// Types
import {
  CREATE_CALL_RESULT_FAILURE,
  CREATE_CALL_RESULT_IN_PROGRESS,
  CREATE_CALL_RESULT_SUCCESS,
  DELETE_CALL_RESULT_FAILURE,
  DELETE_CALL_RESULT_IN_PROGRESS,
  DELETE_CALL_RESULT_SUCCESS,
  EXPORT_CSV_FAILURE,
  EXPORT_CSV_IN_PROGRESS,
  EXPORT_CSV_SUCCESS,
  GET_CALL_RESULT_OPTIONS_FAILURE,
  GET_CALL_RESULT_OPTIONS_IN_PROGRESS,
  GET_CALL_RESULT_OPTIONS_SUCCESS,
  GET_PROSPECTS_SEARCH_FAILURE,
  GET_PROSPECTS_SEARCH_IN_PROGRESS,
  GET_PROSPECTS_SEARCH_SUCCESS,
  PROSPECT_MASS_UPDATE_FAILURE,
  PROSPECT_MASS_UPDATE_IN_PROGRESS,
  PROSPECT_MASS_UPDATE_SUCCESS,
  UPDATE_PROSPECT_IN_PROGRESS,
  UPDATE_PROSPECT_KEY_FAILURE,
  UPDATE_PROSPECT_KEY_IN_PROGRESS,
  UPDATE_PROSPECT_KEY_SUCCESS,
} from "../actions/Prospects/types";
import { UPDATE_PROSPECT_FAILURE } from "../actions/Prospects/types";
import { UPDATE_PROSPECT_SUCCESS } from "../actions/Prospects/types";
import {
  CREATE_PROSPECT_FAILURE,
  CREATE_PROSPECT_IN_PROGRESS,
  CREATE_PROSPECT_SUCCESS,
  GET_PROSPECTS_FAILURE,
  GET_PROSPECTS_IN_PROGRESS,
  GET_PROSPECTS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_IN_PROGRESS,
  GET_USERS_SUCCESS,
  UPLOAD_PROSPECT_CSV_FAILURE,
  UPLOAD_PROSPECT_CSV_IN_PROGRESS,
  UPLOAD_PROSPECT_CSV_SUCCESS,
} from "../actions/Prospects/types";
import initialState from "../initialState";

export const prospect = (state = initialState.prospect, action) => {
  switch (action.type) {
    case CREATE_PROSPECT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_PROSPECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_PROSPECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    // case GET_PROSPECTS_IN_PROGRESS:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: false,
    //     errorMessage: "",
    //     prospectInfo: {},
    //   };
    // case GET_PROSPECTS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     errorMessage: "",
    //     prospectInfo: action?.data,
    //   };
    // case GET_PROSPECTS_FAILURE:
    //   return {
    //     ...state,
    //     prospectInfo: {},
    //     loading: false,
    //     error: true,
    //     errorMessage: action?.error?.message,
    //   };
    case GET_USERS_IN_PROGRESS:
      return {
        ...state,
        user_details: {
          data: [],
          loading: true,
          error: false,
          errorMessage: "",
        },
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        user_details: {
          data: action?.data,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        user_details: {
          data: [],
          loading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
    case UPLOAD_PROSPECT_CSV_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_PROSPECT_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_PROSPECT_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_PROSPECT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROSPECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROSPECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_PROSPECTS_SEARCH_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        prospectInfo: {},
      };
    case GET_PROSPECTS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        prospectInfo: action?.data,
      };
    case GET_PROSPECTS_SEARCH_FAILURE:
      return {
        ...state,
        prospectInfo: {},
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case PROSPECT_MASS_UPDATE_IN_PROGRESS:
      return {
        ...state,
        isMassUpdateLoading: true,
        error: false,
        errorMessage: "",
      };
    case PROSPECT_MASS_UPDATE_SUCCESS:
      return {
        ...state,
        isMassUpdateLoading: false,
        error: false,
        errorMessage: "",
      };
    case PROSPECT_MASS_UPDATE_FAILURE:
      return {
        ...state,
        isMassUpdateLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case EXPORT_CSV_IN_PROGRESS:
      return {
        ...state,
        isExportLoading: true,
        error: false,
        errorMessage: "",
      };
    case EXPORT_CSV_SUCCESS:
      return {
        ...state,
        isExportLoading: false,
        error: false,
        errorMessage: "",
      };
    case EXPORT_CSV_FAILURE:
      return {
        ...state,
        isExportLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_PROSPECT_KEY_IN_PROGRESS:
      return {
        ...state,
        isUpdateKeyIsloading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROSPECT_KEY_SUCCESS:
      return {
        ...state,
        isUpdateKeyIsloading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROSPECT_KEY_FAILURE:
      return {
        ...state,
        isUpdateKeyIsloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CALL_RESULT_OPTIONS_IN_PROGRESS:
      return {
        ...state,
        callResult: {
          ...state.callResult,
          loading: true,
          error: false,
          errorMessage: "",
        },
      };

    case GET_CALL_RESULT_OPTIONS_SUCCESS:
      return {
        ...state,
        callResult: {
          ...state.callResult,
          options: action.data,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };

    case GET_CALL_RESULT_OPTIONS_FAILURE:
      return {
        ...state,
        callResult: {
          ...state.callResult,
          loading: false,
          error: true,
          errorMessage: action.error.message,
        },
      };
      case CREATE_CALL_RESULT_IN_PROGRESS:
        return {
          ...state,
          callResult: {
            ...state.callResult,
            isCreateLoading:true,
          },
        };
  
      case CREATE_CALL_RESULT_SUCCESS:
        return {
          ...state,
          callResult: {
            ...state.callResult,
            isCreateLoading:false,
          },
        };
  
      case CREATE_CALL_RESULT_FAILURE:
        return {
          ...state,
          callResult: {
            ...state.callResult,
            isCreateLoading:false,
          },
        };
        case DELETE_CALL_RESULT_IN_PROGRESS:
          return {
            ...state,
            callResult: {
              ...state.callResult,
              isCreateLoading:true,
            },
          };
    
        case DELETE_CALL_RESULT_SUCCESS:
          return {
            ...state,
            callResult: {
              ...state.callResult,
              isCreateLoading:false,
            },
          };
    
        case DELETE_CALL_RESULT_FAILURE:
          return {
            ...state,
            callResult: {
              ...state.callResult,
              isCreateLoading:false,
            },
          };
    default:
      return state;
  }
};
