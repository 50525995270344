import {
  GET_TWILIO_ACCESS_TOKEN_FAILURE,
  GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS,
  GET_TWILIO_ACCESS_TOKEN_SUCCESS,
  SET_DEVICE_STATE,
  UPDATE_CALLING_INFO,
} from "../actions/twillio/types";
import initialState from "../initialState";

export const twillio = (state = initialState.twillio, action) => {
  switch (action.type) {
    case GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        access_token: "",
        error: false,
        errorMessage: "",
      };
    case GET_TWILIO_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        isloading: false,
        access_token: action?.token,
        error: false,
        errorMessage: "",
      };
    case GET_TWILIO_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        isloading: false,
        access_token: "",
        error: true,
        errorMessage: action?.error?.message,
      };
    case SET_DEVICE_STATE:
      return {
        ...state,
        deviceState: action?.payload,
      };
    case UPDATE_CALLING_INFO:
      return {
        ...state,
        callingCardInfo: action?.payload,
      };

    default:
      return state;
  }
};
