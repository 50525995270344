import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Callback } from "../components";
import { getCallBackSearch } from "../store/actions/callBack";
import { GetAllEmployees, GetUsers } from "../store/actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchCallBacks: (payload, offset, cb) =>
      dispatch(getCallBackSearch(payload, offset, cb)),
    getAllEmployeesList: () => dispatch(GetAllEmployees()),
    getAllUser: () => dispatch(GetUsers()),
  };
};

export const CallBackContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/Callback":
      ComponentToRender = Callback;
      break;
    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
