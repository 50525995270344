import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";

export const CampaignsTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const headCells =
    userInfo?.user_type === "Administration"
      ? [
          {
            id: "campaignName",
            numeric: false,
            disablePadding: true,
            label: "Campaign Name",
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
          },
          {
            id: "amountOwed",
            numeric: false,
            disablePadding: false,
            label: "Amount Owed",
          },
          {
            id: "accountName",
            numeric: false,
            disablePadding: false,
            label: "Account Name",
          },
          {
            id: "twilioCid",
            numeric: false,
            disablePadding: false,
            label: "Twilio Cid",
          },
          {
            id: "createdBy",
            numeric: false,
            disablePadding: false,
            label: "Created By",
          },
          {
            id: "createdAt",
            numeric: false,
            disablePadding: false,
            label: "Created At",
          },
          {
            id: "modifiedBy",
            numeric: false,
            disablePadding: false,
            label: "Modified By",
          },
          {
            id: "modifiedAt",
            numeric: false,
            disablePadding: false,
            label: "Modified At",
          },
          {
            id: "operations",
            numeric: false,
            disablePadding: false,
            label: "Operations",
          },
        ]
      : [
          {
            id: "campaignName",
            numeric: false,
            disablePadding: true,
            label: "Campaign Name",
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
          },
          {
            id: "amountOwed",
            numeric: false,
            disablePadding: false,
            label: "Amount Owed",
          },
          {
            id: "accountName",
            numeric: false,
            disablePadding: false,
            label: "Account Name",
          },
          {
            id: "twilioCid",
            numeric: false,
            disablePadding: false,
            label: "Twilio Cid",
          },
          {
            id: "createdBy",
            numeric: false,
            disablePadding: false,
            label: "Created By",
          },
          {
            id: "createdAt",
            numeric: false,
            disablePadding: false,
            label: "Created At",
          },
          {
            id: "modifiedBy",
            numeric: false,
            disablePadding: false,
            label: "Modified By",
          },
          {
            id: "modifiedAt",
            numeric: false,
            disablePadding: false,
            label: "Modified At",
          },
        ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingLeft: "15px",
              paddingX: index == 0 ? 2 : 0,
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "operations" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span style={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CampaignsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
