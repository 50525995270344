// LoginScreen.js
import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Autocomplete,
  Alert,
  Snackbar,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  ERROR_MESSAGE,
  TIME_ZONES,
  formatPhoneNumber,
  validateEmail,
  validateMobileNumber,
  validateName,
  validatePincode,
} from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "../Common";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  GoogleMap,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";

export const AddClient = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onCreateClient, onUpdateClient } = props;
  const { isEdit, clientData } = location.state || {};
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [alert, setAlert] = useState(false);

  const initialData = {
    userName: isEdit ? clientData?.user?.first_name : "",
    lastName: isEdit ? clientData?.user?.last_name : "",
    userEmail: isEdit ? clientData?.user?.email : "",
    phoneNo: isEdit ? clientData?.user?.phone_number : "",
    cellPhone: isEdit ? clientData?.user?.cell_phone_number : "",
    localTimeZone: isEdit ? clientData?.user?.time_zone : "",
    state: isEdit ? clientData?.user?.state : "",
    city: isEdit ? clientData?.user?.city : "",
    address: isEdit ? clientData?.user?.address : "",
    street: isEdit ? clientData?.user?.street : "",
    isActive: isEdit ? clientData?.user?.account_status : true,
    zipCode: isEdit ? clientData?.user?.zip_code : "",
  };

  const initialAdditionalData = {
    accountName: isEdit ? clientData?.account_name ?? "" : "",
    additionalEmail: isEdit ? clientData?.additional_email ?? "" : "",
    agencyName: isEdit ? clientData.agency_name ?? "" : "",
    planPurchased: isEdit ? clientData?.plan_purchased ?? "" : "",
    agentTextId: isEdit ? clientData?.agent_text_id ?? "" : "",
    isFirstLogin: isEdit ? clientData?.is_first_login ?? false : false,
    notes: isEdit ? clientData?.notes ?? "" : "",
    invokaId: isEdit ? clientData?.invoka_id ?? "" : "",
    autoPaymentIsActive: isEdit
      ? clientData?.auto_payment_is_active ?? true
      : true,
    sendIntroductionMail: isEdit
      ? clientData?.send_introduction_mail ?? true
      : true,
    additionalEmailForInvoices: isEdit
      ? clientData?.additional_email_for_invoices ?? ""
      : "",
    plusLeadPrice: isEdit ? clientData?.plus_lead_price ?? "" : "",
    plusStandardPrice: isEdit ? clientData?.plus_standard_price ?? "" : "",
  };
  const [userData, setUserData] = useState(initialData);
  const [additionalData, setAdditionalData] = useState(initialAdditionalData);

  const isloading = useSelector((state) => state?.client?.loading);

  const autoCompleteRef = React.useRef(null);

  const handleChange = (event, newValue, type) => {
    if (type === "sms") {
      setAdditionalData({
        ...additionalData,
        sendIntroductionMail: event.target.value,
      });
    } else {
      setUserData({
        ...userData,
        localTimeZone: newValue,
      });
    }
  };

  //Form Validation
  const nameError =
    triedSubmit &&
    (userData?.userName?.trim().length === 0 ||
      !validateName(userData?.userName));
  const nameErrorMessage =
    triedSubmit && userData?.userName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const lastNameError =
    triedSubmit &&
    (userData?.lastName?.trim().length === 0 ||
      !validateName(userData?.lastName));
  const lastNameErrorMessage =
    triedSubmit && userData?.lastName.trim().length === 0
      ? ERROR_MESSAGE.LASTNAME.IN_VALID
      : "";

  const userEmailError =
    triedSubmit &&
    (userData?.userEmail?.trim().length === 0 ||
      !validateEmail(userData?.userEmail));
  const userEmailErrorMessage = triedSubmit && ERROR_MESSAGE.EMAIL.IN_VALID;

  // const userAdditionalEmailError =
  //   triedSubmit &&
  //   (additionalData?.additionalEmail?.trim().length === 0 ||
  //     !validateEmail(additionalData?.additionalEmail));
  // const userAdditionalEmailErrorMessage =
  //   triedSubmit && ERROR_MESSAGE.EMAIL.IN_VALID;

  // const userAdditionalEmailInvoiceError =
  //   triedSubmit &&
  //   (additionalData?.additionalEmailForInvoices?.trim().length === 0 ||
  //     !validateEmail(additionalData?.additionalEmailForInvoices));
  // const userAdditionalEmailInvoiceErrorMessage =
  //   triedSubmit && ERROR_MESSAGE.EMAIL.IN_VALID;

  const mobileError =
    triedSubmit &&
    (userData?.phoneNo?.trim().length === 0 ||
      !validateMobileNumber(userData?.phoneNo));
  const mobileErrorMessage =
    triedSubmit && userData?.phoneNo.trim().length === 0
      ? ERROR_MESSAGE.MOBILE_NUMBER.IN_VALID
      : "";

  const stateError =
    triedSubmit &&
    (userData?.state.trim().length === 0 || !validateName(userData?.state));
  const stateErrorMessage =
    triedSubmit && userData?.state?.trim().length === 0
      ? ERROR_MESSAGE.STATE.IN_VALID
      : "";
  const cityError =
    triedSubmit &&
    (userData?.city.trim().length === 0 || !validateName(userData?.city));
  const cityErrorMessage =
    triedSubmit && userData?.city?.trim().length === 0
      ? ERROR_MESSAGE.CITY.IN_VALID
      : "";
  const zipCodeError = triedSubmit && !validatePincode(userData?.zipCode);
  const zipCodeErrorMessage =
    triedSubmit && userData?.zipCode?.trim().length < 5
      ? "Please enter valid zip code"
      : "";

  const isFormValid =
    userData?.userName.trim().length > 0 &&
    validateName(userData?.userName) &&
    userData?.lastName.trim().length > 0 &&
    userData?.userEmail.trim().length > 0 &&
    validateEmail(userData.userEmail) &&
    userData?.phoneNo.trim().length > 0 &&
    validateMobileNumber(userData?.phoneNo) &&
    userData?.state.trim().length > 0 &&
    validateName(userData?.state) &&
    userData?.city.trim().length > 0 &&
    validateName(userData?.city) &&
    userData?.zipCode.trim().length > 0 &&
    validatePincode(userData?.zipCode);
  // additionalData?.additionalEmailForInvoices?.trim().length > 0 &&
  // validateEmail(additionalData?.additionalEmailForInvoices) &&
  // additionalData?.additionalEmail?.trim().length > 0 &&
  // validateEmail(additionalData?.additionalEmail);

  const handleCloseSnack = () => {
    setAlert(false);
  };

  const callBackFun = () => {
    setAlert(true);
    isEdit &&
      setTimeout(() => {
        navigate(-1);
      }, 500);
  };

  const onSubmitHandler = () => {
    setTriedSubmit(true);

    // const showToastMessage = () => {
    //   toast.success("Success Notification !", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // };
    // showToastMessage();
    if (!isFormValid) return;
    if (isEdit) {
      let payload = {
        ...userData,
        ...additionalData,
      };
      let id = clientData?.id;
      onUpdateClient(id, payload, callBackFun);
    } else {
      let payload = {
        ...userData,
        ...additionalData,
      };
      onCreateClient(payload, callBackFun);
    }
  };

  const handleChangeOptions = (event, type) => {
    switch (type) {
      case "isActive":
        setUserData({
          ...userData,
          isActive: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const addressComponents = place?.address_components;

    let street = "";
    let city = "";
    let state = "";
    let zipCode = "";

    addressComponents?.forEach((component) => {
      const componentType = component.types[0];

      if (componentType === "street_number" || componentType === "route") {
        street = `${street} ${component.long_name}`;
      } else if (componentType === "locality") {
        city = component.long_name;
      } else if (componentType === "administrative_area_level_1") {
        state = component.short_name;
      } else if (componentType === "postal_code") {
        zipCode = component.long_name;
      }
    });
    setUserData({
      ...userData,
      street,
      city,
      state,
      zipCode,
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Client Basic Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="firstname"
                          label="First Name"
                          type="text"
                          id="firstname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={userData?.userName}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              userName: e.target.value,
                            })
                          }
                          error={nameError}
                          helperText={nameError && nameErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Lastname"
                          label="Last Name"
                          type="text"
                          id="lasttname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={userData?.lastName}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              lastName: e.target.value,
                            })
                          }
                          error={lastNameError}
                          helperText={lastNameError && lastNameErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          id="email"
                          label="Email Address"
                          name="email"
                          fontSize="15"
                          value={userData?.userEmail}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              userEmail: e.target.value,
                            })
                          }
                          error={userEmailError}
                          helperText={userEmailError && userEmailErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Phone Number"
                          id="num"
                          fontSize="15"
                          value={
                            formatPhoneNumber(userData?.phoneNo) ??
                            userData?.phoneNo
                          }
                          inputProps={{ maxLength: 12 }}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              phoneNo: e.target.value,
                            })
                          }
                          error={mobileError}
                          helperText={mobileError && mobileErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="cnum"
                          label="Cell Phone Number"
                          id="cnum"
                          fontSize="15"
                          value={
                            formatPhoneNumber(userData?.cellPhone) ??
                            userData?.cellPhone
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              cellPhone: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={TIME_ZONES}
                            value={userData?.localTimeZone}
                            disableClearable={true}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "");
                            }}
                            size="small"
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#6DD6C2!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Client Local Timezone"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      {isEdit && (
                        <Grid item xs={12} md={6}>
                          <FormControl
                            size="small"
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "95%",
                                lg: "95%",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },

                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },

                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2!important",
                                },
                            }}
                          >
                            <InputLabel
                              id="demo-simple-select-label"
                              InputLabelProps={{
                                sx: {
                                  "&.Mui-focused": {
                                    color: "#6DD6C2",
                                  },
                                },
                              }}
                              sx={{
                                fontSize: 15,
                                "&.Mui-focused": {
                                  color: "#6DD6C2!important",
                                  fontWeight: "600",
                                },
                              }}
                            >
                              User Is Active
                            </InputLabel>
                            <Select
                              labelId="sms-label"
                              id="demo-simple-select"
                              label="User Is Active"
                              value={userData?.isActive}
                              onChange={(event) => {
                                handleChangeOptions(event, "isActive");
                              }}
                              size="small"
                              InputLabelProps={{
                                sx: {
                                  "&.Mui-focused": {
                                    color: "#6DD6C2",
                                  },
                                },
                              }}
                            >
                              <MenuItem value={true} sx={{ fontSize: 15 }}>
                                True
                              </MenuItem>
                              <MenuItem value={false} sx={{ fontSize: 15 }}>
                                False
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  User Address Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid item xs={12} sm={12} md={12}>
                      <GoogleAutocomplete
                        onLoad={(autoComplete) =>
                          (autoCompleteRef.current = autoComplete)
                        }
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="street"
                          label="Location Street"
                          type="text"
                          id="street"
                          fontSize="15"
                          value={userData?.street}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              street: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: 3.2,
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "97.5%",
                              lg: "97.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </GoogleAutocomplete>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="city"
                          label="Location City"
                          type="text"
                          id="city"
                          autoComplete="current-password"
                          fontSize="15"
                          value={userData?.city}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              city: e.target.value,
                            })
                          }
                          error={cityError}
                          helperText={cityError && cityErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="state"
                          label="Location State"
                          type="text"
                          id="state"
                          fontSize="15"
                          value={userData?.state}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              state: e.target.value,
                            })
                          }
                          error={stateError}
                          helperText={stateError && stateErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="zip code"
                          label="Location Zip Code"
                          id="zipcode"
                          fontSize="15"
                          inputProps={{ maxLength: 5 }}
                          value={userData?.zipCode}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              zipCode: e.target.value,
                            })
                          }
                          error={zipCodeError}
                          helperText={zipCodeError && zipCodeErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "97.5%",
                              lg: "97.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>

                      {/* <Grid item xs={12} data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          id="Address"
                          label="Location Address"
                          name="Address"
                          fontSize="15"
                          value={userData?.address}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              address: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight:600
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "97.5%",
                              lg: "97.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid> */}
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Additional Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Additionalemail"
                          label="Additional Email"
                          type="email"
                          id="Additionalemail"
                          fontSize="15"
                          value={additionalData?.additionalEmail}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              additionalEmail: e.target.value,
                            })
                          }
                          // error={userAdditionalEmailError}
                          // helperText={
                          //   userAdditionalEmailError &&
                          //   userAdditionalEmailErrorMessage
                          // }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Additionalemail"
                          label="Additional Email for Invoice"
                          type="email"
                          id="Additionalemail"
                          autoComplete="current-password"
                          fontSize="15"
                          value={additionalData?.additionalEmailForInvoices}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              additionalEmailForInvoices: e.target.value,
                            })
                          }
                          // error={userAdditionalEmailInvoiceError}
                          // helperText={
                          //   userAdditionalEmailInvoiceError &&
                          //   userAdditionalEmailInvoiceErrorMessage
                          // }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Accountname"
                          label="Account Name"
                          type="text"
                          id="Accountname"
                          fontSize="15"
                          value={additionalData?.accountName}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              accountName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Agencyname"
                          label="Agency Name"
                          type="text"
                          id="Agencyname"
                          fontSize="15"
                          value={additionalData?.agencyName}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              agencyName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Plan"
                          label="Plan Purchased"
                          type="text"
                          id="plan"
                          fontSize="15"
                          value={additionalData?.planPurchased}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              planPurchased: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Agentid"
                          label="Agent ID"
                          type="text"
                          id="Agentid"
                          fontSize="15"
                          value={additionalData?.agentTextId}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              agentTextId: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            SMS Notification is Active
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="SMS Notification is Active"
                            value={additionalData?.sendIntroductionMail}
                            onChange={(event) => {
                              handleChange(event, "", "sms");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#4fe1c6",
                                  fontWeight: 600,
                                },
                              },
                            }}
                          >
                            <MenuItem value={false} sx={{ fontSize: 15 }}>
                              False
                            </MenuItem>
                            <MenuItem value={true} sx={{ fontSize: 15 }}>
                              True
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="invokaid"
                          label="Invoka ID"
                          type="text"
                          id="invokaid"
                          fontSize="15"
                          value={additionalData?.invokaId}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              invokaId: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight:600
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="pricelead"
                          label="Price for Plus Lead"
                          type="text"
                          id="pricelead"
                          fontSize="15"
                          value={additionalData?.plusLeadPrice}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              plusLeadPrice: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="pricestandard"
                          label="Price for Standard Lead"
                          type="text"
                          id="pricestandard"
                          fontSize="15"
                          value={additionalData?.plusStandardPrice}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              plusStandardPrice: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#4fe1c6",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <form>
                          <TextField
                            label="Notes"
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="0"
                            fullWidth
                            size="small"
                            name="notes"
                            type="text"
                            id="notes"
                            fontSize="15"
                            value={additionalData?.notes}
                            onChange={(e) =>
                              setAdditionalData({
                                ...additionalData,
                                notes: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#4fe1c6",
                                  fontWeight: 600,
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "95%",
                                lg: "95%",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </form>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "0px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            onSubmitHandler();
                          }}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isloading} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={alert}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
        >
          <Alert
            variant="filled"
            severity="success"
            sx={{
              width: "100%",
              backgroundColor: "#32c3a3",
              color: "#fff",
            }}
          >
            Client has been {isEdit ? "updated" : "created"} successfully.
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
