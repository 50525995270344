// LoginScreen.js
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableItem } from "./DraggableItem";
import { useSelector } from "react-redux";
import { ERROR_MESSAGE, validateName } from "../../helpers";
import { Loader } from "../Common";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const CallResult = (props) => {
  const { getAllCallResultOptions, createCallResult, deleteCallResult } = props;
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  useEffect(() => {
    getAllCallResultOptions();
  }, []);

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  const isCreateLoading = useSelector(
    (state) => state?.prospect?.callResult?.isCreateLoading
  );

  useEffect(() => {
    if (callResult.length > 0) {
      setItems(callResult);
    }
  }, [callResult]);

  const [newCallResult, setNewCallResult] = useState("");

  const [triedSubmit, setTriedSubmit] = useState(false);

  //handle errors
  const newCallResultNameError =
    triedSubmit &&
    (newCallResult?.trim().length === 0 || !validateName(newCallResult));
  const newCallResultNameErrorMessage =
    triedSubmit &&
    (newCallResult?.trim().length === 0 || !validateName(newCallResult))
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const isFromValid =
    newCallResult?.trim().length > 0 && validateName(newCallResult);

  const onCreateCallResult = () => {
    setTriedSubmit(true);
    if (!isFromValid) {
      return;
    }
    const payload = {
      description: newCallResult,
    };
    createCallResult(payload, getAllCallResultOptions);
  };
  // const DraggableItem = ({ id, index, moveItem, children }) => {
  //   const [, ref] = useDrag({
  //     type: "ITEM",
  //     item: { id, index },
  //   });

  //   const [, drop] = useDrop({
  //     accept: "ITEM",
  //     hover: (draggedItem) => {
  //       if (draggedItem.index !== index) {
  //         moveItem(draggedItem.index, index);
  //         draggedItem.index = index;
  //       }
  //     },
  //   });

  //   return <div ref={(node) => ref(drop(node))}>{children}</div>;
  // };

  const [items, setItems] = useState([]);

  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };

  const deleteCb = () => {
    getAllCallResultOptions();
  };

  const onDeleteCallResult = (id) => {
    deleteCallResult(id, deleteCb);
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
        >
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Create Result | Total Count :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#18a689",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( {callResult?.length ?? 0} )
                </Typography>{" "}
              </Typography>
            </AccordionSummary>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: "none" }),
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#6DD6C2",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                },
                borderRadius: "50%",
                color: "#212223",
              }}
            >
              <AddBoxIcon />
            </IconButton> */}
          </Box>
          {/* <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#6DD6C2",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#6DD6C2",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>

           
          </Drawer> */}
          <AccordionDetails>
            {callResult?.length > 0 ? (
              <Box sx={{ }}>
                 <Box  sx={{ display: { xs: "grid", sm: "flex" , md:'flex' }, flexWrap: { xs: "wrap", sm: "wrap" , md:'wrap', lg:'inherit' },  gap: { xs: "15px", sm: "20px" , md:'20px' }, alignItems:'center',
                border:'1px solid #ddd',               
                padding: { xs: "10px", sm: "18px" , md:'20px' },                 
                marginTop: { xs: "15px", sm: "20px" , md:'20px' }, 
                marginBottom: { xs: "15px", sm: "20px" , md:'20px' },
                borderRadius:"7px"
               }}>
              {/* <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#212223", fontWeight: 600, mt: 2 }}
              >
                Create New Call Result
              </Typography> */}
              <Box sx={{ display: { xs: "grid", sm: "flex" , md:'flex' },  gap: { xs: "15px", sm: "20px" , md:'20px' }, alignItems:'center',
                
               }}>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#6B7489",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Description
                </Typography> */}
                <TextField
                  margin="0"                  
                  fullWidth
                  size="small"
                  name="state"
                  type="text"
                  label="Description"                 
                  id="state"
                  value={newCallResult}
                  onChange={(event) => {
                    setNewCallResult(event.target.value);
                  }}
                  error={newCallResultNameError}
                  helperText={
                    newCallResultNameError && newCallResultNameErrorMessage
                  }
                  fontSize="15"
                  sx={{
                    
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                    },
                    "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"                  
                  onClick={onCreateCallResult}
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,                   
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
                <DndProvider backend={HTML5Backend}>
                  <Grid container spacing={0.5}>
                    {items?.map((item, index) => (
                      <Grid item xs={12} key={item.id}>
                        <DraggableItem
                          id={item.id}
                          index={index}
                          moveItem={moveItem}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: 1,
                              marginY: 0.5,
                              borderColor: "#676a6c4a",
                              backgroundColor: "#F4F6FA",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <MenuIcon
                                sx={{
                                  backgroundColor: "#ddd",
                                  p: 0.5,
                                  color: "#6B7489",
                                }}
                              />
                              <Typography
                                component="h2"
                                variant="h2"
                                sx={{
                                  fontSize: 14,
                                  color: "#0088cc",
                                  fontWeight: 600,
                                  ml: 1,
                                }}
                              >
                                {item?.description}
                              </Typography>
                            </Box>
                            <DeleteForeverIcon
                              onClick={() => {
                                onDeleteCallResult(item?.id);
                              }}
                              sx={{
                                color: "#e95555",
                                mr: 0.5,
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </DraggableItem>
                      </Grid>
                    ))}
                    {callResult?.length > 0 && (
                      <Grid item xs={12} md={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            mt: 2,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </DndProvider>
                <Modal open={openModal2} onClose={handleCloseModal2}>
                  <Box sx={style}>
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 17,
                        color: "#000",
                        fontWeight: 600,
                        mb: 3,
                      }}
                    >
                      Please select a call result to set in place of deleted
                      Call Result
                    </Typography>
                    <FormControl
                      size="small"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#18a689",
                          },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689!important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: 15,
                          "&.Mui-focused": {
                            color: "#18a689!important",
                            fontWeight: "600",
                            margin: "0",
                          },
                        }}
                      >
                        All
                      </InputLabel>
                      <Select
                        sx={{ backgroundColor: "#fff!important" }}
                        id="selectinfo"
                        label="All"
                        onChange={handleChange}
                        size="small"
                      >
                        <MenuItem value={1} sx={{ fontSize: 15 }}>
                          Active
                        </MenuItem>
                        <MenuItem value={2} sx={{ fontSize: 15 }}>
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "flex",
                          lg: "flex",
                        },
                        justifyContent: "end",
                        mt: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          fontWeight: 500,
                          textTransform: "capitalize",
                          pl: 2,
                          pr: 2,
                          mt: 2,
                          mr: 2,
                          minWidth: 40,
                          backgroundColor: "transparent",
                          borderColor: "#18a689!important",
                          border: 1,
                          color: "#18a689",
                          "&:hover": {
                            // Customize the hover color
                            backgroundColor: "transparent",
                            borderColor: "#32c3a3",
                          },
                        }}
                        onClick={handleCloseModal2}
                      >
                        Cancle
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          fontWeight: 500,
                          textTransform: "capitalize",
                          pl: 2,
                          pr: 2,
                          mt: 2,
                          minWidth: 20,
                          backgroundColor: "#18a689",
                          borderColor: "#18a689",
                          color: "#FFFFFF",
                          "&:hover": {
                            // Customize the hover color
                            backgroundColor: "#32c3a3",
                            borderColor: "#32c3a3",
                          },
                        }}
                      >
                        Ok
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            ) : (
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  fontSize: 18,
                  color: "#454647",
                  fontWeight: 600,
                }}
              >
                No Record Found!
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <Loader loading={isCreateLoading} />
      </Box>
    </>
  );
};
