// LoginScreen.js
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import {
  formatPhoneNumber,
  validateMobileNumber,
  validateName,
} from "../../helpers";

export const NewCampaignsAddData = (props) => {
  const { campaignInfo, setCampaignInfo, triedSubmit } = props;

  const userDetails = useSelector((state) => state?.profile?.allClients);

  //error handling

  const callerIdError =
    triedSubmit && campaignInfo?.callerId?.trim().length === 0;

  const callerIdErrorMessage =
    triedSubmit && campaignInfo?.callerId.trim().length === 0
      ? "Please enter valid caller id"
      : "";

  const agentNameError =
    triedSubmit && campaignInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && campaignInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const campaignNameError =
    triedSubmit && campaignInfo?.campaignName?.trim().length === 0;
  // (campaignInfo?.campaignName?.trim().length === 0 ||
  //   !validateName(campaignInfo?.campaignName));
  const campaignNameErrorMessage =
    triedSubmit && campaignInfo?.campaignName.trim().length === 0
      ? "Please enter valid campaign name"
      : "";

  const handleChange = (newValue) => {
    setCampaignInfo({
      ...campaignInfo,
      agentId: newValue.id,
    });
  };

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    if (campaignInfo?.agentId) {
      const defaultAgent = userDetails.find(
        (agent) => agent.id === campaignInfo.agentId
      );
      if (defaultAgent) {
        setDefaultValue(defaultAgent);
      }
    }
  }, [campaignInfo?.agentId, userDetails]);

  return (
    <>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              margin="0"
              fullWidth
              size="small"
              name="num"
              label="Caller Id Number"
              id="num"
              fontSize="15"
              value={
                formatPhoneNumber(campaignInfo?.callerId) ??
                campaignInfo?.callerId
              }
              error={callerIdError}
              helperText={callerIdError && callerIdErrorMessage}
              onChange={(e) =>
                setCampaignInfo({
                  ...campaignInfo,
                  callerId: e.target.value,
                })
              }
              inputProps={{ maxLength: 12 }}
              InputLabelProps={{
                sx: {
                  fontSize: "15px",
                  "&.Mui-focused": {
                    color: "#5fdbc4",
                    fontWeight: 600,
                  },
                },
              }}
              sx={{
                marginBottom: "10px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "95%",
                  lg: "95%",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              margin="0"
              fullWidth
              size="small"
              name="Campaign Name"
              type="text"
              label="Campaign Name"
              id="campaign_name"
              fontSize="15"
              value={campaignInfo?.campaignName}
              onChange={(e) =>
                setCampaignInfo({
                  ...campaignInfo,
                  campaignName: e.target.value,
                })
              }
              error={campaignNameError}
              helperText={campaignNameError && campaignNameErrorMessage}
              InputLabelProps={{
                sx: {
                  fontSize: "15px",
                  "&.Mui-focused": {
                    color: "#5fdbc4",
                    fontWeight: 600,
                  },
                },
              }}
              sx={{
                marginBottom: "10px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "95%",
                  lg: "95%",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              size="small"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "95%",
                  lg: "95%",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: agentNameError
                    ? "#d32f2f"
                    : "rgba(0, 0, 0, 0.23)",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6DD6C2!important",
                },
              }}
            >
              <Autocomplete
                id="combo-box-demo"
                options={userDetails}
                getOptionLabel={(option) => option.full_name}
                value={defaultValue}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                error={agentNameError}
                helperText={agentNameError && agentNameErrorMessage}
                size="small"
                disableClearable={true}
                sx={{
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#18a689!important",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#18a689",
                    },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#18a689",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#6DD6C2",
                    },
                  },
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} label="Select Agent" />
                    {agentNameError && (
                      <FormHelperText
                        sx={{
                          color: "#d32f2f",
                        }}
                      >
                        {agentNameErrorMessage}
                      </FormHelperText>
                    )}
                  </>
                )}
                renderOption={(props, option, { inputValue }) => {
                  return (
                    <li {...props} key={`${option.id}-${option.full_name}`}>
                      {option?.full_name}
                    </li>
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
