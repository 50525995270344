import React, { useEffect, useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const SearchCampaignsAddData = (props) => {
  // const [val, setVal] = useState([]);

  const { val, setVal } = props;

  const handleAdd = () => {
    setVal([...val, { field: "", type: "", value: "" }]);
  };

  const handleFieldChange = (value, i) => {
    const newData = [...val];
    newData[i].field = value;
    setVal(newData);
  };

  const handleTypeChange = (value, i) => {
    const newData = [...val];
    newData[i].type = value;
    setVal(newData);
  };

  const handleValueChange = (value, i) => {
    const newData = [...val];
    newData[i].value = value;
    setVal(newData);
  };

  const handleDelete = (i) => {
    const newData = [...val];
    newData.splice(i, 1);
    setVal(newData);
  };

  return (
    <>
      {val.map((data, i) => (
        <Box key={i} sx={{ mt: i == 0 ? 2 : 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={10} sm={10} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                     
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                        },
                      }}
                    >
                      Select Field
                    </InputLabel>
                    <Select
                      label="Select Field"
                      value={data.field}
                      onChange={(e) => handleFieldChange(e.target.value, i)}
                      size="small"
                      sx={{
                        width: "100%",
                        marginRight: "20px",
                        
                      }}
                    >
                      <MenuItem
                        value={"alternative_phone_number"}
                        sx={{ fontSize: 15 }}
                      >
                        Alternate Phone Number
                      </MenuItem>
                      <MenuItem value={"call_result"} sx={{ fontSize: 15 }}>
                        Call Result
                      </MenuItem>
                      <MenuItem
                        value={"call_result_description"}
                        sx={{ fontSize: 15 }}
                      >
                        Call Result Description
                      </MenuItem>
                      <MenuItem
                        value={"carrier_of_record"}
                        sx={{ fontSize: 15 }}
                      >
                        Carrier Of Record
                      </MenuItem>
                      <MenuItem value={"comments"} sx={{ fontSize: 15 }}>
                        Comments
                      </MenuItem>
                      <MenuItem value={"company_name"} sx={{ fontSize: 15 }}>
                        Company Name
                      </MenuItem>
                      <MenuItem value={"website"} sx={{ fontSize: 15 }}>
                        Company Website
                      </MenuItem>
                      <MenuItem value={"county"} sx={{ fontSize: 15 }}>
                        County Description
                      </MenuItem>
                      <MenuItem value={"modified"} sx={{ fontSize: 15 }}>
                        Created
                      </MenuItem>
                      <MenuItem value={"modified_by"} sx={{ fontSize: 15 }}>
                        Created By
                      </MenuItem>
                      <MenuItem value={"credit_rating"} sx={{ fontSize: 15 }}>
                        Credit Rating
                      </MenuItem>
                      <MenuItem
                        value={"date_list_produced"}
                        sx={{ fontSize: 15 }}
                      >
                        Date List Produced
                      </MenuItem>
                      <MenuItem value={"ein"} sx={{ fontSize: 15 }}>
                        EIN 1
                      </MenuItem>
                      <MenuItem value={"email"} sx={{ fontSize: 15 }}>
                        Email
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                     
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                        },
                      }}
                    >
                      Select Type
                    </InputLabel>
                    <Select
                      label="Select Type"
                      value={data.type}
                      onChange={(e) => handleTypeChange(e.target.value, i)}
                      size="small"
                      sx={{
                        width: "100%",
                        marginRight: "20px",
                       
                      }}
                    >
                      <MenuItem value={"startswith"} sx={{ fontSize: 15 }}>
                        Begins With
                      </MenuItem>
                      <MenuItem value={"days_gt"} sx={{ fontSize: 15 }}>
                        Days Greater Then
                      </MenuItem>
                      <MenuItem value={"days_lt"} sx={{ fontSize: 15 }}>
                        Days Less Then
                      </MenuItem>
                      <MenuItem value={"endswith"} sx={{ fontSize: 15 }}>
                        Ends With
                      </MenuItem>
                      <MenuItem value={"exact"} sx={{ fontSize: 15 }}>
                        Equal
                      </MenuItem>
                      <MenuItem value={"gte"} sx={{ fontSize: 15 }}>
                        Greater Or Equal
                      </MenuItem>
                      <MenuItem value={"gt"} sx={{ fontSize: 15 }}>
                        Greater
                      </MenuItem>
                      <MenuItem value={"icontains"} sx={{ fontSize: 15 }}>
                        Icontains
                      </MenuItem>
                      <MenuItem value={"in"} sx={{ fontSize: 15 }}>
                        In
                      </MenuItem>
                      <MenuItem value={"not_isnull"} sx={{ fontSize: 15 }}>
                        Is Not Null
                      </MenuItem>
                      <MenuItem value={"isnull"} sx={{ fontSize: 15 }}>
                        Is Null
                      </MenuItem>
                      <MenuItem value={"lte"} sx={{ fontSize: 15 }}>
                        Less Or Equal
                      </MenuItem>
                      <MenuItem value={"lt"} sx={{ fontSize: 15 }}>
                        Less
                      </MenuItem>
                      <MenuItem value={"not_startswith"} sx={{ fontSize: 15 }}>
                        Not Begins With
                      </MenuItem>
                      <MenuItem value={"not_endswith"} sx={{ fontSize: 15 }}>
                        Not Ends With
                      </MenuItem>
                      <MenuItem value={"not_exact"} sx={{ fontSize: 15 }}>
                        Not Equal
                      </MenuItem>
                      <MenuItem value={"not_icontains"} sx={{ fontSize: 15 }}>
                        Not Icontains
                      </MenuItem>
                      <MenuItem value={"not_in"} sx={{ fontSize: 15 }}>
                        Not In
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    margin="0"
                    fullWidth
                    size="small"
                    name="Value"
                    label="Search Value"
                    type="text"
                    id="Value"
                    value={data.value}
                    onChange={(e) => handleValueChange(e.target.value, i)}
                    fontSize="15"
                    InputLabelProps={{
                      sx: {
                        fontSize: "15px",
                        "&.Mui-focused": {
                          color: "#6DD6C2",
                        },
                      },
                    }}
                    sx={{
                      marginBottom: "10px",
                      width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              {i === 0 ? (
                <>
                  <Button
                    onClick={handleAdd}
                    type="submit"
                    variant="contained"
                    sx={{
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "40px",
                      height: "35px",
                      minWidth: "40px",                      
                      pl: 1.5,
                      pr: 1.5,
                      pt: 1.5,
                      pb: 1.5,
                      ml: 1,                     
                      borderRadius: "3px",
                      backgroundColor: "#6DD6C2",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                      },
                      borderColor: "#6DD6C2",
                      color: "#212223",
                    }}
                  >
                    <AddIcon sx={{ fontSize: "28px" }} />
                  </Button>
                </>
              ) : (
                // Show "Remove" button on subsequent filters
                <Button
                  onClick={() => handleDelete(i)}
                  type="submit"
                  variant="contained"
                  sx={{
                    fontWeight: 500,
                    textTransform: "capitalize",
                    width: "40px",
                    height: "35px",
                    minWidth: "40px",
                    pl: 1.5,
                    pr: 1.5,
                    pt: 1.5,
                    pb: 1.5,
                    ml: 1,
                    mb:1,
                    borderRadius: "3px",
                    backgroundColor: "#212223",
                    "&:hover": {
                      backgroundColor: "#212223",
                    },
                    borderColor: "#212223",
                    color: "#fff",
                  }}
                >
                  <DeleteForeverIcon sx={{ fontSize: "20px" }} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};
