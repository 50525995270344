// Action Types for getting Twilio access token
export const GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS = 'GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS';
export const GET_TWILIO_ACCESS_TOKEN_SUCCESS = 'GET_TWILIO_ACCESS_TOKEN_SUCCESS';
export const GET_TWILIO_ACCESS_TOKEN_FAILURE = 'GET_TWILIO_ACCESS_TOKEN_FAILURE';

export const MAKE_CALL_IN_PROGRESS = 'MAKE_CALL_IN_PROGRESS';
export const MAKE_CALL_SUCCESS = 'MAKE_CALL_SUCCESS';
export const MAKE_CALL_FAILURE = 'MAKE_CALL_FAILURE';

export const SET_DEVICE_STATE = 'SET_DEVICE_STATE';
export const UPDATE_CALLING_INFO = 'UPDATE_CALLING_INFO';
