// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Zoom,
  createFilterOptions,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "../Common/Calendar";
import { Loader } from "../Common";
import { useLocation } from "react-router";
import { checkAndReturnNone, formatPhoneNumber } from "../../helpers";
import { clearEvents, deleteCalendarEvent } from "../../store/actions";
const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const AppointmentCalendar = (props) => {
  const { getAllUser, onSearchCalendar, onCreateEvent } = props;

  const location = useLocation();

  const { isViewCalendar, data } = location.state || {};

  const userDetails = useSelector((state) => state?.profile?.allClients);
  const isLeadloading = useSelector((state) => state?.lead?.isLoading);

  const isLoading = useSelector((state) => state?.appointment?.isLoading);

  const isCalendarLoading = useSelector(
    (state) => state?.appointment?.isCalendarLoading
  );

  const calendarInfo = useSelector((state) => state?.appointment?.calendarInfo);

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [agentId, setAgentId] = useState(0);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isViewCalendar === true) {
      onSearchCalendar(data?.agent.id);
      setAgentId(data?.agent.id);
    }
  }, [isViewCalendar]);

  useEffect(() => {
    if (userInfo?.user_type === "Client") {
      onSearchCalendar(userInfo.id);
      setAgentId(userInfo.id);
    }
  }, []);

  // const filterOptionsAgent = (options) => {
  //   // Filter options based on the inputValue (matching the prefix)
  //   return options.filter((option) =>
  //     option.full_name.toLowerCase().startsWith(inputValueTwo?.toLowerCase())
  //   );

  //   // const filteredOptions = options.filter((option) =>
  //   //   option.full_name.toLowerCase().startsWith(inputValue.toLowerCase())
  //   // );
  //   // console.log("Filtered Options:", filteredOptions);
  // };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.full_name,
  });

  useEffect(() => {
    userInfo?.user_type !== "Client" && getAllUser();
    const jsonData = localStorage.getItem("newTabStateForCalendar");

    if (jsonData) {
      // Parse the JSON string back to an object
      const receivedData = JSON.parse(jsonData);
      // Do something with the data
      if (receivedData.isNewTab) {
        setAgentId(receivedData?.agent_id);
      }
      // Clear the data from localStorage (optional)
      localStorage.removeItem("newTabStateForCalendar");
    } else {
      dispatch(clearEvents());
    }
  }, []);

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "searchName":
        setAgentId(newValue.id);
        break;
      default:
        break;
    }
  };

  const onSearch = () => {
    onSearchCalendar(agentId);
  };

  function combineDateTimeFromObject(inputObject) {
    // Extract start and end date and time from the input object
    const { startDate, startTime, endDate, endTime } = inputObject;

    // Combine start date and time
    const startDatetimeString = `${startDate}T${startTime}:00Z`;

    // Combine end date and time
    const endDatetimeString = `${endDate}T${endTime}:00Z`;

    return {
      startDatetime: startDatetimeString,
      endDatetime: endDatetimeString,
    };
  }

  const createEvent = (eventInfo, cb = () => { }) => {
    const formattedDates = combineDateTimeFromObject(eventInfo);

    const callBack = () => {
      cb();
      onSearch();
    };

    let payload = {
      agent: agentId ?? 0,
      days: eventInfo.checkedDays ?? [],
      start_date_time: formattedDates.startDatetime,
      end_date_time: formattedDates.endDatetime,
      summary: eventInfo.eventDiscription ?? "",
    };
    //console.log("Event Details==>", payload);
    onCreateEvent(payload, callBack);
  };

  const onDeleteEvent = (id, cb = () => { }) => {
    dispatch(
      deleteCalendarEvent(id, () => {
        setTimeout(() => {
          onSearch();
        }, 1000);
      })
    );
  };

  return (
    <>
      {calendarInfo?.agent_data && (
        <Box
          sx={{
            margin: "0 20px",
            marginTop: "25px",
            boxShadow: 3,
            borderRadius: "10px!Important",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: 1,
                    gap: "15px",
                    borderColor: "#676a6c4a",
                    backgroundColor: "#90949f21",
                    borderTopLeftRadius: "10px!Important",
                    borderTopRightRadius: "10px!Important",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "#212223", pointerEvents: "auto" }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: 450,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 18,
                          color: "#212223",
                          fontWeight: 600,
                          flexWrap: "nowrap",
                        }}
                      >
                        Client Information
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                    sx={{
                      ...(open && { display: "none" }),
                      marginRight: "30px",
                      width: "40px",
                      height: "40px",
                      minWidth: "40px",
                      padding: "10px",
                      backgroundColor: "#6DD6C2",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                      },
                      borderRadius: "50%",

                      color: "#212223",
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
                <AccordionDetails>
                  <Box sx={{ pt: 0.5, pb: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Full Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {`${calendarInfo?.agent_data?.first_name} ${calendarInfo?.agent_data?.last_name}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Account Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(
                                calendarInfo?.agent_data?.account_name
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Phone Number
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {formatPhoneNumber(
                                calendarInfo?.agent_data?.phone_number
                              ) ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Amount Owed
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#5ae4cb",
                                fontWeight: 600,
                              }}
                            >
                              0
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Active
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.account_status
                                ? "True"
                                : "False"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Agency Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.agency_name ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Email
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.email ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                              }}
                            >
                              Plus Amount Owed
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                                lineHeight: "17px",
                              }}
                            >
                              {"None"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Address Street
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.street ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Address State
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.state ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Address City
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.city ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 14,
                                color: "#454647",
                                fontWeight: 600,
                                paddingLeft: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "15px",
                                },
                              }}
                            >
                              Address Zip Code
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {calendarInfo?.agent_data?.zip_code ?? "None"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
          boxShadow: 3,
          borderRadius: "10px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                borderRadius: "10px 10px 0 0!Important",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Calendar And Event Information
              </Typography>
            </AccordionSummary>



            {/* {userInfo?.user_type !== "Client" && !calendarInfo?.agent_data && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{
                  ...(open && { display: "none" }),
                  marginRight: "30px",
                  width: "40px",
                  height: "40px",
                  minWidth: "40px",
                  padding: "10px",
                  backgroundColor: "#6DD6C2",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                  },
                  borderRadius: "50%",

                  color: "#212223",
                }}
              >
                <SearchIcon />
              </IconButton>
            )} */}
          </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "15%",
                // width: "100%",
                flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens
              }}
            >
              <Box sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: {
                        xs: "90%",
                        sm: "80%",  
                        md: "75%",  
                        lg: "250%",  
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: false ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      options={userDetails}
                      getOptionLabel={(option) => option.full_name}
                      onChange={(event, newValue) => {
                        handleChange(event, newValue, "searchName");
                      }}
                      size="small"
                      disableClearable={true}
                      filterOptions={filterOptions}
                      sx={{
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#18a689!important",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Select Client/Agent" />
                          {/* {employeeNameError && (
                                <FormHelperText
                                  sx={{
                                    color: "#d32f2f",
                                  }}
                                >
                                  {employeeNameErrorMessage}
                                </FormHelperText>
                              )} */}
                        </>
                      )}
                      renderOption={(props, option, { inputValue }) => (
                        <li {...props} key={`${option.id}-${option.full_name}`}>
                          {option?.full_name}
                        </li>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Box>


              <Button
                type="submit"
                variant="contained"
                // fullWidth
                onClick={onSearch}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  mt: 3,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    // Customize the hover color
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>
            </Box>

          {/* <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          > */}
            {/* <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#6DD6C2",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#6DD6C2",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader> */}

            {/* <Box sx={{ padding: "0 20px" }}>
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#212223", fontWeight: 600, mt: 1 }}
              >
                Search Calendar
              </Typography>
              <Box sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "95%",
                        lg: "95%",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: false ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      options={userDetails}
                      getOptionLabel={(option) => option.full_name}
                      onChange={(event, newValue) => {
                        handleChange(event, newValue, "searchName");
                      }}
                      size="small"
                      disableClearable={true}
                      filterOptions={filterOptions}
                      sx={{
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#18a689!important",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#18a689",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Select Client/Agent" />
                          {/* {employeeNameError && (
                            <FormHelperText
                              sx={{
                                color: "#d32f2f",
                              }}
                            >
                              {employeeNameErrorMessage}
                            </FormHelperText>
                          )} */}
                        {/* </>
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        return (
                          <li
                            {...props}
                            key={`${option.id}-${option.full_name}`}
                          >
                            {option?.full_name}
                          </li>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              </Box> */}


              {/* <Button
                type="submit"
                variant="contained"
                fullWidth
                onClick={onSearch}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  mt: 4,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    // Customize the hover color
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>



              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bottom: "0",
                  alignItems: "center",
                }}
              ></Box>
            </Box> */}


          {/* </Drawer> */}
          <AccordionDetails
            sx={{
              padding: 2,
            }}
          >
            <Calendar
              events={calendarInfo?.event_array ?? []}
              onCreateEvent={createEvent}
              agentId={agentId}
              onDeleteEvent={onDeleteEvent}
            />
          </AccordionDetails>
        </Accordion>
        <Loader loading={isLoading || isLeadloading || isCalendarLoading} />
      </Box>
    </>
  );
};
