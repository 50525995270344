import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { campaignIdleTimeManager, idleTimeManager } from "../../store/actions/report";

export const CampaignUtilizationTime = () => {
  const [idleTime, setIdleTime] = useState(0);
  const [lastActiveTime, setLastActiveTime] = useState(new Date().getTime());
  const [isPageVisible, setIsPageVisible] = useState(true);

  const dispatch = useDispatch();

  const campaignLogId = useSelector(
    (state) => state?.campaign?.campaignDetails?.campaign_log_id
  );

  const campaignManageIdleTime = (payload) => {
    dispatch(
      campaignIdleTimeManager(payload, () => {
        // Reset the idle time
        setIdleTime(0);
      })
    );
  };

  const updateCallHistory = (payload) => {
    dispatch(
      updateCallHistory(payload, () => {
        // Reset the idle time
        setIdleTime(0);
      })
    );
  };

  const manageIdleTime = (payload) => {
    dispatch(
      idleTimeManager(payload, () => {
        // Reset the idle time
        setIdleTime(0);
      })
    );
  };

  const millisecondsToTime = (duration) => {
    let milliseconds = parseInt((duration % 1000) / 100);
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  useEffect(() => {
    let apiCallInProgress = false;

    const handleUserActivity = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - lastActiveTime;

      //  // Check if this is hit

      // Check if user has been inactive for more than 5 minutes (300000 milliseconds)
      if (elapsedTime > 300000 && isPageVisible) {  // Adjusted for quicker testing
        // Save the idle time
        setIdleTime(idleTime + elapsedTime);
        // 

        // Check if an API call is already in progress
        if (!apiCallInProgress) {
          apiCallInProgress = true;

          // Make the API call
          const campaignManageIdleTimePayload = {
            campaign_log: campaignLogId ?? 0,
            seconds: idleTime + elapsedTime ?? 0,
          };
          campaignManageIdleTime(campaignManageIdleTimePayload);
          // console.log("campaignManageIdleTimePayload Ran -- Payload", campaignManageIdleTimePayload);

          const manageIdleTimePayload = {
            seconds: idleTime + elapsedTime ?? 0,
          };
          manageIdleTime(manageIdleTimePayload);
          // console.log("manageIdleTimePayload Ran -- Payload", manageIdleTimePayload);

          apiCallInProgress = false;
        }
      }

      // Update the last active time
      setLastActiveTime(currentTime);
    };

    const handleMouseMove = () => {
      handleUserActivity();
    };

    const handleKeyPress = () => {
      handleUserActivity();
    };

    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);

      if (!document.hidden) {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - lastActiveTime;

        // Add elapsed time to idle time if the page was hidden
        if (!isPageVisible) {
          setIdleTime(idleTime + elapsedTime);
        }

        setLastActiveTime(currentTime);
      }
    };

    // Add event listeners
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keypress", handleKeyPress);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keypress", handleKeyPress);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [lastActiveTime, idleTime, isPageVisible]);

  return null;
};
