import React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DraggableItem = React.memo(({ id, index, moveItem, children }) => {
    const [, ref] = useDrag({
      type: 'ITEM',
      item: { id, index },
    });
  
    const [, drop] = useDrop({
      accept: 'ITEM',
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveItem(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
  
    return <div ref={(node) => ref(drop(node))}>{children}</div>;
  });