import { loginAuth } from "./authentication";
import { employee } from "./employee";
import { client } from "./client";
import { prospect } from "./prospects";
import { lead } from "./lead";
import { campaign } from "./campaign";
import { appointment } from "./appointment";
import { twillio } from "./twillio";
import { callback } from "./callback";
import { reports } from "./reports";
import { profile } from "./profile";
import { home } from "./home";
import { invoices } from "./invoices";
import { clientView } from "./clientView";

const AppReducer = {
  loginAuth,
  employee,
  client,
  prospect,
  lead,
  campaign,
  appointment,
  twillio,
  callback,
  reports,
  profile,
  home,
  invoices,
  clientView,
};

export default AppReducer;
