import {
  GET_CLIENT_DASHBOARD_FAILURE,
  GET_CLIENT_DASHBOARD_IN_PROGRESS,
  GET_CLIENT_DASHBOARD_SUCCESS,
} from "../actions/clientView/types";
import initialState from "../initialState";

export const clientView = (state = initialState.clientView, action) => {
  switch (action.type) {
    case GET_CLIENT_DASHBOARD_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case GET_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
        errorMessage: "",
      };
    case GET_CLIENT_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error.message,
      };
    default:
      return state;
  }
};
