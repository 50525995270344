import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Skeleton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
} from "@mui/material";
import { ProfileModal, UpdateTooltip } from "../Common";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { CampaignsTableHead } from "./CampaignsTableHead";
import { CampaignsTableToolbar } from "./CampaignsTableToolbar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { checkAndReturnNone } from "../../helpers";
import Tooltip from "@mui/material/Tooltip";
import { updateCampaignKey } from "../../store/actions";
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

export const CampaignsTable = (props) => {
  const {
    rows,
    onDelete,
    onChangeCampiagnKey,
    setPage,
    page,
    offset,
    setOffset,
    fetchMoreRecord,
    fetchMoreCampaingnData,
     campaignList,
     filteredRows
  } = props;
  const { format } = require("date-fns");

  const [isOpen, setIsOpen] = useState({ open: false, id: 0 });

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [selected, setSelected] = React.useState([]);
  const [isShow, setIsShow] = React.useState(false);
  const [selectedUserID, setSelectedUserID] = useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleDelete = (row) => {
  //   onDelete(row?.user?.id);
  // };

  const handleDelete = () => {
    selectedUserID && onDelete(selectedUserID);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const handleOpenDeleteDialog = (row) => {
    setSelectedUserID(row?.user?.id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelected([]);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    setOpenDeleteDialog(false);
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    // if (!event.target.closest(".show-button")) {
    //   // Exclude clicks on the "Show" button
    //   const selectedIndex = selected.indexOf(row?.operations?.id);
    //   let newSelected = [];

    //   if (selectedIndex === -1) {
    //     newSelected = newSelected.concat(selected, row?.operations?.id);
    //   } else if (selectedIndex === 0) {
    //     newSelected = newSelected.concat(selected.slice(1));
    //   } else if (selectedIndex === selected.length - 1) {
    //     newSelected = newSelected.concat(selected.slice(0, -1));
    //   } else if (selectedIndex > 0) {
    //     newSelected = newSelected.concat(
    //       selected.slice(0, selectedIndex),
    //       selected.slice(selectedIndex + 1)
    //     );
    //   }

    //   setSelected(newSelected);
    // }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const recordsFinished = (newPage + 1) * rowsPerPage >= rows?.length;

    if (recordsFinished) {
      //console.log("Records have finished.");
      const nextOffset = offset + 1;
      setOffset((prevState) => prevState + 1);
      fetchMoreRecord(nextOffset);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const campaignData = useSelector((state) => state?.campaign?.campaignList);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  // const handleScroll = (event) => {
  //   const target = event.target;
  //   const scrollThreshold = 100; // Adjust the threshold as needed

  //   if (
  //     target.scrollHeight - target.scrollTop <=
  //     target.clientHeight + scrollThreshold
  //   ) {
  //     // User has scrolled to the bottom
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  const [addRows, setAddRows] = React.useState(rows);

  // Fetch data when the page changes

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  const handleOpen = (id) => {
    setIsOpen({
      ...isOpen,
      open: true,
      id: id,
    });
  };

  const handleClose = () => {
    setIsOpen({
      ...isOpen,
      open: false,
      id: 0,
    });
  };

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const findCampaignStatusByid = (status) => {
    return (
      [
        {
          id: true,
          label: "Active",
        },
        {
          id: false,
          label: "Inactive",
        },
      ].find((st) => st.id == status) || null
    );
  };
  const sentinelRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchMoreCampaingnData();
      }
    }, { threshold: 1 });
  
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }
  
    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [rows]); // Add rows as a dependency to re-observe when rows change
  
  // const fetchMoreCampaingnData = async () => {
  //   if (campaignList.next != null) {
  //     await dispatch(getCampaignListMoreData());
  //   }
  // };
  
  let loading = useSelector((state) => state?.campaign?.isloading);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={{ maxHeight: 600 }}

          // onScroll={handleScroll}
        >
          <Table
            sx={{ border: 1, borderColor: "#ddd" }}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <CampaignsTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                
                        {stableSort(rows, getComparator(order, orderBy))?.map(
                          (row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                sx={{
                                  backgroundColor:
                                    index % 2 == 1 ? "#fff" : "#fff",
                                  borderBottom: "0!important",
                                  "&:hover": {
                                    backgroundColor: "#F4F6FA!important",
                                  },
                                }}
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.firstName}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  onClick={() => {
                                    handleOpen(row?.user);
                                    // navigate("/CampaignsShow", {
                                    //   state: {
                                    //     isFrom: "campaign",
                                    //     details: row?.user ?? 1,
                                    //   },
                                    // });
                                  }}
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    color: "#0088cc",
                                    width: "220px",
                                    paddingLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row?.user?.name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#0088cc",

                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingX: 0,
                                  }}
                                  align="left"
                                >
                                  {userInfo?.user_type === "Administration" ? (
                                    <Tooltip
                                      sx={{
                                        marginTop: "0!important",
                                        " & .MuiTooltip-tooltip": {
                                          marginTop: "0!important",
                                        },
                                        backgroundColor: "#ffffff",
                                        borderRadius: "5px",
                                        borderColor: "black",
                                      }}
                                      arrow
                                      placement="bottom-start"
                                      PopperProps={{
                                        disablePortal: false,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={
                                        tooltipID === row?.operations?.id
                                          ? true
                                          : false
                                      }
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title={
                                        <UpdateTooltip
                                          onClose={handleTooltipClose}
                                          label={"Status"}
                                          onSubmit={(value) => {
                                            onChangeCampiagnKey(
                                              row?.operations?.id,
                                              value,
                                              () => {
                                                handleTooltipClose();
                                              }
                                            );
                                          }}
                                          selectedKey={
                                            findCampaignStatusByid(row.status)
                                              ?.label
                                          }
                                          inputType={"SelectOptions"}
                                          labelKey={"label"}
                                          optionsData={[
                                            {
                                              id: true,
                                              label: "Active",
                                            },
                                            {
                                              id: false,
                                              label: "Inactive",
                                            },
                                          ]}
                                          value={data}
                                          setValue={setData}
                                        />
                                      }
                                    >
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          const val = checkAndReturnNone(
                                            row.status
                                          );
                                          setData(val);
                                          handleTooltipOpen(
                                            row?.operations?.id
                                          );
                                        }}
                                        sx={{
                                          fontSize: 15,
                                          color: "#0088cc",
                                          fontWeight: 500,
                                          borderStyle: "dashed",
                                          borderWidth: 1,
                                          borderColor: "#0088cc",
                                          borderTop: 0,
                                          borderRight: 0,
                                          borderLeft: 0,
                                          maxWidth: "fit-content",
                                        }}
                                      >
                                        {row.status ? "Active" : "Inactive"}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight: 500,
                                          fontSize: 14,
                                          color: "#6B7489",
                                        }}
                                      >
                                        {row.status ? "Active" : "Inactive"}
                                      </Typography>
                                    </>
                                  )}

                                  {/* {row.status ? "Active" : "Inactive"} */}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.amountOwed}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    width: "35px",
                                    wordWrap: "break-word",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row.accountName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row.twilioCid)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.createdBy}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  { row?.createdAt ?  format(
                                    new Date(row?.createdAt),
                                    "MMMM d, yyyy, h:mm a"
                                  ) ?? row?.createdAt : "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.modifiedBy}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingX: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  { row?.modifiedAt ? format(
                                    new Date(row?.modifiedAt),
                                    "MMMM d, yyyy, h:mm a"
                                  ) ?? row?.modifiedAt : "None"}
                                </TableCell>
                                {userInfo?.user_type === "Administration" && (
                                  <TableCell
                                    align="center"
                                    sx={{ borderBottom: "0" }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        width: "20px",
                                        height: "20px",
                                        minWidth: "30px",
                                        pl: 1.5,
                                        pr: 1.5,
                                        pt: 1.5,
                                        pb: 1.5,
                                        m:.5,
                                        backgroundColor: "#6B7489",
                                        "&:hover": {
                                          backgroundColor: "#6B7489",
                                        },
                                        borderColor: "#6B7489",
                                        color: "#fff",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // Prevent event propagation to TableRow
                                        navigate("/NewCampaigns", {
                                          state: {
                                            isEdit: true,
                                            campaignData: row?.user,
                                            isViewMap: false,
                                          },
                                        });
                                      }}
                                      className="show-button" // Add a class to identify the "Show" button
                                    >
                                      <DriveFileRenameOutlineIcon
                                        sx={{ fontSize: "22px" }}
                                      />
                                    </Button>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        fontWeight: 500,
                                        textTransform: "capitalize",
                                        width: "20px",
                                        height: "20px",
                                        minWidth: "30px",
                                        pl: 1.5,
                                        pr: 1.5,
                                        pt: 1.5,
                                        pb: 1.5,
                                        ml: 1,
                                        m:.5,
                                        backgroundColor: "#6B7489",
                                        "&:hover": {
                                          backgroundColor: "#6B7489",
                                        },
                                        borderRadius: "3px",
                                        borderColor: "#6B7489",
                                        color: "#fff",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation to TableRow

                                        // handleDelete(row);
                                        handleOpenDeleteDialog(row);
                                      }}
                                      className="show-button" // Add a class to identify the "Show" button
                                    >
                                      <DeleteIcon sx={{ fontSize: "19px" }} />
                                    </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        )}
                       <tr ref={sentinelRef}></tr>
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        {/* <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        /> */}
      </Paper>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Campaign</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the Campaign?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#212223",
              borderColor: "#212223",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#212223",
                borderColor: "#212223",
              },
            }}
            onClick={handleConfirmDelete}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpen.open}
        onClose={handleClose}
        style={{
          display: "flex!important",
          justifyContent: "center!important",
          alignItems: "flex-start!important",
          paddingTop: "40px",
          borderColor: "#fff!important",
          "&.css-hz1bth-MuiDialog-container": {
            alignItems: "flex-start!important",
          },
        }}
      >
        <DialogContent>
          <Typography variant="h6" component="div" gutterBottom>
            Auto Calling Prompt!
          </Typography>
          <CloseIcon
            onClick={() => {
              handleClose();
            }}
            sx={{
              fontSize: "20px",
              position: "absolute",
              top: 15,
              right: 15,
              color: "#5f5f5f",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              backgroundColor: "#fff",
              borderColor: "#6DD6C2",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#fff",
              },
            }}
            onClick={() => {
              // Handle Manual Calling
              navigate("/CampaignsShow", {
                state: {
                  isFrom: "campaign",
                  details: isOpen.id ?? 1,
                  isAutoCalling: false,
                },
              });
              handleClose();
            }}
          >
            Manual Calling
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={() => {
              // Handle Auto Calling
              navigate("/CampaignsShow", {
                state: {
                  isFrom: "campaign",
                  details: isOpen.id ?? 1,
                  isAutoCalling: true,
                },
              });
              handleClose();
            }}
          >
            Auto Calling
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
