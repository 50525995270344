// LoginScreen.js
import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useLocation, useNavigate } from "react-router";
import {
  CALL_RESULT,
  formatDate,
  formatPhoneNumber,
  getLastAppWithName,
} from "../../helpers";
import { useSelector } from "react-redux";

export const ShowProspect = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { prospectInfoData } = location.state || {};

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  const getCallResultLabel = (id) => {
    const result = callResult.find((item) => item.id === id);
    return result ? result.description : "Unknown"; // Return "Unknown" if id not found in CALL_RESULT
  };

  const allUsers = useSelector((state) => state?.prospect?.user_details?.data);

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
        }}
      >
        <Box>
          <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: "15px",
                borderBottom: 1,
                borderColor: "#676a6c4a",
                backgroundColor: "#90949f21",
                borderTopLeftRadius: "10px!Important",
                borderTopRightRadius: "10px!Important",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                >
                  Prospects Information
                </Typography>
              </AccordionSummary>
              <Box sx={{ mr: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/CreateProspects", {
                      state: {
                        isEdit: true,
                        prospectInfoData: prospectInfoData,
                      },
                    });
                  }}
                  sx={{
                    width: 90,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    fontWeight: 600,
                    color: "#212223",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  <BorderColorIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Edit
                </Button>
              </Box>
            </Box>
            <AccordionDetails>
              <Box
                sx={{
                  padding: "20px 10px",
                }}
              >
                <Box>
                  <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Phone Number -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {formatPhoneNumber(
                              prospectInfoData?.phone_number
                            ) ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Executive Title -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.title ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Executive Last Name -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.last_name ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Company Website -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: prospectInfoData?.website
                                ? "#0088cc"
                                : "#DD1144",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.website ?? "Empty"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Primary Sic Code Description -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.sic_code_description ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Primary Naics Code Description -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.naics_code_description ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Modified By -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#454647",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.modified_by?.full_name ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Alternative Phone Number -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {formatPhoneNumber(
                              prospectInfoData?.alternative_phone_number
                            ) ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Executive First Name -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.first_name ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Company Name -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.company_name ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Primary Sic Code -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.sic_code ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Primary Naics Code -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.naics_code ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Comments -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.comments ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Modified At -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#454647",
                              fontWeight: 500,
                            }}
                          >
                            {formatDate(prospectInfoData?.modified_at) ??
                              "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: "15px",
                borderBottom: 1,
                borderColor: "#676a6c4a",
                backgroundColor: "#90949f21",
                borderTopLeftRadius: "10px!Important",
                borderTopRightRadius: "10px!Important",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                >
                  Address Information
                </Typography>
              </AccordionSummary>
            </Box>
            <AccordionDetails>
              <Box
                sx={{
                  padding: "20px 10px",
                }}
              >
                <Box>
                  <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Location Address -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.address ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Location State -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.state ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            County Description -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.county ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Location City -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.city ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Location Zip Code -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.zip_code ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: "15px",
                borderBottom: 1,
                borderColor: "#676a6c4a",
                backgroundColor: "#90949f21",
                borderTopLeftRadius: "10px!Important",
                borderTopRightRadius: "10px!Important",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                >
                  Location Information
                </Typography>
              </AccordionSummary>
            </Box>
            <AccordionDetails>
              <Box
                sx={{
                  padding: "20px 10px",
                }}
              >
                <Box>
                  <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Latitude -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.latitude ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Longitude -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.longitude ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ mt: 3, mb: 3 }}>
          <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: "15px",
                borderBottom: 1,
                borderColor: "#676a6c4a",
                backgroundColor: "#90949f21",
                borderTopLeftRadius: "10px!Important",
                borderTopRightRadius: "10px!Important",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                >
                  Additional Information
                </Typography>
              </AccordionSummary>
            </Box>
            <AccordionDetails>
              <Box
                sx={{
                  padding: "20px 10px",
                }}
              >
                <Box>
                  <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Renewal Date -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.renewal_date ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Call Result -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {getCallResultLabel(
                              prospectInfoData?.call_result
                            ) ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Credit Rating -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.credit_rating ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Carrier Of Record -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.carrier_of_record ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Square Footage -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.square_footage ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Call Result Description -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.call_result_description ??
                              "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Last Appointment With -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {getLastAppWithName(
                              prospectInfoData.last_appointment_with,
                              allUsers
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Location Employee Size Actual -
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 13,
                              color: "#0088cc",
                              fontWeight: 500,
                            }}
                          >
                            {prospectInfoData?.employee_size ?? "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};
