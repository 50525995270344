// LoginScreen.js
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { MapboxMap } from "./MapboxMap";
import { ProspectsList } from "../ProspectsComponent";
import { Loader } from "../Common";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getAllClients } from "../../store/actions/profile";
import { ViewMap } from "./ViewMap";

export const NewCampaigns = (props) => {
  const { prospectInfoData, onSearchProspects, onCreateCampaign, getAllUser } =
    props;
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [counties, setCounties] = useState([]);
  const [circles, setCircles] = useState([]);

  const { isEdit, campaignData } = location.state || {};

  const [isViewMap, setIsViewMap] = useState(false);
  const [viewMapData, setViewMapData] = useState({});

  useEffect(() => {
    // dispatch(clearEvents());
    const jsonData = localStorage.getItem("newTabStateForViewMap");

    if (jsonData) {
      // Parse the JSON string back to an object
      const receivedData = JSON.parse(jsonData);
      // Do something with the data
      if (receivedData.isViewMap) {
        setIsViewMap(true);
        setViewMapData(receivedData?.campaignInfo);
      } else {
        setIsViewMap(false);
        setViewMapData({});
      }
    }
  }, []);

  // const rows = [
  //   createData(
  //     "view",
  //     "firstname",
  //     "lastname",
  //     "number",
  //     "siscode",
  //     "naicscode",
  //     "size",
  //     "footage",
  //     "result"
  //   ),
  //   createData(
  //     "view",
  //     "firstname",
  //     "lastname",
  //     "number",
  //     "siscode",
  //     "naicscode",
  //     "size",
  //     "footage",
  //     "result"
  //   ),
  //   createData(
  //     "view",
  //     "firstname",
  //     "lastname",
  //     "number",
  //     "siscode",
  //     "naicscode",
  //     "size",
  //     "footage",
  //     "result"
  //   ),
  // ];

  useEffect(() => {
    // getAllUser();
    if (isViewMap === false) {
      dispatch(getAllClients());
    } else {
      localStorage.removeItem("newTabStateForViewMap");
    }
  }, [isViewMap]);

  const callBackFun = () => {
    isEdit &&
      setTimeout(() => {
        navigate(-1);
      }, 500);
  };

  const createCampaign = (payload) => {
    //console.log("payload=====>",payload);
    onCreateCampaign(payload, callBackFun);
  };

  const isloading = useSelector((state) => state?.campaign?.isloading);

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          boxShadow: 1,
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", margin: "-4px 0!important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%", margin: "-4px 0!important" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                County Selection Map
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails
            sx={{
              padding: 0,
              overflow: "hidden",
            }}
          >
            {isViewMap ? (
              <ViewMap
                counties={counties}
                setCounties={setCounties}
                setCircles={setCircles}
                circles={circles}
                campaignInfo={viewMapData}
              />
            ) : (
              <MapboxMap
                counties={counties}
                setCounties={setCounties}
                setCircles={setCircles}
                circles={circles}
                campaignInfo={campaignData}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Loader loading={isloading} />
        {/* <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%", margin: "-4px 0!important" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Campaign Query Builder
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails>
            <NewCampaignsAddData />
            <Box
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                alignItems: "center",
                gap: "10px",
                mt: 1.5,
                mb: 1.5,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  fontWeight: 600,
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search Prospects
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  fontWeight: 600,
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search Campaign
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#212223",
                  borderColor: "#212223",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#212223",
                    borderColor: "#212223",
                  },
                }}
              >
                Clear Query
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion> */}

        {!isViewMap && (
          <ProspectsList
            onSearchProspects={onSearchProspects}
            prospectInfoData={prospectInfoData}
            counties={counties}
            createCampaign={createCampaign}
            circles={circles}
            isFromCampaign={true}
            isEdit={isEdit}
            campaignData={campaignData}
            style={{ width: "100%" }}
          />
        )}

        {/* <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              paddingBottom: { xs: "15px", sm: "0px", md: "0px" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Filtered Prospects List | Total Prospects Count :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#5ae4cb",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( 26574 )
                </Typography>{" "}
              </Typography>

              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                paddingRight: 10,
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  width: 130,
                  marginRight: 2,
                  backgroundColor: "#1ab394",
                  borderColor: "#1ab394",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Mass Update
              </Button>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  width: 100,
                  backgroundColor: "#1ab394",
                  borderColor: "#1ab394",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Export
              </Button>
            </Box>
            </AccordionSummary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: 130,
                  textTransform: "capitalize",
                  marginRight: 1,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  fontWeight: 600,
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Mass Update
              </Button>
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  height: "37px",
                  width: 100,
                  backgroundColor: "#fff",
                  borderColor: "#6DD6C2",
                  textTransform: "capitalize",
                  border: 2,
                  fontWeight: 600,
                  color: "#1ab394",
                  size: "small",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2!important",
                    color: "#212223",
                  },
                }}
              >
                Export
                <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
              </Button>
            </Box>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            ></Box>
          </AccordionDetails>
        </Accordion> */}
      </Box>
    </>
  );
};
