// Types
import {
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_ERROR_RESET,
  VERIFYOTP_IN_PROGRESS,
  VERIFYOTP_SUCCESS,
  VERIFYOTP_FAILURE,
  VERIFYOTP_ERROR_RESET,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT_IN_PROGRESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  QRCODE_IN_PROGRESS,
  QRCODE_SUCCESS,
  QRCODE_FAILURE,
  VERIFY_QR_OTP_IN_PROGRESS,
  VERIFY_QR_OTP_SUCCESS,
  VERIFY_QR_OTP_FAILURE,
  USER_AUTHENTICATION_IN_PROGRESS,
  USER_AUTHENTICATION_SUCCESS,
  USER_AUTHENTICATION_FAILURE,
  VERIFY_QR_OTP_ERROR_RESET,
  VERIFY_QR_OTP_PROFILE_IN_PROGRESS,
  VERIFY_QR_OTP_PROFILE_SUCCESS,
  VERIFY_QR_OTP_PROFILE_FAILURE,
  VERIFY_QR_OTP_PROFILE_ERROR_RESET,
} from "../actions/authentication/types";
import initialState from "../initialState";

export const loginAuth = (state = initialState.loginAuth, action) => {
  switch (action.type) {
    case LOGIN_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        user_details: {},
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user_details: {},
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case LOGOUT:
      return {
        ...state,
        user_details: {},
        isLoggedIn: false,
        loading: false,
        error: false,
        errorMessage: "",
      };

    case LOGOUT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user_details: {},
        isLoggedIn: false,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case LOGIN_ERROR_RESET:
      return {
        ...state,
        user_details: {},
        loading: false,
        error: false,
        errorMessage: "",
      };
    case VERIFYOTP_IN_PROGRESS:
      return {
        ...state,
        verifyOTP: {
          isLoading: true,
          error: false,
          errorMessage: "",
        },
      };
    case VERIFYOTP_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isRecord:action?.data?.is_record,
        user_details: action?.data?.user,
        verifyOTP: {
          isLoading: false,
          error: false,
          errorMessage: "",
        },
      };
    case VERIFYOTP_FAILURE:
      return {
        ...state,
        verifyOTP: {
          isLoading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
    case VERIFYOTP_ERROR_RESET:
      return {
        ...state,
        verifyOTP: {
          isLoading: false,
          error: false,
          errorMessage: "",
        },
      };

      case QRCODE_IN_PROGRESS:
        return {
          ...state,
          qrCode: {
            isLoading: true,
            error: false,
            errorMessage: "",
          },
        };
      case QRCODE_SUCCESS:
        return {
          ...state,
          qrCode: {
            isLoading: false,
            error: false,
            errorMessage: "",
            qrUrl:action?.data?.qr_code_path,
            userId:action?.data?.user_id
          },
        };
      case QRCODE_FAILURE:
        return {
        ...state,
        qrCode: {
          isLoading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
      case VERIFY_QR_OTP_IN_PROGRESS:
        return {
          ...state,
          verifyQrOTP: {
            isLoading: true,
            error: false,
            errorMessage: "",
          },
        };
      case VERIFY_QR_OTP_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          isRecord:action?.data?.is_record,
          user_details: action?.data?.user,
          verifyQrOTP: {
            isLoading: false,
            error: false,
            errorMessage: "",
          },
        };
      case VERIFY_QR_OTP_FAILURE:
        return {
          ...state,
          verifyQrOTP: {
            isLoading: false,
            error: true,
            errorMessage: action?.error?.message,
          },
        };
        case VERIFY_QR_OTP_ERROR_RESET:
      return {
        ...state,
        verifyQrOTP: {
          isLoading: false,
          error: false,
          errorMessage:""
        },
      };

      case VERIFY_QR_OTP_PROFILE_IN_PROGRESS:
        return {
          ...state,
          verifyQrOTP: {
            isLoading: true,
            error: false,
            errorMessage: "",
          },
        };
      case VERIFY_QR_OTP_PROFILE_SUCCESS:
        return {
          ...state,
          verifyQrOTP: {
            isLoading: false,
            error: false,
            errorMessage: "",
          },
        };
      case VERIFY_QR_OTP_PROFILE_FAILURE:
        return {
          ...state,
          verifyQrOTP: {
            isLoading: false,
            error: true,
            errorMessage: action?.error?.message,
          },
        };
      case VERIFY_QR_OTP_PROFILE_ERROR_RESET:
      return {
        ...state,
        verifyQrOTP: {
          isLoading: false,
          error: false,
          errorMessage: ""
        },
      };


        case USER_AUTHENTICATION_IN_PROGRESS:
          return {
            ...state,
            authType: {
              isLoading: true,
              error: false,
              errorMessage: "",
            },
          };
        case USER_AUTHENTICATION_SUCCESS:
          return {
            ...state,
            authType: {
              isLoading: false,
              error: false,
              errorMessage: "",
              twoFAAuth:action?.data?.two_fa_authentication_enable,
              twoWayAuth:action?.data?.two_way_authentication,
              resetAuth:action.data?.reset_auth,
            },
          };
        case USER_AUTHENTICATION_FAILURE:
          return {
            ...state,
            authType: {
              isLoading: false,
              error: true,
              errorMessage: action?.error?.message,
            },
          };



    case FORGOT_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        forgetPassord: {
          isLoading: true,
          error: false,
          errorMessage: "",
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassord: {
          isLoading: false,
          error: false,
          errorMessage: "",
        },
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgetPassord: {
          isLoading: false,
          error: true,
          errorMessage: action?.error?.message,
        },
      };
    default:
      return state;
  }
};
