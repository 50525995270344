import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";

export const AppointmentsTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const headCells =
    userInfo?.user_type !== "Client"
      ? [
          {
            id: "operations",
            numeric: false,
            disablePadding: false,
            label: "Operations",
          },
          {
            id: "view",
            numeric: false,
            disablePadding: false,
            label: "View Calendar",
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
          },
          {
            id: "fullName",
            numeric: false,
            disablePadding: false,
            label: "Full Name",
          },
          {
            id: "companyName",
            numeric: false,
            disablePadding: false,
            label: "Company Name",
          },
          {
            id: "phoneNumber",
            numeric: false,
            disablePadding: false,
            label: "Phone Number",
          },
          {
            id: "agent",
            numeric: false,
            disablePadding: false,
            label: "Agent",
          },
          {
            id: "agencyName",
            numeric: false,
            disablePadding: false,
            label: "Agency Name",
          },
          {
            id: "accountName",
            numeric: false,
            disablePadding: false,
            label: "Account Name",
          },
          {
            id: "campaign",
            numeric: false,
            disablePadding: false,
            label: "Campaign",
          },
          {
            id: "appointmentDate",
            numeric: false,
            disablePadding: false,
            label: "Appointment Date",
          },
          {
            id: "appointmentTime",
            numeric: false,
            disablePadding: false,
            label: "Appointment Time",
          },
          {
            id: "appointmentType",
            numeric: false,
            disablePadding: false,
            label: "Appointment Type",
          },
          {
            id: "createdBy",
            numeric: false,
            disablePadding: false,
            label: "Created By",
          },
          {
            id: "modifiedBy",
            numeric: false,
            disablePadding: false,
            label: "Modified By",
          },
          {
            id: "modifiedDateTime",
            numeric: false,
            disablePadding: false,
            label: "Modified Date/Time",
          },
          {
            id: "alternatePhoneNumber",
            numeric: false,
            disablePadding: false,
            label: "Alternate Phone Number",
          },
          {
            id: "address",
            numeric: false,
            disablePadding: false,
            label: "Address",
          },
          {
            id: "city",
            numeric: false,
            disablePadding: false,
            label: "City",
          },
          {
            id: "state",
            numeric: false,
            disablePadding: false,
            label: "State",
          },
          {
            id: "zipCode",
            numeric: false,
            disablePadding: false,
            label: "Zip Code",
          },
          {
            id: "comment",
            numeric: false,
            disablePadding: false,
            label: "Comment",
          },
        ]
      : [
          {
            id: "operations",
            numeric: false,
            disablePadding: false,
            label: "Operations",
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
          },
          {
            id: "fullName",
            numeric: false,
            disablePadding: false,
            label: "Full Name",
          },
          {
            id: "companyName",
            numeric: false,
            disablePadding: false,
            label: "Company Name",
          },
          {
            id: "phoneNumber",
            numeric: false,
            disablePadding: false,
            label: "Phone Number",
          },
          {
            id: "agent",
            numeric: false,
            disablePadding: false,
            label: "Agent",
          },
          {
            id: "agencyName",
            numeric: false,
            disablePadding: false,
            label: "Agency Name",
          },
          {
            id: "accountName",
            numeric: false,
            disablePadding: false,
            label: "Account Name",
          },
          {
            id: "campaign",
            numeric: false,
            disablePadding: false,
            label: "Campaign",
          },
          {
            id: "appointmentDate",
            numeric: false,
            disablePadding: false,
            label: "Appointment Date",
          },
          {
            id: "appointmentTime",
            numeric: false,
            disablePadding: false,
            label: "Appointment Time",
          },
          {
            id: "appointmentType",
            numeric: false,
            disablePadding: false,
            label: "Appointment Type",
          },
          {
            id: "createdBy",
            numeric: false,
            disablePadding: false,
            label: "Created By",
          },
          {
            id: "modifiedBy",
            numeric: false,
            disablePadding: false,
            label: "Modified By",
          },
          {
            id: "modifiedDateTime",
            numeric: false,
            disablePadding: false,
            label: "Modified Date/Time",
          },
          {
            id: "alternatePhoneNumber",
            numeric: false,
            disablePadding: false,
            label: "Alternate Phone Number",
          },
          {
            id: "address",
            numeric: false,
            disablePadding: false,
            label: "Address",
          },
          {
            id: "city",
            numeric: false,
            disablePadding: false,
            label: "City",
          },
          {
            id: "state",
            numeric: false,
            disablePadding: false,
            label: "State",
          },
          {
            id: "zipCode",
            numeric: false,
            disablePadding: false,
            label: "Zip Code",
          },
          {
            id: "comment",
            numeric: false,
            disablePadding: false,
            label: "Comment",
          },
        ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingLeft: 2,
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "view" ||
            headCell.id === "operations" ||
            headCell.id === "resetPassword" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AppointmentsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
