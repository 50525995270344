// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useDispatch, useSelector } from "react-redux";
import { Loader, TwilioVoice, getTwilioDevice } from "../Common";
import { DEVICE_STATE } from "../../helpers";
import CallIcon from "@mui/icons-material/Call";
import { updateCampaignKey } from "../../store/actions";
import { commercialInvoiceSearch } from "../../store/actions/invoices";
import { ClientInvoicesParentTable } from "./ClientInvoicesParentTable";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const ClientInvoices = (props) => {
  const [selectedFilter, setSelectedFilter] = useState();
  const [filterCampaignName, setFilterCampaignName] = useState("");

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);
  const isLoading = useSelector((state) => state?.invoices.isGenerateLoading);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNameFilterChange = (event) => {
    setFilterCampaignName(event.target.value);
  };

  useEffect(() => {
    const payload = {
      status: "all",
      agent: userInfo?.id ?? 0,
      from_date: null,
      to_date: null,
    };
    dispatch(commercialInvoiceSearch(payload));
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
          borderRadius: "10px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "10px!Important",
              borderTopRightRadius: "10px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Invoices
              </Typography>
            </AccordionSummary>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "20px 0px 0px 0px",
                  sm: "20px 0px 0px 0px",
                  md: "20px 0px 0px 0px",
                  lg: "20px 0px 0px 0px",
                },
              }}
            >
              <ClientInvoicesParentTable />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Loader loading={isLoading} />
      </Box>
    </>
  );
};
