import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  Link,
  createTheme,
  ThemeProvider,
  Button,
  Slide,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import { VerifyOtpErrorReset } from "../../../store/actions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1ab394", // Green color
    },
    background: {
      default: "#ffffff", // White color
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const OtpInput = (props) => {
  const { isOpen, onSubmit, onClose, onResent, userEmail } = props;
  const [otp, setOtp] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const isOtpError = useSelector((state) => state?.loginAuth?.verifyOTP?.error);
  const otpErrorMessage = useSelector(
    (state) => state?.loginAuth?.verifyOTP?.errorMessage
  );
  const dispatch = useDispatch();

  const handleOtpChange = (newValue) => {
    setIsSubmit(false);
    setOtp(newValue);
    dispatch(VerifyOtpErrorReset());
  };

  const isError = isSubmit && otp?.trim()?.length < 6;
  const OtpError = "Please enter a valid OTP";
  const handleVerify = () => {
    setIsSubmit(true);
    onSubmit(otp);
  };

  const handleClose = () => {
    setOtp("");
    setIsSubmit(false);
    onClose();
  };

  const resentClick = () => {
    setIsSubmit(false);
    dispatch(VerifyOtpErrorReset());
    onResent("resend");
  };

  const maskEmail = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex <= 3) {
      return email;
    }

    const username = email.substring(0, 2) + "*".repeat(atIndex - 2);
    const domain = email.substring(atIndex);

    return username + domain;
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        transitionDuration={{
          enter: 250,
          exit: 250,
        }}
        maxWidth="xs"
        PaperProps={{ style: { overflow: "hidden" } }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          padding={2}
          width={340}
          mx={2}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              color: "#000",
              marginBottom: "5px",
              textAlign: "center",
            }}
          >
            OTP Verification
          </Typography>
          <Typography
            variant="body1"
            marginBottom={2}
            sx={{
              fontStyle: "normal",
              fontWeight: 500,
              color: "#000000cf",
              fontSize: "15px",
              lineHeight: "24px",
              textAlign: "center",
            }}
          >
            An OTP has been sent to your entered email{" "}
            <strong>{maskEmail(userEmail)}</strong>.
          </Typography>
          <MuiOtpInput
            length={6}
            value={otp}
            onChange={handleOtpChange}
            marginBottom={2}
          />
          {(isError || isOtpError) && (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "600",
                color: "#ff0000",
                fontSize: 10,
              }}
            >
              {isOtpError ? otpErrorMessage : OtpError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // sx={{
            //   mt: 1,
            //   mb: 1,
            //   backgroundColor: "#18a689",
            //   borderColor: "#18a689",
            //   color: "#fff",
            //   fontWeight: 600,
            //   padding: "7px",
            //   borderRadius: "5px",
            //   textTransform: "uppercase",
            //   marginBottom: "8px",
            //   width: "100%",
            //   display: "block",
            //   textAlign: "center",
            //   "&:hover": {
            //     backgroundColor: "#32c3a3",
            //     borderColor: "#32c3a3",
            //   },
            // }}
            sx={{
              mt: 1,
              mb: 1,
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              fontWeight: 500,
              padding: "7px",
              borderRadius: "5px",
              textTransform: "uppercase",
              marginBottom: "8px",
              width: "100%",
              display: "block",
              textAlign: "center",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={handleVerify}
          >
            Verify
          </Button>
          <Typography
            variant="body2"
            sx={{
              fontSize: "13px",
              textAlign: "center",
              display: "block",
              color: "#000000b3",
              fontWeight: 500,
              lineHeight: "17px",
            }}
          >
            Didn't receive the code? <br />
            <Link
              onClick={resentClick}
              sx={{
                color: "#6DD6C2",
                fontWeight: 700,
                textDecoration: "none",
              }}
            >
              Resend
            </Link>
          </Typography>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
