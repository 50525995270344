import {
  CAMPAIGN_SEARCH_FAILURE,
  CAMPAIGN_SEARCH_IN_PROGRESS,
  CAMPAIGN_SEARCH_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_IN_PROGRESS,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_LEAD_APPOINTMENT_FAILURE,
  CREATE_LEAD_APPOINTMENT_IN_PROGRESS,
  CREATE_LEAD_APPOINTMENT_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_IN_PROGRESS,
  DELETE_CAMPAIGN_SUCCESS,
  FIND_ALTERNATE_CAMPAIGN_FAILURE,
  FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS,
  FIND_ALTERNATE_CAMPAIGN_SUCCESS,
  GET_CALENDAR_EVENTS_FAILURE,
  GET_CALENDAR_EVENTS_IN_PROGRESS,
  GET_CALENDAR_EVENTS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGNS_IN_PROGRESS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_LIST_BY_FILTER_FAILURE,
  GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS,
  GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS,
  GET_CAMPAIGN_SHOW_FAILURE,
  GET_CAMPAIGN_SHOW_IN_PROGRESS,
  GET_CAMPAIGN_SHOW_SUCCESS,
  GET_VIEW_MAP_DETAILS_FAILURE,
  GET_VIEW_MAP_DETAILS_IN_PROGRESS,
  GET_VIEW_MAP_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_KEY_FAILURE,
  UPDATE_CAMPAIGN_KEY_IN_PROGRESS,
  UPDATE_CAMPAIGN_KEY_SUCCESS,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS,
  UPDATE_CIRCLE_DRAGE,
  UPDATE_RADIUS,
  CAMPAIGN_NAME_SEARCH_FAILURE,
  CAMPAIGN_NAME_SEARCH_IN_PROGRESS,
  CAMPAIGN_NAME_SEARCH_SUCCESS,
} from "../actions/campaign/types";
import initialState from "../initialState";

export const campaign = (state = initialState.campaign, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
        campaignList: {},
      };
    case GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
        campaignList: action?.data,
      };
      case "UPDATE_NEXT":
        return {
          ...state,
          next:action?.url ?? null
        };
    case GET_CAMPAIGN_LIST_BY_FILTER_FAILURE:
      return {
        ...state,
        campaignList: {},
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CAMPAIGN_SHOW_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
        campaignDetails: {},
      };
    case GET_CAMPAIGN_SHOW_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
        campaignDetails: action?.data,
      };
    case GET_CAMPAIGN_SHOW_FAILURE:
      return {
        ...state,
        campaignDetails: {},
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CAMPAIGNS_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
        campaignNamesWithID: [],
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
        campaignNamesWithID: action?.data,
      };
    case GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaignNamesWithID: [],
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CAMPAIGN_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
        searchedCampaignList: {},
      };
    case CAMPAIGN_SEARCH_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
        searchedCampaignList: action?.data,
      };
    case CAMPAIGN_SEARCH_FAILURE:
      return {
        ...state,
        searchedCampaignList: {},
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_CAMPAIGN_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CALENDAR_EVENTS_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
        calendarEvents: [],
      };
    case GET_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
        calendarEvents: action?.data,
      };
    case GET_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        calendarEvents: [],
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_LEAD_APPOINTMENT_IN_PROGRESS:
      return {
        ...state,
        isCreadLeadAppLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_LEAD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isCreadLeadAppLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_LEAD_APPOINTMENT_FAILURE:
      return {
        ...state,
        isCreadLeadAppLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_CAMPAIGN_KEY_IN_PROGRESS:
      return {
        ...state,
        isloading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CAMPAIGN_KEY_SUCCESS:
      return {
        ...state,
        isloading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CAMPAIGN_KEY_FAILURE:
      return {
        ...state,
        isloading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_RADIUS:
      return {
        ...state,
        currentCircleRadius: action.radi,
      };
    case UPDATE_CIRCLE_DRAGE:
      return {
        ...state,
        isCircleIsDraging: action.drag,
      };
    case FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: true,
          error: false,
          errorMessage: "",
        },
      };
    case FIND_ALTERNATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
          data: action.data,
        },
      };
    case FIND_ALTERNATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
          error: true,
          errorMessage: action.error.message,
        },
      };
    case UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: true,
          error: false,
          errorMessage: "",
        },
      };
    case UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
          error: false,
          errorMessage: "",
        },
      };
    case UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
          error: true,
          errorMessage: action.error.message,
        },
      };
      case GET_VIEW_MAP_DETAILS_IN_PROGRESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: true,
          error: false,
          errorMessage: "",
        },
      };
    case GET_VIEW_MAP_DETAILS_SUCCESS:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
        },
      };
    case GET_VIEW_MAP_DETAILS_FAILURE:
      return {
        ...state,
        findCampaigns: {
          ...state.findCampaigns,
          loading: false,
          error: true,
          errorMessage: action.error.message,
        },
      };
      case CAMPAIGN_NAME_SEARCH_IN_PROGRESS:
        return {
          ...state,
          isloading: true,
          error: false,
          errorMessage: "",
          campaignNameSearch: [],
        };
      case CAMPAIGN_NAME_SEARCH_SUCCESS:
        return {
          ...state,
          isloading: false,
          error: false,
          errorMessage: "",
          campaignNameSearch: action?.data,
        };
      case CAMPAIGN_NAME_SEARCH_FAILURE:
        return {
          ...state,
          campaignNameSearch: [],
          isloading: false,
          error: true,
          errorMessage: action?.error?.message,
        }


    default:
      return state;
  }
};
