import {
  CLIENT_RESET_PASSWORD_FAILURE,
  CLIENT_RESET_PASSWORD_REQUEST,
  CLIENT_RESET_PASSWORD_SUCCESS,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_IN_PROGRESS,
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_IN_PROGRESS,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_IN_PROGRESS,
  GET_CLIENTS_SUCCESS,
  UPDATE_ACCOUNT_STATUS_FAILURE,
  UPDATE_ACCOUNT_STATUS_IN_PROGRESS,
  UPDATE_ACCOUNT_STATUS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_IN_PROGRESS,
  UPDATE_CLIENT_SUCCESS,
  UPLOAD_CLIENT_CSV_FAILURE,
  UPLOAD_CLIENT_CSV_IN_PROGRESS,
  UPLOAD_CLIENT_CSV_SUCCESS,
} from "../actions/Client/types";
import initialState from "../initialState";

export const client = (state = initialState.client, action) => {
  switch (action.type) {
    case GET_CLIENTS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        client_listing: {},
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        client_listing: action?.data,
      };
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        client_listing: {},
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_CLIENT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_CLIENT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_CLIENT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_ACCOUNT_STATUS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ACCOUNT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPLOAD_CLIENT_CSV_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_CLIENT_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPLOAD_CLIENT_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
      case CLIENT_RESET_PASSWORD_REQUEST:
        return {
          ...state,
          isResetLoading: true,
          error: false,
          errorMessage: "",
        };
      case CLIENT_RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isResetLoading: false,
          error: false,
          errorMessage: "",
        };
      case CLIENT_RESET_PASSWORD_FAILURE:
        return {
          ...state,
          isResetLoading: false,
          error: true,
          errorMessage: action?.error?.message,
        };
    default:
      return state;
  }
};
