import {
  BASE_URL,
  GET_TWILLIO_ACCESS_TOKEN_URL,
  MAKE_CALL_URL
} from "../../../config/api_urls";
import {
  DEVICE_STATE,
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest } from "../../../services";
import { logout } from "../authentication";
import { LOGOUT } from "../authentication/types";
import {
  GET_TWILIO_ACCESS_TOKEN_FAILURE,
  GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS,
  GET_TWILIO_ACCESS_TOKEN_SUCCESS,
  MAKE_CALL_FAILURE,
  MAKE_CALL_IN_PROGRESS,
  MAKE_CALL_SUCCESS,
  SET_DEVICE_STATE,
  UPDATE_CALLING_INFO,
} from "./types";

import toast from "react-hot-toast";

export const GetTwilioAccessTokenInProgress = () => ({
  type: GET_TWILIO_ACCESS_TOKEN_IN_PROGRESS,
});

export const GetTwilioAccessTokenSuccess = (token) => ({
  type: GET_TWILIO_ACCESS_TOKEN_SUCCESS,
  token,
});

export const GetTwilioAccessTokenFailure = (error) => ({
  type: GET_TWILIO_ACCESS_TOKEN_FAILURE,
  error,
});

export const GetTwilioAccessToken = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetTwilioAccessTokenInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to read employee
      const response = await getRequest(
        `${BASE_URL}${GET_TWILLIO_ACCESS_TOKEN_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        const token = response?.data?.token; // Adjust this according to your API response structure
        dispatch(GetTwilioAccessTokenSuccess(token));
      } else {
        dispatch(
          GetTwilioAccessTokenFailure({
            type: ERROR_TYPE.API.TWILLIO.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetTwilioAccessTokenFailure({
          type: ERROR_TYPE.API.TWILLIO.UNKNOWN,
          message: errorMessage,
        })
      );
      dispatch(setDeviceState(DEVICE_STATE.OFFLINE));
      dispatch(
        updateCallingInfo({
          isVisible: false,
          contact_no: "",
          isOutgoingOrIncoming: "",
        })
      );
      // toast.error(errorMessage, {
      //   position: "bottom-center",
      // });
    }
  };
};

export const MakeCallInProgress = () => ({
  type: MAKE_CALL_IN_PROGRESS,
});

export const MakeCallSuccess = () => ({
  type: MAKE_CALL_SUCCESS,
});

export const MakeCallFailure = (error) => ({
  type: MAKE_CALL_FAILURE,
  error,
});

export const MakeCall = (cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(MakeCallInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const params = {
        phoneNumber: "+919754361010",
      };

      const response = await postRequest(
        `${BASE_URL}${MAKE_CALL_URL}`,
        params,
        "",
        token
      );
      // Check response status
      if (response?.status === 200) {
        // Handle call initiation success
        dispatch(MakeCallSuccess());
        cb(); // Call the callback function if needed
      } else {
        // Handle call initiation failure
        dispatch(
          MakeCallFailure({
            type: ERROR_TYPE.API.TWILLIO.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        MakeCallFailure({
          type: ERROR_TYPE.API.TWILLIO.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//updating device state

export const setDeviceState = (payload) => ({
  type: SET_DEVICE_STATE,
  payload,
});

export const updateCallingInfo = (payload) => ({
  type: UPDATE_CALLING_INFO,
  payload,
});
