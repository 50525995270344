import React from "react";
import CallIcon from "@mui/icons-material/Call";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeviceState,
  updateCallingInfo,
} from "../../../store/actions/twillio";
import { CallingCard } from "../CallingCard.js";
import { getTwilioDevice } from "../TwillioDevice.js";
import { DEVICE_STATE } from "../../../helpers";
import moment from "moment";
import { createCallHistory } from "../../../store/actions/report";
import { useEffect } from "react";

export const TwilioVoice = (props) => {
  const {
    fontSize,
    fontColor,
    phoneNumber,
    callInfo,
    isFromCampaign,
    callHistory,
    isAutoCall,
  } = props;
  // const token = useSelector((state) => state?.twillio?.access_token);

  const deviceStatus = useSelector((state) => state?.twillio?.deviceState);

  const isRecord = useSelector((state) => state?.loginAuth?.isRecord);

  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzhhNjFiNWFiZmI2NjdhMmY3MWUwMDhjODVkYTJmYTM1LTE2OTQ1ODU1MDciLCJncmFudHMiOnsidm9pY2UiOnsiaW5jb21pbmciOnsiYWxsb3ciOnRydWV9LCJvdXRnb2luZyI6eyJhcHBsaWNhdGlvbl9zaWQiOiJBUDRmMmQ3M2I1MjMwNjBmOTUzYjBhNmE4ZTIxY2Q3ZjQ4In19LCJpZGVudGl0eSI6InVzZXIifSwiaXNzIjoiU0s4YTYxYjVhYmZiNjY3YTJmNzFlMDA4Yzg1ZGEyZmEzNSIsImV4cCI6MTY5NDU4OTEwNywibmJmIjoxNjk0NTg1NTA3LCJzdWIiOiJBQzBlNGVjYjg1MTllOTRhNDFiYWE3Y2ZlMjdlNzRiYzE4In0.45odn9ky9NjziHivCxPq0keaAwhNg1IwWmTB2shRHts";
  const dispatch = useDispatch();

  var startDateTime;
  var endDateTime;

  const onCallHistory = (payload) => {
    dispatch(createCallHistory(payload));
  };

  const handleCall = () => {
    const device = getTwilioDevice();
    if (device && deviceStatus === DEVICE_STATE.READY) {
      // You can implement your call logic here
      const params = {
        To: phoneNumber ?? "", // Replace with the recipient's phone number
        record: isRecord ?? false,
        campaign_cid: callInfo?.campaign_cid ?? "+12522622120",
      }; // Specify call parameters

      device
        .connect({
          params: params,
          rtcConstraints: {
            audio: true,
          },
        })
        .then((call) => {
          dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          dispatch(
            updateCallingInfo({
              isVisible: true,
              contact_no: phoneNumber ?? "",
              isOutgoingOrIncoming: "out",
            })
          );
          startDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");
          call.on("accept", () => {
            dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          });
          call.on("cancel", () => {
            //console.log("The call has been canceled.");
          });
          call.on("ringing", (hasEarlyMedia) => {
            //console.log("ringing......");
          });

          call.on("connect", (twilioConnection) => {
            dispatch(setDeviceState(DEVICE_STATE.ON_CALL));
          });

          call.on("disconnect", (connection) => {
            //console.log("The call has been disconnected.");
            const myMap = new Map(connection.customParameters);
            const recordValue = myMap.get("record");
            endDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");

            const payload = {
              agent: callInfo?.agent_id ?? null,
              agency_calling_for: callInfo?.campaign_id ?? null,
              prospect: callInfo?.prospect?.id ?? null,
              phone_number: phoneNumber,
              start_datetime: startDateTime,
              end_datetime: endDateTime,
              call_sid: connection.parameters.CallSid,
              is_recorded: recordValue === "true" ? true : false,
            };

            isFromCampaign ? callHistory(payload) : onCallHistory(payload);
            dispatch(setDeviceState(DEVICE_STATE.READY));
            dispatch(
              updateCallingInfo({
                isVisible: false,
                contact_no: "",
                isOutgoingOrIncoming: "",
              })
            );
          });
          call.on("reject", () => {
            //console.log("The call was rejected.");
            dispatch(setDeviceState(DEVICE_STATE.READY));
            dispatch(
              updateCallingInfo({
                isVisible: false,
                contact_no: "",
                isOutgoingOrIncoming: "",
              })
            );
          });
        })
        .catch((error) => {
          console.error("Error initiating the call:", error);
          // Handle errors, e.g., display an error message to the user
        });
    }
  };

  const onClick = () => {
    handleCall();
  };

  // // Handle hang-up logic when the "Hang Up" button is clicked
  // const handleHangup = () => {
  //   if (connection) {
  //     device.disconnectAll();
  //     setUserState(USER_STATE.READY);
  //     setConnection(null);
  //     setCallState("idle"); // Reset call state
  //     stopCallTimer(); // Stop the call duration timer
  //   }
  // };

  useEffect(() => {
    if (isAutoCall) {
      onClick();
    }
  }, [isAutoCall]);

  return (
    <>
      <Button
        component="label"
        onClick={onClick}
        sx={{
          color: fontColor,
          fontWeight: "600",
          paddingY: 0,
          fontSize: fontSize,
        }}
        startIcon={<CallIcon fontSize="small" sx={{ color: "#6DD6C2" }} />}
      >
        {deviceStatus}
      </Button>
    </>
  );
};

TwilioVoice.propTypes = {
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  callInfo: PropTypes.object,
  callHistory: PropTypes.func,
  isFromCampaign: PropTypes.bool,
  isAutoCall: PropTypes.bool,
};

TwilioVoice.defaultProps = {
  fontSize: 15,
  fontColor: "#454647",
  callInfo: {},
  callHistory: () => {},
  isAutoCall: false,
  isFromCampaign: false,
};
