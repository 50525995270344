// LoginScreen.js
import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export const X_UploadProspects = (props) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Upload Prospects CSV
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <Box
                sx={{
                  display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                  alignItems: "center",
                  gap: { xs: "20px", sm: "20px", md: "50px", lg: "50px" },
                }}
              >
                <TextField
                  name="upload-file"
                  type="file"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                  }}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <Checkbox {...label} />
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 15,
                        color: "#6B7489",
                        fontWeight: 600,
                      }}
                    >
                      Real Time Process
                    </Typography>
                  </Box>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 14,
                      color: "#6B7489",
                      fontWeight: 500,
                      maxWidth: 450,
                      width: "100%",
                      ml: 1,
                    }}
                  >
                    (If checked, process will be real-time. If not, it will
                    process in the background and you will be notified by email
                    once complete.)
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{
            borderRadius: "10px!Important",
            boxShadow: 3,
            marginTop: "25px!important",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#212223", pointerEvents: "auto" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderRadius: "10px 10px 0 0!Important",
            }}
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
              }}
            >
              Listing Prospects CSV Fields
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <Box
                sx={{
                  width: "100%",
                  overflowX: "scroll",
                  whiteSpace: "nowrap",
                  paddingTop: "6px",
                }}
              >
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "20px",
                    marginBottom: "14px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Select
                  </InputLabel>
                  <Select
                    id="selectinfo"
                    label="Select"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Alternate Phone Number
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Alternative Phone Number Line Type
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Call Result
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Call Result Description
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Carrier Of Record
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Comments
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Company Name
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Company Website
                    </MenuItem>
                    <MenuItem value={9} sx={{ fontSize: 15 }}>
                      County Description
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: 15 }}>
                      Created
                    </MenuItem>
                    <MenuItem value={11} sx={{ fontSize: 15 }}>
                      Created By
                    </MenuItem>
                    <MenuItem value={12} sx={{ fontSize: 15 }}>
                      Credit Rating
                    </MenuItem>
                    <MenuItem value={13} sx={{ fontSize: 15 }}>
                      Date List Produced
                    </MenuItem>
                    <MenuItem value={14} sx={{ fontSize: 15 }}>
                      EIN 1
                    </MenuItem>
                    <MenuItem value={15} sx={{ fontSize: 15 }}>
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
