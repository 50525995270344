export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_ERROR_RESET = "LOGIN_ERROR_RESET";
export const LOGOUT = "LOGOUT";
export const VERIFYOTP_IN_PROGRESS = "VERIFYOTP_IN_PROGRESS";
export const VERIFYOTP_SUCCESS = "VERIFYOTP_SUCCESS";
export const VERIFYOTP_FAILURE = "VERIFYOTP_FAILURE";
export const VERIFYOTP_ERROR_RESET = "VERIFYOTP_ERROR_RESET";
export const QRCODE_IN_PROGRESS = "QRCODE_IN_PROGRESS";
export const QRCODE_SUCCESS = "QRCODE_SUCCESS";
export const QRCODE_FAILURE = "QRCODE_FAILURE";
export const VERIFY_QR_OTP_IN_PROGRESS = "VERIFY_QR_OTP_IN_PROGRESS";
export const VERIFY_QR_OTP_SUCCESS = "VERIFY_QR_OTP_SUCCESS";
export const VERIFY_QR_OTP_FAILURE = "VERIFY_QR_OTP_FAILURE";
export const VERIFY_QR_OTP_ERROR_RESET=" VERIFY_QR_OTP_ERROR_RESET";
export const VERIFY_QR_OTP_PROFILE_IN_PROGRESS = "VERIFY_QR_OTP_PROFILE_IN_PROGRESS";
export const VERIFY_QR_OTP_PROFILE_SUCCESS = "VERIFY_QR_OTP_PROFILE_SUCCESS";
export const VERIFY_QR_OTP_PROFILE_FAILURE = "VERIFY_QR_OTP_PROFILE_FAILURE";
export const VERIFY_QR_OTP_PROFILE_ERROR_RESET="VERIFY_QR_OTP_PROFILE_ERROR_RESET,"



export const USER_AUTHENTICATION_IN_PROGRESS = "USER_AUTHENTICATION_IN_PROGRESS";
export const USER_AUTHENTICATION_SUCCESS = "USER_AUTHENTICATION_SUCCESS";
export const USER_AUTHENTICATION_FAILURE = "USER_AUTHENTICATION_FAILURE";


export const FORGOT_PASSWORD_IN_PROGRESS = "FORGOT_PASSWORD_IN_PROGRESS";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

// Action Types
export const LOGOUT_IN_PROGRESS = "LOGOUT_IN_PROGRESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const LOGOUT_ONINE_USER_IN_PROGRESS = "LOGOUT_ONINE_USER_IN_PROGRESS";
export const LOGOUT_ONINE_USER_SUCCESS = "LOGOUT_ONINE_USER_SUCCESS";
export const LOGOUT_ONINE_USER_FAILURE = "LOGOUT_ONINE_USER_FAILURE";
