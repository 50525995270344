// LoginScreen.js
import React, { useCallback, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { CallParentTable } from "./TotalCallInfo";
import { LeadOwedParentTable } from "./LeadOwed/LeadOwedParentTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Common";
import {
  copyTableToClipboard,
  tableToCSV,
  tableToPDF,
  tableToPrint,
  tableToXLSX,
} from "../../helpers";
import { ClientViewComponent } from "./ClientViewComponent";
import {
  getAllCallResultOptions,
  updateCampaignKey,
} from "../../store/actions";
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



export const HomeComponent = (props) => {
  const {
    isloading,
    getUserDashboardProduction,
    getLeadOwedReport,
    createCommercialInvoice,
    onLeadOwedKeyUpdate,
  } = props;

  useEffect(()=>{
    document.title = "Dashboard | Lead Orchard"
  },[]);


  const isCreateInvoiceLoading = useSelector(
    (state) => state?.invoices?.isCreateInvoiceInProgress
  );

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);
  const isleadOwedKeyLoading = useSelector(
    (state) => state?.home?.isleadOwedKeyLoading
  );

  useEffect(() => {
    const userDashBoardPayload = {
      from_datetime: null,
      to_datetime: null,
      type: "current_week",
      employee_name: null,
    };
    userInfo?.user_type !== "Client" &&
      getUserDashboardProduction(userDashBoardPayload);
    const leadPayload = {
      for_date: null,
      campaign_status_type: 1,
      campaign_type: 2,
      client_name: null,
    };
    userInfo?.user_type === "Administration" && getLeadOwedReport(leadPayload);
    dispatch(getAllCallResultOptions());
    
  }, []);


  const initialuserReportSearchInfo = {
    type: "current_week",
    fromDate: "",
    toDate: "",
    name: "",
  };

  //userDashBoard info
  const [userReportSearchInfo, setUserReportSearchInfo] = useState(
    initialuserReportSearchInfo
  );

  //leadOwed

  const initialLeadOwedInfo = {
    for_date: null,
    campaign_status_type: 1,
    campaign_type: 2,
    name: "",
  };

  //leadOwed
  const [leadOwedSearchInfo, setLeadOwedSearchInfo] =
    useState(initialLeadOwedInfo);


  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "type":
        setUserReportSearchInfo({
          ...userReportSearchInfo,
          type: event.target.value,
        });
        break;
      case "campaignType":
        setLeadOwedSearchInfo({
          ...leadOwedSearchInfo,
          campaign_type: event.target.value,
        });
        break;
      case "campaignStatus":
        setLeadOwedSearchInfo({
          ...leadOwedSearchInfo,
          campaign_status_type: event.target.value,
        });
      default:
        break;
    }
  };


  const onSearchUserReport = () => {
    const formatedFromDate = moment(
      userReportSearchInfo.fromDate.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    const formatedToDate = moment(
      userReportSearchInfo.toDate.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const payload = {
      from_datetime:
        userReportSearchInfo.type === "custom_date"
          ? formatedFromDate : null,
      to_datetime:
        userReportSearchInfo.type === "custom_date"
          ? formatedToDate : null,
      type: userReportSearchInfo.type !== "" ? userReportSearchInfo.type : null,
      employee_name:
        userReportSearchInfo.name !== "" ? userReportSearchInfo.name : null,
    };

    getUserDashboardProduction(payload);
  };

  const onSearchLeadOwed = () => {
    const payload = {
      for_date: null,
      campaign_status_type:
        leadOwedSearchInfo.campaign_status_type !== ""
          ? leadOwedSearchInfo.campaign_status_type
          : 1,
      campaign_type:
        leadOwedSearchInfo.campaign_type !== ""
          ? leadOwedSearchInfo.campaign_type
          : 2,
      client_name:
        leadOwedSearchInfo.name !== "" ? leadOwedSearchInfo.name : null,
    };
    getLeadOwedReport(payload);
    //console.log("Payload=====>", payload);
  };


  const onChangeCampiagnKey = useCallback(
    (id, value, cb) => {
      dispatch(
        updateCampaignKey(id, value, () => {
          cb();
          // callBackFunc();
        })
      );
    },
    [dispatch]
  );

  const onCreateCommercialInvoice = useCallback(
    (payload) => {
      createCommercialInvoice(payload);
    },
    [createCommercialInvoice]
  );

  const onKeyUpdate = useCallback(
    (id, key, value, cb) => {
      onLeadOwedKeyUpdate(id, key, value, () => {
        cb();
        onSearchLeadOwed();
      });
    },
    [onLeadOwedKeyUpdate, onSearchLeadOwed]
  );

  return (
    <>
      {userInfo?.user_type === "Client" ? (
        <ClientViewComponent />
      ) : (
        <>
          <Box
            sx={{
              margin: { xs: "10px", md: '30px' },
              marginTop: { xs: "10px", md: '30px' },
              boxShadow: 1,
              borderRadius: "15px!Important",
            }}
          >
            <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
              <Box
                sx={{
                  display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: { xs: "0px", md: "15px" },
                  paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
                  backgroundColor: " #d2f5ec",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
                  >
                    User Dashboard Production
                  </Typography>
                </AccordionSummary>


                <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
                  <ListItemButton
                    onClick={() => {
                      copyTableToClipboard("userDashboardTable");
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#676a6c4a",
                      px: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                        color: "#454647",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ContentCopyIcon
                        sx={{
                          mr: 0.5,
                          fontSize: "18px",
                          display: { xs: "none", sm: "block", md: "block" },
                        }}
                      />
                      Copy
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      tableToCSV("userDashboardTable");
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#676a6c4a",
                      px: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                        color: "#454647",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InsertPageBreakIcon
                        sx={{
                          mr: 0.5,
                          fontSize: "18px",
                          display: { xs: "none", sm: "block", md: "block" },
                        }}
                      />
                      CSV
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      tableToXLSX("userDashboardTable");
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#676a6c4a",
                      px: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                        color: "#454647",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DescriptionIcon
                        sx={{
                          mr: 0.5,
                          fontSize: "18px",
                          display: { xs: "none", sm: "block", md: "block" },
                        }}
                      />
                      Excel
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      tableToPrint("userDashboardTable");
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#676a6c4a",
                      px: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                        color: "#454647",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PrintIcon
                        sx={{
                          mr: 0.5,
                          fontSize: "18px",
                          display: { xs: "none", sm: "block", md: "block" },
                        }}
                      />
                      Print
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      tableToPDF("userDashboardTable");
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#676a6c4a",
                      px: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                        color: "#454647",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PictureAsPdfIcon
                        sx={{
                          mr: 0.5,
                          fontSize: "18px",
                          display: { xs: "none", sm: "block", md: "block" },
                        }}
                      />
                      PDF
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Box>

              <Box
                sx={{
                  border: '1px solid #ddd',
                  marginX: { xs: "15px", sm: "27px", md: '27px' },
                  paddingY: { xs: "15px", sm: "20px", md: '25px' },
                  marginTop: { xs: "15px", sm: "25px", md: '30px' },
                  borderRadius: "7px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-around",
                    width: "100%",
                    flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens
                    flexWrap: 'wrap',
                    gap: { xs: "10px", sm: "10px", md: '20px' },
                    paddingX: { xs: "12px", sm: "20px", md: '20px' },

                  }}
                >
                  <Box
                    sx={{
                      // marginTop: "3%",
                      maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '220px', xl: '270px' }, // full width on small screens
                      width: '100%'
                    }}
                  >

                    <FormControl
                      size="small"
                      sx={{
                        maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' }, // full width on small screens
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: 15,
                          "&.Mui-focused": {
                            color: "#6DD6C2!important",
                            fontWeight: "600",
                            margin: "0",
                          },
                        }}
                      >
                        Select Day
                      </InputLabel>
                      <Select
                        sx={{ backgroundColor: "#fff!important" }}
                        id="selectinfo"
                        label="Select Day"
                        onChange={(event) => {
                          handleChange(event, "", "type");
                        }}
                        value={userReportSearchInfo.type}
                        size="small"
                      >
                        <MenuItem value={"today"} sx={{ fontSize: 15 }}>
                          Today
                        </MenuItem>
                        <MenuItem value={"current_week"} sx={{ fontSize: 15 }}>
                          Current Week
                        </MenuItem>
                        <MenuItem value={"last_week"} sx={{ fontSize: 15 }}>
                          Last Week
                        </MenuItem>
                        <MenuItem value={"current_month"} sx={{ fontSize: 15 }}>
                          Current Month
                        </MenuItem>
                        <MenuItem value={"last_month"} sx={{ fontSize: 15 }}>
                          Last Month
                        </MenuItem>
                        <MenuItem value={"custom_date"} sx={{ fontSize: 15 }}>
                          Custom Date Range
                        </MenuItem>
                      </Select>
                    </FormControl>

                  </Box>
                  <Box>
                    {userReportSearchInfo.type === "custom_date" && (
                      <Box style={{ width: '100%' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>

                            <Box >
                              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{
                                "& .css-1nb8qpj-MuiStack-root>.MuiTextField-root":
                                {
                                  minWidth: '100%'
                                },
                              }}>
                                <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px', }}>
                                  <DateTimePicker label="From Date Time"
                                    value={userReportSearchInfo.fromDate}
                                    onChange={(event) => {
                                      // debugger
                                      setUserReportSearchInfo((prev) => ({
                                        ...prev,
                                        fromDate: event,
                                      }));
                                    }}
                                    sx={{
                                      fontSize: "11px",
                                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                      {
                                        padding: "8px 13px 8px 13px",
                                      },


                                      "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                        top: '-7px',
                                      },
                                      "& .css-o16hp9-MuiStack-root>.MuiTextField-root": {
                                        minWidth: '100%',
                                      },
                                      "& .MuiInputLabel-root": {
                                        "&.Mui-focused": {
                                          color: "#18a689!important",
                                        },
                                      },
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                      },
                                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6DD6C2",
                                      },
                                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#6DD6C2!important",
                                      },
                                    }} />

                                </DemoContainer>
                              </LocalizationProvider>
                            </Box>

                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>

                            <Box >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px' }}>
                                  <DateTimePicker label="To Date Time"
                                    value={userReportSearchInfo.toDate}
                                    onChange={(event) => {
                                      // debugger
                                      setUserReportSearchInfo((prev) => ({
                                        ...prev,
                                        toDate: event,
                                      }));
                                    }}
                                    sx={{
                                      fontSize: "11px",
                                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                      {
                                        padding: "8px 13px 8px 13px",
                                      },

                                      "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                        top: '-7px',
                                      },
                                      "& .MuiInputLabel-root": {
                                        "&.Mui-focused": {
                                          color: "#18a689!important",
                                        },
                                      },
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                      },
                                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6DD6C2",
                                      },
                                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#6DD6C2!important",
                                      },
                                    }} />

                                </DemoContainer>
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '230px', xl: '300px' }, // full width on small screens                 
                      width: '100%'
                    }}
                  >
                    <TextField
                      name="upload-file"
                      type="text"
                      size="small"
                      label="Search by name"

                      value={userReportSearchInfo.name}
                      onChange={(event) => {
                        setUserReportSearchInfo((prev) => ({
                          ...prev,
                          name: event.target.value,
                        }));
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                        width: "100%",
                      }}
                    />
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    onClick={onSearchUserReport}
                    fullWidth
                    sx={{
                      fontWeight: 600,
                      // width: { xs: "100%", sm: "20%" }, // full width on small screens
                      width: "max-content",

                      textTransform: "capitalize",
                      pl: 4,
                      pr: 4,
                      // mt: { xs: 2, sm: 5 }, // adjusted margin top for small screens
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                      color: "#212223",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                        borderColor: "#6DD6C2",
                      },
                    }}
                  >
                    Search
                  </Button>
                </Box>

              </Box>

              <AccordionDetails>
                <Box
                  sx={{
                    padding: {
                      xs: "5px 0px 0px 0px",
                      sm: "20px 10px 0px 10px",
                      md: "20px 10px 0px 10px",
                      lg: "20px 10px 0px 10px",
                    },
                  }}
                >
                  <CallParentTable />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          {userInfo?.user_type === "Administration" && (
            <Box
              sx={{
                margin: { xs: "10px", md: '30px' },
                marginTop: { xs: "10px", md: '30px' },
                boxShadow: 1,
                borderRadius: "15px!Important",
              }}
            >
              <Accordion
                defaultExpanded
                sx={{ borderRadius: "15px!Important" }}
              >
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: { xs: "0px", md: "15px" },
                    paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
                    backgroundColor: " #d2f5ec",
                    borderTopLeftRadius: "15px!Important",
                    borderTopRightRadius: "15px!Important",
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "#212223", pointerEvents: "auto" }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
                    >
                      Leads Owed Report
                    </Typography>
                  </AccordionSummary>


                  <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
                    <ListItemButton
                      onClick={() => {
                        copyTableToClipboard("leadOwedReport");
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#676a6c4a",
                        px: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                          color: "#454647",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ContentCopyIcon
                          sx={{
                            mr: 0.5,
                            fontSize: "18px",
                            display: { xs: "none", sm: "block", md: "block" },
                          }}
                        />
                        Copy
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        tableToCSV("leadOwedReport");
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#676a6c4a",
                        px: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                          color: "#454647",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InsertPageBreakIcon
                          sx={{
                            mr: 0.5,
                            fontSize: "18px",
                            display: { xs: "none", sm: "block", md: "block" },
                          }}
                        />
                        CSV
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        tableToXLSX("leadOwedReport");
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#676a6c4a",
                        px: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                          color: "#454647",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DescriptionIcon
                          sx={{
                            mr: 0.5,
                            fontSize: "18px",
                            display: { xs: "none", sm: "block", md: "block" },
                          }}
                        />
                        Excel
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        tableToPrint("leadOwedReport");
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#676a6c4a",
                        px: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                          color: "#454647",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PrintIcon
                          sx={{
                            mr: 0.5,
                            fontSize: "18px",
                            display: { xs: "none", sm: "block", md: "block" },
                          }}
                        />
                        Print
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        tableToPDF("leadOwedReport");
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#676a6c4a",
                        px: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: { xs: 12, sm: 13, md: 14, lg: 14 },
                          color: "#454647",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PictureAsPdfIcon
                          sx={{
                            mr: 0.5,
                            fontSize: "18px",
                            display: { xs: "none", sm: "block", md: "block" },
                          }}
                        />
                        PDF
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </Box>

                <Box
                  sx={{
                    border: '1px solid #ddd',
                    marginX: { xs: "15px", sm: "27px", md: '27px' },
                    paddingY: { xs: "15px", sm: "20px", md: '25px' },
                    marginTop: { xs: "15px", sm: "25px", md: '30px' },
                    borderRadius: "7px"


                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // justifyContent: "space-around",
                      width: "100%",
                      flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens

                      gap: { xs: "10px", sm: "10px", md: '20px' },
                      paddingX: { xs: "12px", sm: "20px", md: '20px' },
                    }}
                  >
                    <Box sx={{ maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' }, width: '100%' }}>

                      <FormControl
                        size="small"
                        sx={{
                          maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' },
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            fontSize: 15,
                            "&.Mui-focused": {
                              color: "#6DD6C2!important",
                              fontWeight: "600",
                              margin: "0",
                            },
                          }}
                        >
                          Standard / Plus
                        </InputLabel>
                        <Select
                          sx={{ backgroundColor: "#fff!important" }}
                          id="selectinfo"
                          label="Standard / Plus"
                          onChange={(event) => {
                            handleChange(event, "", "campaignType");
                          }}
                          value={leadOwedSearchInfo.campaign_type}
                          size="small"
                        >
                          {/* 2 for All , 1 for Standard , 0 for Plus  */}
                          <MenuItem value={1} sx={{ fontSize: 15 }}>
                            Standard
                          </MenuItem>
                          <MenuItem value={0} sx={{ fontSize: 15 }}>
                            Plus
                          </MenuItem>
                          <MenuItem value={2} sx={{ fontSize: 15 }}>
                            All
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' }, width: '100%' }}>

                      <FormControl
                        size="small"
                        sx={{
                          maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' },
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            fontSize: 15,
                            "&.Mui-focused": {
                              color: "#6DD6C2!important",
                              fontWeight: "600",
                              margin: "0",
                            },
                          }}
                        >
                          Active / Inactive
                        </InputLabel>
                        <Select
                          sx={{ backgroundColor: "#fff!important" }}
                          id="selectinfo"
                          label="Active / Inactive"
                          onChange={(event) => {
                            handleChange(event, "", "campaignStatus");
                          }}
                          value={leadOwedSearchInfo.campaign_status_type}
                          size="small"
                        >
                          {/* 1 for Active , 0 for Inactive  , 2 for All  */}
                          <MenuItem value={2} sx={{ fontSize: 15 }}>
                            All
                          </MenuItem>
                          <MenuItem value={1} sx={{ fontSize: 15 }}>
                            Active
                          </MenuItem>
                          <MenuItem value={0} sx={{ fontSize: 15 }}>
                            Inactive
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' }, width: '100%' }}>

                      <Box>
                        <TextField
                          name="upload-file"
                          type="text"
                          size="small"
                          placeholder="name"
                          defaultValue="Hello World"
                          value={leadOwedSearchInfo.name}
                          onChange={(event) => {
                            setLeadOwedSearchInfo({
                              ...leadOwedSearchInfo,
                              name: event.target.value,
                            });
                          }}
                          label="Search by name"
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2!important",
                            },
                            width: "100%",
                            maxWidth: { xs: '240px', sm: '160px', md: '220px', lg: '270px', xl: '350px' },
                          }}
                        />
                      </Box>
                    </Box>



                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        onSearchLeadOwed();
                      }}
                      fullWidth
                      sx={{
                        fontWeight: 600,
                        // width: { xs: "100%", sm: "20%" }, // full width on small screens
                        width: "max-content",

                        textTransform: "capitalize",
                        pl: 4,
                        pr: 4,
                        // mt: { xs: 2, sm: 5 }, // adjusted margin top for small screens
                        backgroundColor: "#6DD6C2",
                        borderColor: "#6DD6C2",
                        color: "#212223",
                        "&:hover": {
                          backgroundColor: "#6DD6C2",
                          borderColor: "#6DD6C2",
                        },
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Box>


                <AccordionDetails>
                  <Box
                    sx={{
                      padding: {
                        xs: "0px 0px 0px 0px",
                        sm: "20px 10px 0px 10px",
                        md: "20px 10px 0px 10px",
                        lg: "20px 10px 0px 10px",
                      },
                    }}
                  >
                    <LeadOwedParentTable
                      onChangeCampiagnKey={onChangeCampiagnKey}
                      onCreateCommercialInvoice={onCreateCommercialInvoice}
                      onLeadOwedKeyUpdate={onKeyUpdate}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Loader loading={isleadOwedKeyLoading} />
            </Box>
          )}
          <Loader loading={isloading || isCreateInvoiceLoading} />
        </>
      )}
    </>
  );
};
