import axios from "axios";
import toast from "react-hot-toast";
import { ERROR_MESSAGE } from "../helpers";

const axiosClient = axios.create();
const axiosClientForUrlEncoded = axios.create();

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
};

axiosClientForUrlEncoded.defaults.headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
};

const setAuthorizationToken = (token) => {
  if (token) {
    axiosClient.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosClient.defaults.headers["Authorization"];
  }
};


axiosClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.code =="ERR_NETWORK"){
    let errMsg = `${ERROR_MESSAGE.NETWORK}`
    toast.error(errMsg, {
      position: "bottom-center",
    });
    return;
  }
 return Promise.reject(error);
});

export async function getRequest(URL, token, payload = {}, headers = {}) {
  setAuthorizationToken(token);

  const response = await axiosClient.get(URL, {
    params: payload,
    headers,
  });
  return response;
}

export async function postRequest(
  URL,
  payload ={},
  isUrlEncoded,
  token,
  headers = {}
) {
  setAuthorizationToken(token);
  const response = (await isUrlEncoded)
    ? axiosClientForUrlEncoded.post(
        URL,
        payload,
        {
          headers,
        },
        {
          withCredentials: true,
        }
      )
    : axiosClient.post(
        URL,
        payload,
        {
          headers,
        },
        {
          withCredentials: true,
        }
      );
  return response;
}

export async function putRequest(URL, payload, token, headers = {}) {
  setAuthorizationToken(token);
  const response = await axiosClient.put(URL, payload, {
    headers,
  });
  return response;
}

export async function deleteRequest(URL, payload, token, headers = {}) {
  setAuthorizationToken(token);
  const response = await axiosClient.delete(URL, {
    data: payload,
    headers,
  });

  return response;
}

export async function patchRequest(URL, payload, token, headers = {}) {
  setAuthorizationToken(token);
  const response = await axiosClient.patch(URL, payload, {
    headers,
  });
  return response;
}
