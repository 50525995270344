import {
  BASE_URL,
  CREATE_LEADS_URL,
  CREATE_NEW_NOTE_URL,
  DELETE_LEAD_URL,
  DELETE_NOTE_URL,
  DELETE_RETURN_CREDIT_URL,
  GET_ALL_NOTES_URL,
  GET_LEAD_SEARCH_URL,
  GET_LEAD_SHOW_URL,
  GET_RETURN_CREDIT_URL,
  LEAD_MASS_UPDATE_URL,
  LEAD_MASS_UPLOAD_URL,
  RETURN_CREDIT_URL,
  UPDATE_APPOINTMENT_STATUS_URL,
  UPDATE_APPOINTMENT_URL,
  UPDATE_CREDIT_URL,
  UPDATE_LEAD_URL,
  UPDATE_NOTE_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { deleteRequest, getRequest, postRequest } from "../../../services";
import toast from "react-hot-toast";
import { LOGOUT } from "../authentication/types";

import {
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_IN_PROGRESS,
  CREATE_LEAD_SUCCESS,
  CREATE_NEW_NOTE_FAILURE,
  CREATE_NEW_NOTE_IN_PROGRESS,
  CREATE_NEW_NOTE_SUCCESS,
  DELETE_LEAD_FAILURE,
  DELETE_LEAD_IN_PROGRESS,
  DELETE_LEAD_SUCCESS,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_IN_PROGRESS,
  DELETE_NOTE_SUCCESS,
  DELETE_RETURN_CREDIT_FAILURE,
  DELETE_RETURN_CREDIT_IN_PROGRESS,
  DELETE_RETURN_CREDIT_SUCCESS,
  GET_ALL_NOTES_FAILURE,
  GET_ALL_NOTES_IN_PROGRESS,
  GET_ALL_NOTES_SUCCESS,
  GET_LEAD_SEARCH_FAILURE,
  GET_LEAD_SEARCH_IN_PROGRESS,
  GET_LEAD_SEARCH_SUCCESS,
  GET_LEAD_SHOW_FAILURE,
  GET_LEAD_SHOW_IN_PROGRESS,
  GET_LEAD_SHOW_SUCCESS,
  GET_RETURN_CREDIT_FAILURE,
  GET_RETURN_CREDIT_IN_PROGRESS,
  GET_RETURN_CREDIT_SUCCESS,
  LEAD_MASS_UPDATE_FAILURE,
  LEAD_MASS_UPDATE_IN_PROGRESS,
  LEAD_MASS_UPDATE_SUCCESS,
  RETURN_CREDIT_FAILURE,
  RETURN_CREDIT_IN_PROGRESS,
  RETURN_CREDIT_SUCCESS,
  UPDATE_APPOINTMENT_KEY_FAILURE,
  UPDATE_APPOINTMENT_KEY_IN_PROGRESS,
  UPDATE_APPOINTMENT_KEY_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_FAILURE,
  UPDATE_APPOINTMENT_STATUS_IN_PROGRESS,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_LEAD_KEY_FAILURE,
  UPDATE_LEAD_KEY_IN_PROGRESS,
  UPDATE_LEAD_KEY_SUCCESS,
  UPLOAD_LEAD_CSV_FAILURE,
  UPLOAD_LEAD_CSV_IN_PROGRESS,
  UPLOAD_LEAD_CSV_SUCCESS,
} from "./types";
import moment from "moment";
import { logout } from "../authentication";

// Action Creators for creating leads
export const createLeadInProgress = () => ({
  type: CREATE_LEAD_IN_PROGRESS,
});

export const createLeadSuccess = (data) => ({
  type: CREATE_LEAD_SUCCESS,
  data,
});

export const createLeadFailure = (error) => ({
  type: CREATE_LEAD_FAILURE,
  error,
});

// Update your createLead action
export const createLead = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data specific to leads
      const {
        agentCallingFor,
        agentName,
        alternativePhoneNumber,
        appointmentDate,
        appointmentTime,
        appointmentType,
        autoCarrier,
        callResult,
        callResultDescription,
        carrierOfRecord,
        category,
        comment,
        companyName,
        companyWebsite,
        confirmAppointmentDate,
        confirmAppointmentTime,
        countyDescription,
        creditRating,
        executiveFirstName,
        executiveLastName,
        executiveTitle,
        lastAppointmentWith,
        liabilityCarrier,
        liabilityRenewalDate,
        locationCity,
        locationEmployeeSizeActual,
        locationState,
        locationStreet,
        locationZipCode,
        nextRenewalDate,
        phoneNumber,
        primaryNaicsCode,
        primaryNaicsCodeDescription,
        primarySicCode,
        primarySicCodeDescription,
        renewalDate,
        squareFootage,
        status,
        AutoRenewalDate,
        totalLocations,
        totalVehicles,
        workersCompCarrier,
        workersCompRenewalDate,
      } = payload;
      // Create params object for lead creation
      const appointmentFormatedTime = moment(confirmAppointmentTime.$d).format(
        "HH:mm:ss"
      );

      const params = {
        agent: agentName,
        first_name: executiveFirstName ?? null,
        last_name: executiveLastName ?? null,
        category: category ?? null,
        location_employee_size_actual: locationEmployeeSizeActual ?? null,
        next_renewable_date: nextRenewalDate !== "" ? nextRenewalDate : null,
        liability_renewable_date:
          liabilityRenewalDate !== "" ? liabilityRenewalDate : null,
        liability_carrier: liabilityCarrier ?? null,
        total_locations: totalLocations ?? null,
        workers_comp_renewal_date:
          workersCompRenewalDate !== "" ? workersCompRenewalDate : null,
        workers_comp_carrier: workersCompCarrier ?? null,
        auto_renewal_date: AutoRenewalDate !== "" ? AutoRenewalDate : null,
        auto_carrier: autoCarrier ?? null,
        total_vehicles: totalVehicles ?? null,
        // lead_type,

        // is_verified,
        appointment_date: confirmAppointmentDate ?? null,
        appointment_time: appointmentFormatedTime ?? null,
        appointment_type: appointmentType ?? null,
        // zoho_id,
        phone_number: phoneNumber ?? null,
        alternative_phone_number: alternativePhoneNumber ?? null,
        title: executiveTitle ?? null,
        // employee_size,
        agency_calling_for: agentCallingFor ?? null,
        address: locationStreet ?? null,
        city: locationCity ?? null,
        state: locationState ?? null,
        county: countyDescription ?? null,
        zip_code: locationZipCode ?? null,
        company_name: companyName ?? null,
        website: companyWebsite ?? null,
        sic_code: primarySicCode ?? null,
        sic_code_description: primarySicCodeDescription ?? null,
        naics_code: primaryNaicsCode ?? "",
        naics_code_description: primaryNaicsCodeDescription ?? null,
        square_footage: squareFootage ?? null,
        call_result: callResult ?? 0,
        call_result_description: callResultDescription ?? null,
        credit_rating: creditRating ?? null,
        last_appointment_with: lastAppointmentWith ?? null,
        carrier_of_record: carrierOfRecord ?? null,
        employee_size: locationEmployeeSizeActual ?? null,
        renewal_date: renewalDate !== "" ? renewalDate : null,
        comments: comment ?? null,
      };

      // Dispatch in progress action
      dispatch(createLeadInProgress());
      // Make API request to create the lead
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_LEADS_URL}`, // Update to the correct URL for creating leads
        params,
        "",
        token
      );

      // Check response status and dispatch appropriate actions
      if (response?.status === 201) {
        dispatch(createLeadSuccess(response?.data));
        cb();
        toast.success("Lead has been created successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createLeadFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
     // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        createLeadFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

// Define action creators for in progress, success, and failure
export const uploadLeadCsvInProgress = () => ({
  type: UPLOAD_LEAD_CSV_IN_PROGRESS,
});

export const uploadLeadCsvSuccess = (data) => ({
  type: UPLOAD_LEAD_CSV_SUCCESS,
  data,
});

export const uploadLeadCsvFailure = (error) => ({
  type: UPLOAD_LEAD_CSV_FAILURE,
  error,
});

// Define the uploadLeadCsv method
export const uploadLeadCsv = (file, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(uploadLeadCsvInProgress());

      // Create formData object and append the file
      const formData = new FormData();
      formData.append("csv_file", file);

      // Make API request to upload the CSV file
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${LEAD_MASS_UPLOAD_URL}`,
        formData,
        "",
        token,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );

      // Check response status
      if (response?.status === 201) {
        dispatch(uploadLeadCsvSuccess(response?.data));
        cb();
        return response.data; // Resolve the promise with the response data
      } else {
        dispatch(
          uploadLeadCsvFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        throw new Error(response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN); // Reject the promise with an error
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error?.response?.data) {
        let value = error?.response?.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        uploadLeadCsvFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
      //  throw new Error(err); // Reject the promise with an error
    }
  };
};

// Action Creators
export const getLeadSearchInProgress = () => ({
  type: GET_LEAD_SEARCH_IN_PROGRESS,
});

export const getLeadSearchSuccess = (data) => ({
  type: GET_LEAD_SEARCH_SUCCESS,
  data,
});

export const getLeadSearchFailure = (error) => ({
  type: GET_LEAD_SEARCH_FAILURE,
  error,
});

const getUpdatedData = (existingData, newData, offset) => {
  return offset !== 1 ? existingData.concat(newData) : newData;
};

export const getLeadSearch = (payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const { lead } = getState();
      dispatch(getLeadSearchInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get lead search data
      const URL = `${BASE_URL}${GET_LEAD_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        
        const newData = getUpdatedData(
          lead?.leadData?.leadData,
          response?.data.results ?? [],
          payload.page ?? 1
        );
        
        const data = {leadData:newData, totalCount:response?.data?.count }
        
        dispatch(getLeadSearchSuccess(data));
        cb();
      } else {
        dispatch(
          getLeadSearchFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getLeadSearchFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for delete lead request
export const deleteLeadInProgress = () => ({
  type: DELETE_LEAD_IN_PROGRESS,
});

// Action creators for delete lead success
export const deleteLeadSuccess = (data) => ({
  type: DELETE_LEAD_SUCCESS,
  data,
});

// Action creators for delete lead failure
export const deleteLeadFailure = (error) => ({
  type: DELETE_LEAD_FAILURE,
  error,
});

// Action method for deleting a lead
export const deleteLead = (leadId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch delete lead request action
      dispatch(deleteLeadInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete lead

      let URL = `${BASE_URL}${DELETE_LEAD_URL}${leadId ?? 0}/`;

      const response = await deleteRequest(URL, {}, token);

      // Check response status
      if (response?.status === 204) {
        dispatch(deleteLeadSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteLeadFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteLeadFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const updateLeadKeyInProgress = () => ({
  type: UPDATE_LEAD_KEY_IN_PROGRESS,
});

export const updateLeadKeySuccess = (data) => ({
  type: UPDATE_LEAD_KEY_SUCCESS,
  data,
});

export const updateLeadKeyFailure = (error) => ({
  type: UPDATE_LEAD_KEY_FAILURE,
  error,
});

export const updateLeadKey = (leadId, key, value, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Create params object with the key and value to update

      const params = {
        lead_id: leadId,
        field_name: key,
        value: value,
      };

      // Dispatch in progress action
      dispatch(updateLeadKeyInProgress());

      // Make API request to update the specific key in the prospect
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${UPDATE_LEAD_URL}`,
        params,
        "",
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateLeadKeySuccess(response?.data));
        cb();
        toast.success(`Lead ${key} has been updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateLeadKeyFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateLeadKeyFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for getting lead show data
export const getLeadShowInProgress = () => ({
  type: GET_LEAD_SHOW_IN_PROGRESS,
});

export const getLeadShowSuccess = (data) => ({
  type: GET_LEAD_SHOW_SUCCESS,
  data,
});

export const getLeadShowFailure = (error) => ({
  type: GET_LEAD_SHOW_FAILURE,
  error,
});

export const getLeadShow = (leadId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getLeadShowInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get lead show data

      let URL = `${BASE_URL}${GET_LEAD_SHOW_URL}${leadId ?? 0}/`;
      const response = await getRequest(URL, token);

      // Check response status
      if (response?.status === 200) {
        dispatch(getLeadShowSuccess(response?.data));
        cb();
      } else {
        dispatch(
          getLeadShowFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getLeadShowFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for leadMassUpdate
export const leadMassUpdateInProgress = () => ({
  type: LEAD_MASS_UPDATE_IN_PROGRESS,
});

export const leadMassUpdateSuccess = () => ({
  type: LEAD_MASS_UPDATE_SUCCESS,
});

export const leadMassUpdateFailure = (error) => ({
  type: LEAD_MASS_UPDATE_FAILURE,
  error,
});

// Method for leadMassUpdate
export const leadMassUpdate = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(leadMassUpdateInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to perform lead mass update

      const URL = `${BASE_URL}${LEAD_MASS_UPDATE_URL}`;
      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200 || response?.status === 201) {
        dispatch(leadMassUpdateSuccess());
        cb();
        const message = response?.data?.message ?? "Updated successfully";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          leadMassUpdateFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        leadMassUpdateFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for creating a new note in progress
export const createNewNoteInProgress = () => ({
  type: CREATE_NEW_NOTE_IN_PROGRESS,
});

// Action creators for creating a new note success
export const createNewNoteSuccess = () => ({
  type: CREATE_NEW_NOTE_SUCCESS,
});

// Action creators for creating a new note failure
export const createNewNoteFailure = (error) => ({
  type: CREATE_NEW_NOTE_FAILURE,
  error,
});

// Method for creating a new note
export const createNewNote = (payload, cb = () => {}, isEdit = false) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(createNewNoteInProgress());

      // Make API request to create a new note
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      let URL = isEdit
        ? `${BASE_URL}${UPDATE_NOTE_URL}`
        : `${BASE_URL}${CREATE_NEW_NOTE_URL}`;
      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(createNewNoteSuccess());
        cb();
        toast.success(
          `New note has been ${isEdit ? "updated" : "created"} successfully`,
          {
            position: "bottom-center",
          }
        );
      } else {
        dispatch(
          createNewNoteFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createNewNoteFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for getting all notes in progress
export const getAllNotesInProgress = () => ({
  type: GET_ALL_NOTES_IN_PROGRESS,
});

// Action creators for getting all notes success
export const getAllNotesSuccess = (data) => ({
  type: GET_ALL_NOTES_SUCCESS,
  data,
});

// Action creators for getting all notes failure
export const getAllNotesFailure = (error) => ({
  type: GET_ALL_NOTES_FAILURE,
  error,
});

// Method for getting all notes
export const getAllNotes = (payload) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getAllNotesInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get all notes
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_NOTES_URL}`,
        token,
        payload
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(getAllNotesSuccess(response?.data));
      } else {
        dispatch(
          getAllNotesFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getAllNotesFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for deleting a note in progress
export const deleteNoteInProgress = () => ({
  type: DELETE_NOTE_IN_PROGRESS,
});

// Action creators for deleting a note success
export const deleteNoteSuccess = (data) => ({
  type: DELETE_NOTE_SUCCESS,
  data,
});

// Action creators for deleting a note failure
export const deleteNoteFailure = (error) => ({
  type: DELETE_NOTE_FAILURE,
  error,
});

// Method for deleting a note
export const deleteNote = (noteId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(deleteNoteInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const payload = {
        note_id: noteId,
      };

      let URL = `${BASE_URL}${DELETE_NOTE_URL}`;

      const response = await deleteRequest(URL, payload, token);

      // Check response status
      if (response?.status === 200) {
        dispatch(deleteNoteSuccess(response?.data));
        cb();
        toast.success("Note has been deleted successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          deleteNoteFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteNoteFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for returning credit in progress
export const returnCreditInProgress = () => ({
  type: RETURN_CREDIT_IN_PROGRESS,
});

// Action creators for returning credit success
export const returnCreditSuccess = () => ({
  type: RETURN_CREDIT_SUCCESS,
});

// Action creators for returning credit failure
export const returnCreditFailure = (error) => ({
  type: RETURN_CREDIT_FAILURE,
  error,
});

// Method for returning credit
export const returnCredit = (payload, cb = () => {}, isEdit = false) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(returnCreditInProgress());

      // Make API request to return credit
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      let URL = isEdit
        ? `${BASE_URL}${UPDATE_CREDIT_URL}`
        : `${BASE_URL}${RETURN_CREDIT_URL}`;
      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(returnCreditSuccess());
        cb();
      } else {
        dispatch(
          returnCreditFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        returnCreditFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for getting return credit in progress
export const getReturnCreditInProgress = () => ({
  type: GET_RETURN_CREDIT_IN_PROGRESS,
});

// Action creators for getting return credit success
export const getReturnCreditSuccess = (data) => ({
  type: GET_RETURN_CREDIT_SUCCESS,
  data,
});

// Action creators for getting return credit failure
export const getReturnCreditFailure = (error) => ({
  type: GET_RETURN_CREDIT_FAILURE,
  error,
});

// Method for getting return credit
export const getReturnCredit = (payload) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getReturnCreditInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get return credit
      const response = await getRequest(
        `${BASE_URL}${GET_RETURN_CREDIT_URL}`,
        token,
        payload
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(getReturnCreditSuccess(response?.data));
      } else {
        dispatch(
          getReturnCreditFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getReturnCreditFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for updating appointment status in progress
export const updateAppointmentStatusInProgress = () => ({
  type: UPDATE_APPOINTMENT_STATUS_IN_PROGRESS,
});

// Action creators for updating appointment status success
export const updateAppointmentStatusSuccess = () => ({
  type: UPDATE_APPOINTMENT_STATUS_SUCCESS,
});

// Action creators for updating appointment status failure
export const updateAppointmentStatusFailure = (error) => ({
  type: UPDATE_APPOINTMENT_STATUS_FAILURE,
  error,
});

// Method for updating appointment status
export const updateAppointmentStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(updateAppointmentStatusInProgress());

      // Make API request to update appointment status
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${UPDATE_APPOINTMENT_STATUS_URL}`,
        payload,
        "",
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateAppointmentStatusSuccess());
        cb();
      } else {
        dispatch(
          updateAppointmentStatusFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateAppointmentStatusFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for updating appointment key in progress
export const updateAppointmentKeyInProgress = () => ({
  type: UPDATE_APPOINTMENT_KEY_IN_PROGRESS,
});

// Action creators for updating appointment key success
export const updateAppointmentKeySuccess = (data) => ({
  type: UPDATE_APPOINTMENT_KEY_SUCCESS,
  data,
});

// Action creators for updating appointment key failure
export const updateAppointmentKeyFailure = (error) => ({
  type: UPDATE_APPOINTMENT_KEY_FAILURE,
  error,
});

// Action method for updating appointment key
export const updateAppointmentKey = (appId, key, value, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Create params object with the key and value to update
      const params = {
        pk: appId,
        name: key,
        value: value,
      };

      // Dispatch in progress action
      dispatch(updateAppointmentKeyInProgress());

      // Make API request to update the specific key in the appointment
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${UPDATE_APPOINTMENT_URL}`,
        params,
        "",
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateAppointmentKeySuccess(response?.data));
        cb();
        toast.success(`Updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateAppointmentKeyFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateAppointmentKeyFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for delete return credit request
export const deleteReturnCreditInProgress = () => ({
  type: DELETE_RETURN_CREDIT_IN_PROGRESS,
});

// Action creators for delete return credit success
export const deleteReturnCreditSuccess = (data) => ({
  type: DELETE_RETURN_CREDIT_SUCCESS,
  data,
});

// Action creators for delete return credit failure
export const deleteReturnCreditFailure = (error) => ({
  type: DELETE_RETURN_CREDIT_FAILURE,
  error,
});

// Action method for deleting a return credit
export const deleteReturnCredit = (returnCreditId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch delete return credit request action
      dispatch(deleteReturnCreditInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete return credit

      let URL = `${BASE_URL}${DELETE_RETURN_CREDIT_URL}`;

      const payload = { credit_id: returnCreditId };

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(deleteReturnCreditSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteReturnCreditFailure({
            type: ERROR_TYPE.API.LEADS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteReturnCreditFailure({
          type: ERROR_TYPE.API.LEADS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
