import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  ListItemText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CALL_RESULT } from "../../helpers";
import { useSelector } from "react-redux";

const green = {
  100: "#6DD6C2",
  200: "#6DD6C2",
  400: "#6DD6C2",
  500: "#6DD6C2",
  600: "#6DD6C2",
  900: "#6DD6C2",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${green[400]};
  }

  &:focus {
    border-color: ${green[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export const AddFilter = (props) => {
  // const [val, setVal] = useState([]);

  const { val, setVal } = props;

  const handleAdd = () => {
    setVal([...val, { operator: "", field: "", type: "", value: "" }]);
  };

  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
    {
      title: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
    { title: "The Good, the Bad and the Ugly", year: 1966 },
    { title: "Fight Club", year: 1999 },
    {
      title: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
    },
    {
      title: "Star Wars: Episode V - The Empire Strikes Back",
      year: 1980,
    },
    { title: "Forrest Gump", year: 1994 },
    { title: "Inception", year: 2010 },
    {
      title: "The Lord of the Rings: The Two Towers",
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: "Goodfellas", year: 1990 },
    { title: "The Matrix", year: 1999 },
    { title: "Seven Samurai", year: 1954 },
    {
      title: "Star Wars: Episode IV - A New Hope",
      year: 1977,
    },
    { title: "City of God", year: 2002 },
    { title: "Se7en", year: 1995 },
    { title: "The Silence of the Lambs", year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: "Life Is Beautiful", year: 1997 },
    { title: "The Usual Suspects", year: 1995 },
    { title: "Léon: The Professional", year: 1994 },
    { title: "Spirited Away", year: 2001 },
    { title: "Saving Private Ryan", year: 1998 },
    { title: "Once Upon a Time in the West", year: 1968 },
    { title: "American History X", year: 1998 },
    { title: "Interstellar", year: 2014 },
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );
  const handleOperatorChange = (value, i) => {
    const newData = [...val];
    newData[i].operator = value;
    setVal(newData);
  };

  const handleFieldChange = (value, i) => {
    const newData = [...val];
    newData[i].field = value;
    setVal(newData);
  };

  const handleTypeChange = (value, i) => {
    const newData = [...val];
    newData[i].type = value;
    setVal(newData);
  };

  const handleValueChange = (value, i) => {
    const newData = [...val];
    newData[i].value = value;
    setVal(newData);
  };

  const handleAutocompleteChange = (selected, data, i) => {
    const newData = [...val];
    const existingOptionIds = data.value === "" ? [] : data.value.split(",");
    const lastEle = selected.split(",").pop();

    const index = existingOptionIds.indexOf(lastEle);

    if (index !== -1) {
      // Element is present, so remove it
      existingOptionIds.splice(index, 1);
    } else {
      // Element is not present, so add it
      existingOptionIds.push(lastEle);
    }
    // // Split the selected string into an array of option IDs
    // const selectedOptionIds = selected.split(",");

    // // Toggle the selection of each option individually
    // const updatedOptionIds = selectedOptionIds.reduce((acc, optionId) => {
    //   optionId = optionId.toString().trim();
    //   if (existingOptionIds.includes(optionId)) {
    //     // Remove the ID if it's already present
    //     return acc.filter((id) => id !== optionId);
    //   } else {
    //     // Add the ID if it's not present
    //     return [...acc, optionId];
    //   }
    // }, existingOptionIds);

    // // Update the value based on the updatedOptionIds
    newData[i].value = existingOptionIds.join(",");

    setVal(newData);
  };

  const handleDelete = (i) => {
    const newData = [...val];
    newData.splice(i, 1);
    setVal(newData);
  };

  const [selectedCallResult, setSelectedCallResult] = useState([]);

  useEffect(() => {
    // Extract selected options for call_result from existing data
    const callResultField = val.find(
      (filter) => filter.field === "call_result"
    );
    if (callResultField) {
      const selectedOptions = callResultField.value
        .split(",")
        .map((id) => parseInt(id, 10));
      setSelectedCallResult(selectedOptions);
    }
  }, [val]);

  return (
    <>
      {val.map((data, i) => (
        <Box key={i} sx={{ mt: i == 0 ? 2 : 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                      marginBottom: "14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                        },
                      }}
                    >
                      Select Operator
                    </InputLabel>
                    <Select
                      label="Select Operator"
                      value={data.operator}
                      onChange={(e) => handleOperatorChange(e.target.value, i)}
                      size="small"
                      sx={{
                        width: "100%",
                        marginRight: "20px",
                        marginBottom: "14px",
                      }}
                    >
                      <MenuItem value={"and"} sx={{ fontSize: 15 }}>
                        AND
                      </MenuItem>
                      <MenuItem value={"or"} sx={{ fontSize: 15 }}>
                        OR
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                      marginBottom: "14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                        },
                      }}
                    >
                      Select Field
                    </InputLabel>
                    <Select
                      label="Select Field"
                      value={data.field}
                      onChange={(e) => handleFieldChange(e.target.value, i)}
                      size="small"
                      sx={{
                        width: "100%",
                        marginRight: "20px",
                        marginBottom: "14px",
                      }}
                    >
                      <MenuItem value="address">Address</MenuItem>
                      <MenuItem value="alternative_phone_number">
                        Alternative Phone Number
                      </MenuItem>
                      <MenuItem value="call_result">Call Result</MenuItem>
                      <MenuItem value="call_result_description">
                        Call Result Description
                      </MenuItem>
                      <MenuItem value="carrier_of_record">
                        Carrier of Record
                      </MenuItem>
                      <MenuItem value="city">City</MenuItem>
                      <MenuItem value="comments">Comments</MenuItem>
                      <MenuItem value="company_name">Company Name</MenuItem>
                      <MenuItem value="county">County</MenuItem>
                      <MenuItem value="created_at">Created At</MenuItem>
                      <MenuItem value="created_by">Created By</MenuItem>
                      <MenuItem value="credit_rating">Credit Rating</MenuItem>
                      <MenuItem value="employee_size">Employee Size</MenuItem>
                      <MenuItem value="first_name">
                        Executive First Name
                      </MenuItem>
                      <MenuItem value="last_name">Executive Last Name</MenuItem>
                      <MenuItem value="title">Executive Title</MenuItem>
                      <MenuItem value="id">ID</MenuItem>
                      <MenuItem value="last_appointment_with">
                        Last Appointment With
                      </MenuItem>
                      <MenuItem value="location">Location</MenuItem>
                      <MenuItem value="modified_at">Modified At</MenuItem>
                      <MenuItem value="modified_by">Modified By</MenuItem>
                      <MenuItem value="naics_code">NAICS Code</MenuItem>
                      <MenuItem value="naics_code_description">
                        NAICS Code Description
                      </MenuItem>
                      <MenuItem value="phone_number">Phone Number</MenuItem>
                      <MenuItem value="renewal_date">Renewal Date</MenuItem>
                      <MenuItem value="sic_code">SIC Code</MenuItem>
                      <MenuItem value="sic_code_description">
                        SIC Code Description
                      </MenuItem>
                      <MenuItem value="square_footage">Square Footage</MenuItem>
                      <MenuItem value="state">State</MenuItem>
                      <MenuItem value="website">Website</MenuItem>
                      <MenuItem value="zip_code">Zip Code</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                      marginBottom: "14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                        },
                      }}
                    >
                      Select Type
                    </InputLabel>
                    <Select
                      label="Select Type"
                      value={data.type}
                      onChange={(e) => handleTypeChange(e.target.value, i)}
                      size="small"
                      sx={{
                        width: "100%",
                        marginRight: "20px",
                        marginBottom: "14px",
                      }}
                    >
                      <MenuItem value={"startswith"} sx={{ fontSize: 15 }}>
                        Begins With
                      </MenuItem>
                      <MenuItem value={"days_gt"} sx={{ fontSize: 15 }}>
                        Days Greater Then
                      </MenuItem>
                      <MenuItem value={"days_lt"} sx={{ fontSize: 15 }}>
                        Days Less Then
                      </MenuItem>
                      <MenuItem value={"endswith"} sx={{ fontSize: 15 }}>
                        Ends With
                      </MenuItem>
                      <MenuItem value={"exact"} sx={{ fontSize: 15 }}>
                        Equal
                      </MenuItem>
                      <MenuItem value={"gte"} sx={{ fontSize: 15 }}>
                        Greater Or Equal
                      </MenuItem>
                      <MenuItem value={"gt"} sx={{ fontSize: 15 }}>
                        Greater
                      </MenuItem>
                      <MenuItem value={"icontains"} sx={{ fontSize: 15 }}>
                        Icontains
                      </MenuItem>
                      <MenuItem value={"in"} sx={{ fontSize: 15 }}>
                        In
                      </MenuItem>
                      <MenuItem value={"not_isnull"} sx={{ fontSize: 15 }}>
                        Is Not Null
                      </MenuItem>
                      <MenuItem value={"isnull"} sx={{ fontSize: 15 }}>
                        Is Null
                      </MenuItem>
                      <MenuItem value={"lte"} sx={{ fontSize: 15 }}>
                        Less Or Equal
                      </MenuItem>
                      <MenuItem value={"lt"} sx={{ fontSize: 15 }}>
                        Less
                      </MenuItem>
                      <MenuItem value={"not_startswith"} sx={{ fontSize: 15 }}>
                        Not Begins With
                      </MenuItem>
                      <MenuItem value={"not_endswith"} sx={{ fontSize: 15 }}>
                        Not Ends With
                      </MenuItem>
                      <MenuItem value={"not_exact"} sx={{ fontSize: 15 }}>
                        Not Equal
                      </MenuItem>
                      <MenuItem value={"not_icontains"} sx={{ fontSize: 15 }}>
                        Not Icontains
                      </MenuItem>
                      <MenuItem value={"not_in"} sx={{ fontSize: 15 }}>
                        Not In
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  {data.field === "sic_code" ||
                  data.field === "naics_code" ||
                  data.field === "zip_code" ? (
                    <StyledTextarea
                      aria-label="empty textarea"
                      className="textareaForCode"
                      value={data.value}
                      onChange={(e) => handleValueChange(e.target.value, i)}
                    />
                  ) : (
                    <>
                      {data.field === "call_result" ? (
                        <Select
                          multiple
                          value={data.value.split(",")}
                          onChange={(e) => {
                            const filteredArray = e.target.value?.filter(
                              (value) => value !== ""
                            );
                            const val = filteredArray.join(",");
                            handleAutocompleteChange(val, data, i);
                          }}
                          renderValue={(selected) =>
                            selected.length === 0 || data.value === ""
                              ? "None Selected"
                              : `${selected.length} Selected`
                          }
                          sx={{
                            marginBottom: 2,
                            "& .MuiInputLabel-root": {
                              "&.Mui-focused": {
                                color: "#18a689!important",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#18a689",
                            },
                          }}
                          inputProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                        >
                          {callResult?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Checkbox
                                checked={data.value
                                  .split(",")
                                  .includes(option.id.toString())}
                              />
                              <ListItemText primary={option.description} />
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Value"
                          label="Search Value"
                          type="text"
                          id="Value"
                          value={data.value}
                          onChange={(e) => handleValueChange(e.target.value, i)}
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              {i === 0 ? (
                <>
                  <Button
                    onClick={handleAdd}
                    type="submit"
                    variant="contained"
                    sx={{
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "40px",
                      height: "35px",
                      minWidth: "40px",
                      pl: 1.5,
                      pr: 1.5,
                      pt: 1.5,
                      pb: 1.5,
                      ml: 1,
                      borderRadius: "3px",
                      backgroundColor: "#6DD6C2",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                      },
                      borderColor: "#6DD6C2",
                      color: "#212223",
                    }}
                  >
                    <AddIcon sx={{ fontSize: "28px" }} />
                  </Button>
                </>
              ) : (
                // Show "Remove" button on subsequent filters
                <Button
                  onClick={() => handleDelete(i)}
                  type="submit"
                  variant="contained"
                  sx={{
                    fontWeight: 500,
                    textTransform: "capitalize",
                    width: "40px",
                    height: "35px",
                    minWidth: "40px",
                    pl: 1.5,
                    pr: 1.5,
                    pt: 1.5,
                    pb: 1.5,
                    ml: 1,
                    borderRadius: "3px",
                    backgroundColor: "#212223",
                    "&:hover": {
                      backgroundColor: "#212223",
                    },
                    borderColor: "#212223",
                    color: "#fff",
                  }}
                >
                  <DeleteForeverIcon sx={{ fontSize: "20px" }} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};
