import * as React from "react";
import Box from "@mui/material/Box";
import { LeadCreateTable } from "./LeadCreateTable";
import { useSelector } from "react-redux";

export const LeadCreateParentTable = (props) => {
  const { tableData ,custumId} = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let campaignReports = useSelector((state) => state?.reports?.campaignReport);

  const createData = (
    company,
    agent,
    campaign,
    firstName,
    lastName,
    appointmentDateTime,
    createdDateTime,
    createdBy,
    leadCategory
  ) => {
    return {
      campaign,
      agent,
      company,
      firstName,
      lastName,
      appointmentDateTime,
      createdDateTime,
      createdBy,
      leadCategory,
    };
  };

  // Create dummy rows for the specified columns and data
  const rows = [
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
  ];

  // "all_leads": [
  //   {
  //       "campaign": "NEW0101",
  //       "agent": "rohit birla",
  //       "prospect": "1-800-Got-Junk?",
  //       "first_name": "",
  //       "last_name": "",
  //       "appointment_datetime": "2023-10-27T10:00:00Z",
  //       "appointment_time": "2023-10-27T10:00:00Z",
  //       "created_by": "rohit birla",
  //       "created_at": "2023-10-27 06:59:53.539619+00:00",
  //       "category_modified_by": null,
  //       "category_modified_at": "2023-10-27 06:59:53.534085+00:00",
  //       "category": "None",
  //       "lead": 65
  //   },

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        agent,
        created_by,
        campaign,
        category_modified_at,
        category,
        appointment_datetime,
        created_at,
        appointment_time,
        first_name,
        last_name,
        category_modified_by,
        prospect,
      } = item;

      return createData(
        prospect,
        agent,
        campaign,
        first_name,
        last_name,
        appointment_datetime,
        created_at,
        created_by,
        category
      );
    });
  };

  const res =  tableData ?? [];

  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <LeadCreateTable  custumId={custumId}rows={tableRows} filterName={filterName} />
    </Box>
  );
};
