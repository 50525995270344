// LoginScreen.js
import React from "react";
import {
  Box,
  Button,
  Skeleton,
  TextField,
  Typography,
  ClickAwayListener,
  TextareaAutosize,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import {
  CALL_RESULT,
  DEVICE_STATE,
  checkAndReturnNone,
  formatDate,
  formatPhoneNumber,
  getLastAppWithName,
} from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  ActiveData,
  Calendar,
  Loader,
  TwilioVoice,
  UpdateTooltip,
  getTwilioDevice,
} from "../Common";
import { useDispatch, useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { styled } from "@mui/system";
import { CreateLead } from "./CreateLead";
import { useRef } from "react";
import { CampaignUtilizationTime } from "./CampaignUtilizationTime";
import { campaignStop, createCallHistory } from "../../store/actions/report";
import { updateCallingInfo, updateProspectKey } from "../../store/actions";

const green = {
  100: "#6DD6C2",
  200: "#6DD6C2",
  400: "#6DD6C2",
  500: "#6DD6C2",
  600: "#6DD6C2",
  900: "#6DD6C2",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 475px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${green[400]};
  }

  &:focus {
    border-color: ${green[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? green[500] : green[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: 3,
  borderColor: "#6DD6C2",
  p: 3,
  borderRadius: "7px",
  "&:focus-visible": {
    outline: "0",
  },
};

export const CampaignsShow = (props) => {
  const {
    onCampaignShow,
    campaignDetails,
    onCallBackShow,
    onCallBackScheduled,
    getAllCalendarEvents,
    onCreateLeadAppointment,
    updatePropspectKey,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  const { details, isFrom, isAutoCalling } = location.state || {};

  const fetchCampaignDetails = () => {
    onCampaignShow(details.id);
    getAllCalendarEvents(details?.agent);
  };

  const fetchCallbackDetails = () => {
    const campaignId = details?.agency_calling_for ?? 0;
    const prospectId = details?.prospect ?? 0;

    onCallBackShow(campaignId, prospectId);
    getAllCalendarEvents(details?.agent);
  };

  const [open1, set1Open] = useState(false);
  const [isAutoCall, setIsAutoCall] = useState(null);

  const [createLead, setCreateLead] = useState(false);
  const [data, setData] = useState("");
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [tooltipID, setTooltipID] = useState(null);

  const handle1ClickOpen = () => {
    set1Open(true);
  };

  const handle1Close = () => {
    setCallbackInfo(initailCallBack);
    setTriedSubmit(false);
    set1Open(false);
  };

  useEffect(() => {
    if (isFrom === "callbacks") {
      details && fetchCallbackDetails();
    } else {
      details && fetchCampaignDetails();
    }
  }, [details]);

  const iscampaignloading = useSelector((state) => state?.campaign?.isloading);

  const isCallBackloading = useSelector((state) => state?.callback?.isLoading);

  const isCreadLeadAppLoading = useSelector(
    (state) => state?.campaign?.isCreadLeadAppLoading
  );

  const campaignData = useSelector((state) => state?.campaign?.campaignDetails);
  const callBackData = useSelector((state) => state?.callback?.callbackDetails);

  const campaignInfo = isFrom !== "callbacks" ? campaignData : callBackData;
  // console.log(campaignInfo,"campaignInfo")

  const [prospectDetails, setProspectDetails] = useState(
    campaignInfo?.prospect
  );

  const isloading =
    isFrom !== "callbacks"
      ? iscampaignloading || !prospectDetails
      : isCallBackloading;

  useEffect(() => {
    if (campaignInfo) {
      setProspectDetails(campaignInfo.prospect);
    }
  }, [campaignInfo]);

  const allUsers = useSelector((state) => state?.prospect?.user_details?.data);

  const calendarEvents = useSelector(
    (state) => state?.campaign?.calendarEvents
  );
  const targetCreateLeadRef = useRef(null);

  const campaignLogId = useSelector(
    (state) => state?.campaign?.campaignDetails?.campaign_log_id
  );

  const isCampaignStopLoading = useSelector(
    (state) => state?.reports?.isStopCampaignLoading
  );
  const isUpdateKeyLoading = useSelector(
    (state) => state?.prospect?.isUpdateKeyIsloading
  );

  const getCallResultLabel = (id) => {
    const result = callResult.find((item) => item.id === id);
    return result ? result.description : "Unknown"; // Return "Unknown" if id not found in CALL_RESULT
  };

  useEffect(() => {
    if (isAutoCalling && campaignData && isAutoCall === null) {
      setIsAutoCall(true);
    }
  }, [campaignData]);

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const openModalForCallBack = (value) => {
    if (value === 7) {
      //for updating call back scheduled
      handle1ClickOpen();
    } else if (value === 10) {
      //for Set appoinment
      setCreateLead(true);
      handleTooltipClose(null);
      targetCreateLeadRef &&
        targetCreateLeadRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      
      if (getCallResultLabel(prospectDetails?.call_result) === value) {
        handleTooltipClose();
        const payload = {
          call_result: prospectDetails?.call_result,
        };
        dispatch(
          updateProspectKey(prospectDetails?.id, payload, [], () => {
            handleTooltipClose();
            finalUpdateProspect();
          })
        );
        setCreateLead(false);
      } else {
        const payload = {
          call_result: value,
        };
        dispatch(
          updateProspectKey(prospectDetails?.id, payload, [], () => {
            handleTooltipClose();
            finalUpdateProspect();
          })
        );
        setCreateLead(false);
      }
    }
  };

  useEffect(() => {
    if (createLead) {
      targetCreateLeadRef &&
        targetCreateLeadRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [createLead]);

  const initailCallBack = {
    dateTime: "",
    callBackReason: "",
  };

  const [callBackInfo, setCallbackInfo] = useState(initailCallBack);

  //calback form validation
  const dateTimeError =
    triedSubmit && callBackInfo?.dateTime?.toString()?.trim().length === 0;

  const dateTimeErrorMessage =
    triedSubmit && callBackInfo?.dateTime?.toString().trim().length === 0
      ? "Please enter valid date time"
      : "";

  const isCheckFormValid = () => {
    const isDateTimeValid =
      callBackInfo?.dateTime?.toString()?.trim().length > 0;
    return isDateTimeValid;
  };
  const isFormValid = isCheckFormValid();

  const callBackFunc = () => {
    const payload = {
      call_result: 7,
    };
    dispatch(
      updateProspectKey(prospectDetails?.id, payload, [], () => {
        finalUpdateProspect();
      })
    );
    handle1Close();
    handleTooltipClose();
  };

  const callBackSubmit = () => {
      setTriedSubmit(true);
      if (!isFormValid) return;
      //console.log(callBackInfo);
      const payload = {
        ...callBackInfo,
        prospectId: prospectDetails?.id ?? null,
        campaignId: campaignInfo?.campaign_id ?? null,
        agentId: campaignInfo?.agent_id ?? null,
      };
      onCallBackScheduled(payload, callBackFunc);
    };

  const leadAppCallBack = () => {
    finalUpdateProspect();
    setCreateLead(false);
    getAllCalendarEvents(details?.agent);
  };

  const onCreateLead = (data, cb = () => {}) => {
    const payload = {
      ...data,
      campaignInfo,
    };
    
    onCreateLeadAppointment(payload, () => {
      leadAppCallBack();
      cb();
    });
  };

  //prospect timer

  const [stopTimer, setStopTimer] = useState(false);
  let seconds = 0;
  const [previewTime, setPreviewTime] = useState(false);
  let idleTime = false;
  let wrapupTime = false;
  let idleTimeSeconds = 0;
  const [id, setId] = useState("");

  const [currentLocation, setCurrentLocation] = useState(null);

  const timerRef = useRef(null);

  const timer = () => {
    if (stopTimer) {
      return;
    }

    seconds++;

    if (seconds === 15 && !previewTime) {
      updateTimer(seconds, "preview");
      setPreviewTime(true);
      seconds = 0;
      idleTime = true;
    } else if (seconds === 30 && !wrapupTime && !idleTime) {
      updateTimer(seconds, "wrapup");
      wrapupTime = true;
      seconds = idleTime ? idleTimeSeconds : 0;
      idleTime = true;
    } else if (seconds % 15 === 0 && idleTime) {
      idleTimeSeconds = seconds;
      updateTimer(idleTimeSeconds, "idle");
    }

    timerRef.current = setTimeout(timer, 1000);
  };

  useEffect(() => {
    timer();

    // Check if the browser supports Geolocation
    if (navigator.geolocation) {
      // Get the current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation(`${latitude},${longitude}`);
        },
        (error) => {
          // console.error("Error getting current location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }

    return () => {
      setStopTimer(true);
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleOpenGoogleMapsForCurrentLocation = () => {
    if (currentLocation) {
      // Construct the Google Maps URL for directions
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation}&destination=${prospectDetails?.address},${prospectDetails?.city},${prospectDetails?.state}&travelmode=driving`;

      // Open a new tab with the Google Maps URL
      const newTab = window.open(googleMapsUrl, "_blank");

      if (newTab) {
        newTab.focus();
      }
    }
  };

  const handleOpenGoogleMapsForAgentLocation = () => {
    if (campaignInfo?.user_data?.street) {
      // Construct the Google Maps URL for directions
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${campaignInfo?.user_data?.street},${campaignInfo?.user_data?.city},${campaignInfo?.user_data?.state}&destination=${prospectDetails?.address},${prospectDetails?.city},${prospectDetails?.state}&travelmode=driving`;

      // Open a new tab with the Google Maps URL
      const newTab = window.open(googleMapsUrl, "_blank");

      if (newTab) {
        newTab.focus();
      }
    }
  };

  const updateTimer = (time, type) => {
    const data = {
      seconds: time,
      type,
      id,
    };
  };

  const onCallHistory = (payload) => {
    if (!previewTime && seconds < 15) {
      payload.preview_time_seconds = seconds;
      dispatch(
        createCallHistory(payload, () => {
          changeStopTimer(false);
          timer();
          setPreviewTime(true);
        })
      );
    } else {
      dispatch(
        createCallHistory(payload, () => {
          changeStopTimer(false);
          timer();
        })
      );
    }
  };

  // const resetIdleTime = () => {
  //   setSeconds(0);
  //   setIdleTime(false);
  // };

  const changeStopTimer = (bool) => {
    setStopTimer(bool);
    seconds = 0;
    idleTime = false;
  };

  const stopCampaign = () => {
    dispatch(
      campaignStop(campaignLogId, () => {
        navigate(-1);
      })
    );
  };

  const onChangeProspectKey = (key, val) => {
    setProspectDetails((prevDetails) => ({
      ...prevDetails,
      [key]: val,
    }));
    handleTooltipClose();

    const payload = {
      [key]: val,
    };
    
    dispatch(
      updateProspectKey(prospectDetails?.id, payload, [], () => {
        handleTooltipClose();
      })
    );

  };

  const finalUpdateProspect = () => {
    dispatch(
      updateProspectKey(
        prospectDetails?.id,
        prospectDetails,
        ["call_result"], // Exclude 'call_result'
        () => {
          // 
          onCampaignShow(campaignInfo.campaign_id);
          handleTooltipClose();
        }
      )
    );
  };

  return (
    <>
      <CampaignUtilizationTime />
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "20px",
          marginBottom: 8,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderTopLeftRadius: "10px!Important",
                  borderTopRightRadius: "10px!Important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: "#212223", pointerEvents: "auto" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: 450,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: 18,
                        color: "#212223",
                        fontWeight: 600,
                        flexWrap: "nowrap",
                      }}
                    >
                      Campaign Name :
                    </Typography>

                    {isloading ? (
                      <Skeleton
                        variant="rectangular"
                        width="50%"
                        height={25}
                        sx={{
                          ml: 1,
                        }}
                        animation="wave"
                      />
                    ) : (
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 18,
                          color: "#454647",
                          fontWeight: 600,
                          display: "initial",
                          ml: 1,
                        }}
                      >
                        {campaignInfo?.campaign_name ?? "None"}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                {isFrom !== "callbacks" && (
                  <>
                    <Button
                      variant="contained"
                      onClick={stopCampaign}
                      sx={{
                        textTransform: "capitalize",
                        width: "100%",
                        maxWidth: "max-content",
                        backgroundColor: "#212223",
                        borderColor: "#212223",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#212223",
                          borderColor: "#212223",
                        },
                      }}
                    >
                      Stop Campaign
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setIsAutoCall(!isAutoCall);
                      }}
                      sx={{
                        mr: 2,
                        textTransform: "capitalize",
                        width: "100%",
                        maxWidth: "max-content",
                        backgroundColor: isAutoCall ? "#fc8003" : "#6DD6C2",
                        borderColor: "#6DD6C2",
                        color: isAutoCall ? "#fff" : "#212223",
                        fontWeight: 600,
                        "&:hover": {
                          backgroundColor: isAutoCall ? "#fc8003" : "#6DD6C2",
                          borderColor: isAutoCall ? "#fc8003" : "#6DD6C2",
                        },
                      }}
                    >
                      {!isAutoCall ? "Start" : "Pause"} Auto Calling
                    </Button>
                  </>
                )}
              </Box>
              <AccordionDetails>
                <Box sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Full Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {`${campaignInfo?.user_data?.first_name} ${campaignInfo?.user_data?.last_name}`}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Account Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {checkAndReturnNone(campaignInfo?.account_name)}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Phone Number
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {formatPhoneNumber(
                                campaignInfo?.user_data?.phone_number
                              ) ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Amount Owed
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#5ae4cb",
                                fontWeight: 600,
                              }}
                            >
                              {campaignInfo?.amount_owned ?? 0}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Campaign Status
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.campaign_status ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Agency Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.agency_name ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Email
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.user_data?.email ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                            }}
                          >
                            Notes
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Box
                              sx={{
                                height: "50px",
                                maxWidth: "180px",
                                width: "100%",
                                overflowY: "scroll",
                              }}
                            >
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 13,
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  lineHeight: "17px",
                                }}
                              >
                                {campaignInfo?.user_data?.notes ?? "None"}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Address Street
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.user_data?.street ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Address State
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.user_data?.state ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Address City
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.user_data?.city ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            component="h4"
                            variant="h4"
                            sx={{
                              fontSize: 14,
                              color: "#454647",
                              fontWeight: 600,
                              paddingLeft: { xs: "0px", sm: "0px", md: "15px" },
                            }}
                          >
                            Address Zip Code
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {isloading ? (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={18}
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              component="h4"
                              variant="h4"
                              sx={{
                                fontSize: 13,
                                color: "#6B7489",
                                fontWeight: 500,
                              }}
                            >
                              {campaignInfo?.user_data?.zip_code ?? "None"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            {campaignInfo?.prospects_count !== 0 || isloading ? (
              <>
                <Accordion
                  defaultExpanded
                  sx={{ borderRadius: "10px!Important" }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "grid",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "15px",
                      borderBottom: 1,
                      borderColor: "#676a6c4a",
                      backgroundColor: "#90949f21",
                      borderTopLeftRadius: "10px!Important",
                      borderTopRightRadius: "10px!Important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{ color: "#212223", pointerEvents: "auto" }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ width: "100%" }}
                    >
                      <Typography
                        component="h2"
                        variant="h2"
                        sx={{
                          fontSize: 18,
                          color: "#212223",
                          fontWeight: 600,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        Prospects Information | Total Prospects Count :{" "}
                        {isloading ? (
                          <Skeleton
                            variant="rectangular"
                            width="5%"
                            style={{ marginLeft: 5 }}
                            height={28}
                            animation="wave"
                          />
                        ) : (
                          <Typography
                            component="h2"
                            variant="h2"
                            sx={{
                              fontSize: 18,
                              color: "#454647",
                              fontWeight: 600,
                              marginLeft: "5px",
                              display: "initial",
                            }}
                          >
                            ( {campaignInfo?.prospects_count} )
                          </Typography>
                        )}
                      </Typography>
                    </AccordionSummary>
                    
                  </Box>
                  <AccordionDetails>
                    <Box
                      sx={{
                        padding: "20px 10px",
                      }}
                    >
                      <Box>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Call Result -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Box sx={{ alignItems: "center" }}>
                                    <ClickAwayListener
                                      onClickAway={
                                        open1 ? () => {} : handleTooltipClose
                                      }
                                    >
                                      <div>
                                        <Tooltip
                                          sx={{
                                            marginTop: "0!important",
                                            " & .MuiTooltip-tooltip": {
                                              marginTop: "0!important",
                                            },
                                            backgroundColor: "#ffffff",
                                            borderRadius: "5px",
                                            borderColor: "black",
                                          }}
                                          arrow
                                          PopperProps={{
                                            disablePortal: false,
                                          }}
                                          onClose={handleTooltipClose}
                                          open={tooltipID === 1 ? true : false}
                                          disableFocusListener
                                          disableHoverListener
                                          disableTouchListener
                                          title={
                                            <UpdateTooltip
                                              onClose={handleTooltipClose}
                                              label={"Call result"}
                                              onSubmit={openModalForCallBack}
                                              selectedKey={getCallResultLabel(
                                                prospectDetails?.call_result
                                              )}
                                              inputType={"SelectOptions"}
                                              optionsData={callResult}
                                              labelKey={"description"}
                                              value={data}
                                              setValue={setData}
                                            />
                                          }
                                        >
                                          <Button
                                            sx={{
                                              "&:hover": {
                                                color: "#6B7489",
                                              },
                                              height: "20px",
                                              minWidth: "50px",
                                              padding: 0,
                                              fontWeight: 500,
                                              borderStyle: "dashed",
                                              borderWidth: 1,
                                              borderColor: "#0088cc",
                                              borderTop: 0,
                                              borderRight: 0,
                                              color: "#0088cc",
                                              borderLeft: 0,
                                              maxWidth: "fit-content",
                                              position: "relative",
                                              cursor: "pointer",
                                              fontSize: "14px",
                                              textTransform: "capitalize",
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              const val = getCallResultLabel(
                                                prospectDetails?.call_result
                                              );
                                              setData(val); // Prevent event propagation to TableRow
                                              handleTooltipOpen(1);
                                            }}
                                          >
                                            {getCallResultLabel(
                                              prospectDetails?.call_result
                                            )}
                                          </Button>
                                        </Tooltip>
                                      </div>
                                    </ClickAwayListener>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Phone Number -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Box sx={{ alignItems: "center" }}>
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      sx={{
                                        fontSize: 13,
                                        color: "#6B7489",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {formatPhoneNumber(
                                        prospectDetails?.phone_number
                                      ) ?? "None"}
                                      {prospectDetails?.phone_number && (
                                        <TwilioVoice
                                          phoneNumber={
                                            prospectDetails?.phone_number
                                          }
                                          callInfo={campaignInfo}
                                          callHistory={onCallHistory}
                                          isAutoCall={isAutoCall}
                                        />
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Executive Title -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 2 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Title"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey("title", value);
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.title
                                        );
                                        setData(val);
                                        handleTooltipOpen(2);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.title
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Executive Last Name -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 3 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Last Name"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "last_name",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.last_name
                                        );
                                        setData(val);
                                        handleTooltipOpen(3);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.last_name
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Email -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 4 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Email"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey("email", value);
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.email
                                        );
                                        setData(val);
                                        handleTooltipOpen(4);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.email
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Company Website -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 5 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Company Website"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey("website", value);
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.website
                                        );
                                        setData(val);
                                        handleTooltipOpen(5);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        color: prospectDetails?.website
                                          ? "#0088cc"
                                          : "#DD1144",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.website
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Primary Sic Code Description -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 6 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Description"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "sic_code_description",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.sic_code_description
                                        );
                                        setData(val);
                                        handleTooltipOpen(6);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.sic_code_description
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Primary Naics Code Description -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 7 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Description"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "naics_code_description",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.naics_code_description
                                        );
                                        setData(val);
                                        handleTooltipOpen(7);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.naics_code_description
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Carrier Of Record -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 8 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Carrier Of Record"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "carrier_of_record",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.carrier_of_record
                                        );
                                        setData(val);
                                        handleTooltipOpen(8);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.carrier_of_record
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Year In Business -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 9 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Year In Business"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "year_in_business",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.year_in_business
                                        );
                                        setData(val);
                                        handleTooltipOpen(9);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.year_in_business
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Modified By -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {prospectDetails?.modified_by ?? "None"}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Created By -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {prospectDetails?.created_by ?? "None"}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Renewal Date -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 10 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Renewal Date"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "renewal_date",
                                            value
                                          );
                                        }}
                                        inputType={"Date"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.renewal_date
                                        );
                                        setData(val);
                                        handleTooltipOpen(10);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.renewal_date
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Alternative Phone Number -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Tooltip
                                      sx={{
                                        marginTop: "0!important",
                                        " & .MuiTooltip-tooltip": {
                                          marginTop: "0!important",
                                        },
                                        backgroundColor: "#ffffff",
                                        borderRadius: "5px",
                                        borderColor: "black",
                                      }}
                                      arrow
                                      placement="bottom-start"
                                      PopperProps={{
                                        disablePortal: false,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={tooltipID === 11 ? true : false}
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title={
                                        <UpdateTooltip
                                          onClose={handleTooltipClose}
                                          label={"Alternative Phone Number"}
                                          onSubmit={(value) => {
                                            onChangeProspectKey(
                                              "alternative_phone_number",
                                              value
                                            );
                                          }}
                                          inputType={"Text"}
                                          value={data}
                                          setValue={setData}
                                        />
                                      }
                                    >
                                      <Typography
                                        component="h4"
                                        variant="h4"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          const val =
                                            formatPhoneNumber(
                                              prospectDetails?.alternative_phone_number
                                            ) ?? "None";
                                          setData(val);
                                          handleTooltipOpen(11);
                                        }}
                                        sx={{
                                          fontSize: 13,
                                          fontWeight: 500,
                                          borderStyle: "dashed",
                                          borderWidth: 1,
                                          borderColor: "#0088cc",
                                          borderTop: 0,
                                          borderRight: 0,
                                          color: "#0088cc",
                                          borderLeft: 0,
                                          maxWidth: "fit-content",
                                          position: "relative",
                                          cursor: "pointer",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {formatPhoneNumber(
                                          prospectDetails?.alternative_phone_number
                                        ) ?? "None"}
                                      </Typography>
                                    </Tooltip>
                                    {prospectDetails?.alternative_phone_number && (
                                      <TwilioVoice
                                        phoneNumber={
                                          prospectDetails?.alternative_phone_number
                                        }
                                        callInfo={campaignInfo}
                                        callHistory={onCallHistory}
                                        isAutoCall={isAutoCall}
                                      />
                                    )}
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Executive First Name -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 12 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"First Name"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "first_name",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.first_name
                                        );
                                        setData(val);
                                        handleTooltipOpen(12);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.first_name
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Company Name -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 13 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Company Name"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "company_name",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.company_name
                                        );
                                        setData(val);
                                        handleTooltipOpen(13);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.company_name
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Primary Sic Code -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 14 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Primary Sic Code"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "sic_code",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.sic_code
                                        );
                                        setData(val);
                                        handleTooltipOpen(14);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.sic_code
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Primary Naics Code -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 15 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Primary Naics Code"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "naics_code",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.naics_code
                                        );
                                        setData(val);
                                        handleTooltipOpen(15);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.naics_code
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Last Appointment With -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {getLastAppWithName(
                                      prospectDetails?.last_appointment_with,
                                      allUsers
                                    )}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Location Employee Size Actual -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(
                                      prospectDetails?.employee_size
                                    )}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Comments -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltipID === 16 ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Comments"}
                                        onSubmit={(value) => {
                                          onChangeProspectKey(
                                            "comments",
                                            value
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          prospectDetails?.comments
                                        );
                                        setData(val);
                                        handleTooltipOpen(16);
                                      }}
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: "#0088cc",
                                        borderTop: 0,
                                        borderRight: 0,
                                        color: "#0088cc",
                                        borderLeft: 0,
                                        maxWidth: "fit-content",
                                        position: "relative",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {checkAndReturnNone(
                                        prospectDetails?.comments
                                      )}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Modified At -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {formatDate(prospectDetails?.modified_at) ??
                                      "None"}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 0.2 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Created At -
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                {isloading ? (
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={18}
                                    animation="wave"
                                  />
                                ) : (
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    sx={{
                                      fontSize: 13,
                                      color: "#6B7489",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {formatDate(prospectDetails?.created_at) ??
                                      "None"}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <Box
                sx={{
                  padding: "15px",
                  boxShadow: 3,
                  borderRadius: "10px!Important",
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  sx={{
                    fontSize: 14,
                    color: "#ff3131",
                    fontWeight: 600,
                  }}
                >
                  No Prospect Available for this Campaign
                </Typography>
              </Box>
            )}
          </Grid>
          {(campaignInfo?.prospects_count !== 0 || isloading) && (
            <Grid item xs={12} md={12}>
              <Accordion
                defaultExpanded
                sx={{ borderRadius: "10px!Important" }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "grid",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "15px",
                    borderBottom: 1,
                    borderColor: "#676a6c4a",
                    backgroundColor: "#90949f21",
                    borderTopLeftRadius: "10px!Important",
                    borderTopRightRadius: "10px!Important",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "#212223", pointerEvents: "auto" }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                    >
                      Address Information
                    </Typography>
                  </AccordionSummary>
                </Box>
                <AccordionDetails>
                  <Box
                    sx={{
                      padding: "20px 10px",
                    }}
                  >
                    <Box>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Location Address -
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              {isloading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={18}
                                  animation="wave"
                                />
                              ) : (
                                <Tooltip
                                  sx={{
                                    marginTop: "0!important",
                                    " & .MuiTooltip-tooltip": {
                                      marginTop: "0!important",
                                    },
                                    backgroundColor: "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "black",
                                  }}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    disablePortal: false,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={tooltipID === 17 ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title={
                                    <UpdateTooltip
                                      onClose={handleTooltipClose}
                                      label={"Address"}
                                      onSubmit={(value) => {
                                        onChangeProspectKey("address", value);
                                      }}
                                      inputType={"Text"}
                                      value={data}
                                      setValue={setData}
                                    />
                                  }
                                >
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const val = checkAndReturnNone(
                                        prospectDetails?.address
                                      );
                                      setData(val);
                                      handleTooltipOpen(17);
                                    }}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      borderStyle: "dashed",
                                      borderWidth: 1,
                                      borderColor: "#0088cc",
                                      borderTop: 0,
                                      borderRight: 0,
                                      color: "#0088cc",
                                      borderLeft: 0,
                                      maxWidth: "fit-content",
                                      position: "relative",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(
                                      prospectDetails?.address
                                    )}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 0.2 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Location State -
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              {isloading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={18}
                                  animation="wave"
                                />
                              ) : (
                                <Tooltip
                                  sx={{
                                    marginTop: "0!important",
                                    " & .MuiTooltip-tooltip": {
                                      marginTop: "0!important",
                                    },
                                    backgroundColor: "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "black",
                                  }}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    disablePortal: false,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={tooltipID === 18 ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title={
                                    <UpdateTooltip
                                      onClose={handleTooltipClose}
                                      label={"State"}
                                      onSubmit={(value) => {
                                        onChangeProspectKey("state", value);
                                      }}
                                      inputType={"Text"}
                                      value={data}
                                      setValue={setData}
                                    />
                                  }
                                >
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const val = checkAndReturnNone(
                                        prospectDetails?.state
                                      );
                                      setData(val);
                                      handleTooltipOpen(18);
                                    }}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      borderStyle: "dashed",
                                      borderWidth: 1,
                                      borderColor: "#0088cc",
                                      borderTop: 0,
                                      borderRight: 0,
                                      color: "#0088cc",
                                      borderLeft: 0,
                                      maxWidth: "fit-content",
                                      position: "relative",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(prospectDetails?.state)}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 0.2 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                County Description -
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              {isloading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={18}
                                  animation="wave"
                                />
                              ) : (
                                <Tooltip
                                  sx={{
                                    marginTop: "0!important",
                                    " & .MuiTooltip-tooltip": {
                                      marginTop: "0!important",
                                    },
                                    backgroundColor: "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "black",
                                  }}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    disablePortal: false,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={tooltipID === 19 ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title={
                                    <UpdateTooltip
                                      onClose={handleTooltipClose}
                                      label={"County Description"}
                                      onSubmit={(value) => {
                                        onChangeProspectKey("county", value);
                                      }}
                                      inputType={"Text"}
                                      value={data}
                                      setValue={setData}
                                    />
                                  }
                                >
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const val = checkAndReturnNone(
                                        prospectDetails?.county
                                      );
                                      setData(val);
                                      handleTooltipOpen(19);
                                    }}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      borderStyle: "dashed",
                                      borderWidth: 1,
                                      borderColor: "#0088cc",
                                      borderTop: 0,
                                      borderRight: 0,
                                      color: "#0088cc",
                                      borderLeft: 0,
                                      maxWidth: "fit-content",
                                      position: "relative",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(
                                      prospectDetails?.county
                                    )}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Location City -
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              {isloading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={18}
                                  animation="wave"
                                />
                              ) : (
                                <Tooltip
                                  sx={{
                                    marginTop: "0!important",
                                    " & .MuiTooltip-tooltip": {
                                      marginTop: "0!important",
                                    },
                                    backgroundColor: "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "black",
                                  }}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    disablePortal: false,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={tooltipID === 20 ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title={
                                    <UpdateTooltip
                                      onClose={handleTooltipClose}
                                      label={"City"}
                                      onSubmit={(value) => {
                                        onChangeProspectKey("city", value);
                                      }}
                                      inputType={"Text"}
                                      value={data}
                                      setValue={setData}
                                    />
                                  }
                                >
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const val = checkAndReturnNone(
                                        prospectDetails?.city
                                      );
                                      setData(val);
                                      handleTooltipOpen(20);
                                    }}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      borderStyle: "dashed",
                                      borderWidth: 1,
                                      borderColor: "#0088cc",
                                      borderTop: 0,
                                      borderRight: 0,
                                      color: "#0088cc",
                                      borderLeft: 0,
                                      maxWidth: "fit-content",
                                      position: "relative",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(prospectDetails?.city)}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 0.5 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Location Zip Code -
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              {isloading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={18}
                                  animation="wave"
                                />
                              ) : (
                                <Tooltip
                                  sx={{
                                    marginTop: "0!important",
                                    " & .MuiTooltip-tooltip": {
                                      marginTop: "0!important",
                                    },
                                    backgroundColor: "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "black",
                                  }}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    disablePortal: false,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={tooltipID === 21 ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title={
                                    <UpdateTooltip
                                      onClose={handleTooltipClose}
                                      label={"ZipCode"}
                                      onSubmit={(value) => {
                                        onChangeProspectKey("zip_code", value);
                                      }}
                                      inputType={"Text"}
                                      value={data}
                                      setValue={setData}
                                    />
                                  }
                                >
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const val = checkAndReturnNone(
                                        prospectDetails?.zip_code
                                      );
                                      setData(val);
                                      handleTooltipOpen(21);
                                    }}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      borderStyle: "dashed",
                                      borderWidth: 1,
                                      borderColor: "#0088cc",
                                      borderTop: 0,
                                      borderRight: 0,
                                      color: "#0088cc",
                                      borderLeft: 0,
                                      maxWidth: "fit-content",
                                      position: "relative",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkAndReturnNone(prospectDetails?.zip_code)}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 0.5 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography
                                component="h4"
                                variant="h4"
                                sx={{
                                  fontSize: 14,
                                  color: "#454647",
                                  fontWeight: 600,
                                }}
                              >
                                Google Directions From
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleOpenGoogleMapsForCurrentLocation();
                                }}
                                sx={{
                                  fontSize: "13px",
                                  mr: 2,
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Current Location
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleOpenGoogleMapsForAgentLocation();
                                }}
                                sx={{
                                  fontSize: "13px",
                                  textTransform: "capitalize",
                                  width: "100%",
                                  maxWidth: "max-content",
                                  backgroundColor: "#6DD6C2",
                                  borderColor: "#6DD6C2",
                                  color: "#212223",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                  },
                                }}
                              >
                                Agent Location
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <CreateLead
                ref={targetCreateLeadRef}
                onCreateLead={onCreateLead}
              />
            </Grid>
          )}

          <Box
            sx={{
              width: "100%",
              marginTop: "25px",
              marginLeft: "25px",
            }}
          >
            <Calendar
              events={calendarEvents?.event_array}
              isFromCampaign={true}
            />
          </Box>
          {/* <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10px 0 0!Important",
                    pointerEvents: "none",

                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Sync Calendar Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{pt:2, pb:1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={2} sx={{alignItems:'center'}}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Sync Outlook Calendar
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "100%",
                                    maxWidth: "max-content",
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                    color: "#212223",
                                    fontWeight: 600,
                                    "&:hover": {
                                      backgroundColor: "#6DD6C2",
                                      borderColor: "#6DD6C2",
                                    },
                                  }}
                                  >
                                  Outlook Login
                                </Button>
                              </Grid>
                            </Grid> 
                                              
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={2} sx={{alignItems:'center'}}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  component="h4"
                                  variant="h4"
                                  sx={{
                                    fontSize: 14,
                                    color: "#454647",
                                    fontWeight: 600,
                                  }}
                                >
                                  Account Status
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "100%",
                                    maxWidth: "max-content",
                                    backgroundColor: "#6DD6C2",
                                    borderColor: "#6DD6C2",
                                    color: "#212223",
                                    fontWeight: 600,
                                    "&:hover": {
                                      backgroundColor: "#6DD6C2",
                                      borderColor: "#6DD6C2",
                                    },
                                  }}
                                  >
                                  Google Login
                                </Button>
                              </Grid>
                            </Grid> 
                                                     
                        </Grid>                       
                    </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid> */}
        </Grid>
        <Modal
          open={open1}
          onClose={handle1Close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 9999,
          }}
        >
          <Box sx={style}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 18,
                color: "#212223",
                fontWeight: 600,
                backgroundColor: "#90949f21",
                pt: 1.5,
                pl: 1.5,
                pb: 1.5,
                mb: 2.5,
              }}
            >
              Callback Date Time
            </Typography>
            <Box>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  fontSize: 15,
                  color: "#6B7489                        ",
                  fontWeight: 600,
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                Date And Time
              </Typography>
              <Box sx={{ marginTop: "10px", gap: "10px" }}>
                <Box>
                  <TextField
                    name="dateTime"
                    type="datetime-local"
                    value={callBackInfo?.dateTime}
                    onChange={(e) => {
                      setCallbackInfo({
                        ...callBackInfo,
                        dateTime: e.target.value,
                      });
                    }}
                    error={dateTimeError}
                    helperText={dateTimeError && dateTimeErrorMessage}
                    ampm={true}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2!important",
                        },
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  fontSize: 15,
                  color: "#6B7489",
                  fontWeight: 600,
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                Callback Reason
              </Typography>
              <Box sx={{ marginTop: "10px", gap: "10px" }}>
                <Box>
                  <StyledTextarea
                    aria-label="empty textarea"
                    placeholder="Type Something..."
                    value={callBackInfo?.callBackReason}
                    onChange={(e) => {
                      setCallbackInfo({
                        ...callBackInfo,
                        callBackReason: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: 1,
                borderColor: "#dcdcdc",
                mt: 3.5,
              }}
            >
              <Button
                variant="contained"
                onClick={callBackSubmit}
                sx={{
                  mt: 2.5,
                  mr: 2,
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Okay
              </Button>
              <Button
                onClick={handle1Close}
                variant="contained"
                sx={{
                  mt: 2.5,
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#000",
                  borderColor: "#6DD6C2",
                  color: "#fff",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#000",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        <Loader
          loading={
            tooltipID ? isCallBackloading : isCreadLeadAppLoading || false
          }
        />
        <Loader loading={isCampaignStopLoading || isUpdateKeyLoading} />
      </Box>
    </>
  );
};
