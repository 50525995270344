import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { ProfileModal } from "../../../Common";
import { CallTableHead } from "./CallTableHead";

export const CallTable = (props) => {
  const { rows } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Username");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    if (!event.target.closest(".show-button")) {
      // Exclude clicks on the "Show" button
      const selectedIndex = selected.indexOf(row?.operations?.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row?.operations?.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  let loading = useSelector((state) => state?.home?.isUserProdLoading);

  let total_dict = useSelector(
    (state) => state?.home?.userProduction?.total_dict
  );

  const TotalTableRow = ({ totalDict }) => {
    return (
      <TableRow
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          borderBottom: "0!important",
          "&:hover": {
            backgroundColor: "#F4F6FA!important",
          },
        }}
      >
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          Total
        </TableCell>

        {/* {
       "total_active_hours": "0:0:0",
       "total_total_login_time": "0:0:0",
       "total_total_utilization_time": "0:0:0",
       "total_total_points": 0.0,
       "total_valid_point_per_hour": 0.0,
       "total_hourly_earning": 0.0,
       "total_commission_only": 0,
       "total_bonus_pay": 0.0,
       "total_standard_leads": 0,
       "total_plus_leads": 0,
       "total_call_counts": 0,
       "total_total_calls_per_hour": 0
   } */}

        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_active_hours}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_total_utilization_time}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_total_login_time}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_call_counts}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_total_calls_per_hour}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_standard_leads}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_plus_leads}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_total_points}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          {totalDict.total_valid_point_per_hour}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          ${totalDict.total_hourly_earning}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          ${totalDict.total_commission_only}
        </TableCell>
        <TableCell
          sx={{
            color: "#212223",
            fontWeight: 600,
            fontSize: 15,
            backgroundColor: "#F4F6FA",
            whiteSpace: "nowrap",
            paddingX: "0!important",
            "& .Mui-active": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
            "& .Mui-active .MuiTableSortLabel-icon": {
              color: "#45c9b1!important",
              fontWeight: "600!important",
            },
          }}
          align="left"
        >
          ${totalDict.total_bonus_pay}
        </TableCell>
        {/* {Object.keys(arrangedTotalDict).map((key) => (
          <TableCell
            
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingX: "0!important",
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            align="left"
          >
            {key === "total_hourly_earning" ||
            key === "total_commission_only" ||
            key === "total_bonus_pay"
              ? numberWithDollarSign(totalDict[key])
              : totalDict[key]}
          </TableCell>
        ))} */}
      </TableRow>
    );
  };

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={
            userInfo?.user_type === "Administration" ? { height: "65vh" } : {}
          }
        >
          <Table
            id="userDashboardTable"
            sx={{
              minWidth: 750,
              maxHeight: 0,
              border: 1,
              borderColor: "#ddd",
            }}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <CallTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    {[1, 2, 3, 4, 5].map((skeletonRow) => (
                      <TableRow key={skeletonRow}>
                        <TableCell colSpan={18}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={38}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "70%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))?.map((row, index) => {
                          const isItemSelected = isSelected(row);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{
                                backgroundColor:
                                  index % 2 === 1 ? "#f4f6fa" : "#fff",
                                borderBottom: "0!important",
                                "&:hover": {
                                  backgroundColor: "#F4F6FA!important",
                                },
                              }}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.username}
                              selected={isItemSelected}
                            >
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  paddingLeft: "20px",
                                }}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.userProduction}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.activeHoursLogged}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.utilizationTime}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.totalHoursLogged}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.calls}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.totalCallsPerHour}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.validStandardLeads}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.validPlusLeads}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.totalPoints}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                {row.validPointsPerHour}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                ${row.hourlyEarnings}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                ${row.commisionOnly}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#6B7489",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  borderBottom: "0",
                                  whiteSpace: "nowrap",
                                  paddingX: "0!important",
                                }}
                                align="left"
                              >
                                ${row.bonus}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                {loading ? (
                  <>
                    {[1].map((skeletonRow) => (
                      <TableRow key={skeletonRow}>
                        <TableCell colSpan={18}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={38}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <></>
                    ) : (
                      <TotalTableRow totalDict={total_dict ?? {}} />
                    )}
                  </>
                )}

                {/* {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )} */}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        /> */}
      </Paper>
    </Box>
  );
};
