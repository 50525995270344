// Action Types
export const CREATE_CAMPAIGN_IN_PROGRESS = "CREATE_CAMPAIGN_IN_PROGRESS";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

// Action Types
export const GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS =
  "GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS";
export const GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS =
  "GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS";
export const GET_CAMPAIGN_LIST_BY_FILTER_FAILURE =
  "GET_CAMPAIGN_LIST_BY_FILTER_FAILURE";
export const GET_CAMPAIGN_SHOW_IN_PROGRESS = "GET_CAMPAIGN_SHOW_IN_PROGRESS";
export const GET_CAMPAIGN_SHOW_SUCCESS = "GET_CAMPAIGN_SHOW_SUCCESS";
export const GET_CAMPAIGN_SHOW_FAILURE = "GET_CAMPAIGN_SHOW_FAILURE";
// Action types for campaign delete
export const DELETE_CAMPAIGN_IN_PROGRESS = "DELETE_CAMPAIGN_IN_PROGRESS";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

// Action types for getting campaigns
export const GET_CAMPAIGNS_IN_PROGRESS = "GET_CAMPAIGNS_IN_PROGRESS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";

export const CAMPAIGN_SEARCH_IN_PROGRESS = "CAMPAIGN_SEARCH_IN_PROGRESS";
export const CAMPAIGN_SEARCH_SUCCESS = "CAMPAIGN_SEARCH_SUCCESS";
export const CAMPAIGN_SEARCH_FAILURE = "CAMPAIGN_SEARCH_FAILURE";

export const CAMPAIGN_NAME_SEARCH_IN_PROGRESS = "CAMPAIGN_NAME_SEARCH_IN_PROGRESS";
export const CAMPAIGN_NAME_SEARCH_SUCCESS = "CAMPAIGN_NAME_SEARCH_SUCCESS";
export const CAMPAIGN_NAME_SEARCH_FAILURE = "CAMPAIGN_NAME_SEARCH_FAILURE";

export const GET_CALENDAR_EVENTS_IN_PROGRESS =
  "GET_CALENDAR_EVENTS_IN_PROGRESS";
export const GET_CALENDAR_EVENTS_SUCCESS = "GET_CALENDAR_EVENTS_SUCCESS";
export const GET_CALENDAR_EVENTS_FAILURE = "GET_CALENDAR_EVENTS_FAILURE";

// Action Types for creating lead appointments
export const CREATE_LEAD_APPOINTMENT_IN_PROGRESS =
  "CREATE_LEAD_APPOINTMENT_IN_PROGRESS";
export const CREATE_LEAD_APPOINTMENT_SUCCESS =
  "CREATE_LEAD_APPOINTMENT_SUCCESS";
export const CREATE_LEAD_APPOINTMENT_FAILURE =
  "CREATE_LEAD_APPOINTMENT_FAILURE";

export const UPDATE_CAMPAIGN_KEY_IN_PROGRESS =
  "UPDATE_CAMPAIGN_KEY_IN_PROGRESS";
export const UPDATE_CAMPAIGN_KEY_SUCCESS = "UPDATE_CAMPAIGN_KEY_SUCCESS";
export const UPDATE_CAMPAIGN_KEY_FAILURE = "UPDATE_CAMPAIGN_KEY_FAILURE";

export const UPDATE_RADIUS = "UPDATE_RADIUS";
export const UPDATE_CIRCLE_DRAGE = "UPDATE_CIRCLE_DRAGE";

export const FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS =
  "FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS";
export const FIND_ALTERNATE_CAMPAIGN_SUCCESS =
  "FIND_ALTERNATE_CAMPAIGN_SUCCESS";
export const FIND_ALTERNATE_CAMPAIGN_FAILURE =
  "FIND_ALTERNATE_CAMPAIGN_FAILURE";

export const UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS =
  "UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS";
export const UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS =
  "UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE =
  "UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE";

// Add this to your constants or action types file
export const GET_VIEW_MAP_DETAILS_IN_PROGRESS = "GET_VIEW_MAP_DETAILS_IN_PROGRESS";
export const GET_VIEW_MAP_DETAILS_SUCCESS = "GET_VIEW_MAP_DETAILS_SUCCESS";
export const GET_VIEW_MAP_DETAILS_FAILURE = "GET_VIEW_MAP_DETAILS_FAILURE";
