import * as React from "react";
import Box from "@mui/material/Box";
import { CancellationAndReturnTable } from "./CancellationAndReturnTable";
import { useSelector } from "react-redux";


export const CancellationAndReturnParentTable = (props) => {
  const { clientData } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let userReports = useSelector((state) => state?.reports?.userReports);

 
  const createData = (
    createdBy,
    company,
    agent,
    campaign,
    firstName,
    lastName,
    appointmentDateTime,
    createdDateTime,
    leadCategory,
    leadCategoryModifiedBy
  ) => {
    return {
      createdBy,
      company,
      agent,
      campaign,
      firstName,
      lastName,
      appointmentDateTime,
      createdDateTime,
      leadCategory,
      leadCategoryModifiedBy,
    };
  };
  
  // Create dummy rows for the specified columns and data
  const rows = [
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
    createData(
      "Robin Turner",
      "Shy Beaver Pro Shop Inc. Dba Shy Beaver Boat Center",
      "Barry D'Andrea",
      "D'Andrea, Barry (Plus: 10+) X Dates",
      "Joe",
      "Brumbaugh",
      "Nov. 7, 2023, 10 a.m.",
      "2023-10-31 17:34:20.679886+00:00",
      "Accepted",
      "None"
    ),
  ];
  
  
  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        agent,
        created_by,
        campaign,
        category_modified_at,
        category,
        appointment_datetime,
        created_at,
        appointment_time,
        first_name,
        last_name,
        category_modified_by,
        prospect,
      } = item;

      return createData(
        created_by,
        prospect,
        agent,
        campaign,
        first_name,
        last_name,
        appointment_datetime,
        created_at,
        category,
        category_modified_by
      );
    });
  };

  const res = userReports?.ccrn_leads ?? [];

  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <CancellationAndReturnTable
        rows={tableRows}
        filterName={filterName}
      />
    </Box>
  );
};
