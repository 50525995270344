import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import * as turf from "@turf/turf";

export const MouseTooltip = () => {
  const tooltipRef = useRef();

  const isDrag = useSelector((state) => state.campaign.isCircleIsDraging);

  const radi = useSelector((state) => state.campaign.currentCircleRadius);
  const radiusInMiles = turf.convertLength(radi ?? 0, "kilometers", "miles");
  const fromated = radiusInMiles.toFixed(2);

  const handleMouseDown = (event) => {
    if (isDrag && tooltipRef?.current) {
      const tooltip = tooltipRef?.current;
      const x = event.clientX;
      const y = event.clientY;
      tooltip.style.left = x + "px";
      tooltip.style.top = y + "px";
    }
  };

  const handleMouseMove = (event) => {
    if (isDrag && tooltipRef?.current) {
      const tooltip = tooltipRef?.current;
      const x = event.clientX;
      const y = event.clientY;
      tooltip.style.left = x + "px";
      tooltip.style.top = y + "px";
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.addEventListener("mousedown", handleMouseDown);
    };
  }, [isDrag]);

  const tooltipStyle = {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    padding: "8px",
    borderRadius: "4px",
    pointerEvents: "none",
    zIndex: 9999,
  };

  return (
    <React.Fragment>
      {isDrag && (
        <>
          <div style={tooltipStyle} ref={tooltipRef}>
            <h4
              style={{
                margin: "0px",
                fontFamily: "Open Sans",
                fontSize: "11px",
                fontWeight: 500,
                color: "#ffe9da",
              }}
            >
              Radius: {fromated} miles{" "}
            </h4>
            <h4
              style={{
                margin: "0px",
                fontFamily: "Open Sans",
                fontSize: "11px",
                fontWeight: 500,
                color: "#ffffff",
              }}
            >
              Release mouse to finish drawing.
            </h4>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
