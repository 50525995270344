import * as React from "react";
import Box from "@mui/material/Box";
import { LeadTable } from "./LeadTable";
import { useSelector } from "react-redux";
import { format } from "date-fns";

export const LeadParentTable = (props) => {
  const {
    fetchMoreRecord,
    page,
    setPage,
    onDelete,
    onChangeLeadKey,
    onChangeAppointmentKey,
    selected,
    setSelected,
    onUpdateCampaignWithAltCampaign,
  } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const {leadData, totalCount} = useSelector((state) => state?.lead?.leadData);
  
  const createData = (
    viewCalendar,
    leadCategory,
    verified,
    operations,
    alternateCampaigns,
    companyName,
    fullName,
    phoneNumber,
    createdBy,
    createdAt,
    agent,
    campaign,
    appointmentDateTime,
    liabilityRenewalDate,
    liabilityCarrier,
    totalEmployed,
    businessClass,
    email,
    state,
    alternatePhoneNumber,
    address,
    city,
    stateZipCode
  ) => {
    return {
      viewCalendar,
      leadCategory,
      verified,
      operations,
      alternateCampaigns,
      companyName,
      fullName,
      phoneNumber,
      createdBy,
      createdAt,
      agent,
      campaign,
      appointmentDateTime,
      liabilityRenewalDate,
      liabilityCarrier,
      totalEmployed,
      businessClass,
      email,
      state,
      alternatePhoneNumber,
      address,
      city,
      stateZipCode,
    };
  };

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        category,
        is_verified,
        company_name,
        first_name,
        last_name,
        phone_number,
        created_at,
        appointment_date,
        appointment_time,
        liability_renewable_date,
        liability_carrier,
        employee_size,
        business_class,
        email,
        state,
        alternate_phone_number,
        address,
        city,
        zip_code,
        agent_name,
        campaign_name,
        created_by_name,
        appointment_data,
      } = item;
      const user = item;
      const full_name = `${first_name} ${last_name}`;

      // Combine date and time
      let combinedDateTime;

      if (appointment_data !== null) {
        combinedDateTime = new Date(
          `${appointment_data?.appointment_date} ${appointment_data?.appointment_time}`
        );
      }

      // Format the date and time
      const formattedDateTime =
        appointment_data === null
          ? "None"
          : format(combinedDateTime, "yyyy-MM-dd hh:mm a") ?? "None";

      //campaign,phonenumber,alternate_campaign,business_class
      return createData(
        user,
        category,
        is_verified,
        user,
        user,
        company_name,
        full_name,
        phone_number,
        created_by_name,
        created_at,
        agent_name,
        campaign_name,
        formattedDateTime,
        liability_renewable_date,
        liability_carrier,
        employee_size,
        business_class,
        email,
        state,
        alternate_phone_number,
        address,
        city,
        zip_code
      );
    });
  };

  const tableRows = convertApiResponseToRows(leadData);

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <LeadTable
        rows={tableRows}
        totalCount={totalCount}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        fetchMoreRecord={fetchMoreRecord}
        page={page}
        setPage={setPage}
        onDelete={onDelete}
        handleActiveFilterChange={handleActiveFilterChange}
        onChangeLeadKey={onChangeLeadKey}
        onChangeAppointmentKey={onChangeAppointmentKey}
        selected={selected}
        setSelected={setSelected}
        onUpdateCampaignWithAltCampaign={onUpdateCampaignWithAltCampaign}
      />
    </Box>
  );
};
