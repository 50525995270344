export const GET_GOOGLE_AUTH_URL_IN_PROGRESS =
  "GET_GOOGLE_AUTH_URL_IN_PROGRESS";
export const GET_GOOGLE_AUTH_URL_SUCCESS = "GET_GOOGLE_AUTH_URL_SUCCESS";
export const GET_GOOGLE_AUTH_URL_FAILURE = "GET_GOOGLE_AUTH_URL_FAILURE";

export const SYNC_GOOGLE_CALENDAR_IN_PROGRESS =
  "SYNC_GOOGLE_CALENDAR_IN_PROGRESS";
export const SYNC_GOOGLE_CALENDAR_SUCCESS = "SYNC_GOOGLE_CALENDAR_SUCCESS";
export const SYNC_GOOGLE_CALENDAR_FAILURE = "SYNC_GOOGLE_CALENDAR_FAILURE";

export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS";

export const AUTH_CALLBACK_IN_PROGRESS = "AUTH_CALLBACK_IN_PROGRESS";
export const AUTH_CALLBACK_SUCCESS = "AUTH_CALLBACK_SUCCESS";
export const AUTH_CALLBACK_FAILURE = "AUTH_CALLBACK_FAILURE";

// Action Types
export const GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS =
  "GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS";
export const GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS =
  "GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS";
export const GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE =
  "GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE";

export const CHANGE_PASSWORD_IN_PROGRESS = "CHANGE_PASSWORD_IN_PROGRESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
// Action Types for getUserProfile
export const GET_USER_PROFILE_IN_PROGRESS = "GET_USER_PROFILE_IN_PROGRESS";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

// Action Types
export const GET_ALL_CLIENTS_IN_PROGRESS = 'GET_ALL_CLIENTS_IN_PROGRESS';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const UPDATE_PROFILE_KEY_IN_PROGRESS = 'UPDATE_PROFILE_KEY_IN_PROGRESS';
export const UPDATE_PROFILE_KEY_SUCCESS = 'UPDATE_PROFILE_KEY_SUCCESS';
export const UPDATE_PROFILE_KEY_FAILURE = 'UPDATE_PROFILE_KEY_FAILURE';