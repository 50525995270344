export const CREATE_PROSPECT_IN_PROGRESS = "CREATE_PROSPECT_IN_PROGRESS";
export const CREATE_PROSPECT_SUCCESS = "CREATE_PROSPECT_SUCCESS";
export const CREATE_PROSPECT_FAILURE = "CREATE_PROSPECT_FAILURE";

export const GET_PROSPECTS_IN_PROGRESS = "GET_PROSPECTS_IN_PROGRESS";
export const GET_PROSPECTS_SUCCESS = "GET_PROSPECTS_SUCCESS";
export const GET_PROSPECTS_FAILURE = "GET_PROSPECTS_FAILURE";

export const GET_USERS_IN_PROGRESS = "GET_USERS_IN_PROGRESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const UPLOAD_PROSPECT_CSV_IN_PROGRESS = "UPLOAD_PROSPECT_CSV_IN_PROGRESS";
export const UPLOAD_PROSPECT_CSV_SUCCESS = "UPLOAD_PROSPECT_CSV_SUCCESS";
export const UPLOAD_PROSPECT_CSV_FAILURE = "UPLOAD_PROSPECT_CSV_FAILURE";

export const UPDATE_PROSPECT_IN_PROGRESS = 'UPDATE_PROSPECT_IN_PROGRESS';
export const UPDATE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS';
export const UPDATE_PROSPECT_FAILURE = 'UPDATE_PROSPECT_FAILURE';

export const GET_PROSPECTS_SEARCH_IN_PROGRESS = 'GET_PROSPECTS_SEARCH_IN_PROGRESS';
export const GET_PROSPECTS_SEARCH_SUCCESS = 'GET_PROSPECTS_SEARCH_SUCCESS';
export const GET_PROSPECTS_SEARCH_FAILURE = 'GET_PROSPECTS_SEARCH_FAILURE';

export const PROSPECT_MASS_UPDATE_IN_PROGRESS = "PROSPECT_MASS_UPDATE_IN_PROGRESS";
export const PROSPECT_MASS_UPDATE_SUCCESS = "PROSPECT_MASS_UPDATE_SUCCESS";
export const PROSPECT_MASS_UPDATE_FAILURE = "PROSPECT_MASS_UPDATE_FAILURE";

export const EXPORT_CSV_IN_PROGRESS = "EXPORT_CSV_IN_PROGRESS";
export const EXPORT_CSV_SUCCESS = "EXPORT_CSV_SUCCESS";
export const EXPORT_CSV_FAILURE = "EXPORT_CSV_FAILURE";

export const UPDATE_PROSPECT_KEY_IN_PROGRESS = 'UPDATE_PROSPECT_KEY_IN_PROGRESS';
export const UPDATE_PROSPECT_KEY_SUCCESS = 'UPDATE_PROSPECT_KEY_SUCCESS';
export const UPDATE_PROSPECT_KEY_FAILURE = 'UPDATE_PROSPECT_KEY_FAILURE';

// ActionTypes
export const GET_CALL_RESULT_OPTIONS_IN_PROGRESS = "GET_CALL_RESULT_OPTIONS_IN_PROGRESS";
export const GET_CALL_RESULT_OPTIONS_SUCCESS = "GET_CALL_RESULT_OPTIONS_SUCCESS";
export const GET_CALL_RESULT_OPTIONS_FAILURE = "GET_CALL_RESULT_OPTIONS_FAILURE";


// ActionTypes
export const CREATE_CALL_RESULT_IN_PROGRESS = "CREATE_CALL_RESULT_IN_PROGRESS";
export const CREATE_CALL_RESULT_SUCCESS = "CREATE_CALL_RESULT_SUCCESS";
export const CREATE_CALL_RESULT_FAILURE = "CREATE_CALL_RESULT_FAILURE";

// ActionTypes
export const DELETE_CALL_RESULT_IN_PROGRESS = "DELETE_CALL_RESULT_IN_PROGRESS";
export const DELETE_CALL_RESULT_SUCCESS = "DELETE_CALL_RESULT_SUCCESS";
export const DELETE_CALL_RESULT_FAILURE = "DELETE_CALL_RESULT_FAILURE";
