// LoginScreen.js
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { CampaignInfoParentTable } from "./CampaignInfo";
import { CallParentTable } from "./TotalCallInfo";
import { LeadCreateParentTable } from "./LeadCreate";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  copyTableToClipboard,
  tableToCSV,
  tableToPDF,
  tableToPrint,
  tableToXLSX,
} from "../../../helpers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";

export const CampaignReport = (props) => {

  const { getCampaignReportSearch, getAllCampaignNames } = props;

  useEffect(() => {
    getAllCampaignNames();
  }, []);

  const initialuserReportSearchInfo = {
    campaignId: 0,
    type: "",
    fromDate: "",
    toDate: "",
  };

  //lead info
  const [campaignReportSearchInfo, setCampaignReportSearchInfo] = useState(
    initialuserReportSearchInfo
  );


  const campaignNames = useSelector(
    (state) => state?.campaign?.campaignNamesWithID?.results
  );

  const campaignReports = useSelector(
    (state) => state?.reports?.campaignReport
  );

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "campaign":
        setCampaignReportSearchInfo({
          ...campaignReportSearchInfo,
          campaignId: newValue?.id,
        });
        break;
      case "type":
        setCampaignReportSearchInfo({
          ...campaignReportSearchInfo,
          type: event?.target?.value,
        });
        break;
      default:
        break;
    }
  };


  const onSearchUserReport = () => {
    const formatedFromDate = moment(campaignReportSearchInfo.fromDate.$d).toISOString();
    const formatedToDate = moment(campaignReportSearchInfo.toDate.$d).toISOString();

    const payload = {
      campaign: campaignReportSearchInfo.campaignId,
      from_datetime: formatedFromDate,
      to_datetime: formatedToDate,
      type: campaignReportSearchInfo.type ?? null,
    };
    //console.log("payload===>", payload);
    getCampaignReportSearch(payload);
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Per Campaign Information
              </Typography>
            </AccordionSummary>
            <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
              <ListItemButton
                onClick={() => {
                  copyTableToClipboard("campaignInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Copy
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToCSV("campaignInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InsertPageBreakIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  CSV
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToXLSX("campaignInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Excel
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPrint("campaignInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Print
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPDF("campaignInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  PDF
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
          <AccordionDetails>
            <Box>
              <Box sx={{
                display: { xs: "grid", sm: "flex", md: 'flex' }, flexWrap: { xs: "wrap", sm: "wrap", md: 'wrap', lg: 'inherit' }, gap: { xs: "15px", sm: "20px", md: '20px' }, alignItems: 'center',
                border: '1px solid #ddd',
                padding: { xs: "10px", sm: "18px", md: '20px' },
                marginTop: { xs: "15px", sm: "20px", md: '20px' },
                marginBottom: { xs: "15px", sm: "20px", md: '20px' },
                borderRadius: "7px"
              }}>

                <Box sx={{ maxWidth: '230px', width: '100%' }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      size="small"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        options={campaignNames}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          handleChange(event, newValue, "campaign");
                        }}
                        size="small"
                        disableClearable={true}
                        sx={{
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#18a689",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#18a689",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField {...params} label="Select Campaign" />
                          </>
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={`${option?.id}-${option?.name}`}>
                              {option?.name}
                            </li>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Box>
                <Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px' }}>
                      <DateTimePicker label="From Date Time"
                        onChange={(event) => {
                          setCampaignReportSearchInfo({
                            ...campaignReportSearchInfo,
                            fromDate: event,
                          });
                        }}
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px 13px",
                          },
                          width: "100%",

                          "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                            top: '-7px',
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }} />

                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                <Box >

                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px' }}>
                      <DateTimePicker label="To Date Time"
                        onChange={(event) => {
                          setCampaignReportSearchInfo({
                            ...campaignReportSearchInfo,
                            fromDate: event,
                          });
                        }}
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px 13px",
                          },
                          width: "100%",

                          "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                            top: '-7px',
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6DD6C2!important",
                          },
                        }} />

                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                <Box sx={{ maxWidth: '230px', width: '100%' }}>

                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: 15,
                        "&.Mui-focused": {
                          color: "#6DD6C2!important",
                          fontWeight: "600",
                          margin: "0",
                        },
                      }}
                    >
                      Select Day
                    </InputLabel>
                    <Select
                      sx={{ backgroundColor: "#fff!important" }}
                      id="selectinfo"
                      label="Select Agent"
                      onChange={(event) => {
                        handleChange(event, "", "type");
                      }}
                      size="small"
                    >
                      <MenuItem value={"today"} sx={{ fontSize: 15 }}>
                        Today
                      </MenuItem>
                      <MenuItem value={"current_week"} sx={{ fontSize: 15 }}>
                        Current Week
                      </MenuItem>
                      <MenuItem value={"last_week"} sx={{ fontSize: 15 }}>
                        Last Week
                      </MenuItem>
                      <MenuItem value={"current_month"} sx={{ fontSize: 15 }}>
                        Current Month
                      </MenuItem>
                      <MenuItem value={"last_month"} sx={{ fontSize: 15 }}>
                        Last Month
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={onSearchUserReport}
                  sx={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    pl: 4,
                    pr: 4,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    "&:hover": {
                      // Customize the hover color
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
              <CampaignInfoParentTable />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", marginTop: "30px!important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Per User Information
              </Typography>
            </AccordionSummary>
            <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
              <ListItemButton
                onClick={() => {
                  copyTableToClipboard("callInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Copy
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToCSV("callInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InsertPageBreakIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  CSV
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToXLSX("callInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Excel
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPrint("callInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Print
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPDF("callInfoCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  PDF
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <CallParentTable />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", marginTop: "30px!important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Production Information Leads Created
              </Typography>
            </AccordionSummary>
            <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
              <ListItemButton
                onClick={() => {
                  copyTableToClipboard("allLeadsCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Copy
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToCSV("allLeadsCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InsertPageBreakIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  CSV
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToXLSX("allLeadsCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Excel
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPrint("allLeadsCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Print
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPDF("allLeadsCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  PDF
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <LeadCreateParentTable
                custumId="allLeadsCampaignReport"
                tableData={campaignReports.all_leads}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", marginTop: "30px!important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Production Information Cancelations And Returns
              </Typography>
            </AccordionSummary>
            <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
              <ListItemButton
                onClick={() => {
                  copyTableToClipboard("ccrnCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Copy
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToCSV("ccrnCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InsertPageBreakIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  CSV
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToXLSX("ccrnCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Excel
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPrint("ccrnCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Print
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPDF("ccrnCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  PDF
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <LeadCreateParentTable
                custumId="ccrnCampaignReport"
                tableData={campaignReports?.ccrn_leads}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "15px!Important", marginTop: "30px!important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Production Information Rescheduled (CWTR)
              </Typography>
            </AccordionSummary>
            <ListItem sx={{ maxWidth: "420px", alignItems: "center" }}>
              <ListItemButton
                onClick={() => {
                  copyTableToClipboard("cwtrCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Copy
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToCSV("cwtrCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InsertPageBreakIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  CSV
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToXLSX("cwtrCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Excel
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPrint("cwtrCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  Print
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  tableToPDF("cwtrCampaignReport");
                }}
                sx={{
                  border: 1,
                  borderColor: "#676a6c4a",
                  px: 1,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      mr: 0.5,
                      fontSize: "18px",
                      display: { xs: "none", sm: "block", md: "block" },
                    }}
                  />
                  PDF
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <LeadCreateParentTable
                custumId="cwtrCampaignReport"
                tableData={campaignReports?.reschedule_leads}
              />
            </Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#454647",
                fontWeight: 600,
              }}
            >
              Total Valid Appoinments :{" "}
              {campaignReports?.total_valid_appointments ?? 0}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
