import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { CommercialTableHead } from "./CommercialTableHead";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SendIcon from "@mui/icons-material/Send";
import { checkAndReturnNone } from "../../helpers";
import { PdfViewer } from "./PdfViewer";
import { useState } from "react";

export const CommercialTable = (props) => {
  const { rows, generatePdf, sendEmail } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Username");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const commercialInvoices = useSelector(
    (state) => state?.invoices?.commercialInvoices
  );

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    if (!event.target.closest(".show-button")) {
      // Exclude clicks on the "Show" button
      const selectedIndex = selected.indexOf(row?.operations?.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row?.operations?.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const findAgentNameById = (agentId) => {
    const agent = commercialInvoices?.agents_list.find(
      (agent) => agent.id === agentId
    );
    return agent ? `${agent.first_name} ${agent.last_name}` : "Unknown Agent";
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedDate ?? "";
    //console.log(formattedDate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  let loading = useSelector((state) => state?.invoices?.isLoading);

  const [openModal, setOpenModal] = useState({ id: "", isOpen: false });

  const handleOpenModal = (id) => {
    setOpenModal({
      ...openModal,
      isOpen: true,
      id: id,
    });
  };

  const handleCloseModal = () => {
    setOpenModal({
      ...openModal,
      isOpen: false,
      id: "",
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750, border: 1, borderColor: "#ddd" }}
            aria-labelledby="tableTitle"
            aria-label="caption table"
            size="medium"
          >
            <CommercialTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    {[1, 2, 3, 4].map((skeletonRow) => (
                      <TableRow key={skeletonRow}>
                        <TableCell colSpan={6}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={38}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            sx={{
                              backgroundColor:
                                index % 2 === 1 ? "#f4f6fa" : "#fff",
                              borderBottom: "0!important",
                              "&:hover": {
                                backgroundColor: "#F4F6FA!important",
                              },
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.username}
                            selected={isItemSelected}
                          >
                            <TableCell
                              sx={{
                                color: "#6B7489",
                                fontWeight: 500,
                                fontSize: 14,
                                borderBottom: "0",
                                paddingLeft: "20px",
                              }}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {findAgentNameById(row.agent)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#6B7489",
                                fontWeight: 500,
                                fontSize: 14,
                                borderBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              ${checkAndReturnNone(row.amount)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#6B7489",
                                fontWeight: 500,
                                fontSize: 14,
                                borderBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {checkAndReturnNone(row.month)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#6B7489",
                                fontWeight: 500,
                                fontSize: 14,
                                borderBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {formatDateTime(row.createdDate)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#6B7489",
                                fontWeight: 500,
                                fontSize: 14,
                                borderBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {formatDateTime(row.modifiedDate)}
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  onClick={() => {
                                    generatePdf(row?.operations?.id);
                                  }}
                                  sx={{
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    width: "20px",
                                    height: "20px",
                                    minWidth: "30px",
                                    pl: 1.5,
                                    pr: 1.5,
                                    pt: 1.5,
                                    pb: 1.5,
                                    m: "2px",
                                    backgroundColor: "#00c96f",
                                    "&:hover": {
                                      backgroundColor: "#00c96f",
                                    },
                                    borderRadius: "3px",
                                    borderColor: "#6DD6C2",
                                    color: "#fff",
                                  }}
                                >
                                  <DownloadForOfflineIcon
                                    sx={{ fontSize: "22px" }}
                                  />
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    navigate("/InvoiceCreate", {
                                      state: {
                                        isEdit: true,
                                        invoiceData: row?.operations,
                                      },
                                    });
                                  }}
                                  sx={{
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    width: "20px",
                                    height: "20px",
                                    minWidth: "30px",
                                    pl: 1.5,
                                    pr: 1.5,
                                    pt: 1.5,
                                    pb: 1.5,
                                    m: "2px",
                                    borderRadius: "3px",
                                    backgroundColor: "#18a689",
                                    "&:hover": {
                                      backgroundColor: "#18a689",
                                    },
                                    borderColor: "#18a689",
                                    color: "#fff",
                                  }}
                                >
                                  <BorderColorIcon sx={{ fontSize: "17px" }} />
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  onClick={() => {
                                    // generatePdf(row?.operations?.id,"preview");
                                    handleOpenModal(row?.operations?.id);
                                  }}
                                  sx={{
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    width: "20px",
                                    height: "20px",
                                    minWidth: "30px",
                                    pl: 1.5,
                                    pr: 1.5,
                                    pt: 1.5,
                                    pb: 1.5,
                                    m: "2px",
                                    backgroundColor: "#212223",
                                    "&:hover": {
                                      backgroundColor: "#212223",
                                    },
                                    borderRadius: "3px",
                                    borderColor: "#212223",
                                    color: "#fff",
                                  }}
                                >
                                  <RemoveRedEyeIcon sx={{ fontSize: "19px" }} />
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  onClick={() => {
                                    sendEmail(row?.operations?.id);
                                  }}
                                  sx={{
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    width: "20px",
                                    height: "20px",
                                    minWidth: "30px",
                                    pl: 1.5,
                                    pr: 1.5,
                                    pt: 1.5,
                                    pb: 1.5,
                                    m: "2px",
                                    backgroundColor: "#0f5396",
                                    "&:hover": {
                                      backgroundColor: "#0f5396",
                                    },
                                    borderRadius: "3px",
                                    borderColor: "#6DD6C2",
                                    color: "#fff",
                                  }}
                                >
                                  <SendIcon sx={{ fontSize: "18px" }} />
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                )}

                {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={19} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <PdfViewer open={openModal} handleClose={handleCloseModal} />
    </Box>
  );
};
