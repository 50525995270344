import React, { useEffect, useState ,useRef} from "react";
import Box from "@mui/material/Box";
import { CampaignsTable } from "./CampaignsTable";
import { useSelector } from "react-redux";

export const CampaignsParentTable = (props) => {
  const {
    onDelete,
    onChangeCampiagnKey,
    page,
    setPage,
    fetchMoreRecord,
    offset,
    setOffset,
    filteredRows,
    fetchMoreCampaingnData,
    campaignList
  } = props;

    
  // const nameFilter = filterCampaignName.trim().toLowerCase();
  // const filteredRows = tableRows?.filter((row) => {
  //   return row?.firstName?.toLowerCase().includes(nameFilter);
  // });

  return (
    <Box>
  
              <CampaignsTable
                rows={filteredRows}
                onDelete={onDelete}
                onChangeCampiagnKey={onChangeCampiagnKey}
                page={page}
                setPage={setPage}
                fetchMoreRecord={fetchMoreRecord}
                offset={offset}
                setOffset={setOffset}
                fetchMoreCampaingnData={fetchMoreCampaingnData}
                campaignList={campaignList}
                filteredRows={filteredRows}

              />
    </Box>
  );
};
