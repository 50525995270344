// LoginScreen.js
import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";

export const UpdateTooltip = (props) => {
  const {
    onClose,
    onSubmit,
    label,
    inputType,
    value,
    setValue,
    optionsData,
    labelKey,
    selectedKey,
  } = props;

  const onSubmitHandler = () => {
    onSubmit(value);
  };

  const renderInputs = () => {
    switch (inputType) {
      case "Text":
        return (
          <TextField
            margin="0"
            size="small"
            type="text"
            fontSize="15"
            value={value}
            defaultValue={value}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              const updatedValue = e.target.value;
              setValue(updatedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "15px",
                "&.Mui-focused": {
                  color: "#6DD6C2",
                },
              },
            }}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "200px",
                lg: "200px",
              },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
            }}
          />
        );
        break;
      case "SelectOptions":
        return (
          <Box>
            <FormControl
              size="small"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "95%",
                  lg: "95%",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6DD6C2!important",
                },
              }}
            >
              <Autocomplete
                id="combo-box-demo"
                options={optionsData}
                defaultValue={optionsData.find(
                  (v) => v[labelKey] === selectedKey
                )}
                getOptionLabel={(option) => option[labelKey]}
                onChange={(event, newValue) => {
                  setValue(newValue.id);
                }}
                size="small"
                disableClearable={true}
                disablePortal={true}
                sx={{
                  width: "200px",
                  overflow: "auto",
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#18a689!important",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#18a689",
                    },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#18a689",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#6DD6C2",
                    },
                  },
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
                // PopperComponent={({ children, ...other }) => (
                //   <Popper
                //     {...other}
                //     placement="bottom-start"
                //     positionFixed={true}
                //     style={{ zIndex: 10000 }}
                //   >
                //     {children}
                //   </Popper>
                // )}
              />
            </FormControl>
          </Box>
        );
        break;
      case "Date":
        return (
          <TextField
            margin="0"
            size="small"
            type="date"
            fontSize="15"
            value={value}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const updatedValue = e.target.value;
              setValue(updatedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "15px",
                "&.Mui-focused": {
                  color: "#6DD6C2",
                },
              },
            }}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "200px",
                lg: "200px",
              },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#6DD6C2",
                },
            }}
          />
        );
        break;
      case "Time":
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 0.5,
          marginTop: "0",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 14,
                color: "#454647",
                fontWeight: 700,
                borderBottom: 1,
                borderColor: "#ddd",
                borderRadius: 1,
                padding: 0.5,
                backgroundColor: "#f1f1f1",
              }}
            >
              Update {label}
            </Typography>
            <Box
              sx={{
                margin: "8px",
                display: "flex",
                gap: "7px",
                alignItems: "center",
              }}
            >
              {renderInputs()}
              <Button
                variant="contained"
                sx={{
                  width: "25px",
                  height: "30px",
                  minWidth: "30px",
                  pl: 1.5,
                  pr: 1.5,
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "#1ab394",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                  borderRadius: "3px",
                  borderColor: "#1ab394",
                  color: "#fff",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onSubmitHandler();
                }}
              >
                <FileDownloadDoneIcon
                  sx={{ fontSize: "20px", backgroundColor: "#1ab394" }}
                />
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "25px",
                  height: "30px",
                  minWidth: "30px",
                  pl: 1.5,
                  pr: 1.5,
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },

                  color: "#000",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

UpdateTooltip.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  optionsData: PropTypes.array,
  labelKey: PropTypes.string,
};

UpdateTooltip.defaultProps = {
  label: "Value",
  inputType: "Text",
  onClose: () => {},
  setValue: () => {},
  optionsData: [],
};
