import {
  BASE_URL,
  CREATE_EMPLOYEE_URL,
  DELETE_EMPOYEES_URL,
  EMPLOYEE_MASS_UPLOAD_URL,
  GET_ALL_EMPLOYEES,
  UPDATE_CALL_RECORDING_STATUS_URL,
  UPDATE_EMPLOYEE_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../services";
import { logout } from "../authentication";
import { LOGOUT } from "../authentication/types";
import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_IN_PROGRESS,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_IN_PROGRESS,
  DELETE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_IN_PROGRESS,
  GET_EMPLOYEES_SUCCESS,
  UPDATE_CALL_RECORDING_STATUS_FAILURE,
  UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS,
  UPDATE_CALL_RECORDING_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_IN_PROGRESS,
  UPDATE_EMPLOYEE_SUCCESS,
  UPLOAD_EMPLOYEE_CSV_FAILURE,
  UPLOAD_EMPLOYEE_CSV_IN_PROGRESS,
  UPLOAD_EMPLOYEE_CSV_SUCCESS,
} from "./types";
import toast from "react-hot-toast";

export const CreateEmployeeInProgress = () => ({
  type: CREATE_EMPLOYEE_IN_PROGRESS,
});

export const CreateEmployeeSuccess = (data) => ({
  type: CREATE_EMPLOYEE_SUCCESS,
  data,
});

export const CreateEmployeeFailure = (error) => ({
  type: CREATE_EMPLOYEE_FAILURE,
  error,
});

export const CreateEmployee = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        address,
        bonusPay,
        cellPhone,
        city,
        commissionPlusPay,
        commissionStranded,
        lastName,
        localTimeZone,
        phoneNo,
        ratePerHour,
        state,
        street,
        team,
        userEmail,
        userName,
        zipCode,
        isActive,
        isAdmin,
      } = payload;

      // // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          is_active: isActive === false ? false : true,
          is_admin: isAdmin ?? false,
        },
        rate_per_hour: ratePerHour ?? "",
        commission_standard_pay: commissionStranded ?? "",
        team: team.toLowerCase() ?? "",
        call_record: true,
        commission_plus_pay: commissionPlusPay ?? "",
        bonus_pay: bonusPay ?? "",
      };

      // Dispatch in progress action
      dispatch(CreateEmployeeInProgress());

      // Make API request to create employee
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_EMPLOYEE_URL}`,
        params,
        "",
        token
      );

      // Check response status
      if (response?.status === 201) {
        dispatch(CreateEmployeeSuccess(response?.data));
        cb();
      } else {
        dispatch(
          CreateEmployeeFailure({
            type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      dispatch(
        CreateEmployeeFailure({
          type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

export const GetEmployeeInProgress = () => ({
  type: GET_EMPLOYEES_IN_PROGRESS,
});

export const GetEmployeeSuccess = (data) => ({
  type: GET_EMPLOYEES_SUCCESS,
  data,
});

export const GetEmployeeFailure = (error) => ({
  type: GET_EMPLOYEES_FAILURE,
  error,
});

export const GetEmployees = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetEmployeeInProgress());

      // {{lead}}api/employee-profiles/?name=mic&state=fl&account_status=false

      const params = {
        name: payload.name !== "" ? payload?.name : null,
        state: payload.stateName !== "" ? payload?.stateName : null,
        account_status:
          payload.isActive !== "all"
            ? payload.isActive === "active"
              ? true
              : false
            : null,
      };

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to read employee
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_EMPLOYEES}`,
        token,
        params
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetEmployeeSuccess(response?.data));
        cb();
      } else {
        dispatch(
          GetEmployeeFailure({
            type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetEmployeeFailure({
          type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const DeleteEmployeeInProgress = () => ({
  type: DELETE_EMPLOYEE_IN_PROGRESS,
});

export const DeleteEmployeeSuccess = (data) => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  data,
});

export const DeleteEmployeeFailure = (error) => ({
  type: DELETE_EMPLOYEE_FAILURE,
  error,
});

export const DeleteEmployee = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(DeleteEmployeeInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete employee

      const response = await deleteRequest(
        `${BASE_URL}${DELETE_EMPOYEES_URL}`,
        payload,
        token
      );

      // Check response status
      if (response?.status === 204) {
        dispatch(DeleteEmployeeSuccess(response?.data));
        cb();
      } else {
        dispatch(
          DeleteEmployeeFailure({
            type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        DeleteEmployeeFailure({
          type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const UpdateEmployeeInProgress = () => ({
  type: UPDATE_EMPLOYEE_IN_PROGRESS,
});

export const UpdateEmployeeSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  data,
});

export const UpdateEmployeeFailure = (error) => ({
  type: UPDATE_EMPLOYEE_FAILURE,
  error,
});

export const UpdateEmployee = (employeeId, payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        address,
        bonusPay,
        cellPhone,
        city,
        commissionPlusPay,
        commissionStranded,
        lastName,
        localTimeZone,
        phoneNo,
        ratePerHour,
        state,
        street,
        team,
        userEmail,
        userName,
        zipCode,
        isActive,
        isAdmin,
      } = payload;

      // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          is_active: isActive === false ? false : true,
          is_admin: isAdmin ?? false,
        },
        rate_per_hour: ratePerHour ?? "",
        commission_standard_pay: commissionStranded ?? "",
        team: team.toLowerCase() ?? "",
        call_record: true,
        commission_plus_pay: commissionPlusPay ?? "",
        bonus_pay: bonusPay ?? "",
      };

      // Dispatch in progress action
      dispatch(UpdateEmployeeInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to update employee
      const response = await putRequest(
        `${BASE_URL}${UPDATE_EMPLOYEE_URL}${employeeId}/`,
        params,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(UpdateEmployeeSuccess(response?.data));
        cb();
      } else {
        dispatch(
          UpdateEmployeeFailure({
            type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        UpdateEmployeeFailure({
          type: ERROR_TYPE.API.EMPLOYEE.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//Update call-recording status
export const updateCallRecordingStatusInProgress = () => ({
  type: UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS,
});

export const updateCallRecordingStatusSuccess = (data) => ({
  type: UPDATE_CALL_RECORDING_STATUS_SUCCESS,
  data,
});

export const updateCallRecordingStatusFailure = (error) => ({
  type: UPDATE_CALL_RECORDING_STATUS_FAILURE,
  error,
});

export const updateCallRecordingStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const { profile_id, call_record } = payload;

      dispatch(updateCallRecordingStatusInProgress());

      const params = { call_record: call_record };

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to update call recording status
      const response = await patchRequest(
        `${BASE_URL}${UPDATE_CALL_RECORDING_STATUS_URL}${profile_id}/call-status/`,
        params,
        token
      );

      // Check response status
      if (response.status === 204) {
        dispatch(updateCallRecordingStatusSuccess(response?.data));
        toast.success("Call recording status updated successfully", {
          position: "bottom-center",
        });
        cb(); // Call the callback function
      } else {
        dispatch(
          updateCallRecordingStatusFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateCallRecordingStatusFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Define action creators for in progress, success, and failure
export const uploadEmployeeCsvInProgress = () => ({
  type: UPLOAD_EMPLOYEE_CSV_IN_PROGRESS,
});

export const uploadEmployeeCsvSuccess = (data) => ({
  type: UPLOAD_EMPLOYEE_CSV_SUCCESS,
  data,
});

export const uploadEmployeeCsvFailure = (error) => ({
  type: UPLOAD_EMPLOYEE_CSV_FAILURE,
  error,
});

// Define the uploadEmployeeCsv method
export const uploadEmployeeCsv = (file, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(uploadEmployeeCsvInProgress());

      // Create formData object and append the file
      const formData = new FormData();

      formData.append("csv_file", file);

      // Make API request to upload the CSV file
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${EMPLOYEE_MASS_UPLOAD_URL}`,
        formData,
        "",
        token,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(uploadEmployeeCsvSuccess(response?.data));
        cb();
        return response.data; // Resolve the promise with the response data
      } else {
        dispatch(
          uploadEmployeeCsvFailure({
            type: ERROR_TYPE.API.EMPLOYEE.CSV_UPLOAD,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        throw new Error(response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN); // Reject the promise with an error
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        uploadEmployeeCsvFailure({
          type: ERROR_TYPE.API.EMPLOYEE.CSV_UPLOAD,
          message: errorMessage,
        })
      );
      throw new Error(errorMessage); // Reject the promise with an error
    }
  };
};
