import { connect } from "react-redux";
import { GetUsers, getProspectsSearch, updateProspectKey } from "../store/actions/Prospects";
import { useLocation } from "react-router-dom";
import {
  CampaignsList,
  CampaignsShow,
  NewCampaigns,
  SearchCampaigns,
} from "../components/CampaignsComponent";
import {
  campaignNameSearch,
  campaignSearch,
  createCampaign,
  createLeadAppointment,
  deleteCampaign,
  getAllCampaigns,
  getCalendarEvents,
  getCampaignListByFilter,
  getCampaignShow,
  updateCampaignKey,
} from "../store/actions/campaign";
import { createCallback, getCallBackShow } from "../store/actions/callBack";

const mapStateToProps = (state) => ({
  prospectInfoData: state?.prospect?.prospectInfo,
  userDetails: state?.prospect?.user_details?.data,
  campaignDetails: state?.campaign?.campaignDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchProspects: (payload, offset, cb) =>
      dispatch(getProspectsSearch(payload, offset, cb)),
    onCreateCampaign: (payload, cb) => dispatch(createCampaign(payload, cb)),
    getCampaignsFilter: (payload,page,cb) =>
      dispatch(getCampaignListByFilter(payload,page,cb)),
    onCampaignShow: (campaignId, cb) =>
      dispatch(getCampaignShow(campaignId, cb)),
    onDeleteCampaign: (campaignId, cb) =>
      dispatch(deleteCampaign(campaignId, cb)),
    onSearchCampaign: (payload, cb) => dispatch(campaignSearch(payload, cb)),
    onCallBackScheduled: (payload, cb) => dispatch(createCallback(payload, cb)),
    onCallBackShow: (campaignId, prospectId, cb) =>
      dispatch(getCallBackShow(campaignId, prospectId, cb)),
    getAllUser: () => dispatch(GetUsers()),
    // getAllCalendarEvents: () => dispatch(getCalendarEvents()),
    getAllCalendarEvents:(id,cb)=>dispatch(getCalendarEvents(id,cb)),
    onCreateLeadAppointment: (payload, cb) =>
      dispatch(createLeadAppointment(payload, cb)),
    updatePropspectKey:(prospectId,key,value,cb)=>dispatch(updateProspectKey(prospectId,key,value,cb)),
    onNameSearchCampaign: (payload) => dispatch(campaignNameSearch(payload)),
  };
};

export const CampaignContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/CampaignsList":
      ComponentToRender = CampaignsList;
      break;
    case "/NewCampaigns":
      ComponentToRender = NewCampaigns;
      break;
    case "/SearchCampaigns":
      ComponentToRender = SearchCampaigns;
      break;
    case "/CampaignsShow":
      ComponentToRender = CampaignsShow;
      break;

    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
