// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormHelperText,
  Skeleton,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { checkAndReturnNone, getMinToDate } from "../../helpers";
import { CommercialParentTable } from "./CommercialParentTable";
import { Loader } from "../Common";
import { getAllClients } from "../../store/actions/profile";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const CommercialInvoices = (props) => {
  const {
    getAllCommercialInvoices,
    generateAndDownloadInvoicePDF,
    sendEmailForCommercialInvoices,
  } = props;

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state?.profile?.allClients);

  const isLoading = useSelector((state) => state?.invoices.isGenerateLoading);
  const isSendEmailLoading = useSelector(
    (state) => state?.invoices.isSendEmailLoading
  );
  const initialInvoicesSearchInfo = {
    agentId: 0,
    type: "all",
    fromDate: "",
    toDate: "",
  };

  //lead info
  const [invoiceSearchInfo, setInvoicesSearchInfo] = useState(
    initialInvoicesSearchInfo
  );
  const [triedSubmit] = useState(false);

  const agentNameError =
    triedSubmit && invoiceSearchInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && invoiceSearchInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setInvoicesSearchInfo({
          ...invoiceSearchInfo,
          agentId: newValue.id,
        });
        break;
      case "type":
        setInvoicesSearchInfo({
          ...invoiceSearchInfo,
          type: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const generatePdf = (id, url) => {
    generateAndDownloadInvoicePDF(id, url);
  };

  useEffect(() => {
    const payload = {
      status: null,
      agent: null,
      from_date: null,
      to_date: null,
    };
    getAllCommercialInvoices(payload);
    dispatch(getAllClients());
  }, []);

  const onSearch = () => {
    const payload = {
      status: invoiceSearchInfo.type !== "all" ? invoiceSearchInfo.type : null,
      agent: invoiceSearchInfo.agentId !== 0 ? invoiceSearchInfo.agentId : null,
      from_date:
        invoiceSearchInfo.fromDate !== ""
          ? moment(invoiceSearchInfo.fromDate).format("MM-DD-YYYY")
          : null,
      to_date:
        invoiceSearchInfo.toDate !== ""
          ? moment(invoiceSearchInfo.toDate).format("MM-DD-YYYY")
          : null,
    };
    // console.log("payload===>", payload);
    getAllCommercialInvoices(payload);
  };

  const sendEmail = (id) => {
    sendEmailForCommercialInvoices(id);
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 }, color: "#212223", fontWeight: 600 }}
              >
                Commercial Invoices
              </Typography>
            </AccordionSummary>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: "none" }),
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#6DD6C2",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                },
                borderRadius: "50%",

                color: "#212223",
              }}
            >
              <SearchIcon />
            </IconButton> */}
          </Box>
          {/* <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#00c96f",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#00c96f",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>

            
          </Drawer> */}
          <AccordionDetails>
            <Box sx={{ }}>
            <Box sx={{ display: { xs: "grid", sm: "flex" , md:'flex' }, flexWrap: { xs: "wrap", sm: "wrap" , md:'wrap', lg:'inherit' },  gap: { xs: "15px", sm: "20px" , md:'20px' }, alignItems:'center',
                border:'1px solid #ddd',               
                padding: { xs: "10px", sm: "18px" , md:'20px' },                 
                marginTop: { xs: "15px", sm: "20px" , md:'20px' }, 
                marginBottom: { xs: "15px", sm: "20px" , md:'20px' },
                borderRadius:"7px"
               }}>
              {/* <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#454647", fontWeight: 600, mt: 2 }}
              >
                Search Commercial Invoices
              </Typography> */}
              <Box sx={{maxWidth: { xs: "100%", sm: "230px" , md:'230px' }, width:'100%' }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: agentNameError
                          ? "#d32f2f"
                          : "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    
                    <Autocomplete
                      id="combo-box-demo"
                      options={userDetails}
                      getOptionLabel={(option) => option.full_name}
                      onChange={(event, newValue) => {
                        handleChange(event, newValue, "agentName");
                      }}
                      
                      error={agentNameError}
                      helperText={agentNameError && agentNameErrorMessage}
                      size="small"
                      disableClearable={true}
                      sx={{
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#18a689!important",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#18a689",
                          },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Select Agent" />
                          {agentNameError && (
                            <FormHelperText
                              sx={{
                                color: "#d32f2f",
                              }}
                            >
                              {agentNameErrorMessage}
                            </FormHelperText>
                          )}
                        </>
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        return (
                          <li
                            {...props}
                            key={`${option.id}-${option.full_name}`}
                          >
                            {option?.full_name}
                          </li>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              </Box>
              <Box sx={{maxWidth: { xs: "100%", sm: "230px" , md:'230px' }, width:'100%' }}>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Select
                </Typography> */}
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    Plus / Standard
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Plus / Standard"
                    value={invoiceSearchInfo.type}
                    onChange={(event) => {
                      handleChange(event, "", "type");
                    }}
                    size="small"
                  >
                    <MenuItem value={"all"} sx={{ fontSize: 15 }}>
                      All
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Plus
                    </MenuItem>
                    <MenuItem value={0} sx={{ fontSize: 15 }}>
                      Standard
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,                  
                    maxWidth:'46px', width:'100%'
                  }}
                >
                  From :
                </Typography>
                <TextField
                  name="upload-file"
                  type="date"
                  size="small"
                  value={invoiceSearchInfo.fromDate}
                  onChange={(event) => {
                    setInvoicesSearchInfo({
                      ...invoiceSearchInfo,
                      fromDate: event.target.value,
                    });
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                  }}
                /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{marginTop:'-8px'}}>
                      <DatePicker label="From Date" sx={{
                                  fontSize: "11px",
                                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                  {
                                    padding: "9px 13px 9px 13px",
                                  },
                                  width: "100%",
                                 
                                  "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                    top:'-7px',                            
                                  },
                                  "& .MuiInputLabel-root": {
                                                  "&.Mui-focused": {
                                                    color: "#18a689!important",
                                                  },
                                                },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6DD6C2!important",
                                  },
                                }} />
                    </DemoContainer>
                  </LocalizationProvider>
              </Box>
              <Box sx={{ display: { xs: "grid", sm: "flex" , md:'flex' },gap: { xs: "5px", sm: "5px" , md:'10px' }, alignItems:'center', }}>
                {/* <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,                    
                    maxWidth:'46px', width:'100%'
                  }}
                >
                  To :
                </Typography>
                <TextField
                  name="upload-file"
                  type="date"
                  size="small"
                  value={invoiceSearchInfo.toDate}
                  InputProps={{
                    inputProps: {
                      min: getMinToDate(invoiceSearchInfo.fromDate),
                    },
                  }}
                  onChange={(event) => {
                    setInvoicesSearchInfo({
                      ...invoiceSearchInfo,
                      toDate: event.target.value,
                    });
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                  }}
                /> */}
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{marginTop:'-8px'}}>
                      <DatePicker label="To Date" sx={{
                                  fontSize: "11px",
                                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                  {
                                    padding: "9px 13px 9px 13px",
                                  },
                                  width: "100%",
                                 
                                  "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                    top:'-7px',                            
                                  },
                                  "& .MuiInputLabel-root": {
                                                  "&.Mui-focused": {
                                                    color: "#18a689!important",
                                                  },
                                                },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6DD6C2",
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6DD6C2!important",
                                  },
                                }} />
                    </DemoContainer>
                  </LocalizationProvider>
              </Box>
              <Button
                type="submit"
                variant="contained"                
                onClick={() => {
                  onSearch();
                }}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,                 
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    // Customize the hover color
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>
            </Box>
              <CommercialParentTable
                generatePdf={generatePdf}
                sendEmail={sendEmail}
              />
              <Loader loading={isLoading || isSendEmailLoading} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
