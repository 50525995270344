import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Fade, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import Popper from "@mui/material/Popper";
import {
  AlternateCampaignModal,
  Loader,
  ProfileModal,
  UpdateTooltip,
} from "../Common";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CallbackTableHead } from "./CallbackTableHead";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  checkAndReturnNone,
  convertApiResponseToRows,
  formatPhoneNumber,
} from "../../helpers";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { findAlternateCampaign, getViewMapDetails } from "../../store/actions";
import { DatePicker, TimePicker } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import dayjs from "dayjs";
import moment from "moment";

export const CallbackTable = (props) => {
  const {
    rows,
    filterName,
    handleNameFilterChange,
    filterActive,
    handleActiveFilterChange,
    allCommentsExpanded,
    toggleAllComments,
    page,
    setPage,
    offset,
    fetchMoreRecord,
    setOffset,
    onUpdateCampaignWithAltCampaign,
    updateEmployeeToCallBack,
  } = props;

  const { format, addMinutes, parseISO } = require("date-fns");

  const dispatch = useDispatch();

  const [openId, setOpenId] = React.useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const [open, setOpen] = useState({ isOpen: false, id: null });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);

  const handleArrowRef = (node) => {
    setArrowRef(node);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleToggle = (event, callBackId = null) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => ({
      isOpen: !prevOpen.isOpen,
      id: callBackId,
    }));
  };

  const handleClose = () => {
    setOpen({ isOpen: false, id: null });
  };

  const allEmployees = useSelector(
    (state) => state?.appointment?.allEmployeelist
  );

  const findEmployeeByid = (statusId) => {
    return allEmployees?.find((status) => status?.id == statusId) || null;
  };

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id) => {
    setTooltipID(id);
  };

  const keyCss = {
    fontSize: 13,
    color: "#0088cc",
    fontWeight: 500,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#0088cc",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    maxWidth: "fit-content",
    position: "relative",
    cursor: "pointer",
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const alternateCampaigns = useSelector(
    (state) => state?.campaign?.findCampaigns?.data
  );
  const tableRows = convertApiResponseToRows(alternateCampaigns?.concatedData);

  const handleOpenModal = () => {
    // Set your data accordingly

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    // if (!event.target.closest(".show-button")) {
    //   // Exclude clicks on the "Show" button
    //   const selectedIndex = selected.indexOf(row?.operations?.id);
    //   let newSelected = [];

    //   if (selectedIndex === -1) {
    //     newSelected = newSelected.concat(selected, row?.operations?.id);
    //   } else if (selectedIndex === 0) {
    //     newSelected = newSelected.concat(selected.slice(1));
    //   } else if (selectedIndex === selected.length - 1) {
    //     newSelected = newSelected.concat(selected.slice(0, -1));
    //   } else if (selectedIndex > 0) {
    //     newSelected = newSelected.concat(
    //       selected.slice(0, selectedIndex),
    //       selected.slice(selectedIndex + 1)
    //     );
    //   }

    //   setSelected(newSelected);
    // }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const recordsFinished = (newPage + 1) * rowsPerPage >= rows.length;

    if (recordsFinished) {
      //console.log("Records have finished.");
      const nextOffset = offset + 1;
      setOffset((prevState) => prevState + 1);
      fetchMoreRecord(nextOffset);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleShowDetails = (callback) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  let loading = useSelector((state) => state?.callback?.isLoading);
  const findCamapignsLoading = useSelector(
    (state) => state?.campaign?.findCampaigns?.loading
  );
  const isCalendarLoading = useSelector(
    (state) => state?.appointment?.isCalendarLoading
  );

  function toggleUniqueIdInArray(id) {
    setOpenId((prevIds) => {
      const index = prevIds?.indexOf(id);
      if (index === -1) {
        // ID is not in the array, so push it
        return [...prevIds, id];
      } else {
        // ID is already in the array, so remove it
        const newIds = [...prevIds];
        newIds?.splice(index, 1);
        return newIds;
      }
    });
  }

  function isIdInArray(id) {
    return openId?.includes(id);
  }

  const findCamapigns = (prospectId, callBackId) => {
    const payload = {
      prospect_id: prospectId,
      callback_id: callBackId,
    };
    dispatch(findAlternateCampaign(payload, handleOpenModal, false));
    // findAlternateCampaign();
  };

  const onUpdate = (campaignId) => {
    const payload = {
      callback_id: alternateCampaigns?.callback_id,
      checked_campaign_id: campaignId,
    };
    // console.log("payload=====>", payload);
    onUpdateCampaignWithAltCampaign(payload, handleCloseModal);
  };

  const openNewLeadTabForMap = (data) => {
    const dataToSend = {
      isViewMap: true,
      campaignInfo: data,
    };

    // console.log("dateToSend==>",dataToSend);
    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabStateForViewMap", jsonData);

    // Open a new tab
    const newTab = window.open("/NewCampaigns", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getViewMapData = (camId) => {
    const payload = {
      prospectId: alternateCampaigns?.prospect_id,
      campaignId: camId,
      isFromLead: false,
    };
    dispatch(
      getViewMapDetails(payload, (data) => {
        openNewLeadTabForMap(data);
      })
    );
  };

  const onAssignedto = (callBackId, employeeId) => {
    const payload = {
      callback_id: callBackId,
      employee_id: employeeId,
      date_time: null,
    };
    updateEmployeeToCallBack(payload, () => {
      handleTooltipClose();
    });
  };

  const initialAppointmentData = {
    confirmAppointmentDate: "",
    confirmAppointmentTime: "",
  };

  const [appointmentData, setAppointmentData] = useState(
    initialAppointmentData
  );

  const [triedSubmit, setTriedSubmit] = useState(false);

  const confirmAppointmentDateError =
    triedSubmit &&
    appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0;

  const confirmAppointmentTimeError =
    triedSubmit &&
    appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0;

  const isCheckFormValid = () => {
    const isCNFApppointmentDateValid =
      appointmentData?.confirmAppointmentDate?.toString()?.trim().length > 0;

    const isCNFAppointmentTimeValid =
      appointmentData?.confirmAppointmentTime?.toString()?.trim().length > 0;

    return isCNFApppointmentDateValid && isCNFAppointmentTimeValid;
  };

  const isFormValid = isCheckFormValid();

  const onSubmitHandler = () => {
    setTriedSubmit(true);
    if (!isFormValid) return;

    handleClose();

    const combinedDateTime = parseISO(
      `${appointmentData?.confirmAppointmentDate}T${appointmentData?.confirmAppointmentTime}:00Z`
    );

    // Adjust the time zone offset (replace 'America/New_York' with your actual time zone)
    const adjustedDateTime = addMinutes(
      combinedDateTime,
      new Date().getTimezoneOffset()
    );

    // Format the adjusted date and time
    const formattedDateTime = format(adjustedDateTime, "MM/dd/yyyy hh:mm a");

    const payload = {
      callback_id: open.id,
      employee_id: null,
      date_time: formattedDateTime,
    };
    updateEmployeeToCallBack(payload, () => {
      setTriedSubmit(false);
      setAppointmentData(initialAppointmentData);
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <CallbackTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={18}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const isCommentExpanded =
                              allCommentsExpanded || isIdInArray(index);
                            return (
                              <>
                                <TableRow
                                  hover
                                  sx={{
                                    backgroundColor:
                                      index % 2 == 1 ? "#fff" : "#fff",
                                    borderBottom: "0!important",
                                    "&:hover": {
                                      backgroundColor: "#F4F6FA!important",
                                    },
                                  }}
                                  onClick={(event) => handleClick(event, row)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.firstName}
                                  selected={isItemSelected}
                                >
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      paddingX: 0,
                                      borderBottom: "0",
                                    }}
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        toggleUniqueIdInArray(index)
                                      }
                                    >
                                      {isCommentExpanded ? (
                                        <KeyboardArrowDownIcon />
                                      ) : (
                                        <KeyboardArrowRightIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{ borderBottom: "0" }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        fontWeight: 500,
                                        textTransform: "capitalize",
                                        width: "20px",
                                        height: "20px",
                                        minWidth: "30px",
                                        pl: 1.5,
                                        pr: 1.5,
                                        pt: 1.5,
                                        pb: 1.5,
                                        backgroundColor: "#6B7489",
                                        "&:hover": {
                                          backgroundColor: "#6B7489",
                                        },
                                        borderRadius: "3px",
                                        borderColor: "#6B7489",
                                        color: "#fff",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        navigate("/CampaignsShow", {
                                          state: {
                                            isFrom: "callbacks",
                                            details: row?.show ?? [],
                                          },
                                        }); // Prevent event propagation to TableRow
                                      }}
                                      className="show-button" // Add a class to identify the "Show" button
                                    >
                                      <RemoveRedEyeIcon
                                        sx={{ fontSize: "19px" }}
                                      />
                                    </Button>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "nowrap",
                                      paddingX: 5,
                                    }}
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                  >
                                    {checkAndReturnNone(row?.amountOwed)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "nowrap",
                                      paddingX: 0,
                                      paddingRight: 2,
                                    }}
                                    align="left"
                                  >
                                    {checkAndReturnNone(row?.agent)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "0",
                                    }}
                                    align="left"
                                  >
                                    <Typography
                                      onClick={(event) => {
                                        handleToggle(event, row?.show?.id);
                                      }}
                                      sx={{
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "wrap",
                                        paddingX: 0,
                                        cursor: "pointer",
                                        paddingRight: 3,
                                      }}
                                    >
                                      {moment
                                        .utc(row?.scheduleTime)
                                        .format("MMMM D, YYYY, h:mm A") ??
                                        row?.scheduleTime}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "wrap",
                                      paddingX: 0,
                                    }}
                                    align="left"
                                  >
                                    {checkAndReturnNone(row?.campaign)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      width: "220px",
                                      paddingX: 0,
                                    }}
                                    align="left"
                                  >
                                    {checkAndReturnNone(row?.accountName)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      paddingX: 0,
                                    }}
                                    align="left"
                                  >
                                    {formatPhoneNumber(row.phoneNumber) ??
                                      "None"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      width: "220px",
                                      paddingX: 0,
                                    }}
                                    align="left"
                                  >
                                    {checkAndReturnNone(row?.prospects)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "nowrap",
                                    }}
                                    align="left"
                                  >
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      onClick={() => {
                                        findCamapigns(
                                          row?.show.prospect,
                                          row?.show?.id
                                        );
                                      }}
                                      sx={{
                                        fontWeight: 600,
                                        textTransform: "capitalize",
                                        pl: 2,
                                        pr: 2,
                                        backgroundColor: "#6DD6C2",
                                        borderColor: "#6DD6C2",
                                        color: "#212223",
                                        "&:hover": {
                                          // Customize the hover color
                                          backgroundColor: "#6DD6C2",
                                          borderColor: "#6DD6C2",
                                        },
                                      }}
                                    >
                                      Find
                                    </Button>
                                  </TableCell>
                                  {userInfo?.user_type !== "Administration" ? (
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "wrap",
                                        paddingX: 0,
                                        paddingRight: "5px",
                                      }}
                                      align="left"
                                    >
                                      {checkAndReturnNone(
                                        findEmployeeByid(row?.show?.assigned_to)
                                          ?.full_name
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      sx={{
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                        paddingX: 0,
                                      }}
                                      align="left"
                                    >
                                      <Tooltip
                                        sx={{
                                          marginTop: "0!important",
                                          " & .MuiTooltip-tooltip": {
                                            marginTop: "0!important",
                                          },
                                          backgroundColor: "#ffffff",
                                          borderRadius: "5px",
                                          borderColor: "black",
                                        }}
                                        arrow
                                        placement="bottom-start"
                                        PopperProps={{
                                          disablePortal: false,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={
                                          tooltipID === `employee${index}`
                                            ? true
                                            : false
                                        }
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={
                                          <UpdateTooltip
                                            onClose={handleTooltipClose}
                                            label={"Assigned to"}
                                            onSubmit={(value) => {
                                              // onChangeLeadKey(
                                              //   "agency_calling_for",
                                              //   value
                                              // );
                                              if (
                                                value ===
                                                  findEmployeeByid(
                                                    row?.show?.assigned_to
                                                  )?.full_name ||
                                                value == row?.show?.assigned_to
                                              ) {
                                                handleTooltipClose();
                                              } else {
                                                onAssignedto(
                                                  row?.show?.id,
                                                  value
                                                );
                                              }
                                              // console.log("value====>",value);
                                            }}
                                            selectedKey={
                                              findEmployeeByid(
                                                row?.show?.assigned_to
                                              )?.full_name
                                            }
                                            inputType={"SelectOptions"}
                                            labelKey={"full_name"}
                                            optionsData={allEmployees}
                                            value={data}
                                            setValue={setData}
                                          />
                                        }
                                      >
                                        <Typography
                                          component="h4"
                                          variant="h4"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            const val = checkAndReturnNone(
                                              row?.assignedTo
                                            );
                                            setData(val);
                                            handleTooltipOpen(
                                              `employee${index}`
                                            );
                                          }}
                                          sx={{
                                            fontSize: 15,
                                            color: "#0088cc",
                                            fontWeight: 500,
                                            borderStyle: "dashed",
                                            borderWidth: 1,
                                            borderColor: "#0088cc",
                                            borderTop: 0,
                                            borderRight: 0,
                                            textTransform: "capitalize",
                                            borderLeft: 0,
                                            cursor: "pointer",
                                            maxWidth: "fit-content",
                                          }}
                                        >
                                          {checkAndReturnNone(
                                            findEmployeeByid(
                                              row?.show?.assigned_to
                                            )?.full_name
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    </TableCell>
                                  )}

                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "wrap",
                                      paddingX: 0,
                                      paddingRight: "5px",
                                    }}
                                    align="left"
                                  >
                                    {format(
                                      new Date(row.createdAt),
                                      "MMMM d, yyyy, h:mm a"
                                    ) ?? row.createdAt}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "nowrap",
                                      paddingX: 0,
                                    }}
                                    align="left"
                                  >
                                    {checkAndReturnNone(row?.createdBy)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                      borderBottom: "0",
                                      backgroundColor: "#F4F6FA",
                                    }}
                                    colSpan={18}
                                  >
                                    <Collapse
                                      in={isCommentExpanded}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box
                                        sx={{
                                          padding: 1,
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#212223",
                                            fontWeight: 600,
                                            fontSize: 14,
                                            borderBottom: "0",
                                            whiteSpace: "nowrap",
                                            paddingX: 0,
                                            marginRight: 1,
                                          }}
                                        >
                                          Comment :
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: 14,
                                            color: "#6B7489",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {checkAndReturnNone(
                                            row?.show?.comment
                                          )}
                                        </Typography>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Paper>
      <AlternateCampaignModal
        open={isModalOpen}
        data={tableRows}
        onClose={handleCloseModal}
        onUpdate={onUpdate}
        onViewMap={getViewMapData}
      />
      <Loader loading={findCamapignsLoading || isCalendarLoading} />
      <Popper
        open={open.isOpen}
        anchorEl={anchorEl}
        ref={handleArrowRef}
        placement="bottom"
        transition
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          preventOverflow: {
            enabled: "true",
            boundariesElement: "scrollParent",
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={50}>
            <Paper>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 14,
                    color: "#454647",
                    fontWeight: 700,
                    borderBottom: 1,
                    borderColor: "#ddd",
                    borderRadius: 1,
                    padding: 0.5,
                    mb: 1,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  Update Date/Time
                </Typography>
                <TextField
                  margin="0"
                  size="small"
                  type="date"
                  fontSize="15"
                  error={confirmAppointmentDateError}
                  value={appointmentData.confirmAppointmentDate}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      confirmAppointmentDate: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "15px",
                      "&.Mui-focused": {
                        color: "#6DD6C2",
                      },
                    },
                  }}
                  sx={{
                    marginBottom: "10px",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "200px",
                      lg: "200px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                  }}
                />
                <TextField
                  ampm={true}
                  type="time"
                  name="confirmappointmentTime"
                  size="small"
                  value={appointmentData.confirmAppointmentTime}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      confirmAppointmentTime: e.target.value,
                    });
                  }}
                  error={confirmAppointmentTimeError}
                  InputLabelProps={{
                    sx: {
                      fontSize: "15px",
                      "&.Mui-focused": {
                        color: "#6DD6C2",
                      },
                    },
                  }}
                  sx={{
                    marginBottom: "10px",
                    width: "200px!important",
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "7px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "25px",
                      height: "30px",
                      minWidth: "30px",
                      p: 1.5,
                      backgroundColor: "#1ab394",
                      "&:hover": {
                        backgroundColor: "#1ab394",
                      },
                      borderRadius: "3px",
                      borderColor: "#1ab394",
                      color: "#fff",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();

                      onSubmitHandler();
                    }}
                  >
                    <FileDownloadDoneIcon
                      sx={{ fontSize: "20px", backgroundColor: "#1ab394" }}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: "25px",
                      height: "30px",
                      minWidth: "30px",
                      pl: 1.5,
                      pr: 1.5,
                      pt: 1.5,
                      pb: 1.5,
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },

                      color: "#000",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClose();
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
