export const USER_DASHBOARD_PRODUCTION_IN_PROGRESS = 'USER_DASHBOARD_PRODUCTION_IN_PROGRESS';
export const USER_DASHBOARD_PRODUCTION_SUCCESS = 'USER_DASHBOARD_PRODUCTION_SUCCESS';
export const USER_DASHBOARD_PRODUCTION_FAILURE = 'USER_DASHBOARD_PRODUCTION_FAILURE';

export const LEAD_OWED_REPORT_IN_PROGRESS = 'LEAD_OWED_REPORT_IN_PROGRESS';
export const LEAD_OWED_REPORT_SUCCESS = 'LEAD_OWED_REPORT_SUCCESS';
export const LEAD_OWED_REPORT_FAILURE = 'LEAD_OWED_REPORT_FAILURE';



// Action Types
export const UPDATE_LEAD_OWN_KEY_IN_PROGRESS = 'UPDATE_LEAD_OWN_KEY_IN_PROGRESS';
export const UPDATE_LEAD_OWN_KEY_SUCCESS = 'UPDATE_LEAD_OWN_KEY_SUCCESS';
export const UPDATE_LEAD_OWN_KEY_FAILURE = 'UPDATE_LEAD_OWN_KEY_FAILURE';
