// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OnlineUserParentTable } from "./OnlineUserParentTable";

export const OnlineUserReport = (props) => {
  const { getOnlineUsers } = props;

  useEffect(() => {
    getOnlineUsers();
  }, []);

  const logoutCallBack = () => {
    getOnlineUsers();
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 }, color: "#212223", fontWeight: 600 }}
              >
                Current Users Online
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <OnlineUserParentTable logoutCallBack={logoutCallBack} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
