const MapboxDraw = require("@mapbox/mapbox-gl-draw");

const circle = require("@turf/circle").default;
const distance = require("@turf/distance").default;
const turfHelpers = require("@turf/helpers");
const { store } = require("../../store");
const {
  updateCurrentCircleRadius,
  updateCurrentDrag,
} = require("../../store/actions");

const throttle = require("lodash/throttle");



const throttledDispatch = throttle((distanceInKm) => {
  store.dispatch(updateCurrentCircleRadius(distanceInKm));
}, 1000); // Adjust the throttle duration (in milliseconds) as needed

const DragCircleMode = { ...MapboxDraw.modes.draw_polygon };

DragCircleMode.onSetup = function (opts) {
  const polygon = this.newFeature({
    type: MapboxDraw.constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: [],
    },
    geometry: {
      type: MapboxDraw.constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  double_click_zoom_disable(this);
  drag_pan_disable(this);
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  this.activateUIButton(MapboxDraw.constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

DragCircleMode.onMouseDown = DragCircleMode.onTouchStart = function (state, e) {
  const currentCenter = state.polygon.properties.center;
  if (currentCenter.length === 0) {
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
  }
  store.dispatch(updateCurrentDrag(true));
};

DragCircleMode.onDrag = DragCircleMode.onMouseMove = function (state, e) {
  const center = state.polygon.properties.center;
  if (center.length > 0) {
    const distanceInKm = distance(
      turfHelpers.point(center),
      turfHelpers.point([e.lngLat.lng, e.lngLat.lat]),
      { units: "kilometers" }
    );
    const circleFeature = circle(center, distanceInKm);
    state.polygon.incomingCoords(circleFeature.geometry.coordinates);
    state.polygon.properties.radiusInKm = distanceInKm;
    throttledDispatch(distanceInKm);
    // store.dispatch(updateCurrentCircleRadius(distanceInKm));
  }
};

DragCircleMode.onMouseUp = DragCircleMode.onTouchEnd = function (state, e) {
  drag_pan_enable(this);
  store.dispatch(updateCurrentDrag(false));
  return this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
};

DragCircleMode.onClick = DragCircleMode.onTap = function (state, e) {
  // don't draw the circle if its a tap or click event
  state.polygon.properties.center = [];
};


DragCircleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.polygon.id;
  geojson.properties.active = isActivePolygon
    ? MapboxDraw.constants.activeStates.ACTIVE
    : MapboxDraw.constants.activeStates.INACTIVE;
  return display(geojson);
};

 function drag_pan_enable(ctx) {
  setTimeout(() => {
      // First check we've got a map and some context.
      if (!ctx.map || !ctx.map.dragPan || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue) return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('dragPan')) return;
      ctx.map.dragPan.enable();
  }, 0);
}
 function drag_pan_disable(ctx) {
  setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.dragPan.disable();
  }, 0);
}

 function double_click_zoom_enable(ctx) {
  setTimeout(() => {
    // First check we've got a map and some context.
    if (!ctx.map || !ctx.map.doubleClickZoom || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue) return;
    // Now check initial state wasn't false (we leave it disabled if so)
    if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
    ctx.map.doubleClickZoom.enable();
  }, 0);
}
 function double_click_zoom_disable(ctx) {
  setTimeout(() => {
    if (!ctx.map || !ctx.map.doubleClickZoom) return;
    // Always disable here, as it's necessary in some cases.
    ctx.map.doubleClickZoom.disable();
  }, 0);
}

module.exports = DragCircleMode;
