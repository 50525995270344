// LoginScreen.js
import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const InvoiceList = (props) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5f5f5",
      color: "#000000b8",
      fontWeight: "600",
      border: 0,
      padding: "15px 15px",
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13.5,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    "td, th": {
      border: 0,
      padding: "11px 15px",
      color: "#676a6c!important",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
      padding: "11px 15px",
    },
    "&:first-child th": {
      width: "300px",
    },
  }));

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(
      "Erwin, Christine +",
      "$ 1276.00",
      "March",
      "March 8, 2019, 1:19 p.m.",
      "March 8, 2019, 1:19 p.m."
    ),
    createData(
      "Reaver, Vince +	",
      "$ 2041.60",
      "March",
      "March 11, 2019, 8:54 a.m.",
      "March 11, 2019, 8:54 a.m."
    ),
    createData(
      "Erwin, Christine +",
      "$ 1276.00",
      "March",
      "March 8, 2019, 1:19 p.m.",
      "March 8, 2019, 1:19 p.m."
    ),
    createData(
      "Reaver, Vince +	",
      "$ 2041.60",
      "March",
      "March 11, 2019, 8:54 a.m.",
      "March 11, 2019, 8:54 a.m."
    ),
    createData(
      "Erwin, Christine +",
      "$ 1276.00",
      "March",
      "March 8, 2019, 1:19 p.m.",
      "March 8, 2019, 1:19 p.m."
    ),
    createData(
      "Reaver, Vince +	",
      "$ 2041.60",
      "March",
      "March 11, 2019, 8:54 a.m.",
      "March 11, 2019, 8:54 a.m."
    ),
  ];

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{ paddingLeft: "15px", paddingRight: "15px", marginTop: "20px" }}
      >
        {/* <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{borderBottom:1, borderColor:'#676a6c4a' }}>
                    <Typography component="h2" variant="h2" sx={{fontSize: 18, color: "#454647",fontWeight: 600,}}>Search Commercial Invoices</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{padding:"20px 0", display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },alignItems: "center",gap:"20px"}}>
                    <FormControl  size="small" sx={{  width: '250px',
                            "& .MuiOutlinedInput-notchedOutline" : {                                                                    
                                borderColor: "rgba(0, 0, 0, 0.23)"
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                  borderColor: "#18a689",
                              },                              
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline" : {
                                borderColor: "#18a689!important"
                              }, }}>
                            <InputLabel id="demo-simple-select-label" sx={{fontSize: 15, "&.Mui-focused": {color: "#18a689!important",fontWeight:'600', margin:'0'},}} >Select Agent</InputLabel>
                                <Select                                    
                                    id="selectinfo"                                
                                    label="Select Agent"
                                    onChange={handleChange}
                                    size="small">
                                    <MenuItem value={1} sx={{fontSize: 15,}}>Abbott, Kenneth</MenuItem>
                                    <MenuItem value={2} sx={{fontSize: 15,}}>Account, Training</MenuItem>
                                    <MenuItem value={3} sx={{fontSize: 15,}}>Acinapura, Dana</MenuItem> 
                                    <MenuItem value={4} sx={{fontSize: 15,}}>Akin, Larry</MenuItem>
                                    <MenuItem value={5} sx={{fontSize: 15,}}>Albert, Donnie</MenuItem>
                                    <MenuItem value={6} sx={{fontSize: 15,}}>Albert, Dan</MenuItem> 
                                    <MenuItem value={7} sx={{fontSize: 15,}}>Alfred, Mike</MenuItem>
                                    <MenuItem value={8} sx={{fontSize: 15,}}>Allen, Bill</MenuItem>                                                                                                 
                                </Select>
                        </FormControl>
                        <FormControl  size="small" sx={{  width: '250px',
                            "& .MuiOutlinedInput-notchedOutline" : {                                                                    
                                borderColor: "rgba(0, 0, 0, 0.23)"
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                  borderColor: "#18a689",
                              },                              
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline" : {
                                borderColor: "#18a689!important"
                              }, }}>
                            <InputLabel id="demo-simple-select-label" sx={{fontSize: 15, "&.Mui-focused": {color: "#18a689!important",fontWeight:'600', margin:'0'},}} >All</InputLabel>
                                <Select                                    
                                    id="selectinfo"                                
                                    label="All"
                                    onChange={handleChange}
                                    size="small">
                                    <MenuItem value={1} sx={{fontSize: 15,}}>All</MenuItem>
                                    <MenuItem value={2} sx={{fontSize: 15,}}>Plus</MenuItem>
                                    <MenuItem value={3} sx={{fontSize: 15,}}>Standard</MenuItem> 
                                                                                                  
                                </Select>
                        </FormControl>
                        <Box sx={{display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },alignItems: "center",gap:"5px"}}>
                        <Typography component="h2" variant="h2"
                        sx={{
                            fontSize: 16,
                            color: "#454647",
                            fontWeight: 600,                            
                        }}>From Date :</Typography>
                        <TextField
                            name="upload-file"
                            type="date"
                            size="small"                             
                            sx={{
                                   
                                "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#18a689",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#18a689!important",
                                },
                            }}
                        />
                        </Box>
                        <Box sx={{display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },alignItems: "center",gap:"5px"}}>
                        <Typography component="h2" variant="h2"
                        sx={{
                            fontSize: 16,
                            color: "#454647",
                            fontWeight: 600,                            
                        }}>To Date :</Typography>
                        <TextField
                            name="upload-file"
                            type="date"
                            size="small"                             
                            sx={{
                                   
                                "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#18a689",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#18a689!important",
                                },
                            }}
                        />
                        </Box>
                        <Button
                                type="submit"                                
                                variant="contained"                                                        
                                sx={{
                                    fontWeight: 500,
                                    textTransform:"capitalize",    
                                    pl: 4,
                                    pr: 4,                               
                                    backgroundColor: "#18a689",
                                    borderColor: "#18a689",
                                    color: "#FFFFFF",
                                    "&:hover": {
                                    // Customize the hover color
                                    backgroundColor: "#32c3a3",
                                    borderColor: "#32c3a3",
                                    },
                                }}>Search
                            </Button>
                    </Box>
                </AccordionDetails>
            </Accordion> */}
        <Accordion defaultExpanded>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#454647", fontWeight: 600 }}
              >
                Invoices
              </Typography>
            </AccordionSummary>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: "none" }),
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#00c96f",
                "&:hover": {
                  backgroundColor: "#18a689",
                },
                borderRadius: "50%",
                borderColor: "#18a689",
                color: "#fff",
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#00c96f",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#00c96f",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>

            <Box sx={{ padding: "0 20px" }}>
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#454647", fontWeight: 600, mt: 2 }}
              >
                Search Invoices
              </Typography>
              <Box sx={{ marginTop: "30px" }}>
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Select Agent
                </Typography>
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#18a689!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#18a689!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    Select Agent
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Select Agent"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Abbott, Kenneth
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Account, Training
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      Acinapura, Dana
                    </MenuItem>
                    <MenuItem value={4} sx={{ fontSize: 15 }}>
                      Akin, Larry
                    </MenuItem>
                    <MenuItem value={5} sx={{ fontSize: 15 }}>
                      Albert, Donnie
                    </MenuItem>
                    <MenuItem value={6} sx={{ fontSize: 15 }}>
                      Albert, Dan
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: 15 }}>
                      Alfred, Mike
                    </MenuItem>
                    <MenuItem value={8} sx={{ fontSize: 15 }}>
                      Allen, Bill
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Select
                </Typography>
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#18a689!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#18a689!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    All
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="All"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Plus
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      Standard
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  From Date :
                </Typography>
                <TextField
                  name="upload-file"
                  type="date"
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689!important",
                      },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 15,
                    color: "#7b7b7b",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  To Date :
                </Typography>
                <TextField
                  name="upload-file"
                  type="date"
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#18a689!important",
                      },
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  mt: 4,
                  backgroundColor: "#18a689",
                  borderColor: "#18a689",
                  color: "#FFFFFF",
                  "&:hover": {
                    // Customize the hover color
                    backgroundColor: "#32c3a3",
                    borderColor: "#32c3a3",
                  },
                }}
              >
                Search
              </Button>
            </Box>
          </Drawer>
          <AccordionDetails>
            <Box sx={{ padding: "20px 0" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Agent</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Month</StyledTableCell>
                      <StyledTableCell>Created Date</StyledTableCell>
                      <StyledTableCell>Modified Date</StyledTableCell>
                      <StyledTableCell>Operations</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell>{row.calories}</StyledTableCell>
                        <StyledTableCell>{row.fat}</StyledTableCell>
                        <StyledTableCell>{row.carbs}</StyledTableCell>
                        <StyledTableCell>{row.protein}</StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                fontWeight: 500,
                                textTransform: "capitalize",
                                width: "20px",
                                height: "20px",
                                minWidth: "30px",
                                pl: 1.5,
                                pr: 1.5,
                                pt: 1.5,
                                pb: 1.5,
                                ml: 1,
                                backgroundColor: "#00c96f",
                                "&:hover": {
                                  backgroundColor: "#00c96f",
                                },
                                borderRadius: "3px",
                                borderColor: "#18a689",
                                color: "#fff",
                              }}
                            >
                              <DownloadForOfflineIcon
                                sx={{ fontSize: "22px" }}
                              />
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                fontWeight: 500,
                                textTransform: "capitalize",
                                width: "20px",
                                height: "20px",
                                minWidth: "30px",
                                pl: 1.5,
                                pr: 1.5,
                                pt: 1.5,
                                pb: 1.5,
                                ml: 1,
                                borderRadius: "3px",
                                backgroundColor: "#18a689",
                                "&:hover": {
                                  backgroundColor: "#18a689",
                                },
                                borderColor: "#18a689",
                                color: "#fff",
                              }}
                            >
                              <BorderColorIcon sx={{ fontSize: "17px" }} />
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                fontWeight: 500,
                                textTransform: "capitalize",
                                width: "20px",
                                height: "20px",
                                minWidth: "30px",
                                pl: 1.5,
                                pr: 1.5,
                                pt: 1.5,
                                pb: 1.5,
                                ml: 1,
                                backgroundColor: "#0f5396",
                                "&:hover": {
                                  backgroundColor: "#0f5396",
                                },
                                borderRadius: "3px",
                                borderColor: "#18a689",
                                color: "#fff",
                              }}
                            >
                              <RemoveRedEyeIcon sx={{ fontSize: "19px" }} />
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                fontWeight: 500,
                                textTransform: "capitalize",
                                width: "20px",
                                height: "20px",
                                minWidth: "30px",
                                pl: 1.5,
                                pr: 1.5,
                                pt: 1.5,
                                pb: 1.5,
                                ml: 1,
                                borderRadius: "3px",
                                borderColor: "#18a689",
                                color: "#fff",
                              }}
                            >
                              <SendIcon sx={{ fontSize: "18px" }} />
                            </Button>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
