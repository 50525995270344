import {
  BASE_URL,
  CLIENT_MASS_UPLOAD_URL,
  CREATE_CLIENT_URL,
  DELETE_CLIENTS_URL,
  GET_ALL_CLIENTS,
  UPDATE_CLIENT_URL,
  UPDATE_STATUS_URL,
  CLIENT_RESET_PASSWORD_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../services";
import { logout } from "../authentication";
import { LOGOUT } from "../authentication/types";
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_IN_PROGRESS,
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_IN_PROGRESS,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_IN_PROGRESS,
  GET_CLIENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_IN_PROGRESS,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_ACCOUNT_STATUS_FAILURE,
  UPDATE_ACCOUNT_STATUS_IN_PROGRESS,
  UPDATE_ACCOUNT_STATUS_SUCCESS,
  UPLOAD_CLIENT_CSV_IN_PROGRESS,
  UPLOAD_CLIENT_CSV_SUCCESS,
  UPLOAD_CLIENT_CSV_FAILURE,
  CLIENT_RESET_PASSWORD_REQUEST,
  CLIENT_RESET_PASSWORD_SUCCESS,
  CLIENT_RESET_PASSWORD_FAILURE,
} from "./types";

import toast from "react-hot-toast";

export const CreateClientInProgress = () => ({
  type: CREATE_CLIENT_IN_PROGRESS,
});

export const CreateClientSuccess = (data) => ({
  type: CREATE_CLIENT_SUCCESS,
  data,
});

export const CreateClientFailure = (error) => ({
  type: CREATE_CLIENT_FAILURE,
  error,
});

export const CreateClient = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        address,
        cellPhone,
        city,
        lastName,
        localTimeZone,
        phoneNo,
        state,
        street,
        userEmail,
        userName,
        zipCode,
        accountName,
        additionalEmail,
        agencyName,
        planPurchased,
        agentTextId,
        isFirstLogin,
        notes,
        invokaId,
        autoPaymentIsActive,
        sendIntroductionMail,
        additionalEmailForInvoices,
        plusLeadPrice,
        plusStandardPrice,
        isActive,
      } = payload;

      // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          is_active: isActive === false ? false : true,
        },
        additional_email: additionalEmail ?? "",
        account_name: accountName ?? "",
        agency_name: agencyName ?? "",
        plan_purchased: planPurchased ?? "",
        agent_text_id: agentTextId ?? "",
        is_first_login: isFirstLogin ?? "",
        notes: notes ?? "",
        invoka_id: invokaId ?? "",
        auto_payment_is_active: autoPaymentIsActive ?? "",
        send_introduction_mail: sendIntroductionMail ?? "",
        additional_email_for_invoices: additionalEmailForInvoices ?? "",
        plus_lead_price: plusLeadPrice ?? "",
        plus_standard_price: plusStandardPrice ?? "",
      };

      // Dispatch in progress action
      dispatch(CreateClientInProgress());

      // Make API request to create client
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_CLIENT_URL}`,
        params,
        "",
        token
      );

      // Check response status
      if (response?.status === 201) {
        dispatch(CreateClientSuccess(response?.data));
        cb();
      } else {
        dispatch(
          CreateClientFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      //const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
    
      dispatch(
        CreateClientFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};
//Get client
export const GetClientInProgress = () => ({
  type: GET_CLIENTS_IN_PROGRESS,
});

export const GetClientSuccess = (data) => ({
  type: GET_CLIENTS_SUCCESS,
  data,
});

export const GetClientFailure = (error) => ({
  type: GET_CLIENTS_FAILURE,
  error,
});

export const GetClients = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetClientInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const params = {
        name: payload.name !== "" ? payload?.name : null,
        account_status:
          payload.isActive !== "all"
            ? payload.isActive === "active"
              ? true
              : false
            : null,
      };
      // Make API request to read clients
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_CLIENTS}`,
        token,
        params
      );

   
      // Check response status
      if (response?.status === 200) {
        dispatch(GetClientSuccess(response?.data));
        cb();
      } else {
        dispatch(
          GetClientFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetClientFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//Update client information
export const updateClientInProgress = () => ({
  type: UPDATE_CLIENT_IN_PROGRESS,
});

export const updateClientSuccess = (data) => ({
  type: UPDATE_CLIENT_SUCCESS,
  data,
});

export const updateClientFailure = (error) => ({
  type: UPDATE_CLIENT_FAILURE,
  error,
});

export const updateClient = (clientId, payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      // Extract payload data
      const {
        address,
        cellPhone,
        city,
        lastName,
        localTimeZone,
        phoneNo,
        state,
        street,
        userEmail,
        userName,
        zipCode,
        accountName,
        additionalEmail,
        agencyName,
        planPurchased,
        agentTextId,
        isFirstLogin,
        notes,
        invokaId,
        autoPaymentIsActive,
        sendIntroductionMail,
        additionalEmailForInvoices,
        plusLeadPrice,
        plusStandardPrice,
        isActive,
      } = payload;

      // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          is_active: isActive === false ? false :true,
        },
        additional_email: additionalEmail ?? "",
        account_name: accountName ?? "",
        agency_name: agencyName ?? "",
        plan_purchased: planPurchased ?? "",
        agent_text_id: agentTextId ?? "",
        is_first_login: isFirstLogin ?? "",
        notes: notes ?? "",
        invoka_id: invokaId ?? "",
        auto_payment_is_active: autoPaymentIsActive ?? "",
        send_introduction_mail: sendIntroductionMail ?? "",
        additional_email_for_invoices: additionalEmailForInvoices ?? "",
        plus_lead_price: plusLeadPrice ?? "",
        plus_standard_price: plusStandardPrice ?? "",
      };

      // Dispatch in progress action
      dispatch(updateClientInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to update client
      const response = await putRequest(
        `${BASE_URL}${UPDATE_CLIENT_URL}${clientId}/`,
        params,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateClientSuccess(response?.data));
        cb();
      } else {
        dispatch(
          updateClientFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      console.log("error?.response",error?.response)
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
   
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${nestedKey}: ${value[nestedKey]} `;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${key}: ${value}`;
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
   
   
      dispatch(
        updateClientFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message:  errArr.join(", "),
        })
      );
    }
  };
};

//Delete client
export const deleteClientInProgress = () => ({
  type: DELETE_CLIENT_IN_PROGRESS,
});

export const deleteClientSuccess = (data) => ({
  type: DELETE_CLIENT_SUCCESS,
  data,
});

export const deleteClientFailure = (error) => ({
  type: DELETE_CLIENT_FAILURE,
  error,
});

export const deleteClient = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(deleteClientInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete client
      const response = await deleteRequest(
        `${BASE_URL}${DELETE_CLIENTS_URL}`,
        payload,
        token
      );

      // Check response status
      if (response?.status === 204) {
        dispatch(deleteClientSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteClientFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteClientFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//update status of employee account

export const updateAccountStatusInProgress = () => ({
  type: UPDATE_ACCOUNT_STATUS_IN_PROGRESS,
});

export const updateAccountStatusSuccess = (data) => ({
  type: UPDATE_ACCOUNT_STATUS_SUCCESS,
  data,
});

export const updateAccountStatusFailure = (error) => ({
  type: UPDATE_ACCOUNT_STATUS_FAILURE,
  error,
});

export const updateAccountStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const { profile_id, account_status } = payload;

      dispatch(updateAccountStatusInProgress());

      const params = { account_status: account_status };

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to update account status
      const response = await patchRequest(
        `${BASE_URL}${UPDATE_STATUS_URL}${profile_id}/user-status/`,
        params,
        token
      );

      // Check response status
      if (response.status === 204) {
        dispatch(updateAccountStatusSuccess(response?.data));
        toast.success("Client's account status updated successfully", {
          position: "bottom-center",
        });
        cb(); // Call the callback function
      } else {
        dispatch(
          updateAccountStatusFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateAccountStatusFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
// Define action creators for in progress, success, and failure
export const uploadClientCsvInProgress = () => ({
  type: UPLOAD_CLIENT_CSV_IN_PROGRESS,
});

export const uploadClientCsvSuccess = (data) => ({
  type: UPLOAD_CLIENT_CSV_SUCCESS,
  data,
});

export const uploadClientCsvFailure = (error) => ({
  type: UPLOAD_CLIENT_CSV_FAILURE,
  error,
});

// Define the uploadClientCsv method
export const uploadClientCsv = (file, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(uploadClientCsvInProgress());

      // Create formData object and append the file
      const formData = new FormData();
      formData.append("csv_file", file);

      // Make API request to upload the CSV file
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CLIENT_MASS_UPLOAD_URL}`,
        formData,
        "",
        token,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(uploadClientCsvSuccess(response?.data));
        cb();
        return response.data; // Resolve the promise with the response data
      } else {
        dispatch(
          uploadClientCsvFailure({
            type: ERROR_TYPE.API.CLIENT.CSV_UPLOAD,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        throw new Error(response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN); // Reject the promise with an error
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        uploadClientCsvFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      throw new Error(errorMessage); // Reject the promise with an error
    }
  };
};


// Action Creators
export const clientResetPasswordRequest = () => ({
  type: CLIENT_RESET_PASSWORD_REQUEST,
});

export const clientResetPasswordSuccess = (data) => ({
  type: CLIENT_RESET_PASSWORD_SUCCESS,
  data,
});

export const clientResetPasswordFailure = (error) => ({
  type: CLIENT_RESET_PASSWORD_FAILURE,
  error,
});

// Async Action Creator
export const clientResetPassword = (id, cb = () => {}) => {
  return async (dispatch) => {
    try {

      dispatch(clientResetPasswordRequest());

      const params = {
        client_id: id,
      };

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${CLIENT_RESET_PASSWORD_URL}`;
      const response = await postRequest(URL, params,"",token);

      if (response?.status === 200) {
        dispatch(clientResetPasswordSuccess(response?.data));
        cb();
        const message =
          response?.data.message ?? "New Password is sent to registered email id";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          clientResetPasswordFailure({
            type: ERROR_TYPE.API.CLIENT.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        clientResetPasswordFailure({
          type: ERROR_TYPE.API.CLIENT.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};


