import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {
  Skeleton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  TextField,
} from "@mui/material";
import {
  AlternateCampaignModal,
  Loader,
  ProfileModal,
  TwilioVoice,
  UpdateTooltip,
} from "../Common";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LeadTableHead } from "./LeadTableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  CATEGORY,
  CATEGORY_IN_OBJ,
  checkAndReturnNone,
  convertApiResponseToRows,
  formatPhoneNumber,
} from "../../helpers";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  clearEvents,
  findAlternateCampaign,
  getViewMapDetails,
} from "../../store/actions";

export const LeadTable = (props) => {
  const {
    rows,
    totalCount,
    filterName,
    handleNameFilterChange,
    filterActive,
    handleActiveFilterChange,
    fetchMoreRecord,
    page,
    setPage,
    onDelete,
    onChangeLeadKey,
    onChangeAppointmentKey,
    selected,
    setSelected,
    onUpdateCampaignWithAltCampaign,
  } = props;
  const { format } = require("date-fns");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [isShow, setIsShow] = React.useState(false);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const [open2, set2Open] = React.useState({
    id: null,
    isVisible: false,
    value: null,
  });

  const [offset, setOffset] = React.useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    selectedUserID && onDelete(selectedUserID);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = (row) => {
    setSelectedUserID(row?.operations?.id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelected([]);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    setOpenDeleteDialog(false);
  };
  const handleClick = (event, row) => {
    // event.stopPropagation();
    if (!event.target.closest(".show-button")) {
      // Exclude clicks on the "Show" button
      const selectedIndex = selected.indexOf(row?.operations?.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row?.operations?.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    // debugger;
    setPage(newPage);
    const recordsFinished = newPage * rowsPerPage >= rows.length;
    // debugger
    if (recordsFinished) {
      //console.log("Records have finished.");
      // debugger
      const nextOffset = offset + 1;
      setOffset((prevState) => prevState + 1);
      fetchMoreRecord(nextOffset);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const findVerifiedByid = (status) => {
    return (
      [
        {
          id: true,
          label: "True",
        },
        {
          id: false,
          label: "False",
        },
      ].find((st) => st.id == status) || null
    );
  };


  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: 3,
    borderColor: "#6DD6C2",
    p: 3,
    borderRadius: "7px",
    "&:focus-visible": {
      outline: "0",
    },
  };

  const [formData, setFormData] = useState({
    appointmentDate: "",
    confirmAppointmentDate: "",
    appointmentTime: "",
    confirmAppointmentTime: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handle1ClickOpen = (id, value) => {
    set2Open({ id: id, isVisible: true, value: value });
  };

  const handle1Close = () => {
    setErrors({});
    set2Open({ id: null, isVisible: false, value: null });
  };

  const handleFormSubmit = () => {
    // Validation logic
    const newErrors = {};

    if (!formData.appointmentDate) {
      newErrors.appointmentDate = "Appointment Date is required";
    }

    if (!formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate = "Confirm Appointment Date is required";
    }

    if (!formData.appointmentTime) {
      newErrors.appointmentTime = "Appointment Time is required";
    }

    if (!formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime = "Confirm Appointment Time is required";
    }

    if (formData.appointmentDate !== formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate =
        "Confirm Appointment date and Appointment date is not same";
    }

    if (formData.appointmentTime !== formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime =
        "Confirm Appointment time and Appointment time is not same";
    }

    // Add additional validation logic, e.g., date/time matching

    if (Object.keys(newErrors).length > 0) {
      // There are errors, don't submit the form
      setErrors(newErrors);
    } else {
     
      const dateObject = new Date(formData.confirmAppointmentDate);

      // Format the date to the desired format
      const formattedDate = format(dateObject, "MM-dd-yyyy");

      const payload = {
        appointment_id: open2.id,
        appointment_date: formattedDate,
        appointment_time: formData.confirmAppointmentTime,
        lead_status: open2?.value !== null ? parseInt(open2?.value, 10) : 6,
      };

      onChangeAppointmentKey(payload, () => {
        setErrors({});
        handle1Close();
      });
    }
  };

  const isloading = useSelector((state) => state?.lead?.isLoading);
  const isAppointmentStatusUpdate = useSelector(
    (state) => state?.lead?.isAppointmentStatusUpdate
  );

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  function getOptionName(input) {
    const optionName = CATEGORY[input];
    return optionName || "None";
  }

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id, type) => {
    setTooltipID({ id: id, type: type });
  };

  const findLeadCategoryByid = (catId) => {
    return CATEGORY_IN_OBJ.find((cat) => cat.id == catId) || null;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    // Set your data accordingly

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const alternateCampaigns = useSelector(
    (state) => state?.campaign?.findCampaigns?.data
  );
  const tableRows = convertApiResponseToRows(alternateCampaigns?.concatedData);
  const findCamapignsLoading = useSelector(
    (state) => state?.campaign?.findCampaigns?.loading
  );
  const isCalendarLoading = useSelector(
    (state) => state?.appointment?.isCalendarLoading
  );

  const findCamapigns = (leadId) => {
    const payload = {
      lead_id: leadId,
    };
    dispatch(findAlternateCampaign(payload, handleOpenModal, true));
  };

  const onUpdate = (campaignId) => {
    const payload = {
      lead_id: alternateCampaigns?.lead_id,
      checked_campaign_id: campaignId,
    };
    // console.log("payload=====>", payload);
    onUpdateCampaignWithAltCampaign(payload, handleCloseModal);
  };

  const keyCss = {
    fontSize: 13,
    color: "#0088cc",
    fontWeight: 500,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#0088cc",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    maxWidth: "fit-content",
    position: "relative",
    cursor: "pointer",
  };

  const openNewLeadTabForMap = (data) => {
    const dataToSend = {
      isViewMap: true,
      campaignInfo: data,
    };

    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabStateForViewMap", jsonData);

    // Open a new tab
    const newTab = window.open("/NewCampaigns", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getViewMapData = (camId) => {
    const payload = {
      leadId: alternateCampaigns?.lead_id,
      campaignId: camId,
      isFromLead: true,
    };
    dispatch(
      getViewMapDetails(payload, (data) => {
        openNewLeadTabForMap(data);
      })
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            id="leadlistTable"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <LeadTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalCount}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {isloading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <>
                                {userInfo?.user_type !== "Client" ? (
                                  <TableRow
                                    hover
                                    sx={{
                                      backgroundColor:
                                        index % 2 === 1 ? "#fff" : "#fff",
                                      borderBottom: "0!important",
                                      "&:hover": {
                                        backgroundColor: "#F4F6FA!important",
                                      },
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.firstName}
                                    selected={isItemSelected}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                        paddingLeft: "0px",
                                      }}
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      <Checkbox
                                        size="small"
                                        checked={isItemSelected}
                                        onClick={(event) =>
                                          handleClick(event, row)
                                        }
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                      <Button
                                        variant="contained"
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          minWidth: "30px",
                                          pl: 1.5,
                                          pr: 1.5,
                                          pt: 1.5,
                                          pb: 1.5,
                                          backgroundColor: "#6B7489",
                                          "&:hover": {
                                            backgroundColor: "#6B7489",
                                          },
                                          borderColor: "#6B7489",
                                          color: "#fff",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          // Prevent event propagation to TableRow
                                          navigate("/ShowLead", {
                                            state: {
                                              isEdit: false,
                                              leadData: row.operations,
                                            },
                                          });
                                        }}
                                        className="show-button"
                                      >
                                        <RemoveRedEyeIcon
                                          sx={{ fontSize: "19px" }}
                                        />
                                      </Button>
                                    </TableCell>
                                    {userInfo?.user_type !== "Client" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                          paddingLeft: "15px",
                                        }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                      >
                                        <Typography
                                          component="h4"
                                          variant="h4"
                                          onClick={() => {
                                            dispatch(clearEvents());

                                            navigate("/Calendar", {
                                              state: {
                                                isViewCalendar: true,
                                                data: row?.operations,
                                              },
                                            });
                                            // console.log("row.operations==>",row.operations);
                                          }}
                                          sx={keyCss}
                                        >
                                          View Calendar
                                        </Typography>
                                      </TableCell>
                                    )}

                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {userInfo?.user_type ===
                                      "Administration" ? (
                                        <Tooltip
                                          sx={{
                                            marginTop: "0!important",
                                            " & .MuiTooltip-tooltip": {
                                              marginTop: "0!important",
                                            },
                                            backgroundColor: "#ffffff",
                                            borderRadius: "5px",
                                            borderColor: "black",
                                          }}
                                          arrow
                                          placement="bottom-start"
                                          PopperProps={{
                                            disablePortal: false,
                                          }}
                                          onClose={handleTooltipClose}
                                          open={
                                            tooltipID?.id ===
                                              row.operations.id &&
                                            tooltipID?.type === "cat"
                                              ? true
                                              : false
                                          }
                                          disableFocusListener
                                          disableHoverListener
                                          disableTouchListener
                                          title={
                                            <UpdateTooltip
                                              onClose={handleTooltipClose}
                                              label={"Lead Category"}
                                              onSubmit={(value) => {
                                                if (value == 6 || value == 7) {
                                                  handleTooltipClose();
                                                  handle1ClickOpen(
                                                    row.operations
                                                      ?.appointment_data?.id,
                                                    value
                                                  );
                                                } else {
                                                  onChangeLeadKey(
                                                    row.operations.id,
                                                    "category",
                                                    value,
                                                    handleTooltipClose
                                                  );
                                                }
                                              }}
                                              selectedKey={
                                                findLeadCategoryByid(
                                                  row.leadCategory
                                                )?.label
                                              }
                                              inputType={"SelectOptions"}
                                              labelKey={"label"}
                                              optionsData={CATEGORY_IN_OBJ}
                                              value={data}
                                              setValue={setData}
                                            />
                                          }
                                        >
                                          <Typography
                                            component="h4"
                                            variant="h4"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              const val = checkAndReturnNone(
                                                row.leadCategory
                                              );
                                              setData(val);
                                              handleTooltipOpen(
                                                row.operations.id,
                                                "cat"
                                              );
                                            }}
                                            sx={keyCss}
                                          >
                                            {getOptionName(row.leadCategory)}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          component="h4"
                                          variant="h4"
                                          sx={{
                                            fontSize: 13,
                                            color: "#6B7489",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {getOptionName(row.leadCategory)}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    {userInfo?.user_type !== "Client" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="left"
                                      >
                                        {userInfo?.user_type ===
                                        "Administration" ? (
                                          <Tooltip
                                            sx={{
                                              marginTop: "0!important",
                                              " & .MuiTooltip-tooltip": {
                                                marginTop: "0!important",
                                              },
                                              backgroundColor: "#ffffff",
                                              borderRadius: "5px",
                                              borderColor: "black",
                                            }}
                                            arrow
                                            placement="bottom-start"
                                            PopperProps={{
                                              disablePortal: false,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={
                                              tooltipID?.id ===
                                                row.operations.id &&
                                              tooltipID?.type === "verified"
                                                ? true
                                                : false
                                            }
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={
                                              <UpdateTooltip
                                                onClose={handleTooltipClose}
                                                label={"Verified"}
                                                onSubmit={(value) => {
                                                  const val =
                                                    value === true
                                                      ? "True"
                                                      : "False";
                                                  onChangeLeadKey(
                                                    row.operations.id,
                                                    "is_verified",
                                                    val,
                                                    handleTooltipClose
                                                  );
                                                }}
                                                selectedKey={
                                                  findVerifiedByid(
                                                    row?.verified
                                                  )?.label
                                                }
                                                inputType={"SelectOptions"}
                                                labelKey={"label"}
                                                optionsData={[
                                                  {
                                                    id: true,
                                                    label: "True",
                                                  },
                                                  {
                                                    id: false,
                                                    label: "False",
                                                  },
                                                ]}
                                                value={data}
                                                setValue={setData}
                                              />
                                            }
                                          >
                                            <Typography
                                              component="h4"
                                              variant="h4"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                const val = row.verified
                                                  ? "True"
                                                  : "False";
                                                setData(val);
                                                handleTooltipOpen(
                                                  row.operations.id,
                                                  "verified"
                                                );
                                              }}
                                              sx={keyCss}
                                            >
                                              {row?.verified ? "True" : "False"}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            component="h4"
                                            variant="h4"
                                            sx={{
                                              fontSize: 13,
                                              color: "#6B7489",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row?.verified ? "True" : "False"}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    )}

                                    {userInfo?.user_type ===
                                      "Administration" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="left"
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            minWidth: "30px",
                                            pl: 1.5,
                                            pr: 1.5,
                                            pt: 1.5,
                                            pb: 1.5,
                                            backgroundColor: "#6B7489",
                                            "&:hover": {
                                              backgroundColor: "#6B7489",
                                            },
                                            borderColor: "#6B7489",
                                            color: "#fff",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            // Prevent event propagation to TableRow
                                            // navigate("/NewCampaigns", {
                                            //   state: {
                                            //     isEdit: true,
                                            //     campaignData: row?.user,
                                            //   },
                                            // });
                                            // handleDelete(row);
                                            handleOpenDeleteDialog(row);
                                          }}
                                          className="show-button" // Add a class to identify the "Show" button
                                        >
                                          <DeleteIcon
                                            sx={{ fontSize: "19px" }}
                                          />
                                        </Button>
                                      </TableCell>
                                    )}
                                    {userInfo?.user_type ===
                                      "Administration" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="left"
                                      >
                                        <Button
                                          type="submit"
                                          variant="contained"
                                          onClick={() => {
                                            findCamapigns(row.operations.id);
                                          }}
                                          sx={{
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                            pl: 2,
                                            pr: 2,
                                            backgroundColor: "#6DD6C2",
                                            borderColor: "#6DD6C2",
                                            color: "#212223",
                                            "&:hover": {
                                              // Customize the hover color
                                              backgroundColor: "#6DD6C2",
                                              borderColor: "#6DD6C2",
                                            },
                                          }}
                                        >
                                          Find
                                        </Button>
                                      </TableCell>
                                    )}

                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.companyName}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.fullName}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {formatPhoneNumber(row.phoneNumber) ??
                                        row.phoneNumber}
                                      {row?.phoneNumber &&
                                        userInfo?.user_type !== "Client" && (
                                          <TwilioVoice
                                            phoneNumber={row?.phoneNumber}
                                            fontColor={"#6B7489"}
                                            fontSize={13}
                                          />
                                        )}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.createdBy ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {format(
                                        new Date(row.createdAt),
                                        "MMMM d, yyyy, h:mm a"
                                      ) ?? row.createdAt}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.agent ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.campaign ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Typography
                                        sx={keyCss}
                                        onClick={() => {
                                          handle1ClickOpen(
                                            row.operations?.appointment_data
                                              ?.id,
                                            null
                                          );
                                        }}
                                      >
                                        {row.appointmentDateTime ?? "None"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.liabilityRenewalDate ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.liabilityCarrier === ""
                                        ? "None"
                                        : row.liabilityCarrier}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.totalEmployed ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.businessClass ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.email ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.state ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.alternatePhoneNumber ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.address ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.city ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.stateZipCode ?? "None"}
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow
                                    hover
                                    sx={{
                                      backgroundColor:
                                        index % 2 === 1 ? "#fff" : "#fff",
                                      borderBottom: "0!important",
                                      "&:hover": {
                                        backgroundColor: "#F4F6FA!important",
                                      },
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.firstName}
                                    selected={isItemSelected}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                        paddingLeft:
                                          userInfo?.user_type ===
                                          "Administration"
                                            ? "0px"
                                            : "10px",
                                      }}
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      <Button
                                        variant="contained"
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          minWidth: "30px",
                                          pl: 1.5,
                                          pr: 1.5,
                                          pt: 1.5,
                                          pb: 1.5,
                                          backgroundColor: "#6B7489",
                                          "&:hover": {
                                            backgroundColor: "#6B7489",
                                          },
                                          borderColor: "#6B7489",
                                          color: "#fff",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          // Prevent event propagation to TableRow
                                          navigate("/ShowLead", {
                                            state: {
                                              isEdit: false,
                                              leadData: row.operations,
                                            },
                                          });
                                        }}
                                        className="show-button"
                                      >
                                        <RemoveRedEyeIcon
                                          sx={{ fontSize: "19px" }}
                                        />
                                      </Button>
                                    </TableCell>
                                    {userInfo?.user_type !== "Client" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                          paddingLeft: "15px",
                                        }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                      >
                                        <Typography
                                          component="h4"
                                          variant="h4"
                                          onClick={() => {
                                            dispatch(clearEvents());

                                            navigate("/Calendar", {
                                              state: {
                                                isViewCalendar: true,
                                                data: row?.operations,
                                              },
                                            });
                                            // console.log("row.operations==>",row.operations);
                                          }}
                                          sx={keyCss}
                                        >
                                          View Calendar
                                        </Typography>
                                      </TableCell>
                                    )}

                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {userInfo?.user_type ===
                                      "Administration" ? (
                                        <Tooltip
                                          sx={{
                                            marginTop: "0!important",
                                            " & .MuiTooltip-tooltip": {
                                              marginTop: "0!important",
                                            },
                                            backgroundColor: "#ffffff",
                                            borderRadius: "5px",
                                            borderColor: "black",
                                          }}
                                          arrow
                                          placement="bottom-start"
                                          PopperProps={{
                                            disablePortal: false,
                                          }}
                                          onClose={handleTooltipClose}
                                          open={
                                            tooltipID?.id ===
                                              row.operations.id &&
                                            tooltipID?.type === "cat"
                                              ? true
                                              : false
                                          }
                                          disableFocusListener
                                          disableHoverListener
                                          disableTouchListener
                                          title={
                                            <UpdateTooltip
                                              onClose={handleTooltipClose}
                                              label={"Lead Category"}
                                              onSubmit={(value) => {
                                                if (value == 6 || value == 7) {
                                                  handleTooltipClose();
                                                  handle1ClickOpen(
                                                    row.operations
                                                      ?.appointment_data?.id,
                                                    value
                                                  );
                                                } else {
                                                  onChangeLeadKey(
                                                    row.operations.id,
                                                    "category",
                                                    value,
                                                    handleTooltipClose
                                                  );
                                                }
                                              }}
                                              selectedKey={
                                                findLeadCategoryByid(
                                                  row.leadCategory
                                                )?.label
                                              }
                                              inputType={"SelectOptions"}
                                              labelKey={"label"}
                                              optionsData={CATEGORY_IN_OBJ}
                                              value={data}
                                              setValue={setData}
                                            />
                                          }
                                        >
                                          <Typography
                                            component="h4"
                                            variant="h4"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              const val = checkAndReturnNone(
                                                row.leadCategory
                                              );
                                              setData(val);
                                              handleTooltipOpen(
                                                row.operations.id,
                                                "cat"
                                              );
                                            }}
                                            sx={keyCss}
                                          >
                                            {getOptionName(row.leadCategory)}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          component="h4"
                                          variant="h4"
                                          sx={{
                                            fontSize: 13,
                                            color: "#6B7489",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {getOptionName(row.leadCategory)}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    {userInfo?.user_type !== "Client" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="left"
                                      >
                                        {userInfo?.user_type ===
                                        "Administration" ? (
                                          <Tooltip
                                            sx={{
                                              marginTop: "0!important",
                                              " & .MuiTooltip-tooltip": {
                                                marginTop: "0!important",
                                              },
                                              backgroundColor: "#ffffff",
                                              borderRadius: "5px",
                                              borderColor: "black",
                                            }}
                                            arrow
                                            placement="bottom-start"
                                            PopperProps={{
                                              disablePortal: false,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={
                                              tooltipID?.id ===
                                                row.operations.id &&
                                              tooltipID?.type === "verified"
                                                ? true
                                                : false
                                            }
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={
                                              <UpdateTooltip
                                                onClose={handleTooltipClose}
                                                label={"Verified"}
                                                onSubmit={(value) => {
                                                  const val =
                                                    value === true
                                                      ? "True"
                                                      : "False";
                                                  onChangeLeadKey(
                                                    row.operations.id,
                                                    "is_verified",
                                                    val,
                                                    handleTooltipClose
                                                  );
                                                }}
                                                selectedKey={
                                                  findVerifiedByid(
                                                    row?.verified
                                                  )?.label
                                                }
                                                inputType={"SelectOptions"}
                                                labelKey={"label"}
                                                optionsData={[
                                                  {
                                                    id: true,
                                                    label: "True",
                                                  },
                                                  {
                                                    id: false,
                                                    label: "False",
                                                  },
                                                ]}
                                                value={data}
                                                setValue={setData}
                                              />
                                            }
                                          >
                                            <Typography
                                              component="h4"
                                              variant="h4"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                const val = row.verified
                                                  ? "True"
                                                  : "False";
                                                setData(val);
                                                handleTooltipOpen(
                                                  row.operations.id,
                                                  "verified"
                                                );
                                              }}
                                              sx={keyCss}
                                            >
                                              {row?.verified ? "True" : "False"}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            component="h4"
                                            variant="h4"
                                            sx={{
                                              fontSize: 13,
                                              color: "#6B7489",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row?.verified ? "True" : "False"}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    )}

                                    {userInfo?.user_type ===
                                      "Administration" && (
                                      <TableCell
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="left"
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            minWidth: "30px",
                                            pl: 1.5,
                                            pr: 1.5,
                                            pt: 1.5,
                                            pb: 1.5,
                                            backgroundColor: "#6B7489",
                                            "&:hover": {
                                              backgroundColor: "#6B7489",
                                            },
                                            borderColor: "#6B7489",
                                            color: "#fff",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            // Prevent event propagation to TableRow
                                            // navigate("/NewCampaigns", {
                                            //   state: {
                                            //     isEdit: true,
                                            //     campaignData: row?.user,
                                            //   },
                                            // });
                                            // handleDelete(row);
                                            handleOpenDeleteDialog(row);
                                          }}
                                          className="show-button" // Add a class to identify the "Show" button
                                        >
                                          <DeleteIcon
                                            sx={{ fontSize: "19px" }}
                                          />
                                        </Button>
                                      </TableCell>
                                    )}

                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.fullName}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.companyName}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {formatPhoneNumber(row.phoneNumber) ??
                                        row.phoneNumber}
                                      {row?.phoneNumber &&
                                        userInfo?.user_type !== "Client" && (
                                          <TwilioVoice
                                            phoneNumber={row?.phoneNumber}
                                            fontColor={"#6B7489"}
                                            fontSize={13}
                                          />
                                        )}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.alternatePhoneNumber ?? "None"}
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#6B7489",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          borderBottom: "0",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {row.appointmentDateTime ?? "None"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.liabilityRenewalDate ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.liabilityCarrier === ""
                                        ? "None"
                                        : row.liabilityCarrier}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.totalEmployed ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.businessClass ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.email ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.alternatePhoneNumber ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.address ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.city ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.stateZipCode ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.createdBy ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {format(
                                        new Date(row.createdAt),
                                        "MMMM d, yyyy, h:mm a"
                                      ) ?? row.createdAt}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.agent ?? "None"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#6B7489",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        borderBottom: "0",
                                        whiteSpace: "nowrap",
                                      }}
                                      align="left"
                                    >
                                      {row.campaign ?? "None"}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !isloading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA!important" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Paper>
      <Modal
        open={open2.isVisible}
        onClose={handle1Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 18,
              color: "#212223",
              fontWeight: 600,
              backgroundColor: "#90949f21",
              pt: 1.5,
              pl: 1.5,
              pb: 1.5,
              mb: 2.5,
            }}
          >
            Rescheduled Date Time
          </Typography>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Appointment Date
            </Typography>
            <Box sx={{ marginTop: "10px", gap: "10px" }}>
              <Box>
                <TextField
                  name="appointmentDate"
                  type="date"
                  size="small"
                  value={formData.appointmentDate}
                  onChange={(e) =>
                    handleInputChange("appointmentDate", e.target.value)
                  }
                  error={!!errors.appointmentDate}
                  helperText={errors.appointmentDate}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Confirm Appointment Date
            </Typography>
            <Box sx={{ marginTop: "10px", gap: "10px" }}>
              <Box>
                <TextField
                  name="confirmAppointmentDate"
                  type="date"
                  size="small"
                  value={formData.confirmAppointmentDate}
                  onChange={(e) =>
                    handleInputChange("confirmAppointmentDate", e.target.value)
                  }
                  error={!!errors.confirmAppointmentDate}
                  helperText={errors.confirmAppointmentDate}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Appointment Time :
            </Typography>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <TextField
                  ampm={true}
                  focused
                  type="time"
                  label="Time"
                  name="appointmentTime"
                  size="small"
                  value={formData.appointmentTime}
                  onChange={(e) =>
                    handleInputChange("appointmentTime", e.target.value)
                  }
                  error={!!errors.appointmentTime}
                  helperText={errors.appointmentTime}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  name="confirmAppointmentTime"
                  type="time"
                  size="small"
                  value={formData.confirmAppointmentTime}
                  onChange={(e) =>
                    handleInputChange("confirmAppointmentTime", e.target.value)
                  }
                  error={!!errors.confirmAppointmentTime}
                  helperText={errors.confirmAppointmentTime}
                  label="Confirm Time"
                  focused
                  ampm={true}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },

                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: 1,
              borderColor: "#dcdcdc",
              mt: 3.5,
            }}
          >
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              sx={{
                mt: 2.5,
                mr: 2,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                color: "#212223",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Submit
            </Button>
            <Button
              onClick={handle1Close}
              variant="contained"
              sx={{
                mt: 2.5,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#000",
                borderColor: "#6DD6C2",
                color: "#fff",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#000",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Lead</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the Lead?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#212223",
              borderColor: "#212223",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#212223",
                borderColor: "#212223",
              },
            }}
            onClick={handleConfirmDelete}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AlternateCampaignModal
        open={isModalOpen}
        data={tableRows}
        onClose={handleCloseModal}
        onUpdate={onUpdate}
        onViewMap={getViewMapData}
      />
      <Loader
        loading={
          isAppointmentStatusUpdate || findCamapignsLoading || isCalendarLoading
        }
      />
    </Box>
  );
};
