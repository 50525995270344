import React from "react";
import { Box, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
export const ForbiddenComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <LockIcon color="error" sx={{ fontSize: "4rem" }} />
      <Typography variant="h5" color="error" mt={2}>
        Access Forbidden!
      </Typography>
      <Typography variant="body1" color="textSecondary" mt={1}>
        You don't have permission to access this page.
      </Typography>
    </Box>
  );
};
