import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  calendarSearch,
  clearEvents,
  getViewMapDetails,
} from "../../../store/actions";

export const AlternateCampaignModal = ({
  open,
  onClose,
  data,
  onUpdate,
  onViewMap,
}) => {
  const dispatch = useDispatch();
  // const findCampaignsInfo = useSelector(state);

  const headCss = {
    backgroundColor: "#F4F6FA",
    whiteSpace: "nowrap",
    paddingLeft: 0,
    color: "#676A6C",
    fontWeight: 600,
    fontSize: 16,
  };

  const rowCss = {
    color: "#6B7489",
    fontWeight: 500,
    fontSize: 14,
    borderBottom: "0",
    whiteSpace: "nowrap",
    paddingLeft: "0px",
  };
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleRowSelect = (index) => {
    setSelectedRow(index);
  };

  const openNewLeadTab = (id) => {
    const dataToSend = {
      isNewTab: true,
      agent_id: id,
    };

    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabStateForCalendar", jsonData);

    // Open a new tab
    const newTab = window.open("/Calendar", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getCalenderData = (agent) => {
    dispatch(clearEvents());
    dispatch(
      calendarSearch(agent, () => {
        openNewLeadTab(agent);
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        alignSelf: "flex-start!important",
      }}
    >
      <DialogTitle style={{ textAlign: "center", paddingBottom: "5px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Alternate Campaigns</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {data?.length > 0 && (
                  <TableCell sx={{ ...headCss, paddingLeft: 2 }}></TableCell>
                )}
                <TableCell
                  sx={{ ...headCss, paddingLeft: data.length > 0 ? 0 : 2 }}
                >
                  Campaign Name
                </TableCell>
                <TableCell sx={headCss}>Status</TableCell>
                <TableCell sx={headCss}>Amount Owed</TableCell>
                <TableCell sx={headCss}>Account Name</TableCell>
                <TableCell sx={headCss}>Show Calendar</TableCell>
                <TableCell sx={headCss}>Map</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length <= 0 ? (
                <TableRow>
                  <Typography
                    component="h4"
                    variant="h4"
                    fullWidth
                    sx={{
                      fontSize: 15,
                      color: "#6B7489",
                      margin: 2,
                      fontWeight: 600,
                    }}
                  >
                    No Campaign found.
                  </Typography>
                </TableRow>
              ) : (
                <>
                  {data?.map((row, index) => (
                    <TableRow key={index}>
                      <Radio
                        checked={selectedRow === row.id}
                        onChange={(e) => {
                          handleRowSelect(row.id);
                        }}
                        value={index}
                        name="radio-buttons"
                        sx={{
                          mt: "5px",
                        }}
                        inputProps={{ "aria-label": "A" }}
                      />
                      <TableCell
                        sx={{ ...rowCss }}
                        onClick={() => {
                          handleRowSelect(row.id);
                        }}
                      >
                        {row.campaignName}
                      </TableCell>
                      <TableCell sx={rowCss}>{row.status}</TableCell>
                      <TableCell sx={rowCss}>{row.amountOwed}</TableCell>
                      <TableCell sx={rowCss}>{row.accountName}</TableCell>
                      <TableCell
                        onClick={() => {
                          getCalenderData(row?.showCalendar);
                        }}
                        sx={{
                          color: "#0088cc",
                          fontWeight: 500,
                          fontSize: 14,
                          cursor: "pointer",
                          borderBottom: "0px!important",
                          whiteSpace: "nowrap",
                          paddingLeft: "0px",
                        }}
                      >
                        {/* {row.showCalendar} */}
                        Show Calendar
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#0088cc",
                          fontWeight: 500,
                          fontSize: 14,
                          cursor: "pointer",
                          borderBottom: "0px!important",
                          whiteSpace: "nowrap",
                          paddingLeft: "0px",
                        }}
                        onClick={() => {
                          onViewMap(row.id);
                        }}
                      >
                        {/* {row.viewMap} */}
                        View Map
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedRow !== null) {
              onUpdate && onUpdate(selectedRow);
            }
          }}
          sx={{
            fontWeight: 600,
            textTransform: "capitalize",
            pl: 2,
            pr: 2,
            backgroundColor: "#6DD6C2",
            borderColor: "#6DD6C2",
            color: "#212223",
            "&:hover": {
              // Customize the hover color
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
            },
          }}
        >
          Update
        </Button>
        <Button
          onClick={onClose}
          sx={{
            textTransform: "capitalize",
            width: "100%",
            maxWidth: "max-content",
            backgroundColor: "#000",
            borderColor: "#6DD6C2",
            color: "#fff",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: "#000",
              borderColor: "#6DD6C2",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
