import toast from "react-hot-toast";
import {
  BASE_URL,
  CREATE_CALL_RESULT_URL,
  CREATE_PROSPECTS_URL,
  DELETE_CALL_RESULT_URL,
  EXPORT_CSV_URL,
  GET_ALL_CALL_RESULT_OPTIONS_URL,
  GET_ALL_PROSPECTS,
  GET_ALL_USERS_URL,
  GET_PROSPECTS_SEARCH_URL,
  PROSPECT_MASS_UPLOAD_URL,
  UPDATE_PROSPECT_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import {
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../services";
import {
  CREATE_PROSPECT_FAILURE,
  CREATE_PROSPECT_IN_PROGRESS,
  CREATE_PROSPECT_SUCCESS,
  GET_PROSPECTS_FAILURE,
  GET_PROSPECTS_IN_PROGRESS,
  GET_PROSPECTS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_IN_PROGRESS,
  GET_USERS_SUCCESS,
  UPLOAD_PROSPECT_CSV_FAILURE,
  UPLOAD_PROSPECT_CSV_IN_PROGRESS,
  UPLOAD_PROSPECT_CSV_SUCCESS,
  UPDATE_PROSPECT_FAILURE,
  UPDATE_PROSPECT_IN_PROGRESS,
  UPDATE_PROSPECT_SUCCESS,
  GET_PROSPECTS_SEARCH_IN_PROGRESS,
  GET_PROSPECTS_SEARCH_SUCCESS,
  GET_PROSPECTS_SEARCH_FAILURE,
  PROSPECT_MASS_UPDATE_IN_PROGRESS,
  PROSPECT_MASS_UPDATE_SUCCESS,
  PROSPECT_MASS_UPDATE_FAILURE,
  EXPORT_CSV_IN_PROGRESS,
  EXPORT_CSV_SUCCESS,
  EXPORT_CSV_FAILURE,
  UPDATE_PROSPECT_KEY_IN_PROGRESS,
  UPDATE_PROSPECT_KEY_SUCCESS,
  UPDATE_PROSPECT_KEY_FAILURE,
  GET_CALL_RESULT_OPTIONS_FAILURE,
  GET_CALL_RESULT_OPTIONS_SUCCESS,
  GET_CALL_RESULT_OPTIONS_IN_PROGRESS,
  CREATE_CALL_RESULT_IN_PROGRESS,
  CREATE_CALL_RESULT_SUCCESS,
  CREATE_CALL_RESULT_FAILURE,
  DELETE_CALL_RESULT_IN_PROGRESS,
  DELETE_CALL_RESULT_SUCCESS,
  DELETE_CALL_RESULT_FAILURE,
} from "./types";
import { LOGOUT } from "../authentication/types";
import { logout } from "../authentication";

// Action Creators
export const createProspectInProgress = () => ({
  type: CREATE_PROSPECT_IN_PROGRESS,
});

export const createProspectSuccess = (data) => ({
  type: CREATE_PROSPECT_SUCCESS,
  data,
});

export const createProspectFailure = (error) => ({
  type: CREATE_PROSPECT_FAILURE,
  error,
});

export const createProspect = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        phoneNumber,
        alternativePhoneNumber,
        executiveTitle,
        executiveFirstName,
        executiveLastName,
        companyName,
        companyWebsite,
        primarySicCode,
        primarySicCodeDescription,
        primaryNaicsCode,
        primaryNaicsCodeDescription,
        comments,
        locationStreet,
        locationCity,
        locationState,
        locationZipCode,
        countyDescription,
        renewalDate,
        squareFootage,
        callResult,
        callResultDescription,
        creditRating,
        lastAppointmentWith,
        carrierOfRecord,
        locationEmployeeSizeActual,
        latitude,
        longitude,
      } = payload;

      // Create params object with the required keys for the API request
      const params = {
        phone_number: phoneNumber ?? "",
        alternative_phone_number: alternativePhoneNumber ?? "",
        title: executiveTitle ?? "",
        first_name: executiveFirstName ?? "",
        last_name: executiveLastName ?? "",
        company_name: companyName ?? "",
        website: companyWebsite ?? "",
        sic_code: primarySicCode ?? "",
        sic_code_description: primarySicCodeDescription ?? "",
        naics_code: primaryNaicsCode ?? "",
        naics_code_description: primaryNaicsCodeDescription ?? "",
        comments: comments ?? "",
        address: locationStreet ?? "",
        city: locationCity ?? "",
        state: locationState ?? "",
        zip_code: locationZipCode ?? "",
        county: countyDescription ?? "",
        renewal_date: renewalDate ?? "",
        square_footage: squareFootage ?? "",
        call_result: callResult ?? 0,
        call_result_description: callResultDescription ?? "",
        credit_rating: creditRating ?? "",
        last_appointment_with: lastAppointmentWith ?? "",
        carrier_of_record: carrierOfRecord ?? "",
        employee_size: locationEmployeeSizeActual ?? "",
        latitude: latitude ?? "",
        longitude: longitude ?? "",
        // Add other properties to the params object if required
      };

  

      // Dispatch in progress action
      dispatch(createProspectInProgress());

      // Make API request to create the prospect
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_PROSPECTS_URL}`,
        params,
        "",
        token
      );

      console.log("response--------------------",response)

      // Check response status
      if (response?.status === 201) {
        dispatch(createProspectSuccess(response?.data));
        cb();
        toast.success("Prospects has been created successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createProspectFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
    let errArr = []
      for (let key in error.response.data) {
        let errToast = `${error?.response?.data[key]}`
        errArr.push(errToast)
        console.log(`--------Key: ${key}, Value: ${error.response.data[key]}`);
        toast.error(errToast, {
          position: "right-top",
        });

    }
    dispatch(
      createProspectFailure({
        type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
        message: errArr.join(", ")
      })
    );
     
    }
  };
};

//get prospects
export const GetProspectsInProgress = () => ({
  type: GET_PROSPECTS_IN_PROGRESS,
});

export const GetProspectsSuccess = (data) => ({
  type: GET_PROSPECTS_SUCCESS,
  data,
});

export const GetProspectsFailure = (error) => ({
  type: GET_PROSPECTS_FAILURE,
  error,
});

export const GetProspects = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetProspectsInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get prospects
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_PROSPECTS}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetProspectsSuccess(response?.data));
      } else {
        dispatch(
          GetProspectsFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetProspectsFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//get all users

// Action Creators
export const GetUsersInProgress = () => ({
  type: GET_USERS_IN_PROGRESS,
});

export const GetUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  data,
});

export const GetUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  error,
});

// Method for getting all users
export const GetUsers = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetUsersInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get all users
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_USERS_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetUsersSuccess(response?.data));
      } else {
        dispatch(
          GetUsersFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetUsersFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

//upload prospects csv
// Action Creators for Prospect CSV Upload

export const uploadProspectCsvInProgress = () => ({
  type: UPLOAD_PROSPECT_CSV_IN_PROGRESS,
});

export const uploadProspectCsvSuccess = (data) => ({
  type: UPLOAD_PROSPECT_CSV_SUCCESS,
  data,
});

export const uploadProspectCsvFailure = (error) => ({
  type: UPLOAD_PROSPECT_CSV_FAILURE,
  error,
});

// Define the uploadProspectCsv method
export const uploadProspectCsv = (file, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(uploadProspectCsvInProgress());

      // Create formData object and append the file
      const formData = new FormData();
      formData.append("csv_file", file);

      // Make API request to upload the CSV file
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${PROSPECT_MASS_UPLOAD_URL}`,
        formData,
        "",
        token,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(uploadProspectCsvSuccess(response?.data));
        cb();
        return response.data; // Resolve the promise with the response data
      } else {
        dispatch(
          uploadProspectCsvFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        throw new Error(response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN); // Reject the promise with an error
      }
    } catch (error) {
      // Handle error scenarios

      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        uploadProspectCsvFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      throw new Error(errorMessage); // Reject the promise with an error
    }
  };
};

// Update prospect information
export const updateProspectInProgress = () => ({
  type: UPDATE_PROSPECT_IN_PROGRESS,
});

export const updateProspectSuccess = (data) => ({
  type: UPDATE_PROSPECT_SUCCESS,
  data,
});

export const updateProspectFailure = (error) => ({
  type: UPDATE_PROSPECT_FAILURE,
  error,
});

export const updateProspect = (prospectId, payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        phoneNumber,
        alternativePhoneNumber,
        executiveTitle,
        executiveFirstName,
        executiveLastName,
        companyName,
        companyWebsite,
        primarySicCode,
        primarySicCodeDescription,
        primaryNaicsCode,
        primaryNaicsCodeDescription,
        comments,
        locationStreet,
        locationCity,
        locationState,
        locationZipCode,
        countyDescription,
        renewalDate,
        squareFootage,
        callResult,
        callResultDescription,
        creditRating,
        lastAppointmentWith,
        carrierOfRecord,
        locationEmployeeSizeActual,
        latitude,
        longitude,
      } = payload;

      // Create params object with the required keys for the API request
      const params = {
        phone_number: phoneNumber ?? "",
        alternative_phone_number: alternativePhoneNumber ?? "",
        title: executiveTitle ?? "",
        first_name: executiveFirstName ?? "",
        last_name: executiveLastName ?? "",
        company_name: companyName ?? "",
        website: companyWebsite ?? "",
        sic_code: primarySicCode ?? "",
        sic_code_description: primarySicCodeDescription ?? "",
        naics_code: primaryNaicsCode ?? "",
        naics_code_description: primaryNaicsCodeDescription ?? "",
        comments: comments ?? "",
        address: locationStreet ?? "",
        city: locationCity ?? "",
        state: locationState ?? "",
        zip_code: locationZipCode ?? "",
        county: countyDescription ?? "",
        renewal_date: renewalDate ?? "",
        square_footage: squareFootage ?? "",
        call_result: callResult ?? 0,
        call_result_description: callResultDescription ?? "",
        credit_rating: creditRating ?? "",
        last_appointment_with: lastAppointmentWith ?? "",
        carrier_of_record: carrierOfRecord ?? "",
        employee_size: locationEmployeeSizeActual ?? "",
        latitude: latitude ?? "",
        longitude: longitude ?? "",
        // Add other properties to the params object if required
      };

      // Dispatch in progress action
      dispatch(updateProspectInProgress());

      // Make API request to update the prospect
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      
      const response = await putRequest(
        `${BASE_URL}${UPDATE_PROSPECT_URL}${prospectId}/`,
        params,
        token
      );
      // Check response status
      if (response?.status === 200) {
        dispatch(updateProspectSuccess(response?.data));
        cb();
        toast.success("Prospect has been updated successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateProspectFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateProspectFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
// Action Creators
export const getProspectsSearchInProgress = () => ({
  type: GET_PROSPECTS_SEARCH_IN_PROGRESS,
});

export const getProspectsSearchSuccess = (data) => ({
  type: GET_PROSPECTS_SEARCH_SUCCESS,
  data,
});

export const getProspectsSearchFailure = (error) => ({
  type: GET_PROSPECTS_SEARCH_FAILURE,
  error,
});

const getupdatedData = (existingData, newData, offset) => {
  return offset != 1 ? existingData.concat(newData) : newData;
};

export const getProspectsSearch = (payload, offset, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action

      const { prospect } = getState();
      dispatch(getProspectsSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get prospects search data

      const URL = `${BASE_URL}${GET_PROSPECTS_SEARCH_URL}?page=${offset ?? 1}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        const newData = getupdatedData(
          prospect?.prospectInfo,
          response?.data ?? [],
          offset
        );
        dispatch(getProspectsSearchSuccess(newData));
      } else {
        dispatch(
          getProspectsSearchFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getProspectsSearchFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for mass update
export const prospectMassUpdateInProgress = () => ({
  type: PROSPECT_MASS_UPDATE_IN_PROGRESS,
});

export const prospectMassUpdateSuccess = (data) => ({
  type: PROSPECT_MASS_UPDATE_SUCCESS,
  data,
});

export const prospectMassUpdateFailure = (error) => ({
  type: PROSPECT_MASS_UPDATE_FAILURE,
  error,
});

export const prospectsMassUpdate = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(prospectMassUpdateInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get prospects search data

      const URL = `${BASE_URL}${GET_PROSPECTS_SEARCH_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(prospectMassUpdateSuccess());
        cb();
        const message =
          response?.data?.message ?? " Record has been updated successfully";
        toast.success(message, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          getProspectsSearchFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        prospectMassUpdateFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for exporting CSV
export const exportCsvInProgress = () => ({
  type: EXPORT_CSV_IN_PROGRESS,
});

export const exportCsvSuccess = (data) => ({
  type: EXPORT_CSV_SUCCESS,
  data,
});

export const exportCsvFailure = (error) => ({
  type: EXPORT_CSV_FAILURE,
  error,
});

export const exportCsv = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(exportCsvInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to export CSV

      const URL = `${BASE_URL}${EXPORT_CSV_URL}`; // Replace with your CSV export endpoint

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(exportCsvSuccess(response.data));
        cb();
        // const message =
        //   response?.data?.message ??
        //   "CSV generation successful. Download link sent to your email.";
        // toast.success(message, {
        //   position: "bottom-center",
        // });
        return response.data;
      } else {
        dispatch(
          exportCsvFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
        throw new Error(response?.data?.message ?? ERROR_MESSAGE.API.UNKNOWN);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        exportCsvFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      throw new Error(errorMessage);
    }
  };
};

// Action Creators for updating a single key in the prospect information
export const updateProspectKeyInProgress = () => ({
  type: UPDATE_PROSPECT_KEY_IN_PROGRESS,
});

export const updateProspectKeySuccess = (data) => ({
  type: UPDATE_PROSPECT_KEY_SUCCESS,
  data,
});

export const updateProspectKeyFailure = (error) => ({
  type: UPDATE_PROSPECT_KEY_FAILURE,
  error,
});

export const updateProspectKey = (
  prospectId,
  payload,
  excludedFields = [],
  cb = () => {}
) => {
  return async (dispatch) => {
    try {
      // Exclude specified fields from the payload
      const filteredPayload = Object.keys(payload).reduce((acc, key) => {
        if (!excludedFields.includes(key)) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});

      // Dispatch in progress action
      dispatch(updateProspectKeyInProgress());

      // Make API request to update the specific key in the prospect
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await patchRequest(
        `${BASE_URL}${UPDATE_PROSPECT_URL}${prospectId}/`,
        filteredPayload, // Use the filtered payload
        token
      );

      // Check response status
      if (response?.status === 200) {
        // 
        dispatch(updateProspectKeySuccess(response?.data));
        cb();
      } else {
        dispatch(
          updateProspectKeyFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateProspectKeyFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const GetCallResultOptionsInProgress = () => ({
  type: GET_CALL_RESULT_OPTIONS_IN_PROGRESS,
});

export const GetCallResultOptionsSuccess = (data) => ({
  type: GET_CALL_RESULT_OPTIONS_SUCCESS,
  data,
});

export const GetCallResultOptionsFailure = (error) => ({
  type: GET_CALL_RESULT_OPTIONS_FAILURE,
  error,
});

// Method for getting all call result options
export const getAllCallResultOptions = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetCallResultOptionsInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get all call result options
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_CALL_RESULT_OPTIONS_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetCallResultOptionsSuccess(response?.data));
      } else {
        dispatch(
          GetCallResultOptionsFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetCallResultOptionsFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const createCallResultInProgress = () => ({
  type: CREATE_CALL_RESULT_IN_PROGRESS,
});

export const createCallResultSuccess = () => ({
  type: CREATE_CALL_RESULT_SUCCESS,
});

export const createCallResultFailure = (error) => ({
  type: CREATE_CALL_RESULT_FAILURE,
  error,
});

// Method for creating a call result
export const createCallResult = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(createCallResultInProgress());

      // Make API request to create the call result
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_CALL_RESULT_URL}`,
        // Assuming the payload is not required for creating a call result
        payload,
        "",
        token
      );

      // Check response status
      if (response?.status === 201) {
        dispatch(createCallResultSuccess());
        cb();
        toast.success("Call result has been created successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createCallResultFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createCallResultFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for call result delete in progress
export const deleteCallResultInProgress = () => ({
  type: DELETE_CALL_RESULT_IN_PROGRESS,
});

// Action creators for call result delete success
export const deleteCallResultSuccess = (data) => ({
  type: DELETE_CALL_RESULT_SUCCESS,
  data,
});

// Action creators for call result delete failure
export const deleteCallResultFailure = (error) => ({
  type: DELETE_CALL_RESULT_FAILURE,
  error,
});

// Action method for call result delete
export const deleteCallResult = (callResultId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(deleteCallResultInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const payload = {
        id: callResultId,
      };
      // Make API request to delete call result

      let URL = `${BASE_URL}${DELETE_CALL_RESULT_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(deleteCallResultSuccess(response?.data));
        cb();
        toast.success("Call Result has been deleted successfully", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          deleteCallResultFailure({
            type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteCallResultFailure({
          type: ERROR_TYPE.API.PROSPECTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
