import { useEffect } from 'react';

export const PageTitle = (title) => {
  useEffect(() => {
    document.title = `${title} | Lead Orchard (CRM)`;

    return () => {
      document.title = 'Lead Orchard (CRM)';
    };
  }, [title]);
};

export default PageTitle;
