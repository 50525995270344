import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

export const Loader = (props) => {
  const { loading } = props;
  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: 99999,
      //  backgroundColor: "none",
      }}
    >
      {/* <CircularProgress /> */}
      <span className="loader"></span>
    </Backdrop>
  );
};

Loader.protoTypes = {
  loading: PropTypes.bool,
};
Loader.defaultProps = {
  loading: false,
};
