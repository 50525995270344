import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/index.js";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import "open-sans-fontface";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import {
  CallingCard,
  InteractionTime,
  TwilioDevice,
} from "./app/components/index.js";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import IntercomChat from "./app/components/Common/IntercomChat/IntercomChat.js";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    breakpoints: {
      values: {
        xs: 0, // Extra small screens
        sm: 600, // Small screens
        md: 960, // Medium screens
        lg: 1280, // Large screens
        xl: 1920, // Extra large screens
      },
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
          <div className="app-container">
            <App />
            <Toaster
              toastOptions={{
                success: {
                  iconTheme: {
                    primary: "#FFF",
                    secondary: "#1ab394",
                  },
                  style: {
                    background: "#1ab394",
                    color: "#FFFFFF",
                  },
                },
                loading: {
                  iconTheme: {
                    primary: "#FFF",
                    secondary: "#1ab394",
                  },
                  style: {
                    fontWeight: "500",
                  },
                },
                error: {
                  icon: (
                    <ErrorOutlineOutlinedIcon
                      color="#ffffff"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  ),
                  iconTheme: {
                    primary: "#FFF",
                    secondary: "#eb4034",
                  },
                  style: {
                    background: "#eb4034",
                    color: "#FFFFFF",
                  },
                },
              }}
            />
            <TwilioDevice />
            <CallingCard />
            <IntercomChat/>
            {/* <InteractionTime/> */}
          </div>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
