//development url
export const BASE_URL = "https://development.leadorchard.com/api/v1/";
//staging url
// export const BASE_URL = "http://192.168.1.129:8000/api/v1/";

export const LOGIN_URL = "user/login/";
export const VERIFY_OTP_URL = "user/verify-otp/";
export const FORGOT_PASSWORD_URL = "user/forget-password/";
export const LOGOUT_URL = "user/logout/";
export const QR_CODE_GENERATE_URL = "user/generate-qr-code/";
export const USER_AUTHENTICATION = "user/enable-authentication/";

// Employee
export const CREATE_EMPLOYEE_URL = "user/employee-profiles/";
export const GET_ALL_EMPLOYEES = "user/employee-profiles/";
export const UPDATE_EMPLOYEE_URL = "user/employee-profiles/";
export const DELETE_EMPOYEES_URL = "user/employee-profiles/delete-multiple/";
export const UPDATE_CALL_RECORDING_STATUS_URL = "user/employee-profiles/";
export const EMPLOYEE_MASS_UPLOAD_URL = "user/employee-profiles/mass-upload/";

//Client
export const CREATE_CLIENT_URL = "user/client-profiles/";
export const GET_ALL_CLIENTS = "user/client-profiles/";
export const UPDATE_CLIENT_URL = "user/client-profiles/";
export const DELETE_CLIENTS_URL = "user/client-profiles/delete-multiple/";
export const UPDATE_STATUS_URL = "user/client-profiles/";
export const CLIENT_MASS_UPLOAD_URL = "user/client-profiles/mass-upload/";
export const CLIENT_RESET_PASSWORD_URL = "user/client-reset-password/";

//Prospects
export const CREATE_PROSPECTS_URL = "prospect/";
export const GET_ALL_PROSPECTS = "prospect/";
export const GET_ALL_USERS_URL = "prospect/all-users/";
export const UPDATE_PROSPECT_URL = "prospect/";
export const PROSPECT_MASS_UPLOAD_URL = "prospect/mass-upload/";
export const GET_PROSPECTS_SEARCH_URL = "prospect/search/";
export const EXPORT_CSV_URL = "prospect/export-csv/";
export const GET_ALL_CALL_RESULT_OPTIONS_URL = "call_result/";
export const CREATE_CALL_RESULT_URL = "call_result/";
export const DELETE_CALL_RESULT_URL = "call_result/delete/";

//lead
export const CREATE_LEADS_URL = "lead/";
export const LEAD_MASS_UPLOAD_URL = "lead/mass-upload-leads/";
export const GET_LEAD_SEARCH_URL = "lead/lead-search/";
export const DELETE_LEAD_URL = "lead/";
export const UPDATE_LEAD_URL = "lead/update/";
export const GET_LEAD_SHOW_URL = "lead/";
export const LEAD_MASS_UPDATE_URL = "lead/mass-update-leads/";
export const CREATE_NEW_NOTE_URL = "lead/lead/create-notes/";
export const GET_ALL_NOTES_URL = "lead/get-notes/";
export const DELETE_NOTE_URL = "lead/delete-notes/";
export const UPDATE_NOTE_URL = "lead/update-notes/";
export const RETURN_CREDIT_URL = "lead/return-for-credit/";
export const UPDATE_CREDIT_URL = "lead/return-for-credit/";
export const GET_RETURN_CREDIT_URL = "lead/get-return/";
export const UPDATE_APPOINTMENT_STATUS_URL =
  "appointment/update_appointment_status/";
export const UPDATE_APPOINTMENT_URL = "appointment/update_call-result/";
export const DELETE_RETURN_CREDIT_URL = "lead/delete-credit/";

//campaign
export const CREATE_CAMPAIGN_URL = "campaign/create/";
export const GET_CAMPAIGN_LIST_BY_FILTER_URL = "campaign";
export const GET_CAMPAIGN_SHOW_URL = "campaign/";
export const DELETE_CAMPAIGN_URL = "campaign/";
export const EDIT_CAMPAIGN_URL = "campaign/";
export const GET_ALL_CAMPAIGNS_URL = "campaign/list/";
export const CAMPAIGN_SEARCH_URL = "campaign/search/";
export const CREATE_LEAD_APPOINTMENTS_URL = "lead/create-with-appointment/";
export const UPDATE_CAMPAIGN_URL = "campaign/microapi-update/";
export const FIND_ALTERNATE_CAMPAIGN_URL = "callback/get-related-campaigns/";
export const UPDATE_ALTERNATE_CAMPAIGN_URL = "callback/update-campaign/";
export const FIND_ALTERNATE_CAMPAIGN_URL_FOR_LEAD =
  "lead/get-related-campaign/";
export const UPDATE_ALTERNATE_CAMPAIGN_URL_FOR_LEAD = "lead/update-campaign/";
export const GET_VIEW_MAP_DETAILS_URL = "callback/campaign/map/";
export const GET_VIEW_MAP_DETAILS_URL_FOR_LEAD = "lead/campaign/map/";
export const CAMPAIGN_NAME_SEARCH_URL = "campaign/name-search/";

//appointment
export const CREATE_APPOINTMENTS_URL = "appointment/create/";
export const GET_APPOINTMENT_SEARCH_URL = "appointment/search/";
export const DELETE_APPOINTMENT_URL = "appointment/delete/";
export const GET_ALL_EMPLOYEES_URL = "user/employee-list/";
export const CALENDAR_SEARCH_URL =
  "appointment/administration-calendar-events/";
export const CREATE_EVENTS_URL = "appointment/create-events/";
export const DELETE_CALENDAR_EVENT_URL = "event_calendar/delete_event/";

//twillio
export const GET_TWILLIO_ACCESS_TOKEN_URL = "browser_calls/generate-token/";
export const MAKE_CALL_URL = "browser_calls/make-call/";

//CallBack
export const CREATE_CALLBACKS_URL = "callback/";
export const GET_CALLBACK_SEARCH_URL = "callback/";
export const GET_CALLBACK_DETAILS = "campaign/";
export const UPDATE_ASSIGNED_TO_URL = "callback/update-assign/";

//reports
export const CREATE_CALL_HISTORY_URL = "report/call-history-create/";
export const UPDATE_CALL_HISTORY_URL = "report/call-history/update/";
export const CALL_HISTORY_SEARCH_URL = "report/calls-history-report/";
export const USER_REPORT_SEARCH_URL = "report/user-report/";
export const CAMPAIGN_REPORT_SEARCH_URL = "report/campaign-report/";
export const CAMPAIGN_IDLE_TIME_MANAGER_URL = "campaign/campaign-idle-time-manager/";
export const CAMPAIGN_STOP_URL = "campaign/campaign-stop-time/";
export const GET_CURRENT_ONLINE_USERS_URL =
  "report/current-online-user-report/";
export const CWTR_URL = "report/cwtr-report/";

//profile
export const GET_GOOGLE_AUTH_URL = "event_calendar/get_google_auth_url/";
export const SYNC_GOOGLE_CALENDER_URL = "event_calendar/sync-calendar/";
export const GET_CALENDAR_EVENTS_URL = "event_calendar/events/";
export const AUTH_CALLBACK_URL = "event_calendar/oauth2callback/";
export const CHECK_GOOGLE_CALENDAR_LOGIN_STATUS_URL =
  "event_calendar/get-calendar/";
export const CHANGE_PASSWORD_URL = "user/change-password/";
export const GET_USER_PROFILE_URL = "user/user-detail-profile/";
export const GET_ALL_CLIENTS_URL = "user/client-list/";
export const UPDATE_PROFILE_KEY_URL = "user/user-update/";

//dashboard
export const USER_DASHBOARD_PRODUCTION_URL = "home/user-production-dashboard/";
export const LEAD_OWED_REPORT_URL = "home/lead-owned-report/";
export const USER_DASHBOARD_EMPLOYEE_PRODUCTION_URL =
  "home/employee/dashboard/";
export const UPDATE_LEAD_KEY_URL = "campaign/campaign-group/";

//invoices
export const CREATE_COMMERCIAL_INVOICE_URL =
  "invoices/administration/commercial/invoice/create/";
export const UPDATE_COMMERCIAL_INVOICE_URL =
  "invoices/administration/commercial/invoice/update/";
export const COMMERCIAL_INVOICE_SEARCH_URL =
  "invoices/administration/commercial/invoice/list/";
export const GENERATE_INVOICE_PDF_URL =
  "invoices/administration/commercial/invoice/";
export const SEND_EMAIL_COMMERCIAL_INVOICES_URL =
  "invoices/administration/commercial/invoice/send-email";

//clientView
export const GET_CLIENT_DASHBOARD_URL = "home/client/dashboard/";

//outlook
export const OUTLOOK_CALLBACK_URL = "event_calendar/outlook/authcallback/";
