import React, { useState } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, colorCode, createEventId } from "./event-utils";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { CalendarEventsTooltip } from "../CalendarEventTooltip";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

export const Calendar = React.memo((props) => {
  const { events, onCreateEvent, agentId, onDeleteEvent, isFromCampaign } =
    props;
  const [currentEvents, setCurrentEvents] = useState([]);
  const [open, setOpen] = useState(false);

  const initailEventInfo = {
    startTime: "",
    startDate: "",
    endDate: "",
    endTime: "",
    eventDiscription: "",
    checkedDays: [],
  };
  const [newEvent, setNewEvent] = useState(initailEventInfo);

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);
  const [timeError ,setTimeError] = useState(false)
  const [triedSubmit, setTriedSubmit] = useState(false);

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: 3,
    borderColor: "#6DD6C2",
    p: 3,
    borderRadius: "7px",
    "&:focus-visible": {
      outline: "0",
    },
  };

  const handleTooltipOpen = (event) => {
    setCurrentEvents(event);
  };
  const handleTooltipClose = () => {
    setCurrentEvents(null);
  };

  function convertToCustomTimeFormat(dateTimeString) {
    if (!dateTimeString) {
      return "Invalid date-time";
    }

    const customTimeFormat = "hh:mma";
    const customTime = moment(dateTimeString).utc().format(customTimeFormat);

    return customTime;
  }

  const handle1Close = () => {
    setOpen(false);
    setTriedSubmit(false);
    setNewEvent(initailEventInfo);
  };

  const deletEvent = () => {
    handle1Close();

    onDeleteEvent(currentEvents?.event_id);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <Box sx={{ alignItems: "center" }}>
        <div>
          <Tooltip
            sx={{
              width: "100%",
              marginTop: "0!important",
              " & .MuiTooltip-tooltip": {
                marginTop: "0!important",
              },
              backgroundColor: "red",
              borderRadius: "5px",
              borderColor: "black",
            }}
            arrow
            PopperProps={{
              disablePortal: false,
            }}
            onClose={handleTooltipClose}
            open={
              currentEvents?.event_id ===
              eventInfo?.event?._def?.extendedProps?.eventInfo?.event_id
                ? true
                : false
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <CalendarEventsTooltip
                label={currentEvents?.title}
                isFromCampaign={isFromCampaign}
                onSubmit={() => {
                  //console.log("delete clicked");
                  deletEvent();
                }}
                data={currentEvents}
              />
            }
          >
            <div
              className="fc-custom-event"
              style={{
                cursor: "pointer",
                backgroundColor: eventInfo?.backgroundColor,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (
                  currentEvents?.event_id ===
                  eventInfo?.event?._def?.extendedProps?.eventInfo?.event_id
                ) {
                  handleTooltipClose();
                } else {
                  handleTooltipOpen(
                    eventInfo?.event?._def?.extendedProps?.eventInfo
                  );
                }
              }}
            >
              <b>
                {convertToCustomTimeFormat(
                  eventInfo?.event?._def?.extendedProps?.eventInfo?.start
                )}
              </b>
              <i className="fc-custom-event-title">{eventInfo.event.title}</i>
            </div>
          </Tooltip>
        </div>
      </Box>
    );
  };

  const handleDateSelect = (selectInfo) => {
    setNewEvent({
      ...newEvent,
      startDate: selectInfo.dateStr,
      endDate: selectInfo.dateStr,
    });
    agentId !== 0 && userInfo?.user_type !== "Employee" && setOpen(true);
  };

  const handleDaySelect = (dayIndex) => {
    const updatedCheckedDays = newEvent.checkedDays.includes(dayIndex)
      ? newEvent.checkedDays.filter((selectedDay) => selectedDay !== dayIndex)
      : [...newEvent.checkedDays, dayIndex];

    // Update the newEvent state with the selected days
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      checkedDays: updatedCheckedDays,
    }));
  };

  //validation
  const startDateError =
    triedSubmit && newEvent?.startDate?.toString()?.trim().length === 0;

  const startDateErrorMessage =
    triedSubmit && newEvent?.startDate?.trim().length === 0
      ? "Please enter valid date"
      : "";

  const startTimeError =
    triedSubmit && newEvent?.startTime?.toString()?.trim().length === 0;

  const startTimeErrorMessage =
    triedSubmit && newEvent?.startTime?.toString()?.trim().length === 0
      ? "Please enter valid time "
      : "";

  const endDateError =
    triedSubmit && newEvent?.endDate?.toString()?.trim().length === 0;

  const endDateErrorMessage =
    triedSubmit && newEvent?.endDate?.trim().length === 0
      ? "Please enter valid date"
      : "";

  const endTimeError =
    triedSubmit && newEvent?.endTime?.toString()?.trim().length === 0;

  const endTimeErrorMessage =
    triedSubmit && newEvent?.endTime?.toString()?.trim().length === 0
      ? "Please enter valid time "
      : "";
  const sameDateTimeError = timeError ? "End Time should be greater than start time":triedSubmit && timeError ?"End Time should be greater than start time":"";

  const eventDiscriptionError =
    triedSubmit && newEvent?.eventDiscription?.toString()?.trim().length === 0;

  const eventDiscriptionErrorMessage =
    triedSubmit && newEvent?.eventDiscription?.trim().length === 0
      ? "Please enter valid discription"
      : "";

  const renderDayCheckboxes = () => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return (
      <FormGroup row sx={{ marginTop: 1 }}>
        {daysOfWeek.map((day, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                size="small"
                sx={{ color: "#6B7489" }}
                checked={newEvent.checkedDays.includes(index)} // Check if the day index is in the checkedDays array
                onChange={() => handleDaySelect(index)}
                name={day}
                color="primary"
              />
            }
            label={day.substring(0, 3)} // Abbreviate day names
            sx={{ color: "#6B7489", fontWeight: "600" }}
          />
        ))}
      </FormGroup>
    );
  };

  const isCheckFormValid = () => {
    const isStartDateValid = newEvent?.startDate?.toString()?.trim().length > 0;
    const isStartTimeValid = newEvent?.startTime?.toString()?.trim().length > 0;
    const isEndDateValid = newEvent?.endDate?.toString()?.trim().length > 0;
    const isEndTimeValid = newEvent?.endTime?.toString()?.trim().length > 0;
    const isEventDiscriptionValid =  newEvent?.eventDiscription?.toString()?.trim().length > 0;
    const isSameDateTimeError = newEvent?.startDate == newEvent?.endDate && newEvent?.endDate <= newEvent?.startTime

    const isAgentTrue = agentId !== 0 ? true : false;

    return (
      isStartDateValid &&
      isStartTimeValid &&
      isEndDateValid &&
      isEndTimeValid &&
      isEventDiscriptionValid &&
      isAgentTrue &&
      !isSameDateTimeError
    );
  };

  const isFormValid = isCheckFormValid();

  const createCb = () => {
    handle1Close();
  };

  const onCreate = () => {
    setTriedSubmit(true);
    if (!isFormValid) return;
    onCreateEvent(newEvent, createCb);
  };
  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;
    if (newEvent.startDate == newEvent.endDate && selectedEndTime <= newEvent.startTime) {
      setTimeError(true);
    } else {
      setNewEvent({
            ...newEvent,
            endTime: e.target.value,
          })
      setTimeError(false);
    }
  }

  return (
    <Box
      className="demo-app-main"
      sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={false}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={true}
        weekends={true}
        dragScroll={false}
        events={
          events?.length > 0 && typeof events !== "string"
            ? events?.map((event) => ({
                title: event.title,
                eventInfo: event,
                start: event.start, // Replace with the actual year and month
                end: event.end
                  ? event.end // Replace with the actual year and month
                  : null,
                color: colorCode[event.status_id || 0],
              }))
            : []
        }
        dateClick={handleDateSelect}
        eventContent={renderEventContent}
      />
      <Modal
        open={open}
        onClose={handle1Close}
        an
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 9999,
        }}
      >
        <Box sx={style}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 18,
              color: "#212223",
              fontWeight: 600,
              backgroundColor: "#90949f21",
              pt: 1.5,
              pl: 1.5,
              pb: 1.5,
              mb: 2.5,
            }}
          >
            Set Event
          </Typography>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489",
                fontWeight: 600,
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              Date And Time
            </Typography>
            <Box sx={{ marginTop: "10px", gap: "10px" }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      name="startDate"
                      label="Start Date"
                      type="date"
                      fontSize="15"
                      value={newEvent?.startDate}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          startDate: e.target.value,
                        })
                      }
                      error={startDateError}
                      helperText={startDateError && startDateErrorMessage}
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#5fdbc4",
                            fontWeight: 600,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "10px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      margin="0"
                      fullWidth
                      size="small"
                      name="startTime"
                      type="time"
                      fontSize="15"
                      value={newEvent?.startTime}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          startTime: e.target.value,
                        })
                      }
                      error={startTimeError}
                      helperText={startTimeError && startTimeErrorMessage}
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#5fdbc4",
                            fontWeight: 600,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "10px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      margin="0"
                      fullWidth
                      size="small"
                      name="endDate"
                      label="End Date"
                      type="date"
                      fontSize="15"
                      value={newEvent?.endDate}
                      InputProps={{
                        inputProps: {
                          min: newEvent.startDate,
                        },
                      }}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          endDate: e.target.value,
                        })
                      }
                      error={endDateError}
                      helperText={endDateError && endDateErrorMessage}
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#5fdbc4",
                            fontWeight: 600,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "10px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      margin="0"
                      fullWidth
                      size="small"
                      name="endTime"
                      type="time"
                      fontSize="15"
                      placeholder="End Time"
                      // onChange={(e) =>
                      //   setNewEvent({
                      //     ...newEvent,
                      //     endTime: e.target.value,
                      //   })
                      // }
                      onChange={handleEndTimeChange}
                      InputProps={{
                        inputProps: {
                          min: newEvent.startTime,
                        },
                      }}
                      error={endTimeError || timeError}
                      helperText={endTimeError ? endTimeErrorMessage  :timeError && sameDateTimeError}
                      InputLabelProps={{
                        sx: {
                          fontSize: "15px",
                          "&.Mui-focused": {
                            color: "#5fdbc4",
                            fontWeight: 600,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "10px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "95%",
                          lg: "95%",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489",
                fontWeight: 600,
                marginTop: "5px",
              }}
            >
              Event Discription
            </Typography>
            <TextField
              margin="0"
              fullWidth
              size="small"
              name="eventDiscription"
              placeholder=""
              type="text"
              fontSize="15"
              value={newEvent?.eventDiscription}
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  eventDiscription: e.target.value,
                })
              }
              error={eventDiscriptionError}
              helperText={eventDiscriptionError && eventDiscriptionErrorMessage}
              InputLabelProps={{
                sx: {
                  fontSize: "15px",
                  "&.Mui-focused": {
                    color: "#5fdbc4",
                    fontWeight: 600,
                  },
                },
              }}
              sx={{
                marginTop: "10px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "95%",
                  lg: "95%",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
          </Box>

          {renderDayCheckboxes()}
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                onCreate();
              }}
              sx={{
                mt: 2.5,
                mr: 2,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                color: "#212223",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Create
            </Button>
            <Button
              onClick={handle1Close}
              variant="contained"
              sx={{
                mt: 2.5,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#000",
                borderColor: "#6DD6C2",
                color: "#fff",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#000",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
});

Calendar.propTypes = {
  events: PropTypes.array,
  onCreateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  agentId: PropTypes.number,
  isFromCampaign: false,
};

Calendar.defaultProps = {
  events: [],
  agentId: 0,
  onCreateEvent: () => {},
  onDeleteEvent: () => {},
  isFromCampaign: false,
};
