// Replace the old action types with new ones for creating callbacks
export const CREATE_CALLBACK_IN_PROGRESS = 'CREATE_CALLBACK_IN_PROGRESS';
export const CREATE_CALLBACK_SUCCESS = 'CREATE_CALLBACK_SUCCESS';
export const CREATE_CALLBACK_FAILURE = 'CREATE_CALLBACK_FAILURE';

// Action types for callback search
export const GET_CALLBACK_SEARCH_IN_PROGRESS = 'GET_CALLBACK_SEARCH_IN_PROGRESS';
export const GET_CALLBACK_SEARCH_SUCCESS = 'GET_CALLBACK_SEARCH_SUCCESS';
export const GET_CALLBACK_SEARCH_FAILURE = 'GET_CALLBACK_SEARCH_FAILURE';



// Action types for callback Show
export const GET_CALLBACK_SHOW_IN_PROGRESS = 'GET_CALLBACK_SHOW_IN_PROGRESS';
export const GET_CALLBACK_SHOW_SUCCESS = 'GET_CALLBACK_SHOW_SUCCESS';
export const GET_CALLBACK_SHOW_FAILURE = 'GET_CALLBACK_SHOW_FAILURE';

// Action types for updating assigned lead
export const UPDATE_ASSIGNED_LEAD_IN_PROGRESS = 'UPDATE_ASSIGNED_LEAD_IN_PROGRESS';
export const UPDATE_ASSIGNED_LEAD_SUCCESS = 'UPDATE_ASSIGNED_LEAD_SUCCESS';
export const UPDATE_ASSIGNED_LEAD_FAILURE = 'UPDATE_ASSIGNED_LEAD_FAILURE';
