import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createFilter } from "redux-persist-transform-filter";

// Import initial state of the store.
import initialState from "./initialState";
// Import reducers here.
import AppReducers from "./reducers";

// Give keys (store keys) that should be persisted
const saveSubsetFilter = createFilter(AppReducers, ["loginAuth"]);

// Redux-persist configuration
const persistConfig = {
  key: "primary",
  storage: storage,
  transforms: [saveSubsetFilter],
};

// Create the combined reducer
const rootReducer = combineReducers(AppReducers);

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];

if (process.env.NODE_ENV === "development") {
  const { composeWithDevTools } = require("redux-devtools-extension");
  middleware = composeWithDevTools(applyMiddleware(...middleware, logger));
} else {
  middleware = applyMiddleware(...middleware);
}

const store = createStore(persistedReducer, initialState, middleware);

const persistor = persistStore(store);
// persistor.purge();

export { store, persistor };
