import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { CallHistoryTableHead } from "./CallHistoryTableHead";
import { CallHistoryTableToolbar } from "./CallHistoryTableToolbar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ProfileModal } from "../../Common";
import { checkAndReturnNone, formatPhoneNumber } from "../../../helpers";

export const CallHistoryTable = (props) => {
  const {
    rows,
    filterName,
    handleNameFilterChange,
    filterActive,
    handleActiveFilterChange,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    // Handle delete action
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    // if (!event.target.closest(".show-button")) {
    //   // Exclude clicks on the "Show" button
    //   const selectedIndex = selected.indexOf(row?.operations?.id);
    //   let newSelected = [];

    //   if (selectedIndex === -1) {
    //     newSelected = newSelected.concat(selected, row?.operations?.id);
    //   } else if (selectedIndex === 0) {
    //     newSelected = newSelected.concat(selected.slice(1));
    //   } else if (selectedIndex === selected.length - 1) {
    //     newSelected = newSelected.concat(selected.slice(0, -1));
    //   } else if (selectedIndex > 0) {
    //     newSelected = newSelected.concat(
    //       selected.slice(0, selectedIndex),
    //       selected.slice(selectedIndex + 1)
    //     );
    //   }

    //   setSelected(newSelected);
    // }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  let loading = useSelector((state) => state?.reports?.isLoading);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
          id="callHistoryReport"
            sx={{ minWidth: 750, border: 1, borderColor: "#ddd" }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <CallHistoryTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                sx={{
                                  backgroundColor:
                                    index % 2 == 1 ? "#fff" : "#fff",
                                  borderBottom: "0!important",
                                  "&:hover": {
                                    backgroundColor: "#F4F6FA!important",
                                  },
                                }}
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.firstName}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    paddingLeft: "20px",
                                  }}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {checkAndReturnNone(row?.campaign)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    pl: 2,
                                  }}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <Button
                                    sx={{
                                      "&:hover": {
                                        color: "#0088cc",
                                      },
                                      width: 100,
                                      height: "20px",
                                      minWidth: "50px",
                                      padding: "0 0 0 5px",
                                      color: "#0088cc",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                      whiteSpace: "nowrap",
                                      wordWrap: "nowrap",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                    }}
                                  >
                                    {checkAndReturnNone(row?.companyName)}
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.username)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.prospectFirstName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.prospectLastName}
                                  {checkAndReturnNone(row?.prospectFirstName)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {formatPhoneNumber(row?.phoneNumber) ??
                                    row?.phoneNumber}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.address)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.city)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.county)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.zipCode)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {checkAndReturnNone(row?.callTime)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row?.callRecordings ? "True" : "False"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Paper>
    </Box>
  );
};
