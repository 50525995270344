import * as React from "react";
import Box from "@mui/material/Box";
import { ProspectsTable } from "./ProspectsTable";
import { useEffect } from "react";

export const ProspectsParentTable = (props) => {
  const { prospectsData,fetchMoreRecord,page,setPage } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");
  const createData = (
    view,
    createLead,
    phoneNumber,
    altPhoneNumber,
    callResult,
    callResultDesc,
    execTitle,
    execFirstName,
    execLastName,
    companyName,
    companyWebsite,
    countyDesc,
    locationState,
    locationCity,
    locationZipCode,
    locationAddress,
    comments
  ) => {
    return {
      view,
      createLead,
      phoneNumber,
      altPhoneNumber,
      callResult,
      callResultDesc,
      execTitle,
      execFirstName,
      execLastName,
      companyName,
      companyWebsite,
      countyDesc,
      locationState,
      locationCity,
      locationZipCode,
      locationAddress,
      comments,
    };
  };
  const rows = [
    {
      id: 1,
      view: "Show",
      createLead: "Create Lead",
      phoneNumber: "6615899554",
      altPhoneNumber: "None",
      callResult: "Do Not Call List",
      callResultDesc: "Do Not Call List",
      execTitle: "Owner",
      execFirstName: "Ray",
      execLastName: "Vercammen",
      companyName: "Golden Empire Nuts & Bolts Inc",
      companyWebsite: "None",
      countyDesc: "Kern",
      locationState: "CA",
      locationCity: "Bakersfield",
      locationZipCode: "93308",
      locationAddress: "2751 Fruitvale Ave",
      comments: "None",
    },
    {
      id: 1,
      view: "Show",
      createLead: "Create Lead",
      phoneNumber: "6615899554",
      altPhoneNumber: "None",
      callResult: "Do Not Call List",
      callResultDesc: "Do Not Call List",
      execTitle: "Owner",
      execFirstName: "Ray",
      execLastName: "Vercammen",
      companyName: "Golden Empire Nuts & Bolts Inc",
      companyWebsite: "None",
      countyDesc: "Kern",
      locationState: "CA",
      locationCity: "Bakersfield",
      locationZipCode: "93308",
      locationAddress: "2751 Fruitvale Ave",
      comments: "None",
    },
    {
      id: 1,
      view: "Show",
      createLead: "Create Lead",
      phoneNumber: "6615899554",
      altPhoneNumber: "None",
      callResult: "Do Not Call List",
      callResultDesc: "Do Not Call List",
      execTitle: "Owner",
      execFirstName: "Ray",
      execLastName: "Vercammen",
      companyName: "Golden Empire Nuts & Bolts Inc",
      companyWebsite: "None",
      countyDesc: "Kern",
      locationState: "CA",
      locationCity: "Bakersfield",
      locationZipCode: "93308",
      locationAddress: "2751 Fruitvale Ave",
      comments: "None",
    },
    {
      id: 1,
      view: "Show",
      createLead: "Create Lead",
      phoneNumber: "6615899554",
      altPhoneNumber: "None",
      callResult: "Do Not Call List",
      callResultDesc: "Do Not Call List",
      execTitle: "Owner",
      execFirstName: "Ray",
      execLastName: "Vercammen",
      companyName: "Golden Empire Nuts & Bolts Inc",
      companyWebsite: "None",
      countyDesc: "Kern",
      locationState: "CA",
      locationCity: "Bakersfield",
      locationZipCode: "93308",
      locationAddress: "2751 Fruitvale Ave",
      comments: "None",
    },
  ];

  // Filtered rows based on the filter values
  // const filteredRows = rows?.filter((row) => {
  //   const nameFilter = filterName.trim().toLowerCase();
  //   const activeFilter =
  //     filterActive === "all" ||
  //     (filterActive === "active" && row.isActive) ||
  //     (filterActive === "inactive" && !row.isActive);
  //   return row.firstName?.toLowerCase().includes(nameFilter) && activeFilter;
  // });

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <ProspectsTable rows={prospectsData} filterName={filterName} 
      fetchMoreRecord={fetchMoreRecord}
      page={page}
      setPage={setPage}
      />
    </Box>
  );
};
