// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { TwilioVoice, getTwilioDevice } from "../Common";
import { DEVICE_STATE } from "../../helpers";
import CallIcon from "@mui/icons-material/Call";
import { updateCampaignKey } from "../../store/actions";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const CallHistory = (props) => {
  const [selectedFilter, setSelectedFilter] = useState();
  const [filterCampaignName, setFilterCampaignName] = useState("");

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNameFilterChange = (event) => {
    setFilterCampaignName(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 20px",
          marginTop: "25px",
          borderRadius: "10px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "10px!Important",
              borderTopRightRadius: "10px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Call History
              </Typography>
            </AccordionSummary>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: "none" }),
                marginRight: "30px",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                padding: "10px",
                backgroundColor: "#6DD6C2",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                },
                borderRadius: "50%",
                borderColor: "#6DD6C2",
                color: "#212223",
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: "50px!important" }}>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: "20px",
                  color: "#6DD6C2",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&:hover": {
                    color: "#6DD6C2",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>

            <Box sx={{ padding: "0 20px" }}>
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 20, color: "#212223", fontWeight: 600, mt: 1 }}
              >
                Search History
              </Typography>

              <Box sx={{ marginTop: "15px" }}>
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 14,
                    color: "#6B7489",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Select
                </Typography>
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    This Month{" "}
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Filter Active/Inactive"
                    onChange={handleChange}
                    size="small"
                  >
                    <MenuItem value={"active"} sx={{ fontSize: 15 }}>
                      Today
                    </MenuItem>
                    <MenuItem value={"inactive"} sx={{ fontSize: 15 }}>
                      Yesterday
                    </MenuItem>
                    <MenuItem value={"all"} sx={{ fontSize: 15 }}>
                      Last 7 days + Today
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  fontSize: 15,
                  color: "#6B7489                        ",
                  fontWeight: 600,
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                From Date Time :
              </Typography>
              <Box sx={{ marginTop: "1px", display: "flex", gap: "10px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{
                    width: "100%",
                    maxWidth: "max-content",
                  }}
                >
                  <DemoContainer
                    components={["DateTimePicker"]}
                    sx={{
                      width: "100%",
                      maxWidth: "max-content",
                    }}
                  >
                    <DateTimePicker
                      label="Basic date time picker"
                      sx={{
                        width: "100%",
                        maxWidth: "max-content",
                        fontSize: "10px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "10px 13px 10px",
                          },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  fontSize: 15,
                  color: "#6B7489                        ",
                  fontWeight: 600,
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                To Date Time :
              </Typography>
              <Box sx={{ marginTop: "1px", display: "flex", gap: "10px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  size="small"
                  sx={{
                    width: "100%",
                    maxWidth: "max-content",
                  }}
                >
                  <DemoContainer
                    components={["DateTimePicker"]}
                    size="small"
                    sx={{
                      width: "100%",
                      maxWidth: "max-content",
                    }}
                  >
                    <DateTimePicker
                      label="Basic date time picker"
                      size="small"
                      sx={{
                        width: "100%",
                        maxWidth: "max-content",
                        fontSize: "10px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "10px 13px 10px",
                          },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Button
                type="submit"
                variant="contained"
                onClick={""}
                sx={{
                  fontSize: { xs: "13px", sm: "15px", md: "15px", lg: "15px" },
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  fontWeight: 600,
                  marginTop: "15px",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>

              {/* <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  mt: 3,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    // Customize the hover color
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button> */}
            </Box>
          </Drawer>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#fff",
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        background: "#e7f7ff",
                        fontSize: 18,
                        color: "#6B7489",
                        fontWeight: 600,
                        marginBottom: "10px",
                        borderBottom: 1,
                        borderColor: "#676a6c4a",
                        padding: {
                          xs: "10px 5px",
                          sm: "10px 10px",
                          md: "10px 10px",
                          lg: "12px 10px",
                        },
                      }}
                    >
                      Call Volume
                    </Typography>
                    <Box>
                      <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                          fontSize: 22,
                          color: "#000",
                          fontWeight: 600,
                          marginBottom: "20px",
                          padding: {
                            xs: "10px 5px",
                            sm: "15px 20px 20px 20px",
                            md: "15px 20px 20px 20px",
                            lg: "15px 20px 20px 20px",
                          },
                        }}
                      >
                        #
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#fff",
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        background: "#e7d5c880",
                        fontSize: 18,
                        color: "#6B7489",
                        fontWeight: 600,
                        marginBottom: "10px",
                        borderBottom: 1,
                        borderColor: "#676a6c4a",
                        padding: {
                          xs: "10px 5px",
                          sm: "10px 10px",
                          md: "10px 10px",
                          lg: "12px 10px",
                        },
                      }}
                    >
                      Conversion Rate
                    </Typography>
                    <Box>
                      <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                          fontSize: 22,
                          color: "#000",
                          fontWeight: 600,
                          marginBottom: "20px",
                          padding: {
                            xs: "10px 5px",
                            sm: "15px 20px 20px 20px",
                            md: "15px 20px 20px 20px",
                            lg: "15px 20px 20px 20px",
                          },
                        }}
                      >
                        *
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#fff",
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        background: "#fde4eca8",
                        fontSize: 18,
                        color: "#6B7489",
                        fontWeight: 600,
                        marginBottom: "10px",
                        borderBottom: 1,
                        borderColor: "#676a6c4a",
                        padding: {
                          xs: "10px 5px",
                          sm: "10px 10px",
                          md: "10px 10px",
                          lg: "12px 10px",
                        },
                      }}
                    >
                      Valid Calls
                    </Typography>
                    <Box>
                      <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                          fontSize: 22,
                          color: "#000",
                          fontWeight: 600,
                          marginBottom: "20px",
                          padding: {
                            xs: "10px 5px",
                            sm: "15px 20px 20px 20px",
                            md: "15px 20px 20px 20px",
                            lg: "15px 20px 20px 20px",
                          },
                        }}
                      >
                        &
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      background: "#fff",
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        background: "#EEF3F9",
                        fontSize: 18,
                        color: "#6B7489",
                        fontWeight: 600,
                        marginBottom: "10px",
                        borderBottom: 1,
                        borderColor: "#676a6c4a",
                        padding: {
                          xs: "10px 5px",
                          sm: "10px 10px",
                          md: "10px 10px",
                          lg: "12px 10px",
                        },
                      }}
                    >
                      Total Cost
                    </Typography>
                    <Box>
                      <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                          fontSize: 22,
                          color: "#000",
                          fontWeight: 600,
                          marginBottom: "20px",
                          padding: {
                            xs: "10px 5px",
                            sm: "15px 20px 20px 20px",
                            md: "15px 20px 20px 20px",
                            lg: "15px 20px 20px 20px",
                          },
                        }}
                      >
                        %
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#90949f21",
              borderTopLeftRadius: "10px!Important",
              borderTopRightRadius: "10px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Call History | Total Count :
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#6DD6C2",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( 0 )
                </Typography>
              </Typography>
            </AccordionSummary>
            <Button
              variant="contained"
              sx={{
                fontSize: { xs: "13px", sm: "15px", md: "15px", lg: "15px" },
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                color: "#212223",
                marginRight: "15px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Export
            </Button>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            ></Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
