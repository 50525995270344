import {
  BASE_URL,
  LEAD_OWED_REPORT_URL,
  UPDATE_LEAD_KEY_URL,
  USER_DASHBOARD_EMPLOYEE_PRODUCTION_URL,
  USER_DASHBOARD_PRODUCTION_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, putRequest } from "../../../services";
import { logout } from "../authentication";
import {
  LEAD_OWED_REPORT_FAILURE,
  LEAD_OWED_REPORT_IN_PROGRESS,
  LEAD_OWED_REPORT_SUCCESS,
  UPDATE_LEAD_OWN_KEY_FAILURE,
  UPDATE_LEAD_OWN_KEY_IN_PROGRESS,
  UPDATE_LEAD_OWN_KEY_SUCCESS,
  USER_DASHBOARD_PRODUCTION_FAILURE,
  USER_DASHBOARD_PRODUCTION_IN_PROGRESS,
  USER_DASHBOARD_PRODUCTION_SUCCESS,
} from "./types";
import toast from "react-hot-toast";

export const userDashboardProductionInProgress = () => ({
  type: USER_DASHBOARD_PRODUCTION_IN_PROGRESS,
});

export const userDashboardProductionSuccess = (data) => ({
  type: USER_DASHBOARD_PRODUCTION_SUCCESS,
  data,
});

export const userDashboardProductionFailure = (error) => ({
  type: USER_DASHBOARD_PRODUCTION_FAILURE,
  error,
});

export const userDashboardProduction = (payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const state = getState();
      const user_type =
        state?.loginAuth?.user_details?.user_type ?? "Administration";
      dispatch(userDashboardProductionInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for user reports
      const URL =
        user_type === "Administration"
          ? `${BASE_URL}${USER_DASHBOARD_PRODUCTION_URL}`
          : `${BASE_URL}${USER_DASHBOARD_EMPLOYEE_PRODUCTION_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(userDashboardProductionSuccess(response?.data));
        cb();
      } else {
        dispatch(
          userDashboardProductionFailure({
            type: ERROR_TYPE.API.HOME.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        userDashboardProductionFailure({
          type: ERROR_TYPE.API.HOME.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const leadOwedReportInProgress = () => ({
  type: LEAD_OWED_REPORT_IN_PROGRESS,
});

export const leadOwedReportSuccess = (data) => ({
  type: LEAD_OWED_REPORT_SUCCESS,
  data,
});

export const leadOwedReportFailure = (error) => ({
  type: LEAD_OWED_REPORT_FAILURE,
  error,
});

export const leadOwedReport = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(leadOwedReportInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request for lead owed report

      const URL = `${BASE_URL}${LEAD_OWED_REPORT_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(leadOwedReportSuccess(response?.data));
        cb();
      } else {
        dispatch(
          leadOwedReportFailure({
            type: ERROR_TYPE.API.HOME.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += ` ${value[nestedKey]} `;
          }
          value = nestedValue.trim();
      
        }
        let errToast = `${key}: ${value}`;
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
    }
      
      
      dispatch(
        leadOwedReportFailure({
          type: ERROR_TYPE.API.HOME.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};



// Action Creators
export const updateLeadOwnKeyInProgress = () => ({
  type: UPDATE_LEAD_OWN_KEY_IN_PROGRESS,
});

export const updateLeadOwnKeySuccess = (data) => ({
  type: UPDATE_LEAD_OWN_KEY_SUCCESS,
  data,
});

export const updateLeadOwnKeyFailure = (error) => ({
  type: UPDATE_LEAD_OWN_KEY_FAILURE,
  error,
});

export const updateLeadOwnKey = (id, key,value, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Create params object with the key and value to update
      const params = {
        [key]:value
      };

      // Dispatch in progress action
      dispatch(updateLeadOwnKeyInProgress());

      // Make API request to update the specific key in the lead
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await putRequest(
        `${BASE_URL}${UPDATE_LEAD_KEY_URL 
        }${id}/`,
        params,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateLeadOwnKeySuccess(response?.data));
        cb();
        toast.success(`Updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateLeadOwnKeyFailure({
            type: ERROR_TYPE.API.HOME.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateLeadOwnKeyFailure({
          type: ERROR_TYPE.API.HOME.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
