import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = ({ element, allowedRoles }) => {
  const isLoggedIn = useSelector((state) => state?.loginAuth?.isLoggedIn);
  const userRole = useSelector((state) => state?.loginAuth?.user_details?.user_type);

  if (!isLoggedIn) {
    // If not logged in, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    // If the user doesn't have the required role, show forbidden page
    return <Navigate to="/forbidden" />;
  }

  // If authenticated and has the correct role, render the protected route
  return element;
};

export default AuthRoute;
