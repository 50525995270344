import * as React from "react";
import Box from "@mui/material/Box";
import { OnlineUserTable } from "./OnlineUserTable";
import { useSelector } from "react-redux";

export const OnlineUserParentTable = (props) => {
  const { logoutCallBack } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const userData = useSelector((state) => state?.reports?.currentOnlineUsers);

  const createData = (
    fullName,
    status,
    emailId,
    isActive,
    isAdmin,
    userType,
    lastLogin,
    operations
  ) => {
    return {
      fullName,
      status,
      emailId,
      isActive,
      isAdmin,
      userType,
      lastLogin,
      operations,
    };
  };

  const rows = [
    createData(
      "Marybeth Nausbaum",
      "Online",
      "marybeth@example.com",
      "True",
      "True",
      "Administration",
      "Nov. 23, 2023, 3:31 a.m.",
      "Make Offline"
    ),
    createData(
      "John Doe",
      "Offline",
      "john@example.com",
      "False",
      "False",
      "Administration",
      "Nov. 23, 2023, 3:31 a.m.",
      "Make Offline"
    ),
    // Add more rows as needed
  ];

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        status,
        full_name,
        email,
        is_active,
        is_admin,
        user_type,
        last_login,
      } = item;

      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      return createData(
        full_name,
        status,
        email,
        is_active,
        is_admin,
        user_type,
        last_login,
        item
      );
    });
  };

  userData?.online_users &&
    userData?.online_users?.forEach((user) => {
      user.status = "Online";
    });

  // Add status key to offline users
  userData?.offline_users &&
    userData?.offline_users?.forEach((user) => {
      user.status = "Offline";
    });

  // Merge the online and offline user arrays
  const mergedUsers = userData?.offline_users
    ? [...userData?.online_users, ...userData?.offline_users]
    : [];

  const tableRows = convertApiResponseToRows(mergedUsers);

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <OnlineUserTable
        rows={tableRows}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        handleActiveFilterChange={handleActiveFilterChange}
        logoutCallBack={logoutCallBack}
      />
    </Box>
  );
};
