import * as React from "react";
import Box from "@mui/material/Box";
import { CallHistoryTable } from "./CallHistoryTable";
import { useSelector } from "react-redux";

export const CallHistoryParentTable = (props) => {
  const { clientData } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const callHistoryData = useSelector(
    (state) => state.reports.callHistoryReports
  );

  const createData = (
    campaign,
    companyName,
    username,
    prospectFirstName,
    prospectLastName,
    phoneNumber,
    address,
    city,
    county,
    zipCode,
    callTime,
    callRecordings
  ) => {
    return {
      campaign,
      companyName,
      username,
      prospectFirstName,
      prospectLastName,
      phoneNumber,
      address,
      city,
      county,
      zipCode,
      callTime,
      callRecordings,
    };
  };

  // const rows = [
  //   createData(
  //     "Campaign 1",
  //     "Company Name 1",
  //     "Username 1",
  //     "Marybeth",
  //     "Nausbaum",
  //     "610-777-3258",
  //     "Address 1",
  //     "City 1",
  //     "County 1",
  //     "12345",
  //     "2023-10-31 10:00 AM",
  //     "Recording Link 1"
  //   ),
  //   createData(
  //     "Campaign 2",
  //     "Company Name 2",
  //     "Username 2",
  //     "John",
  //     "Doe",
  //     "123-456-7890",
  //     "Address 2",
  //     "City 2",
  //     "County 2",
  //     "67890",
  //     "2023-10-31 11:00 AM",
  //     "Recording Link 2"
  //   ),
  //   createData(
  //     "Campaign 3",
  //     "Company Name 3",
  //     "Username 3",
  //     "Jane",
  //     "Smith",
  //     "987-654-3210",
  //     "Address 3",
  //     "City 3",
  //     "County 3",
  //     "45678",
  //     "2023-10-31 12:00 PM",
  //     "Recording Link 3"
  //   ),
  //   createData(
  //     "Campaign 4",
  //     "Company Name 4",
  //     "Username 4",
  //     "Michael",
  //     "Johnson",
  //     "555-123-4567",
  //     "Address 4",
  //     "City 4",
  //     "County 4",
  //     "78901",
  //     "2023-10-31 01:00 PM",
  //     "Recording Link 4"
  //   ),
  //   createData(
  //     "Campaign 5",
  //     "Company Name 5",
  //     "Username 5",
  //     "Emily",
  //     "Davis",
  //     "111-222-3333",
  //     "Address 5",
  //     "City 5",
  //     "County 5",
  //     "23456",
  //     "2023-10-31 02:00 PM",
  //     "Recording Link 5"
  //   ),
  // ];

  // {
  //   "campaign": "NEW0101",
  //   "company_name": "1-800-Got-Junk?",
  //   "username": "rohit.birla0770@gmail.com",
  //   "prospect_first_name": "",
  //   "prospect_last_name": "",
  //   "prospect_phone_number": "8004685865",
  //   "prospect_address": "60 W Terra Cotta Ave # B",
  //   "prospect_city": "Crystal Lake",
  //   "prospect_county": "Mc Henry",
  //   "prospect_zip_code": "60014",
  //   "call_time": "10-29-2023 10:00 AM",
  //   "call_recording": true
  //   },

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        campaign,
        company_name,
        username,
        prospect_first_name,
        prospect_last_name,
        prospect_phone_number,
        prospect_address,
        prospect_city,
        prospect_county,
        prospect_zip_code,
        call_time,
        call_recording,
      } = item;

      return createData(
        campaign,
        company_name,
        username,
        prospect_first_name,
        prospect_last_name,
        prospect_phone_number,
        prospect_address,
        prospect_city,
        prospect_county,
        prospect_zip_code,
        call_time,
        call_recording
      );
    });
  };

  const tableRows = convertApiResponseToRows(callHistoryData);

  // // Filtered rows based on the filter values
  // const filteredRows = rows?.filter((row) => {
  //   const nameFilter = filterName.trim().toLowerCase();
  //   const activeFilter =
  //     filterActive === "all" ||
  //     (filterActive === "active" && row.isActive) ||
  //     (filterActive === "inactive" && !row.isActive);
  //   return row.firstName.toLowerCase().includes(nameFilter) && activeFilter;
  // });

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <CallHistoryTable
        rows={tableRows}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        handleActiveFilterChange={handleActiveFilterChange}
      />
    </Box>
  );
};
