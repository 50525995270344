import React, { useState } from "react";
import { Modal, Button, Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BASE_URL, GENERATE_INVOICE_PDF_URL } from "../../config/api_urls";
import { Document, Page, pdfjs } from "react-pdf";

export const PdfViewer = ({ open, handleClose }) => {
  const theme = useTheme();

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  // Assuming A4 size is 21cm x 29.7cm
  const a4Size = {
    width: "500px",
    height: "650px",
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  return (
    <Modal
      open={open.isOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          ...a4Size,
        }}
      >
        <Document
          className="preview-pdf"
          file={`${BASE_URL}${GENERATE_INVOICE_PDF_URL}${"preview"}/${
            open?.id
          }/`}
          onLoadStart={() => {
            setIsLoading(true);
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(err) => {
            console.log("====>", err);
          }}
          loading={<CircularProgress className="loader-css" />} // Show loader while loading
        >
          <Page pageNumber={pageNumber} />
          <Button
            sx={{
              position: "absolute",
              top: 10,
              right: 450,
              zIndex: 9999,
              color: "#000000",
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Document>
      </Box>
    </Modal>
  );
};
