// Action Types for creating call history
export const CREATE_CALL_HISTORY_IN_PROGRESS = "CREATE_CALL_HISTORY_IN_PROGRESS";
export const CREATE_CALL_HISTORY_SUCCESS = "CREATE_CALL_HISTORY_SUCCESS";
export const CREATE_CALL_HISTORY_FAILURE = "CREATE_CALL_HISTORY_FAILURE";

// Action Types for updating call history
export const UPDATE_CALL_HISTORY_IN_PROGRESS = "UPDATE_CALL_HISTORY_IN_PROGRESS";
export const UPDATE_CALL_HISTORY_SUCCESS = "UPDATE_CALL_HISTORY_SUCCESS";
export const UPDATE_CALL_HISTORY_FAILURE = "UPDATE_CALL_HISTORY_FAILURE";

// Action Types for searching call history
export const CALL_HISTORY_SEARCH_IN_PROGRESS = "CALL_HISTORY_SEARCH_IN_PROGRESS";
export const CALL_HISTORY_SEARCH_SUCCESS = "CALL_HISTORY_SEARCH_SUCCESS";
export const CALL_HISTORY_SEARCH_FAILURE = "CALL_HISTORY_SEARCH_FAILURE";


export const USER_REPORT_SEARCH_IN_PROGRESS = 'USER_REPORT_SEARCH_IN_PROGRESS';
export const USER_REPORT_SEARCH_SUCCESS = 'USER_REPORT_SEARCH_SUCCESS';
export const USER_REPORT_SEARCH_FAILURE = 'USER_REPORT_SEARCH_FAILURE';

export const CAMPAIGN_REPORT_SEARCH_IN_PROGRESS = 'CAMPAIGN_REPORT_SEARCH_IN_PROGRESS';
export const CAMPAIGN_REPORT_SEARCH_SUCCESS = 'CAMPAIGN_REPORT_SEARCH_SUCCESS';
export const CAMPAIGN_REPORT_SEARCH_FAILURE = 'CAMPAIGN_REPORT_SEARCH_FAILURE';
export const CWTR_SUCCESS = 'CWTR_SUCCESS';


export const CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS = 'CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS';
export const CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS = 'CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS';
export const CAMPAIGN_IDLE_TIME_MANAGER_FAILURE = 'CAMPAIGN_IDLE_TIME_MANAGER_FAILURE';

export const CAMPAIGN_STOP_IN_PROGRESS = 'CAMPAIGN_STOP_IN_PROGRESS';
export const CAMPAIGN_STOP_SUCCESS = 'CAMPAIGN_STOP_SUCCESS';
export const CAMPAIGN_STOP_FAILURE = 'CAMPAIGN_STOP_FAILURE';

export const GET_CURRENT_ONLINE_USERS_IN_PROGRESS = "GET_CURRENT_ONLINE_USERS_IN_PROGRESS";
export const GET_CURRENT_ONLINE_USERS_SUCCESS = "GET_CURRENT_ONLINE_USERS_SUCCESS";
export const GET_CURRENT_ONLINE_USERS_FAILURE = "GET_CURRENT_ONLINE_USERS_FAILURE";


export const IDLE_TIME_MANAGER_IN_PROGRESS = 'IDLE_TIME_MANAGER_IN_PROGRESS';
export const IDLE_TIME_MANAGER_SUCCESS = 'IDLE_TIME_MANAGER_SUCCESS';
export const IDLE_TIME_MANAGER_FAILURE = 'IDLE_TIME_MANAGER_FAILURE';