import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";

export const ProspectsTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "view", numeric: false, disablePadding: false, label: "View" },
    {
      id: "createLead",
      numeric: false,
      disablePadding: false,
      label: "Create Lead",
    },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "altPhoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Alternative Phone Number",
    },
    {
      id: "callResult",
      numeric: false,
      disablePadding: false,
      label: "Call Result",
    },
    {
      id: "callResultDesc",
      numeric: false,
      disablePadding: false,
      label: "Call Result Description",
    },
    {
      id: "execTitle",
      numeric: false,
      disablePadding: false,
      label: "Executive Title",
    },
    {
      id: "execFirstName",
      numeric: false,
      disablePadding: false,
      label: "Executive First Name",
    },
    {
      id: "execLastName",
      numeric: false,
      disablePadding: false,
      label: "Executive Last Name",
    },
    {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      label: "Company Name",
    },
    {
      id: "companyWebsite",
      numeric: false,
      disablePadding: false,
      label: "Company Website",
    },
    {
      id: "countyDesc",
      numeric: false,
      disablePadding: false,
      label: "County Description",
    },
    {
      id: "locationState",
      numeric: false,
      disablePadding: false,
      label: "Location State",
    },
    {
      id: "locationCity",
      numeric: false,
      disablePadding: false,
      label: "Location City",
    },
    {
      id: "locationZipCode",
      numeric: false,
      disablePadding: false,
      label: "Location Zip Code",
    },
    {
      id: "locationAddress",
      numeric: false,
      disablePadding: false,
      label: "Location Address",
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comments",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingLeft: 2,
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "view" ||
            headCell.id === "operations" ||
            headCell.id === "resetPassword" ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ProspectsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
