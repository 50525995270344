export const CREATE_EMPLOYEE_IN_PROGRESS = "CREATE_EMPLOYEE_IN_PROGRESS";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const GET_EMPLOYEES_IN_PROGRESS = "GET_EMPLOYEES_IN_PROGRESS";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";

export const UPDATE_EMPLOYEE_IN_PROGRESS = "UPDATE_EMPLOYEE_IN_PROGRESS";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";

export const DELETE_EMPLOYEE_IN_PROGRESS = "DELETE_EMPLOYEE_IN_PROGRESS";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE";

export const UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS =
  "UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS";
export const UPDATE_CALL_RECORDING_STATUS_SUCCESS =
  "UPDATE_CALL_RECORDING_STATUS_SUCCESS";
export const UPDATE_CALL_RECORDING_STATUS_FAILURE =
  "UPDATE_CALL_RECORDING_STATUS_FAILURE";

export const UPLOAD_EMPLOYEE_CSV_IN_PROGRESS =
  "UPLOAD_EMPLOYEE_CSV_IN_PROGRESS";
export const UPLOAD_EMPLOYEE_CSV_SUCCESS = "UPLOAD_EMPLOYEE_CSV_SUCCESS";
export const UPLOAD_EMPLOYEE_CSV_FAILURE = "UPLOAD_EMPLOYEE_CSV_FAILURE";
