export const ERROR_MESSAGE = {
  PASSWORD: {
    IN_VALID: "Please enter the valid password",
    IN_CORRECT: "Please enter the correct password",
  },
  ORGANISATION: {
    IN_VALID: "Please enter the valid Organisation",
    IN_CORRECT: "Please enter the correct Organisation",
  },
  EMAIL: {
    IN_VALID: "Please enter the valid email ID",
    REQUIRED: "Email ID is required",
  },
  MOBILE_NUMBER: {
    IN_VALID: "Please enter the valid mobile number",
    REQUIRED: "Mobile number is required",
  },
  NAME: {
    IN_VALID: "Please enter the valid name",
    REQUIRED: "Name is required",
  },
  LASTNAME: {
    IN_VALID: "Please enter the valid name",
    REQUIRED: "Name is required",
  },
  STATE: {
    IN_VALID: "Please enter the valid state name",
    REQUIRED: "Name is required",
  },
  CITY: {
    IN_VALID: "Please enter the valid city name",
    REQUIRED: "Name is required",
  },
  API: {
    UNKNOWN: "Oops! Something wrong happened",
    LOGIN: {
      INVALID_INPUT: "Please enter the valid email id and password",
      USER_NOT_FOUND: "This email id is not registered",
      IN_CORRECT_PASSWORD: "Please enter the correct password",
    },
    SEARCH: {
      REQUIRED: "Search field is required",
    },
  },
  AUTHORIZATION: {
    UNAUTHORIZED_TOKEN: "Unauthorized token",
  },
  NETWORK:"Service Unavailable"
};

export const ERROR_TYPE = {
  API: {
    LOGIN: {
      INVALID_PASSWORD: "INVALID_PASSWORD",
      INVALID_STORE_EMAIL: "INVALID_STORE_EMAIL",
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    EMPLOYEE: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    PROSPECTS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    CLIENT: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    LEADS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    CAMPAIGNS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    APPOINTMENTS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    TWILLIO: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    CALLBACKS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    REPORTS: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    GOOGLE_AUTH: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    HOME: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
    INVOICES: {
      UNKNOWN: "UNKNOWN",
      ACCESS_DENIED: "ACCESS_DENIED",
    },
  },
};

export const DEVICE_STATE = {
  CONNECTING: "Connecting",
  READY: "Ready",
  ON_CALL: "On call",
  OFFLINE: "Offline",
};

export const CATEGORY = {
  "None": "None",
  1: "Accepted",
  2: "Cancelled",
  3: "Cancelled - Waiting To Reschedule",
  4: "Not An Appointment",
  5: "Not Interested (CWTR)",
  6: "Rescheduled",
  7: "Rescheduled (CWTR)",
  8: "Returned",
};

export const KEYS = {
  TOKEN: "token",
  REFRESH: "refresh",
};
export const GOOGLE_CID =
  "178698624692-igr7giqkkgk2slmnl7jn946akstfld28.apps.googleusercontent.com";

export const TIME_ZONES = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarksh",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis",
  "US/Central",
  "US/Pacific",
  "UTC",
  "US/Mountain",
  "US/Hawaii",
  "US/Eastern",
  "US/Arizona",
  "US/Alaska",
];

export const UPLOAD_EMPLOYES_OPTIONS = [
  "city",
  "cell_phone_number",
  "commission_plus_pay",
  "last_name",
  "time_zone",
  "is_superuser",
  "created",
  "is_client",
  "is_active",
  "commission_standard_pay",
  "notes",
  "bonus_pay",
  "user",
  "is_staff",
  "zip_code",
  "street",
  "is_admin",
  "verification_code",
  "sms_notification",
  "team",
  "updated",
  "email",
  "state",
  "address",
  "password",
  "salutation",
  "full_name",
  "last_login",
  "call_record",
  "rate_per_hour",
  "is_verified",
  "title",
  "phone_number",
  "first_name",
];

export const UPLOAD_CLIENT_OPTIONS = [
  "plus standard price",
  "Mailing Zip",
  "Staff",
  "Mailing State",
  "Mailing City",
  "last login",
  "Mailing Address",
  "verification code",
  "Mailing Street",
  "is first login",
  "sms notification",
  "Agency Name",
  "Client",
  "superuser status",
  "Title",
  "plus lead price",
  "Additional Email For Invoices",
  "auto payment is active",
  "send introduction mail",
  "Cell Phone",
  "additional email",
  "user",
  "Plan Purchased",
  "password",
  "Is Active",
  "Agent ID",
  "time zone",
  "Invoka ID",
  "is admin",
  "updated",
  "is verified",
  "Email",
  "Full Name",
  "Last Name",
  "Account Name",
  "created",
  "Phone",
  "Notes",
  "First Name",
  "Salutation",
];

export const CATEGORY_IN_OBJ = [
  {
    id: "None",
    label: "None",
  },
  {
    id: 1,
    label: "Accepted",
  },
  {
    id: 2,
    label: "Cancelled",
  },
  {
    id: 3,
    label: "Cancelled - Waiting To Reschedule",
  },
  {
    id: 4,
    label: "Not An Appointment",
  },
  {
    id: 5,
    label: "Not Interested (CWTR)",
  },
  {
    id: 6,
    label: "Rescheduled",
  },
  {
    id: 7,
    label: "Rescheduled (CWTR)",
  },
  {
    id: 8,
    label: "Returned",
  },
  // 1: "Accepted",
  // 2: "Cancelled",
  // 3: "Cancelled - Waiting To Reschedule",
  // 4: "Not An Appointment",
  // 5: "Not Interested (CWTR)",
  // 6: "Rescheduled",
  // 7: "Rescheduled (CWTR)",
  // 8: "Returned",
];

export const CALL_RESULT = [
  {
    id: 0,
    label: "None",
  },
  {
    id: 1,
    label: "Unavailable",
  },
  {
    id: 2,
    label: "Bad Number",
  },
  {
    id: 3,
    label: "Decision Maker Not Yet Interested",
  },
  {
    id: 4,
    label: "Skip",
  },
  {
    id: 5,
    label: "Do Not Call List",
  },
  {
    id: 6,
    label: "Renewal Date Added",
  },
  {
    id: 7,
    label: "Call Back Scheduled",
  },
  {
    id: 8,
    label: "Nationwide",
  },
  {
    id: 9,
    label: "Erie",
  },
  {
    id: 10,
    label: "Set Appointment",
  },
  {
    id: 11,
    label: "Busy",
  },
  {
    id: 12,
    label: "Farmers",
  },
  {
    id: 13,
    label: "Duplicate",
  },
  {
    id: 14,
    label: "Mobile Number",
  },
  {
    id: 17,
    label: "Relationship With Agent",
  },
  {
    id: 18,
    label: "Corp Handles",
  },
  {
    id: 19,
    label: "Technical Difficulty Calling",
  },
  {
    id: 20,
    label: "Going out of Business",
  },
  {
    id: 21,
    label: "Language Barrier",
  },
  {
    id: 22,
    label: "Farm Bureau",
  },
  {
    id: 23,
    label: "Rural Mutual",
  },
  {
    id: 24,
    label: "Country Financial",
  },
  {
    id: 25,
    label: "Selective",
  },
  {
    id: 26,
    label: "State Farm",
  },
  {
    id: 27,
    label: "American National",
  },
  {
    id: 28,
    label: "Cincinnati",
  },
];


export const PROSPECTS__KEYS = [
  {
    key: "Alternate Phone Number",
    value: "alternative_phone_number",
  },
  {
    key: "Alternative Phone Number Line Type",
    value: "alternative_phone_number_line_type",
  },
  {
    key: "Call Result",
    value: "call_result",
  },
  {
    key: "Call Result Description",
    value: "call_result_description",
  },
  {
    key: "Carrier Of Record",
    value: "carrier_of_record",
  },
  {
    key: "Comments",
    value: "comments",
  },
  {
    key: "Company Name",
    value: "company_name",
  },
  {
    key: "Company Website",
    value: "website",
  },
  {
    key: "County Description",
    value: "county",
  },
  {
    key: "Credit Rating",
    value: "credit_rating",
  },
  {
    key: "Date List Produced",
    value: "date_list_produced",
  },
  {
    key: "EIN 1",
    value: "ein",
  },
  {
    key: "Email",
    value: "email",
  },
  {
    key: "Executive First Name",
    value: "first_name",
  },
  {
    key: "Executive Last Name",
    value: "last_name",
  },
  {
    key: "Executive Title",
    value: "title",
  },
  {
    key: "File Name",
    value: "file_name",
  },
  {
    key: "Location Address",
    value: "address",
  },
  {
    key: "Location City",
    value: "city",
  },
  {
    key: "Location Employee Size Actual",
    value: "employee_size",
  },
  {
    key: "Location State",
    value: "state",
  },
  {
    key: "Location ZIP Code",
    value: "zip_code",
  },
  {
    key: "Phone Number Combined Unformatted",
    value: "phone_number",
  },
  {
    key: "Phone Number Line Type",
    value: "phone_number_line_type",
  },
  {
    key: "Primary NAICS Code",
    value: "naics_code",
  },
  {
    key: "Primary NAICS Code Description",
    value: "naics_code_description",
  },
  {
    key: "Primary SIC Code",
    value: "sic_code",
  },
  {
    key: "Primary SIC Code Description",
    value: "sic_code_description",
  },
  {
    key: "Renewal Date",
    value: "renewal_date",
  },
  {
    key: "Square Footage",
    value: "square_footage",
  },
  {
    key: "Street Name",
    value: "location_street",
  },
  {
    key: "Year In Business",
    value: "year_in_business",
  },
  {
    key: "line type modified at",
    value: "line_type_modified_at",
  },
];


export const STATUS = {
  1: "Awaiting Confirmation",
  2: "Confirmed",
  3: "Left Message",
  4: "Rescheduled",
  5: "Cancelled",
  6: "Cancelled - Waiting to Rescheduled",
};

export const APPTYPE = {
  1: "In Person",
  2: "Phone Call",
};
export const APPTYPE_OBJ = [
  {
    id: 1,
    label: "In Person",
  },
  {
    id: 2,
    label: "Phone Call",
  },
];

export const STATUS_OBJ = [
  {
    id: 1,
    label: "Awaiting Confirmation",
  },
  {
    id: 2,
    label: "Confirmed",
  },
  {
    id: 3,
    label: "Left Message",
  },
  {
    id: 4,
    label: "Rescheduled",
  },
  {
    id: 5,
    label: "Cancelled",
  },
  {
    id: 6,
    label: "Cancelled - Waiting to Rescheduled",
  },
];

export const LEADS__KEYS = [
  {
    key: "Agency Calling For",
    value: "agency_calling_for",
  },
  {
    key: "Alternate Phone Number",
    value: "alternative_phone_number",
  },
  {
    key: "Auto Carrier",
    value: "auto_carrier",
  },
  {
    key: "Auto Renewal Date",
    value: "auto_renewal_date",
  },
  {
    key: "Call Result",
    value: "call_result",
  },
  {
    key: "Call Result Description",
    value: "call_result_description",
  },
  {
    key: "Carrier Of Record",
    value: "carrier_of_record",
  },
  {
    key: "Category",
    value: "category",
  },
  {
    key: "Comments",
    value: "comments",
  },
  {
    key: "Company Name",
    value: "company_name",
  },
  {
    key: "Company Website",
    value: "website",
  },
  {
    key: "County Description",
    value: "county",
  },
  {
    key: "Credit Rating",
    value: "credit_rating",
  },
  {
    key: "Date List Produced",
    value: "date_list_produced",
  },
  {
    key: "EIN 1",
    value: "ein",
  },
  {
    key: "Email",
    value: "email",
  },
  {
    key: "Executive First Name",
    value: "first_name",
  },
  {
    key: "Executive Last Name",
    value: "last_name",
  },
  {
    key: "Executive Title",
    value: "title",
  },
  {
    key: "Liability Carrier",
    value: "liability_carrier",
  },
  {
    key: "Liability Renewable Date",
    value: "liability_renewable_date",
  },
  {
    key: "Location Address",
    value: "address",
  },
  {
    key: "Location City",
    value: "city",
  },
  {
    key: "Location Employee Size Actual",
    value: "location_employee_size_actual",
  },
  {
    key: "Location State",
    value: "state",
  },
  {
    key: "Location ZIP Code",
    value: "zip_code",
  },
  {
    key: "Next Renewable Date",
    value: "next_renewable_date",
  },
  {
    key: "Phone Number Combined Unformatted",
    value: "phone_number",
  },
  {
    key: "Primary NAICS Code",
    value: "naics_code",
  },
  {
    key: "Primary NAICS Code Description",
    value: "naics_code_description",
  },
  {
    key: "Primary SIC Code",
    value: "sic_code",
  },
  {
    key: "Primary SIC Code Description",
    value: "sic_code_description",
  },
  {
    key: "Renewal Date",
    value: "renewal_date",
  },
  {
    key: "Square Footage",
    value: "square_footage",
  },
  {
    key: "Street Name",
    value: "location_street",
  },
  {
    key: "Total Locations",
    value: "total_locations",
  },
  {
    key: "Total Vehicles",
    value: "total_vehicles",
  },
  {
    key: "Workers Comp Carrier",
    value: "workers_comp_carrier",
  },
  {
    key: "Workers Comp Renewal Date",
    value: "workers_comp_renewal_date",
  },
  {
    key: "Year In Business",
    value: "year_in_business",
  },
  {
    key: "admin url",
    value: "admin_url",
  },
  {
    key: "client url",
    value: "client_url",
  },
  {
    key: "employee url",
    value: "employee_url",
  },
  {
    key: "nationwide lead",
    value: "nationwide_lead",
  },
];
