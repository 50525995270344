// LoginScreen.js
import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const PaymentDetail = (props) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F4F6FA",
      color: "#212223",
      fontWeight: "600",
      border: 0,
      padding: "15px 15px",
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(even)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    "td, th": {
      border: 0,
      padding: "12px 15px",
      color: "#6B7489!important",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
      padding: "12px 15px",
    },
    "&:first-child th": {
      width: "300px",
    },
  }));

  function createData(cardnumber, cvv, expirydate, status) {
    return { cardnumber, cvv, expirydate, status };
  }

  const rows = [
    createData("000000000", "000", "March 8, 2025,"),
    createData("000000000", "000", "March 8, 2025,"),
    createData("000000000", "000", "March 8, 2025,"),
    createData("000000000", "000", "March 8, 2025,"),
  ];

  return (
    <>
      <Box
        sx={{
          paddingLeft: "15px",
          paddingRight: "15px",
          marginTop: "25px",
          borderRadius: "10px!Important",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <Accordion defaultExpanded>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
                borderBottom:1, borderColor:'#676a6c4a'
            }}
            >
            <Typography
            component="h2"
            variant="h2"
            sx={{
                fontSize: 18,
                color: "#454647",
                fontWeight: 600,
            }}
            >Credit Card Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{
                    
                    padding:'20px 10px'
                    }}>
                    <Box>
                        <Grid container spacing={2}>
                           
                           
                            
                            <Grid item xs={12} sm={6} md={6}>                  
                            <FormControl   size="small" sx={{                                   
                                                                 width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },         
                                                                 "& .MuiOutlinedInput-notchedOutline" : {
                                                                    
                                                                    borderColor: "rgba(0, 0, 0, 0.23)"
                                                                  },

                                                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                  {
                                                                      borderColor: "#18a689",
                                                                  },
                                                                  
                                                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline" : {
                                                                    borderColor: "#18a689!important"
                                                                  },
                                                                           
                                                                        }}>
                                                                      <InputLabel id="demo-simple-select-label" InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#18a689",
                  },
                },
              }} sx={{                                
                                                                          fontSize: 15,
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689!important", 
                                                                            fontWeight:'600'                                           
                                                                        },
                                                                        }} >Agent</InputLabel>
                                                                      <Select
                                                                      labelId="sms-label"
                                                                      id="demo-simple-select"                                
                                                                      label="Agent"
                                                                      onChange={handleChange}
                                                                      size="small" 
                                                                      InputLabelProps={{
                                                                        sx: {
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689",
                                                                          },
                                                                        },
                                                                      }}                               
                                                                      >
                                                                      <MenuItem value={1} sx={{fontSize: 15,}}>Abbott, Kenneth</MenuItem>
                                    <MenuItem value={2} sx={{fontSize: 15,}}>Account, Training</MenuItem>
                                    <MenuItem value={3} sx={{fontSize: 15,}}>Acinapura, Dana</MenuItem> 
                                    <MenuItem value={4} sx={{fontSize: 15,}}>Akin, Larry</MenuItem>
                                    <MenuItem value={5} sx={{fontSize: 15,}}>Albert, Donnie</MenuItem>
                                    <MenuItem value={6} sx={{fontSize: 15,}}>Albert, Dan</MenuItem> 
                                    <MenuItem value={7} sx={{fontSize: 15,}}>Alfred, Mike</MenuItem>
                                    <MenuItem value={8} sx={{fontSize: 15,}}>Allen, Bill</MenuItem>                                                                                                    
                                                                      </Select>
                                                                  </FormControl>
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>                  
                                <TextField
                                    margin="0"                               
                                    fullWidth
                                    size="small"
                                    name="cardnumber"
                                    label="Credit Card Number"
                                    type="number"
                                    id="cardnumber"                              
                                    fontSize="15"           
                                    InputLabelProps={{
                                    sx: {fontSize:"15px",
                                        "&.Mui-focused": {
                                          color: "#18a689",                                            
                                        },}, }}
                                    sx={{
                                            marginBottom:'10px',
                                            width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: "#fff",
                                        },
                                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: "#18a689",
                                        },
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: "#18a689",
                                        },
                                        }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>                  
                                <TextField
                                    margin="0"                               
                                    fullWidth
                                    size="small"
                                    name="cvv"
                                    label="CVV Number"
                                    type="number"
                                    id="cvv"                              
                                    fontSize="15"           
                                    InputLabelProps={{
                                    sx: {fontSize:"15px",
                                        "&.Mui-focused": {
                                          color: "#18a689",                                            
                                        },}, }}
                                    sx={{
                                            marginBottom:'10px',
                                            width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: "#fff",
                                        },
                                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: "#18a689",
                                        },
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: "#18a689",
                                        },
                                        }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>                  
                            <FormControl   size="small" sx={{                                   
                                                                 width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },         
                                                                 "& .MuiOutlinedInput-notchedOutline" : {
                                                                    
                                                                    borderColor: "rgba(0, 0, 0, 0.23)"
                                                                  },

                                                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                  {
                                                                      borderColor: "#18a689",
                                                                  },
                                                                  
                                                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline" : {
                                                                    borderColor: "#18a689!important"
                                                                  },
                                                                           
                                                                        }}>
                                                                      <InputLabel id="demo-simple-select-label" InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#18a689",
                  },
                },
              }} sx={{                                
                                                                          fontSize: 15,
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689!important", 
                                                                            fontWeight:'600'                                           
                                                                        },
                                                                        }} >Expiry Month</InputLabel>
                                                                      <Select
                                                                      labelId="sms-label"
                                                                      id="demo-simple-select"                                
                                                                      label="Expiry Month"
                                                                      onChange={handleChange}
                                                                      size="small" 
                                                                      InputLabelProps={{
                                                                        sx: {
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689",
                                                                          },
                                                                        },
                                                                      }}                               
                                                                      >
                                                                      <MenuItem value={1} sx={{fontSize: 15,}}>1</MenuItem>
                                    <MenuItem value={2} sx={{fontSize: 15,}}>2</MenuItem>
                                    <MenuItem value={3} sx={{fontSize: 15,}}>3</MenuItem> 
                                    <MenuItem value={4} sx={{fontSize: 15,}}>4</MenuItem>
                                    <MenuItem value={5} sx={{fontSize: 15,}}>5</MenuItem>
                                    <MenuItem value={6} sx={{fontSize: 15,}}>6</MenuItem> 
                                    <MenuItem value={7} sx={{fontSize: 15,}}>7</MenuItem>
                                    <MenuItem value={8} sx={{fontSize: 15,}}>8</MenuItem> 
                                    <MenuItem value={9} sx={{fontSize: 15,}}>9</MenuItem>
                                    <MenuItem value={10} sx={{fontSize: 15,}}>10</MenuItem> 
                                    <MenuItem value={11} sx={{fontSize: 15,}}>11</MenuItem>
                                    <MenuItem value={12} sx={{fontSize: 15,}}>12</MenuItem>                                                                                                   
                                                                      </Select>
                                                                  </FormControl>
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>                  
                            <FormControl   size="small" sx={{                                   
                                                                 width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },         
                                                                 "& .MuiOutlinedInput-notchedOutline" : {
                                                                    
                                                                    borderColor: "rgba(0, 0, 0, 0.23)"
                                                                  },

                                                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                  {
                                                                      borderColor: "#18a689",
                                                                  },
                                                                  
                                                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline" : {
                                                                    borderColor: "#18a689!important"
                                                                  },
                                                                           
                                                                        }}>
                                                                      <InputLabel id="demo-simple-select-label" InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#18a689",
                  },
                },
              }} sx={{                                
                                                                          fontSize: 15,
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689!important", 
                                                                            fontWeight:'600'                                           
                                                                        },
                                                                        }} >Expiry Year</InputLabel>
                                                                      <Select
                                                                      labelId="sms-label"
                                                                      id="demo-simple-select"                                
                                                                      label="Expiry Year"
                                                                      onChange={handleChange}
                                                                      size="small" 
                                                                      InputLabelProps={{
                                                                        sx: {
                                                                          "&.Mui-focused": {
                                                                            color: "#18a689",
                                                                          },
                                                                        },
                                                                      }}                               
                                                                      >
                                                                      <MenuItem value={1} sx={{fontSize: 15,}}>2023</MenuItem>
                                    <MenuItem value={2} sx={{fontSize: 15,}}>2024</MenuItem>
                                    <MenuItem value={3} sx={{fontSize: 15,}}>2025</MenuItem> 
                                    <MenuItem value={4} sx={{fontSize: 15,}}>2026</MenuItem>
                                    <MenuItem value={5} sx={{fontSize: 15,}}>2027</MenuItem>
                                    <MenuItem value={6} sx={{fontSize: 15,}}>2028</MenuItem> 
                                    <MenuItem value={7} sx={{fontSize: 15,}}>2029</MenuItem>
                                    <MenuItem value={8} sx={{fontSize: 15,}}>2030</MenuItem> 
                                    <MenuItem value={9} sx={{fontSize: 15,}}>2031</MenuItem>
                                    <MenuItem value={10} sx={{fontSize: 15,}}>2032</MenuItem> 
                                    <MenuItem value={11} sx={{fontSize: 15,}}>2033</MenuItem>
                                    <MenuItem value={12} sx={{fontSize: 15,}}>2034</MenuItem>                                                                                                   
                                                                      </Select>
                                                                  </FormControl>
                            </Grid> 
                            
                           
                            <Grid item xs={12} sx={{ marginTop:"15px"}}> 
                            <Button
                                type="submit"                                
                                variant="contained"                                                        
                                sx={{                                    
                                    fontWeight: 500,
                                    textTransform:"capitalize",    
                                    pl: 4,
                                    pr: 4,                               
                                    backgroundColor: "#18a689",
                                    borderColor: "#18a689",
                                    color: "#FFFFFF",
                                    "&:hover": {
                                    // Customize the hover color
                                    backgroundColor: "#32c3a3",
                                    borderColor: "#32c3a3",
                                    },
                                }}>Submit
                            </Button>
                            <Button
                                type="submit"                                
                                variant="contained"                                                        
                                sx={{                                    
                                    fontWeight: 500,
                                    textTransform:"capitalize",    
                                    pl: 4,
                                    pr: 4,                               
                                    backgroundColor: "#ed5565",
                                    borderColor: "#ed5565",
                                    color: "#FFFFFF",
                                    marginLeft:'10px',
                                    "&:hover": {
                                    // Customize the hover color
                                    backgroundColor: "#ed5565",
                                    borderColor: "#ed5565",
                                    },
                                }}>Reset
                            </Button>
                            </Grid>
                                        
                        </Grid>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>  */}
            <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  paddingTop: "0!important",
                  borderRadius: "10px 10px 0 0!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
                >
                  Invoices
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Box sx={{ padding: "20px 0" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Card Number</StyledTableCell>
                          <StyledTableCell>CVV</StyledTableCell>
                          <StyledTableCell>Expiry Date</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.cardnumber}
                            </StyledTableCell>
                            <StyledTableCell>{row.cvv}</StyledTableCell>
                            <StyledTableCell>{row.expirydate}</StyledTableCell>
                            <StyledTableCell>{row.status}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
