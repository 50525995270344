import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

export const CallHistoryTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;

  const headCells = [
    {
      id: "campaign",
      numeric: false,
      disablePadding: true,
      label: "Campaign",
    },
    {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      label: "Company Name",
    },
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Username",
    },
    {
      id: "prospectFirstName",
      numeric: false,
      disablePadding: false,
      label: "Prospect First Name",
    },
    {
      id: "prospectLastName",
      numeric: false,
      disablePadding: false,
      label: "Prospect Last Name",
    },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Address",
    },
    {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
    {
      id: "county",
      numeric: false,
      disablePadding: false,
      label: "County",
    },
    {
      id: "zipCode",
      numeric: false,
      disablePadding: false,
      label: "Zip Code",
    },
    {
      id: "callTime",
      numeric: false,
      disablePadding: false,
      label: "Call Time",
    },
    {
      id: "callRecordings",
      numeric: false,
      disablePadding: false,
      label: "Call Recordings",
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              color: "#212223",
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: "#F4F6FA",
              whiteSpace: "nowrap",
              paddingLeft: "20px",
              "& .Mui-active": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
              "& .Mui-active .MuiTableSortLabel-icon": {
                color: "#45c9b1!important",
                fontWeight: "600!important",
              },
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CallHistoryTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
