import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProspectsParentTable } from "./ProspectsParentTable";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AddFilter } from "./AddFilter";
import { toast } from "react-hot-toast";
import {
  PROSPECTS__KEYS,
  convertOptToPayload,
  convertPayloadToOpt,
} from "../../helpers";
import { NewCampaignsAddData } from "../CampaignsComponent";
import { Loader, MassUpdate } from "../Common";
import { useSelector } from "react-redux";

export const ProspectsList = (props) => {
  const {
    prospectInfoData,
    onSearchProspects,
    isFromCampaign,
    counties,
    circles,
    createCampaign,
    isEdit,
    campaignData,
    onMassUpdate,
    onExport,
  } = props;

  const initialCampaignData = {
    callerId: isEdit ? campaignData?.twilio_cid : "",
    campaignName: isEdit ? campaignData?.name : "",
    agentId: isEdit ? campaignData?.agent : "",
  };

  const isMassUpdateLoading = useSelector(
    (state) => state.prospect?.isMassUpdateLoading
  );
  //campaign Data
  const [campaignInfo, setCampaignInfo] = useState(initialCampaignData);
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [isMassUpdateOpen, setIsMassUpdateOpen] = useState(false);

  const validJsonForCircle = useMemo(
    () => campaignData?.radius_array?.replace(/'/g, '"'),
    [campaignData?.radius_array]
  );

  const radiusArray = useMemo(
    () => (isEdit ? JSON.parse(validJsonForCircle) : []),
    [isEdit, validJsonForCircle]
  );

  const validJson = useMemo(
    () => campaignData?.county_array?.replace(/'/g, '"'),
    [campaignData?.county_array]
  );
  const countyArray = useMemo(
    () =>
      isEdit ? (campaignData?.county_array ? JSON.parse(validJson) : []) : [],
    [isEdit, validJson]
  );

  const [page, setPage] = useState(0);

  // Convert payload to filter array

  const [optionValues, setOptionValues] = useState([
    { operator: "", field: "", type: "", value: "" },
  ]);

  const initialPayload = isEdit && {
    operator_array:
      JSON.parse(campaignData.operator_array?.replace(/'/g, '"')) ?? [],
    fields_array:
      JSON.parse(campaignData.fields_array?.replace(/'/g, '"')) ?? [],
    types_array: JSON.parse(campaignData.types_array?.replace(/'/g, '"')) ?? [],
    values_array:
      JSON.parse(campaignData.values_array?.replace(/'/g, '"')) ?? [],
  };

  const fetchFilterPropsectForEdit = () => {
    setTimeout(() => {
      // if (counties.length > 0) {
      //   // onSearchProspects(pay, 1);
      // } else {
      //   console.log("payload====>", payload);
      //   // onSearchProspects(payload, 1);
      // }
    }, 5000);
  };

  useEffect(() => {
    if (isEdit) {
      const filterArray = convertPayloadToOpt(initialPayload);
      //console.log("filterArray====>", filterArray
      setOptionValues(filterArray);
      // fetchFilterPropsectForEdit();
      const payload = convertOptToPayload(filterArray);

      const pay = {
        ...payload,
        county_array: countyArray ?? [],
        radius_array: {
          $or: radiusArray.$or,
        },
        campaign_name: isEdit ? campaignData?.name ?? "" : "",
      };
      onSearchProspects(pay, 1);
    }
  }, [countyArray]);

  useEffect(() => {
    !isEdit && fetchAllProspects();
  }, []);

  const areAnyFieldsNotEmpty = (data) => {
    for (const option of data) {
      if (
        option.field !== "" ||
        option.operator !== "" ||
        option.type !== "" ||
        option.value !== ""
      ) {
        return true; // Return true if any field is not empty
      }
    }
    return false; // Return false if all fields are empty
  };

  const fetchAllProspects = async (offset = 1) => {
    let payload;
    payload = await convertOptToPayload(optionValues);
    const pay = {
      ...payload,
      county_array: countyArray ?? [],
      radius_array: {
        $or: radiusArray.$or,
      },
      campaign_name: isEdit ? campaignData?.name ?? "" : "",
    };
    onSearchProspects(pay, offset);
    // getAllProspects();
  };

  const callBackFunc = () => {
    fetchAllProspects();
  };

  // const STORAGE_KEY = "filterOptions";

  // Load filter options from localStorage on component mount
  // useEffect(() => {
  //   const savedOptions = localStorage.getItem(STORAGE_KEY);
  //   if (savedOptions) {
  //     setOptionValues(JSON.parse(savedOptions));
  //   }
  // }, []);

  // Save filter options to localStorage whenever they change
  // useEffect(() => {
  //   localStorage.setItem(STORAGE_KEY, JSON.stringify(optionValues));
  // }, [optionValues]);

  const onSearch = async () => {
    const payload = await convertOptToPayload(optionValues);
    const pay = {
      ...payload,
      county_array: counties ?? [],
      radius_array: {
        $or: circles,
      },
      campaign_name: isEdit ? campaignData?.name ?? "" : "",
    };

    // console.log("pay===>",pay);
    onSearchProspects(pay, 1);
  };

  const handleClearAll = async () => {
    if (areAnyFieldsNotEmpty(optionValues)) {
      setPage(0);
      setOptionValues([{ operator: "", field: "", type: "", value: "" }]);

      const fields_payload = {
        fields_array: [""],
        operator_array: [""],
        types_array: [""],
        values_array: [""],
      };
      const payload = {
        ...fields_payload,
        campaign_name: isEdit ? campaignData?.name ?? "" : "",
      };
      await onSearchProspects(payload, 1);
    }
  };

  const createData = (
    view,
    createLead,
    phoneNumber,
    altPhoneNumber,
    callResult,
    callResultDesc,
    execTitle,
    execFirstName,
    execLastName,
    companyName,
    companyWebsite,
    countyDesc,
    locationState,
    locationCity,
    locationZipCode,
    locationAddress,
    comments
  ) => {
    return {
      view,
      createLead,
      phoneNumber,
      altPhoneNumber,
      callResult,
      callResultDesc,
      execTitle,
      execFirstName,
      execLastName,
      companyName,
      companyWebsite,
      countyDesc,
      locationState,
      locationCity,
      locationZipCode,
      locationAddress,
      comments,
    };
  };

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        title,
        first_name,
        last_name,
        phone_number,
        call_result,
        call_result_description,
        alternative_phone_number,
        company_name,
        website,
        county,
        state,
        city,
        zip_code,
        address,
        comments,
      } = item;
      const user = item;

      return createData(
        user,
        user,
        phone_number,
        alternative_phone_number,
        call_result,
        call_result_description,
        title,
        first_name,
        last_name,
        company_name,
        website,
        county,
        state,
        city,
        zip_code,
        address,
        comments
      );
    });
  };

  const tableData = convertApiResponseToRows(prospectInfoData?.data);

  const isCheckFormValid = () => {
    const isCampaignNameValid = campaignInfo?.campaignName?.trim().length > 0;

    const isCallerIdValid = campaignInfo?.callerId?.trim().length > 0;

    const isAgentNameValid =
      campaignInfo?.agentId?.toString()?.trim().length > 0;

    return isCampaignNameValid && isCallerIdValid && isAgentNameValid;
  };

  const isCampaignValid = isCheckFormValid();
  const onSaveCampaign = async () => {
    setTriedSubmit(true);
    if (!isCampaignValid) return;
    const filter_payload = await convertOptToPayload(optionValues);
    const campaignPayload = {
      ...campaignInfo,
      ...filter_payload,
      county_array: counties ?? [],
      radius_array: {
        $or: circles,
      },
      isEdit: false,
      id: null,
    };
    //console.log("campaignPayload======>", campaignPayload);
    createCampaign(campaignPayload);
  };

  const onUpdateCampaign = async () => {
    setTriedSubmit(true);
    if (!isCampaignValid) return;

    const filter_payload = await convertOptToPayload(optionValues);

    if (isEdit) {
      const campaignPayload = {
        ...campaignInfo,
        ...filter_payload,
        county_array: counties ?? [],
        radius_array: {
          $or: circles,
        },
        isEdit: true,
        id: campaignData?.id ?? null,
      };
      //console.log("campaignPayload======>", campaignPayload);
      createCampaign(campaignPayload);
    }
  };

  const massUpdateCb = () => {
    callBackFunc();
    setIsMassUpdateOpen(false);
  };

  const onSubmitMassUpdate = async (fieldName, fieldValue) => {
    const optionsArray = await convertOptToPayload(optionValues);
    const payload = {
      ...optionsArray,
      mass_update: true,
      field_name: fieldName,
      field_value: fieldValue,
    };
    onMassUpdate(payload, massUpdateCb);
  };

  const onClickExport = async () => {
    const payload = await convertOptToPayload(optionValues);
    const exportPromise = onExport(payload);
    toast.promise(
      exportPromise,
      {
        loading: "Exporting...",
        success: (res) => {
          if (res?.message) {
            return [res?.message?.trim()];
          } else {
            return [
              "CSV generation successful. Download link sent to your email.",
            ];
          }
        },
        error: (error) => {
          if (error?.message && Array.isArray(error?.message)) {
            return error.message.map((errorMessage) => errorMessage.trim());
          } else if (error?.message) {
            return [error.message.trim()];
          } else {
            return ["Failed to export CSV file"];
          }
        },
      },
      {
        position: "bottom-center",
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 },color: "#212223", fontWeight: 600 }}
              >
                {isFromCampaign ? "Campaign Query Builder" : "Prospect Filter"}
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails>
            <AddFilter val={optionValues} setVal={setOptionValues} />
            {isFromCampaign && (
              <NewCampaignsAddData
                campaignInfo={campaignInfo}
                setCampaignInfo={setCampaignInfo}
                triedSubmit={triedSubmit}
              />
            )}
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                alignItems: "center",
                gap: "5px",
                mt: 1.5,
                mb: 1.5,
              }}
            >
              <Button
                variant="contained"
                onClick={onSearch}
                sx={{
                  fontSize: { xs: "13px", sm: "15px", md: "15px", lg: "15px" },
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search Prospects
              </Button>
              {isFromCampaign && isEdit && (
                <Button
                  variant="contained"
                  onClick={onUpdateCampaign}
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "capitalize",
                    width: "100%",
                    maxWidth: "max-content",
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Update Campaign
                </Button>
              )}

              {isFromCampaign && (
                <Button
                  variant="contained"
                  onClick={onSaveCampaign}
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "capitalize",
                    width: "100%",
                    maxWidth: "max-content",
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    color: "#212223",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  {isEdit ? "Save as" : "Save Campaign"}
                </Button>
              )}

              <Button
                variant="contained"
                onClick={handleClearAll}
                sx={{
                  fontSize: { xs: "13px", sm: "15px", md: "15px", lg: "15px" },
                  textTransform: "capitalize",
                  width: "100%",
                  fontWeight: 600,
                  maxWidth: "max-content",
                  backgroundColor: "#212223",
                  borderColor: "#212223",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#212223",
                    borderColor: "#212223",
                  },
                }}
              >
                Clear Query
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            boxShadow: 3,
            borderRadius: "10px!Important",
            marginTop: "25px!important",
          }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              borderBottom: 1,
              borderColor: "#676a6c4a",
              backgroundColor: "#d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                {isFromCampaign
                  ? "Filtered Prospects List | Total Prospects Count"
                  : " Listing Prospects | Total Count "}
                :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#58dfc6",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( {prospectInfoData?.total_prospects ?? "0"} )
                </Typography>{" "}
              </Typography>
            </AccordionSummary>

            {tableData?.length > 0 && areAnyFieldsNotEmpty(optionValues) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!isFromCampaign && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsMassUpdateOpen(true);
                    }}
                    sx={{
                      width: 130,
                      textTransform: "capitalize",
                      marginRight: 1,
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                      fontWeight: 600,
                      color: "#212223",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                        borderColor: "#6DD6C2",
                      },
                    }}
                  >
                    Mass Update
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={onClickExport}
                  sx={{
                    mr: 2,
                    height: "37px",
                    width: 100,
                    backgroundColor: "#fff",
                    borderColor: "#6DD6C2",
                    textTransform: "capitalize",
                    border: 2,
                    fontWeight: 600,
                    color: "#1ab394",
                    size: "small",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2!important",
                      color: "#212223",
                    },
                  }}
                >
                  Export
                  <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
                </Button>
              </Box>
            )}
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <ProspectsParentTable
                prospectsData={tableData}
                fetchMoreRecord={fetchAllProspects}
                page={page}
                setPage={setPage}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <MassUpdate
          open={isMassUpdateOpen}
          onSubmit={onSubmitMassUpdate}
          fieldOptions={PROSPECTS__KEYS}
          onClose={() => {
            setIsMassUpdateOpen(false);
          }}
        />
        <Loader loading={isMassUpdateLoading} />
      </Box>
    </>
  );
};
ProspectsList.defaultProps = {
  isFromCampaign: false,
  createCampaign: () => {},
  circles: [],
  counties: [],
  isEdit: false,
  campaignData: {},
};

// "agent_data": {
//   "id": 262,
//   "email": "rakin@akinandassociates.com",
//   "first_name": "Larry",
//   "last_name": "Akin",
//   "phone_number": "256-355-8500",
//   "cell_phone_number": "",
//   "time_zone": "US/Eastern",
//   "street": "468 Highway 67 S",
//   "address": null,
//   "city": "Decatur",
//   "state": "AL",
//   "zip_code": "35603",
//   "user_type": "Client",
//   "account_status": true
// }
