// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CampaignsParentTable } from "./CampaignsParentTable";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignListMoreData, updateCampaignKey } from "../../store/actions";



export const CampaignsList = (props) => {
  const [selectedFilter, setSelectedFilter] = useState("active");
  const [filterCampaignName, setFilterCampaignName] = useState("");
  const { getCampaignsFilter, onDeleteCampaign } = props;

  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(1);

  const dispatch = useDispatch();
  const campaignData = useSelector((state) => state?.campaign?.campaignList);
  const campaignList = useSelector((state) => state?.campaign)
  const userInfo = useSelector((state) => state?.loginAuth?.user_details);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const campaignNameSearch = useSelector((state) => state?.campaign?.campaignNameSearch?.results);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);


  const fetchCampaigns = (payload) => {
    getCampaignsFilter(payload);
  };
  const callBackFunc = () => {
    const payload = {
      filter: "active",
      campaign_name: ""
    };
    fetchCampaigns(payload);
  };
  const deleteCampaign = (campaignId) => {
    onDeleteCampaign(campaignId, callBackFunc);
  };
  useEffect(() => {
    const payload = {
      filter: "active",
      campaign_name: ""
    };
    fetchCampaigns(payload);
  }, []);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const [filteredRows, setFilteredRows] = React.useState([]);


  const onChangeCampiagnKey = (id, value, cb) => {
    dispatch(
      updateCampaignKey(id, value, () => {
        cb();
        callBackFunc();
      })
    );
  };

  const createData = (
    user,
    status,
    amountOwed,
    accountName,
    twilioCid,
    createdBy,
    createdAt,
    modifiedBy,
    modifiedAt,
    operations
  ) => {
    return {
      user,
      status,
      amountOwed,
      accountName,
      twilioCid,
      createdBy,
      createdAt,
      modifiedBy,
      modifiedAt,
      operations,
    };
  };


  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        is_active,
        total_amount_owned,
        account_name,
        twilio_cid,
        created_by,
        created_at,
        modified_by,
        modified_at,
      } = item;
      const user = item;
      return createData(
        user,
        is_active,
        total_amount_owned,
        account_name,
        twilio_cid,
        created_by,
        created_at,
        modified_by,
        modified_at,
        user
      );
    });
  };

  const tableRows = convertApiResponseToRows(campaignData);

  useEffect(() => {
    if (tableRows?.length > 0) {
      setFilteredRows(tableRows);
    }
  }, []);


  const searchCampaignsName = async (searchTerm) => {
    try {

      await props.onNameSearchCampaign(searchTerm);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // Trigger API call when debouncedSearchTerm changes
    if (debouncedSearchTerm) {

      searchCampaignsName(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (event, value) => {
    if (value) {
      setSelectedCampaign(value);
      setFilterCampaignName(value.name);
    } else {
      setSelectedCampaign("");
      setFilterCampaignName(event.target.value);
    }

    // Update debouncedSearchTerm after 500ms of inactivity
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      setDebouncedSearchTerm(event.target.value);
    }, 700);
    setTimeoutId(newTimeoutId);
  };

  const getFilteredRecord = (event) => {
    event.preventDefault();
    const payload = {
      filter: selectedFilter,
      campaign_name: filterCampaignName
    };
    fetchCampaigns(payload);
    setFilterCampaignName(" ");
    setSelectedCampaign("");
    setDebouncedSearchTerm("");

  };

  const fetchMoreRecord = (offsetVal) => {
    const payload = {
      filter: selectedFilter,
      campaign_name: filterCampaignName
    };
    getCampaignsFilter(payload, offsetVal);
    setFilterCampaignName(" ");
    // console.log("offset==>",offsetVal);
  };
  const fetchMoreCampaingnData = () => {
    if (campaignList && campaignList.next != null) {
      dispatch(getCampaignListMoreData())
    }
  };


  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          boxShadow: 1,
          borderRadius: "15px!Important",
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Listing Campaigns
              </Typography>
            </AccordionSummary>

          </Box>

          <Box
            sx={{
              border: '1px solid #ddd',
              marginX: { xs: "15px", sm: "27px", md: '27px' },
              paddingY: { xs: "15px", sm: "20px", md: '25px' },
              marginTop: { xs: "15px", sm: "25px", md: '30px' },
              borderRadius: "7px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-around",
                width: "100%",
                flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens

                gap: { xs: "10px", sm: "10px", md: '20px' },
                paddingX: { xs: "12px", sm: "20px", md: '20px' },
              }}
            >
              <Box sx={{
                maxWidth: { xs: '240px', sm: '240px', md: '280px', lg: '270px', xl: '350px' }, // full width on small screens
                width: '100%'
              }}>
                {userInfo?.user_type === "Administration" && (
                  <Box>
                    <FormControl
                      size="small"
                      sx={{
                        maxWidth: { xs: '240px', sm: '240px', md: '280px', lg: '270px', xl: '350px' }, // full width on small screens
                        width: '100%',
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          fontSize: 15,
                          "&.Mui-focused": {
                            color: "#6DD6C2!important",
                            fontWeight: "600",
                            margin: "0",
                          },
                        }}
                      >
                        Filter Active/Inactive{" "}
                      </InputLabel>
                      <Select
                        sx={{ backgroundColor: "#fff!important" }}
                        id="selectinfo"
                        label="Filter Active/Inactive"
                        onChange={handleChange}
                        size="small"
                      >
                        <MenuItem value={"active"} sx={{ fontSize: 15 }}>
                          Active
                        </MenuItem>
                        <MenuItem value={"inactive"} sx={{ fontSize: 15 }}>
                          Inactive
                        </MenuItem>
                        <MenuItem value={"all"} sx={{ fontSize: 15 }}>
                          All
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>

              <Box sx={{
                maxWidth: { xs: '240px', sm: '240px', md: '280px', lg: '270px', xl: '350px' }, // full width on small screens
                width: '100%'
              }}>


                <Box sx={{}}>
                  <Autocomplete
                    options={campaignNameSearch || []}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleSearchChange}
                    value={selectedCampaign}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="0"

                        size="small"
                        name="searchcampaigns "
                        label="Search"
                        value={filterCampaignName}
                        type="text"
                        id="searchcampaigns"
                        onChange={handleSearchChange}
                        fontSize="15"
                        InputLabelProps={{
                          sx: {
                            fontSize: "15px",
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{

                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#6DD6C2",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Button
                type="submit"
                variant="contained"
                onClick={getFilteredRecord}
                sx={{
                  fontWeight: 600,
                  width: "max-content",
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  // adjusted margin top for small screens
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "5px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
              }}
            >
              <CampaignsParentTable
                onDelete={deleteCampaign}
                filteredRows={
                  (!filterCampaignName || filterCampaignName.trim().length === 0 || selectedCampaign)
                    ? tableRows
                    : filteredRows
                }
                onChangeCampiagnKey={onChangeCampiagnKey}
                getCampaignsFilter={getCampaignsFilter}
                page={page}
                setPage={setPage}
                offset={offset}
                setOffset={setOffset}
                fetchMoreRecord={fetchMoreRecord}
                fetchMoreCampaingnData={fetchMoreCampaingnData}
                campaignList={campaignList}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
