import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Skeleton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Modal,
  TextField,
} from "@mui/material";
import { Loader, ProfileModal, TwilioVoice, UpdateTooltip } from "../Common";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppointmentsTableHead } from "./AppointmentsTableHead";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  APPTYPE,
  APPTYPE_OBJ,
  STATUS,
  STATUS_OBJ,
  checkAndReturnNone,
  formatPhoneNumber,
} from "../../helpers";
import { useState } from "react";
import { calendarSearch, clearEvents } from "../../store/actions";

export const AppointmentsTable = (props) => {
  const {
    rows,
    filterName,
    handleNameFilterChange,
    filterActive,
    handleActiveFilterChange,
    onDelete,
    onChangeAppointmentKey,
    onAppointmentStatus,
    onChangeLeadKey,
  } = props;
  const { format } = require("date-fns");

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [open1, set1Open] = React.useState({
    id: null,
    value: null,
    isOpen: false,
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.operations?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDelete = () => {
    selectedUserID && onDelete(selectedUserID);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [data, setData] = useState("");
  const [tooltipID, setTooltipID] = useState(null);

  const handleTooltipClose = () => {
    setTooltipID(null);
  };

  const handleTooltipOpen = (id, type) => {
    setTooltipID({ id: id, type: type });
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedUserID(row?.view?.id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelected([]);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    setOpenDeleteDialog(false);
  };

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: 3,
    borderColor: "#6DD6C2",
    p: 3,
    borderRadius: "7px",
    "&:focus-visible": {
      outline: "0",
    },
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const findLeadAppStatusByid = (statusId) => {
    return STATUS_OBJ.find((status) => status.id == statusId) || null;
  };

  const findLeadAppTypeByid = (appId) => {
    return APPTYPE_OBJ.find((app) => app.id == appId) || null;
  };

  const isSelected = (row) => {
    if (!row) {
      return false;
    }
    return selected.includes(row?.operations?.id);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const convertDateIntoTime = (dateTime) => {
    const date = new Date(dateTime);

    const localTimeString = date?.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC", // Specify the time zone of the input string (UTC in this case)
    });
    return localTimeString ?? "None";
  };

  const handleShowDetails = (prospects) => {
    // Handle showing employee details in modal
    setIsShow(true);

    // Pass the employee details to the modal component
  };

  let loading = useSelector((state) => state?.appointment?.isLoading);
  let isCalendarLoading = useSelector(
    (state) => state?.appointment?.isCalendarLoading
  );

  function getOptionName(input, key) {
    if (key === "status") {
      const optionName = STATUS[input];
      return optionName || "none";
    } else {
      const optionName = APPTYPE[input];
      return optionName || "none";
    }
  }

  const openNewLeadTab = (id) => {
    const dataToSend = {
      isNewTab: true,
      agent_id: id,
    };

    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabStateForCalendar", jsonData);

    // Open a new tab
    const newTab = window.open("/Calendar", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getCalenderData = (data) => {
    dispatch(clearEvents());
    dispatch(
      calendarSearch(data?.agent, () => {
        openNewLeadTab(data?.agent);
      })
    );
  };

  const handle1ClickOpen = (id, value) => {
    set1Open({ id: id, value: value, isOpen: true });
  };

  const handle1Close = () => {
    setErrors({});
    set1Open({ value: null, isOpen: false });
  };

  const [formData, setFormData] = useState({
    appointmentDate: "",
    confirmAppointmentDate: "",
    appointmentTime: "",
    confirmAppointmentTime: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFormSubmit = () => {
    // Validation logic
    const newErrors = {};

    if (!formData.appointmentDate) {
      newErrors.appointmentDate = "Appointment Date is required";
    }

    if (!formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate = "Confirm Appointment Date is required";
    }

    if (!formData.appointmentTime) {
      newErrors.appointmentTime = "Appointment Time is required";
    }

    if (!formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime = "Confirm Appointment Time is required";
    }

    if (formData.appointmentDate !== formData.confirmAppointmentDate) {
      newErrors.confirmAppointmentDate =
        "Confirm Appointment date and Appointment date is not same";
    }

    if (formData.appointmentTime !== formData.confirmAppointmentTime) {
      newErrors.confirmAppointmentTime =
        "Confirm Appointment time and Appointment time is not same";
    }

    // Add additional validation logic, e.g., date/time matching

    if (Object.keys(newErrors).length > 0) {
      // There are errors, don't submit the form
      setErrors(newErrors);
    } else {
      // Form is valid, submit the form or perform further actions
      // For now, just close the modal
      const dateObject = new Date(formData.confirmAppointmentDate);

      // Format the date to the desired format
      const formattedDate = format(dateObject, "MM-dd-yyyy");

      const payload = {
        appointment_id: open1.id,
        appointment_date: formattedDate,
        appointment_time: formData.confirmAppointmentTime,
        lead_status: 6,
      };
      onAppointmentStatus(payload, () => {
        if (open1?.value === "app_rescheduled") {
          onChangeAppointmentKey(open1.id, "status", 4, () => {
            setErrors({});
            handle1Close();
          });
        } else {
          setErrors({});
          handle1Close();
        }
      });

    }
  };

  const keyCss = {
    fontSize: 13,
    color: "#0088cc",
    fontWeight: 500,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#0088cc",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    maxWidth: "fit-content",
    position: "relative",
    cursor: "pointer",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            id="appointmentListTable"
            aria-labelledby="tableTitle"
            size="medium"
          >
            <AppointmentsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
            />
            <TableBody>
              <>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={30}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={30}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={30}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={30}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={38}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {rows?.length <= 0 ? (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          left: "44%",
                          top: "50%",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h2"
                          sx={{
                            fontSize: 18,
                            color: "#454647",
                            fontWeight: 600,
                          }}
                        >
                          No Record Found!
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                sx={{
                                  backgroundColor:
                                    index % 2 == 1 ? "#fff" : "#fff",
                                  borderBottom: "0!important",
                                  "&:hover": {
                                    backgroundColor: "#F4F6FA!important",
                                  },
                                }}
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.firstName}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  padding="checkbox"
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      minWidth: "30px",
                                      pl: 1.5,
                                      pr: 1.5,
                                      pt: 1.5,
                                      pb: 1.5,
                                      marginRight: 2,
                                      backgroundColor: "#6B7489",
                                      "&:hover": {
                                        backgroundColor: "#6B7489",
                                      },
                                      borderColor: "#6B7489",
                                      color: "#fff",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      // Prevent event propagation to TableRow
                                      // navigate("/NewCampaigns", {
                                      //   state: {
                                      //     isEdit: true,
                                      //     campaignData: row?.user,
                                      //   },
                                      // });
                                      navigate("/ShowLead", {
                                        state: {
                                          isEdit: false,
                                          leadData: {
                                            id: row?.operations?.lead,
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    <RemoveRedEyeIcon
                                      sx={{ fontSize: "19px" }}
                                    />
                                  </Button>
                                  {userInfo?.user_type === "Administration" && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        width: "20px",
                                        height: "20px",
                                        minWidth: "30px",
                                        pl: 1.5,
                                        pr: 1.5,
                                        pt: 1.5,
                                        pb: 1.5,
                                        backgroundColor: "#6B7489",
                                        "&:hover": {
                                          backgroundColor: "#6B7489",
                                        },
                                        borderColor: "#6B7489",
                                        color: "#fff",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // Prevent event propagation to TableRow
                                        // navigate("/NewCampaigns", {
                                        //   state: {
                                        //     isEdit: true,
                                        //     campaignData: row?.user,
                                        //   },
                                        // });
                                        // handleDelete(row);
                                        handleOpenDeleteDialog(row);
                                      }}
                                      className="show-button" // Add a class to identify the "Show" button
                                    >
                                      <DeleteIcon sx={{ fontSize: "19px" }} />
                                    </Button>
                                  )}
                                </TableCell>
                                {userInfo?.user_type !== "Client" && (
                                  <TableCell
                                    sx={{
                                      color: "#6B7489",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      borderBottom: "0",
                                      whiteSpace: "nowrap",
                                      paddingLeft: "15px",
                                    }}
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    <Button
                                      sx={{
                                        "&:hover": {
                                          color: "#0088cc",
                                        },
                                        height: "20px",
                                        pl: 0,
                                        pr: 1.5,
                                        pt: 1.5,
                                        pb: 1.5,
                                        color: "#0088cc",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation to TableRow
                                        // navigate("/Calendar", {
                                        //   state: {
                                        //     isViewCalendar: true,
                                        //     data: row?.operations,
                                        //   },
                                        // });
                                        getCalenderData(row?.operations);
                                      }}
                                    >
                                      View Calendar
                                    </Button>
                                  </TableCell>
                                )}

                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID?.id === row.operations.id &&
                                      tooltipID?.type === "status"
                                        ? true
                                        : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Status"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey(
                                          //   row.operations.id,
                                          //   "category",
                                          //   value,
                                          //   handleTooltipClose
                                          // );
                                          if (
                                            value ===
                                            findLeadAppStatusByid(row?.status)
                                              ?.label
                                          ) {
                                            handleTooltipClose();
                                          } else {
                                            if (value == 4) {
                                              handleTooltipClose();
                                              handle1ClickOpen(
                                                row.operations.id,
                                                "app_rescheduled"
                                              );
                                            } else {
                                              onChangeAppointmentKey(
                                                row.operations.id,
                                                "status",
                                                value,
                                                () => {
                                                  handleTooltipClose();
                                                }
                                              );
                                            }
                                          }
                                        }}
                                        selectedKey={
                                          findLeadAppStatusByid(row?.status)
                                            ?.label
                                        }
                                        inputType={"SelectOptions"}
                                        labelKey={"label"}
                                        optionsData={STATUS_OBJ}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = getOptionName(
                                          row.status,
                                          "status"
                                        );
                                        setData(val);
                                        handleTooltipOpen(
                                          row.operations.id,
                                          "status"
                                        );
                                      }}
                                      sx={keyCss}
                                    >
                                      {getOptionName(row.status, "status")}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.fullName ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.companyName ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {}
                                  {formatPhoneNumber(row.phoneNumber) ??
                                    false ??
                                    "None"}

                                  {row?.phoneNumber &&
                                    userInfo?.user_type !== "Client" && (
                                      <TwilioVoice
                                        phoneNumber={row?.phoneNumber}
                                        fontColor={"#6B7489"}
                                        fontSize={13}
                                      />
                                    )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {/* {row.isActive ? "Active" : "Inactive"} */}
                                  {row.agent ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.agencyName ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.accountName ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.campaign ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  <Button
                                    sx={{
                                      "&:hover": {
                                        color: "#0088cc",
                                      },
                                      height: "20px",
                                      pl: 0,
                                      pr: 1.5,
                                      pt: 1.5,
                                      pb: 1.5,
                                      color: "#0088cc",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                      // handleTooltipOpen(
                                      //   row?.operations?.id
                                      // );

                                      handle1ClickOpen(
                                        row?.operations?.id,
                                        null
                                      );
                                    }}
                                  >
                                    {format(
                                      new Date(row.appointmentDate),
                                      "MMMM d, yyyy"
                                    ) ?? row.appointmentDate}
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  <Button
                                    sx={{
                                      "&:hover": {
                                        color: "#0088cc",
                                      },
                                      height: "20px",
                                      pl: 0,
                                      pr: 1.5,
                                      pt: 1.5,
                                      pb: 1.5,
                                      color: "#0088cc",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent event propagation to TableRow
                                      // handleTooltipOpen(
                                      //   row?.operations?.id
                                      // );

                                      handle1ClickOpen(
                                        row?.operations?.id,
                                        null
                                      );
                                    }}
                                  >
                                    {convertDateIntoTime(row.appointmentTime)}
                                  </Button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID?.id === row.operations.id &&
                                      tooltipID?.type === "type"
                                        ? true
                                        : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Appointment Type"}
                                        onSubmit={(value) => {
                                          if (value == row?.appointmentType) {
                                            handleTooltipClose();
                                          } else {
                                            onChangeAppointmentKey(
                                              row?.operations?.id,
                                              "appointment_type",
                                              value,
                                              () => {
                                                handleTooltipClose();
                                              }
                                            );
                                          }
                                        }}
                                        selectedKey={
                                          findLeadAppTypeByid(
                                            row?.appointmentType
                                          )?.label
                                        }
                                        inputType={"SelectOptions"}
                                        labelKey={"label"}
                                        optionsData={APPTYPE_OBJ}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          row.appointmentType
                                        );
                                        setData(val);
                                        handleTooltipOpen(
                                          row.operations.id,
                                          "type"
                                        );
                                      }}
                                      sx={keyCss}
                                    >
                                      {checkAndReturnNone(
                                        getOptionName(
                                          row.appointmentType,
                                          "type"
                                        )
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.createdBy ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.modifiedBy ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {format(
                                    new Date(row.modifiedDateTime),
                                    "MMMM d, yyyy, h:mm a"
                                  ) ?? row.modifiedDateTime}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.alternatePhoneNumber ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.address ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.city ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.state ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  {row.zipCode ?? "None"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#6B7489",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    borderBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  <Tooltip
                                    sx={{
                                      marginTop: "0!important",
                                      " & .MuiTooltip-tooltip": {
                                        marginTop: "0!important",
                                      },
                                      backgroundColor: "#ffffff",
                                      borderRadius: "5px",
                                      borderColor: "black",
                                    }}
                                    arrow
                                    placement="bottom-start"
                                    PopperProps={{
                                      disablePortal: false,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={
                                      tooltipID?.id === row.operations.id &&
                                      tooltipID?.type === "comment"
                                        ? true
                                        : false
                                    }
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <UpdateTooltip
                                        onClose={handleTooltipClose}
                                        label={"Comments"}
                                        onSubmit={(value) => {
                                          // onChangeLeadKey("comments", value);
                                          onChangeAppointmentKey(
                                            row.operations.id,
                                            "comment",
                                            value,
                                            () => {
                                              handleTooltipClose();
                                            }
                                          );
                                        }}
                                        inputType={"Text"}
                                        value={data}
                                        setValue={setData}
                                      />
                                    }
                                  >
                                    <Typography
                                      component="h4"
                                      variant="h4"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const val = checkAndReturnNone(
                                          row.comment
                                        );
                                        setData(val);
                                        handleTooltipOpen(
                                          row.operations.id,
                                          "comment"
                                        );
                                      }}
                                      sx={keyCss}
                                    >
                                      {checkAndReturnNone(row.comment)}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    )}
                  </>
                )}

                {emptyRows > 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={30} />
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#F4F6FA!important" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ProfileModal
          open={isShow}
          onClose={() => {
            setIsShow(false);
          }}
        />
      </Paper>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Appointment</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the Appointment?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              backgroundColor: "#6DD6C2",
              borderColor: "#6DD6C2",
              color: "#212223",
              "&:hover": {
                // Customize the hover color
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
              },
            }}
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              width: "100%",
              maxWidth: "max-content",
              backgroundColor: "#212223",
              borderColor: "#212223",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#212223",
                borderColor: "#212223",
              },
            }}
            onClick={handleConfirmDelete}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Loader loading={isCalendarLoading} />
      <Modal
        open={open1.isOpen}
        onClose={handle1Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 18,
              color: "#212223",
              fontWeight: 600,
              backgroundColor: "#90949f21",
              pt: 1.5,
              pl: 1.5,
              pb: 1.5,
              mb: 2.5,
            }}
          >
            Rescheduled Date Time
          </Typography>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Appointment Date
            </Typography>
            <Box sx={{ marginTop: "10px", gap: "10px" }}>
              <Box>
                <TextField
                  name="appointmentDate"
                  type="date"
                  size="small"
                  value={formData.appointmentDate}
                  onChange={(e) =>
                    handleInputChange("appointmentDate", e.target.value)
                  }
                  error={!!errors.appointmentDate}
                  helperText={errors.appointmentDate}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Confirm Appointment Date
            </Typography>
            <Box sx={{ marginTop: "10px", gap: "10px" }}>
              <Box>
                <TextField
                  name="confirmAppointmentDate"
                  type="date"
                  size="small"
                  value={formData.confirmAppointmentDate}
                  onChange={(e) =>
                    handleInputChange("confirmAppointmentDate", e.target.value)
                  }
                  error={!!errors.confirmAppointmentDate}
                  helperText={errors.confirmAppointmentDate}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 15,
                color: "#6B7489                        ",
                fontWeight: 600,
                marginBottom: "0px",
                marginTop: "15px",
              }}
            >
              Appointment Time :
            </Typography>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <TextField
                  ampm={true}
                  focused
                  type="time"
                  label="Time"
                  name="appointmentTime"
                  size="small"
                  value={formData.appointmentTime}
                  onChange={(e) =>
                    handleInputChange("appointmentTime", e.target.value)
                  }
                  error={!!errors.appointmentTime}
                  helperText={errors.appointmentTime}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },
                    width: "100%",
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  name="confirmAppointmentTime"
                  type="time"
                  size="small"
                  value={formData.confirmAppointmentTime}
                  onChange={(e) =>
                    handleInputChange("confirmAppointmentTime", e.target.value)
                  }
                  error={!!errors.confirmAppointmentTime}
                  helperText={errors.confirmAppointmentTime}
                  label="Confirm Time"
                  focused
                  ampm={true}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2!important",
                      },

                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: 1,
              borderColor: "#dcdcdc",
              mt: 3.5,
            }}
          >
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              sx={{
                mt: 2.5,
                mr: 2,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                color: "#212223",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Submit
            </Button>
            <Button
              onClick={handle1Close}
              variant="contained"
              sx={{
                mt: 2.5,
                textTransform: "capitalize",
                width: "100%",
                maxWidth: "max-content",
                backgroundColor: "#000",
                borderColor: "#6DD6C2",
                color: "#fff",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#000",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
